import {
  styled,
  TypographyProps,
  Typography,
  Box,
  BoxProps,
  ButtonProps,
} from "@mui/material";
import { SolidButton } from "Components/Buttons";
import { theme } from "ui/theme/theme";

export const TextNormal = styled(Typography)<TypographyProps>(() => ({
  color: theme.colors.white,
  fontWeight: 500,
  fontSize: "1.25rem",
  lineHeight: "120%",
  paddingTop: "16px",
  textAlign: "center",
  paddingBottom: "32px",
}));

export const MainHead = styled(Typography)<TypographyProps>(() => ({
  color: theme.colors.white,
  fontWeight: 600,
  fontSize: "2rem",
  lineHeight: "38.4px",
  marginTop: "18px",
}));

export const MainSection = styled(Box)<BoxProps>(() => ({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  marginBottom: "224px",
}));

export const HeadSection = styled(Box)<BoxProps>(() => ({
  justifyContent: "end",
  alignItems: "center",
  display: "flex",
  paddingTop: "129px",
  paddingBottom: "129px",
  marginRight: "120px",
}));

export const MainContainer = styled(Box)<BoxProps>(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "44.125rem",
  backgroundColor: theme.colors.black,
  borderRadius: "20px 0px 0px 20px",
  height: "auto",
  color: "white",
  justifyContent: "space-between",
  alignItems: "center",
  "@media (max-width: 450px)": {
    width: "23.438rem",
    padding: "28px",
    height: "812px",
  },
}));

export const SolidWhiteButton = styled(SolidButton)<ButtonProps>(() => ({
  height: 50,
  marginLeft: "10px",
  marginTop: "10px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
  },
}));
