import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";

import check from "assets/check.svg";

export const Accordion = styled(MuiAccordion)<AccordionProps>(({ theme }) => ({
  border: `none`,
  "&:before": {
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    backgroundColor: theme.colors.black,
  },
}));

export const AccordionSummary = styled(
  MuiAccordionSummary
)<AccordionSummaryProps>(({ theme }) => ({
  backgroundColor: "rgba(2, 9, 21, .08)",
  borderRadius: "5px",
  "&.Mui-expanded": {
    borderRadius: "5px 5px 0 0",
  },
  [theme.breakpoints.down("md")]: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: theme.colors.white,
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "rgba(2, 9, 21, .03)",
  borderRadius: "0 0 5px 5px",
  padding: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

export const TextField = styled(MuiTextField)<TextFieldProps>(({ theme }) => ({
  background: theme.colors.white,
  borderRadius: 54,
  borderWidth: 0,
  position: "relative",
  height: 28,
  "&:focus": {
    outline: "none",
  },
  "& .MuiOutlinedInput-root": {
    border: "none",
    color: theme.colors.black,
    height: 28,
    fontSize: "11px",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  [theme.breakpoints.down("md")]: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    "& .MuiOutlinedInput-root": {
      color: theme.colors.white,
    },
  },
}));

export const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 18,
  height: 18,
  backgroundColor: theme.colors.teal,
}));

export const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.colors.teal,
  "&:before": {
    display: "block",
    width: 18,
    height: 18,
    backgroundImage: `url(${check})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    content: '""',
  },
}));

export const FormLabel = styled(FormControlLabel)(({ theme }) => ({
  "&.MuiFormControlLabel-root": {
    marginLeft: "-6px",
    marginRight: 0,
  },
  "& .MuiFormControlLabel-label": {
    color: theme.colors.black,
    fontSize: 14,
    flexGrow: 1,
    [theme.breakpoints.down("md")]: {
      color: theme.colors.white,
    },
  },
  "& .MuiCheckbox-root": {
    padding: "6.5px",
  },
}));
