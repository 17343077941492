import styled from "@emotion/styled";
import { SystemColorKey } from "ui/theme/models/Colors";
import { DefaultThemeColorKey } from "ui/theme/models/DefaultThemeColors";
import { theme } from "ui/theme/theme";

export enum StyledHistoryItemTypeTagColor {
  Teal = SystemColorKey.Teal,
  Navy = SystemColorKey.Navy,
  Blue = SystemColorKey.Blue,
  DarkWhite = SystemColorKey.DarkWhite,
  Lilac = SystemColorKey.Lilac,
  Grey = SystemColorKey.Grey,
  White = SystemColorKey.White,
  Black = SystemColorKey.DarkWhite,
}

type StyledHistoryItemTypeTagProps = {
  $color?: StyledHistoryItemTypeTagColor;
};

export const StyledHistoryItemTypeTag = styled.div<StyledHistoryItemTypeTagProps>`
  min-width: 64px;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  gap: 3px;

  border-radius: 40px;
  font-weight: 400;
  font-size: 9px;
  line-height: 120%;

  > svg {
    width: 12px;
    height: 12px;
  }
  background-color: ${({ $color }) => {
    if ($color) {
      return theme.colors[
        ($color as unknown as DefaultThemeColorKey | undefined) ||
          SystemColorKey.Black
      ];
    }

    return theme.colors[SystemColorKey.Black];
  }};
  color: ${({ $color }) => {
    if ($color === StyledHistoryItemTypeTagColor.Navy) {
      return theme.colors.white;
    }

    return theme.colors.black;
  }};
`;
