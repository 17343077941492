import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { CollectionActivityRequestConfigOrdering } from "api/models";
import { Sort } from "assets/icons/Sort";
import { theme } from "ui/theme/theme";
import { GreyButton, IconContainer } from "../styles";
import { StyledMenu } from "./styles";

interface ActivityOrderOption {
  name: string;
  value: CollectionActivityRequestConfigOrdering;
}

interface ActivityOrderButtonProps {
  onSetOrder: (value: CollectionActivityRequestConfigOrdering) => void;
}

export const activityOrderOptions: ActivityOrderOption[] = [
  {
    name: "Latest to oldest",
    value: "latest_action",
  },
  {
    name: "Oldest to latest",
    value: "oldest_action",
  },
];

function ActivityOrderButton({ onSetOrder }: ActivityOrderButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [state, setState] =
    useState<CollectionActivityRequestConfigOrdering>("latest_action");
  const showLabel = activityOrderOptions.find(
    ({ value }) => value === state
  )?.name;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSetState = (value: CollectionActivityRequestConfigOrdering) => {
    setState(value);
    onSetOrder(value);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <GreyButton width="152px" onClick={handleClick}>
        <IconContainer marginRight="5px">
          <Sort height={18} width={18} color={theme.colors.white} />
        </IconContainer>
        {showLabel}
      </GreyButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {activityOrderOptions.map(({ name, value }) => (
          <MenuItem key={value} onClick={() => handleSetState(value)}>
            {name}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}

export default ActivityOrderButton;
