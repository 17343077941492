import { useState } from "react";

import Box from "@mui/material/Box";
import { AllCollection } from "assets/icons/AllCollection";
import { theme } from "ui/theme/theme";

import * as S from "./styles";
import * as Icons from "assets/icons";

import { CollectionCard } from "Components/Cards/CollectionCard";
import { CircularProgress, Grid } from "@mui/material";
import { Section } from "Components/Section";
import { SectionHeader } from "Components/Section/SectionHeader";
import { useCollections, useHomeLabels } from "api/hooks";
import { RequestConfig, RequestConfigOrdering } from "api";
import { FilterMenu } from "Components/List/Control/Filter";

const LIMIT_TO_SHOW = 20;

const AllCollections = () => {
  const [limit] = useState(LIMIT_TO_SHOW);
  const [requestConfig, setRequestConfig] = useState({} as RequestConfig);

  const collections = useCollections(requestConfig);
  const labels = useHomeLabels();

  const updateFilters = (options: Array<string>) => {
    const config: RequestConfig = {
      ...requestConfig,
      has_labels: options.join(","),
    };
    setRequestConfig(config);
  };

  const updateLimit = (filters: Array<string>) => {
    setRequestConfig({
      ...requestConfig,
      limit: Number(filters[0]),
    })
  }

  const updateSort = (filters: Array<string>) => {
    setRequestConfig({
      ...requestConfig,
      order_by: filters[0] as RequestConfigOrdering,
    })
  }

  const artStyle = labels?.data?.find(
    (label) => label.label_type === "art_style"
  );

  const category = labels?.data?.find(
    (label) => label.label_type === "category"
  );

  return (
    <Section id="all-collections">
      <S.SectionHeaderContainer>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <SectionHeader
            Icon={
              <AllCollection
                height="35.51"
                width="35.45"
                color={theme.colors.white}
              />
            }
            title="Explore all collections"
          />
          <S.Filters>
            {artStyle ? (
              <FilterMenu
                startIcon={<Icons.ArtStyle color="inherit" />}
                onChange={updateFilters}
                label="Art style"
                multiple
                options={artStyle.label_values}
              />
            ) : null}
            {category ? (
              <FilterMenu
                startIcon={<Icons.Category color="inherit" />}
                onChange={updateFilters}
                label="Category"
                options={category.label_values}
                allowAll
              />
            ) : null}
            <FilterMenu
              startIcon={<Icons.Sort color="inherit" />}
              onChange={updateSort}
              options={["recently_added", "floor_low", "floor_high"]}
            />
            <FilterMenu
              endIcon={<Icons.DropdownIcon color="inherit" />}
              onChange={updateLimit}
              options={["20", "40", "60", "80", "100"]}
              prefix="Show "
            />
          </S.Filters>
        </Box>
      </S.SectionHeaderContainer>
      {collections.isError ? (<Icons.PageNotFoundAvatar style={{ margin: "0 auto" }}/>) : null}

      {collections.isLoading ? (
        <CircularProgress sx={{ margin: "0 auto" }} />
      ) : (
        collections.data && (
          <Grid container spacing="2rem">
            {collections.data.slice(0, limit).map((collection, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={collection.contract_id}
              >
                <CollectionCard {...collection} index={index + 1} />
              </Grid>
            ))}
          </Grid>
        )
      )}
    </Section>
  );
};

export default AllCollections;
