import { Fragment } from "react";
import { Box, Typography, Theme, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { format } from "timeago.js";

import { IconContainer } from "../styles";
import {
  BlackBox,
  TealButton,
  SubTitleText,
  MiniNumberBoxContainer,
  RightContainerBox,
  NumberBoxesContainer,
  MiniNumberBoxesContainer,
  NumberBoxTitleText,
  MiniNumberBoxTitleText,
} from "./styles";
import { numberFormat } from "utils/number-format";
import { theme } from "ui/theme/theme";

// hide for now
// import { Coins } from "assets/icons/Coins";
import { NearLogo } from "assets/icons/NearLogo";
import { useTestnet } from "hooks/useNear";

interface NumberBoxProps {
  title: string;
  subtitle: string;
  showNearLogo?: boolean;
}

function NumberBox({ title, subtitle, showNearLogo }: NumberBoxProps) {
  const smallScreen = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  return (
    <BlackBox>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <NumberBoxTitleText>{title}</NumberBoxTitleText>
        {showNearLogo && (
          <IconContainer
            size={smallScreen ? "22px" : "26px"}
            sx={{ marginLeft: "8px" }}
          >
            <NearLogo
              height={smallScreen ? 22 : 26}
              width={smallScreen ? 22 : 26}
              color={theme.colors.white}
            />
          </IconContainer>
        )}
      </Box>

      <SubTitleText>{subtitle}</SubTitleText>
    </BlackBox>
  );
}

function MiniNumberBox({ title, subtitle, showNearLogo }: NumberBoxProps) {
  const smallScreen = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  return (
    <MiniNumberBoxContainer>
      <Typography
        fontSize="11px"
        fontWeight={600}
        sx={{ lineHeight: "normal" }}
      >
        {subtitle}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <MiniNumberBoxTitleText>{title}</MiniNumberBoxTitleText>
        {showNearLogo && (
          <IconContainer
            size={smallScreen ? "16px" : "18px"}
            sx={{ marginLeft: "3px" }}
          >
            <NearLogo
              height={smallScreen ? 16 : 18}
              width={smallScreen ? 16 : 18}
              color={theme.colors.white}
            />
          </IconContainer>
        )}
      </Box>
    </MiniNumberBoxContainer>
  );
}

interface RightContainerProps {
  item: number;
  uniqueHolders: number;
  floorPrice: string;
  totalSales: string;
  highestSale: string;
  lastSale: number;
  contractId: string;
}

function RightContainer({
  item,
  uniqueHolders,
  floorPrice,
  highestSale,
  lastSale,
  totalSales,
  contractId,
}: RightContainerProps) {
  const { isTestnet, getToken } = useTestnet();
  const smallScreen = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  const Container = smallScreen ? Fragment : Stack;

  return (
    <RightContainerBox>
      <NumberBoxesContainer>
        <Container direction="row" spacing={2.5}>
          <NumberBox
            title={numberFormat(item)}
            subtitle={`Item${item > 1 ? "s" : ""}`}
          />
          <NumberBox
            title={numberFormat(uniqueHolders)}
            subtitle={`Unique holder${uniqueHolders > 1 ? "s" : ""}`}
          />
        </Container>
        <Container direction="row" spacing={2.5}>
          <NumberBox
            title={numberFormat(Number(floorPrice), 2)}
            subtitle="Floor price"
            showNearLogo
          />
          <NumberBox
            title={numberFormat(Number(totalSales), 2)}
            subtitle="Total sales"
            showNearLogo
          />
        </Container>
      </NumberBoxesContainer>
      <MiniNumberBoxesContainer>
        <MiniNumberBox title={format(lastSale)} subtitle="Last sale" />
        <MiniNumberBox
          title={numberFormat(Number(highestSale), 2)}
          subtitle="Highest sale"
          showNearLogo
        />
      </MiniNumberBoxesContainer>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {isTestnet && (
          <TealButton
            variant="contained"
            sx={{ display: "flex", alignItems: "center", marginX: "5px" }}
            onClick={() => getToken(contractId)}
          >
            <Typography sx={{ fontWeight: 600 }}>Get Token</Typography>
          </TealButton>
        )}
      </Box>
    </RightContainerBox>
  );
}

export default RightContainer;
