import { FC } from "react";
import { SVGProps } from "interfaces";

export const Coins: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
  type,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {type === "duotone" ? (
      <>
        <path
          d="M2.43464 4.33203C2.35881 5.35934 2.31807 6.38894 2.3125 7.41903C2.3125 8.65075 2.4025 9.85546 2.49893 11.0242C2.54994 11.626 2.80224 12.1932 3.21513 12.6341C3.62802 13.0749 4.17741 13.3638 4.77464 13.4542C5.00479 13.485 5.23493 13.5159 5.46764 13.5429"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.1025 10.2553C3.62907 10.1421 2.44621 9.24854 2.3125 8.16211"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
        <path
          d="M5.35696 6.70601C3.45154 6.44758 2.40625 5.69544 2.40625 4.54344C2.40625 3.09444 4.05711 2.2793 6.99239 2.2793C9.22696 2.2793 10.7158 2.75115 11.2995 3.61644"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.7864 17.5232C11.5102 17.6261 12.2508 17.7083 13.0029 17.7083C13.7564 17.7083 14.4956 17.6261 15.2195 17.5232C15.8165 17.4329 16.3658 17.1442 16.7786 16.7036C17.1915 16.263 17.4439 15.6961 17.4952 15.0945C17.5929 13.9245 17.6816 12.7211 17.6816 11.4893C17.6816 10.4389 17.6174 9.40777 17.5376 8.40234H8.44636C8.37053 9.42965 8.32979 10.4593 8.32422 11.4893C8.32422 12.7211 8.41422 13.9258 8.51065 15.0945C8.56166 15.6964 8.81396 16.2635 9.22685 16.7044C9.63974 17.1452 10.1891 17.4342 10.7864 17.5245V17.5232Z"
          fill="white"
          stroke={color}
          strokeWidth="1.7"
          strokeLinejoin="round"
        />
        <path
          d="M17.6816 12.2305C17.5492 13.3169 16.3651 14.2105 14.8916 14.3236C14.2732 14.3712 13.6432 14.4059 13.0029 14.4059C12.3639 14.4059 11.7326 14.3712 11.1142 14.3236C9.64208 14.2105 8.45793 13.3169 8.32422 12.2305"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
        <path
          d="M13.0028 10.8753C15.9368 10.8753 17.5877 10.0602 17.5877 8.61247C17.5877 7.16347 15.9368 6.34961 13.0028 6.34961C10.0688 6.34961 8.41797 7.16347 8.41797 8.61247C8.41797 10.0602 10.0688 10.8753 13.0028 10.8753Z"
          fill="white"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M2.43464 4.33203C2.35881 5.35934 2.31807 6.38894 2.3125 7.41903C2.3125 8.65075 2.4025 9.85546 2.49893 11.0242C2.54994 11.626 2.80224 12.1932 3.21513 12.6341C3.62802 13.0749 4.17741 13.3638 4.77464 13.4542C5.00479 13.485 5.23493 13.5159 5.46764 13.5429"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.1025 10.2553C3.62907 10.1421 2.44621 9.24854 2.3125 8.16211"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
        <path
          d="M5.35696 6.70601C3.45154 6.44758 2.40625 5.69544 2.40625 4.54344C2.40625 3.09444 4.05711 2.2793 6.99239 2.2793C9.22696 2.2793 10.7158 2.75115 11.2995 3.61644"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5376 8.40234C17.6174 9.40777 17.6816 10.4389 17.6816 11.4893C17.6816 12.7211 17.5929 13.9245 17.4952 15.0945C17.4439 15.6961 17.1915 16.263 16.7786 16.7036C16.3658 17.1442 15.8165 17.4329 15.2195 17.5232C14.4956 17.6261 13.7564 17.7083 13.0029 17.7083C12.2508 17.7083 11.5102 17.6261 10.7864 17.5232V17.5245C10.1891 17.4342 9.63974 17.1452 9.22685 16.7044C8.81396 16.2635 8.56166 15.6964 8.51065 15.0945C8.41422 13.9258 8.32422 12.7211 8.32422 11.4893C8.32979 10.4593 8.37053 9.42965 8.44636 8.40234"
          stroke={color}
          strokeWidth="1.7"
          strokeLinejoin="round"
        />
        <path
          d="M17.6816 12.2305C17.5492 13.3169 16.3651 14.2105 14.8916 14.3236C14.2732 14.3712 13.6432 14.4059 13.0029 14.4059C12.3639 14.4059 11.7326 14.3712 11.1142 14.3236C9.64208 14.2105 8.45793 13.3169 8.32422 12.2305"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
        <path
          d="M13.0028 10.8753C15.9368 10.8753 17.5877 10.0602 17.5877 8.61247C17.5877 7.16347 15.9368 6.34961 13.0028 6.34961C10.0688 6.34961 8.41797 7.16347 8.41797 8.61247C8.41797 10.0602 10.0688 10.8753 13.0028 10.8753Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
