import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import * as S from "./styles";
import { Collection } from "api";
import { CollectionPrice } from "Components/Collection/Price";
import { theme } from "ui/theme/theme";
import { CollectionVerified } from "Components/Collection/Verified";
import { CollectionCreatorName } from "Components/Collection/Creator/Name";
import { CollectionCardAction, CollectionCardActions } from "./actions";
import { ArrowRight } from "assets/icons";

export interface CollectionCardProps extends Collection {
  filter?: boolean;
  index?: number;
}

export const CollectionCard = ({
  collection_name,
  featured_image_url,
  profile_picture_url,
  contract_id,
  floor_price,
  filter,
  supply,
  index,
}: CollectionCardProps) => {
  const actions: Array<CollectionCardAction> = [
    {
      label: "View",
      href: `/collections/${contract_id}`,
      Icon: <ArrowRight color="inherit" height="13.89" width="13.83" />,
    },
  ];

  return (
    <S.Card id={contract_id}>
      <Box sx={{ position: "relative", aspectRatio: "1" }}>
        {featured_image_url && (
          <CardMedia
            component="img"
            image={featured_image_url}
            alt={collection_name}
            sx={{ borderRadius: "5px 5px 0px 0px", aspectRatio: "1" }}
          />
        )}
        <S.CardHeader>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {Boolean(index) && <S.Index>{index}</S.Index>}
            <CollectionCreatorName avatar={profile_picture_url} />
          </Box>
        </S.CardHeader>
      </Box>

      <S.CardContentCard>
        <Box
          sx={{
            marginBottom: "0.5rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CollectionVerified
            name={collection_name}
            sx={{ fontSize: "1.375rem", color: theme.colors.white, flex: "1" }}
          />
          <CollectionPrice
            fontSize="1.125rem"
            color={theme.colors.white}
            price={floor_price}
          />
        </Box>

        <S.CardBox filter={filter}>
          <Box component="div" sx={{ display: "block" }}>
            <Typography gutterBottom variant="caption" component="div">
              Collection of {supply}
            </Typography>
          </Box>
        </S.CardBox>
      </S.CardContentCard>
      <CollectionCardActions actions={actions} />
    </S.Card>
  );
};
