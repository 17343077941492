import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

interface CardSkeletonProps {
  smallCollectionCard: boolean;
  filterMode: boolean;
  number?: number;
}

function getCardHeight({ smallCollectionCard, filterMode }: CardSkeletonProps) {
  if (smallCollectionCard) {
    if (filterMode) {
      return 300;
    }
    return 400;
  }
  if (filterMode) {
    return 400;
  }
  return 500;
}

function CardSkeleton({
  smallCollectionCard,
  filterMode,
  number = 8,
}: CardSkeletonProps) {
  return (
    <>
      {Array.from(Array(number).keys()).map((index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={smallCollectionCard ? 4 : 6}
          lg={smallCollectionCard ? 3 : 4}
          xl={smallCollectionCard ? 3 : 4}
          key={index}
        >
          <Skeleton
            sx={{ bgcolor: "grey.900" }}
            variant="rectangular"
            height={getCardHeight({ smallCollectionCard, filterMode })}
          />
        </Grid>
      ))}
    </>
  );
}

export default CardSkeleton;
