import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { RequestConfigOrdering } from "api/models";
import { Sort } from "assets/icons/Sort";
import { theme } from "ui/theme/theme";
import { GreyButton, IconContainer } from "../CollectionTabs/styles";
import { StyledMenu } from "./styles";

interface OrderOption {
  name: string;
  value: RequestConfigOrdering;
}

interface OrderButtonProps {
  onSetOrder: (value: RequestConfigOrdering) => void;
}

export const orderOptions: OrderOption[] = [
  {
    name: "Recently added",
    value: "recently_added",
  },
  {
    name: "Floor: High to Low",
    value: "floor_high",
  },
  {
    name: "Floor: Low to High",
    value: "floor_low",
  },
  {
    name: "Best match",
    value: "best_match",
  },
];

function OrderButton({ onSetOrder }: OrderButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [state, setState] = useState<RequestConfigOrdering>("recently_added");
  const showLabel = orderOptions.find(({ value }) => value === state)?.name;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSetState = (value: RequestConfigOrdering) => {
    setState(value);
    onSetOrder(value);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <GreyButton width="160px" onClick={handleClick}>
        <IconContainer marginRight="5px">
          <Sort height={18} width={18} color={theme.colors.white} />
        </IconContainer>
        {showLabel}
      </GreyButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {orderOptions.map(({ name, value }) => (
          <MenuItem key={value} onClick={() => handleSetState(value)}>
            {name}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}

export default OrderButton;
