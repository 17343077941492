import { FC } from "react";
import { SVGProps } from "interfaces";

export const Bolt: FC<SVGProps> = ({
  width = "40",
  height = "40",
  color = "#020915",
  secondColor = "#FFFFFF",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill={secondColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.64571 20C8.90285 15.0486 9.84571 8.59144 12.2457 3.84572C12.4259 3.4899 12.6914 3.1842 13.0184 2.95587C13.3454 2.72755 13.7239 2.5837 14.12 2.53715C16.6531 2.25798 19.2002 2.12633 21.7486 2.14286C24.26 2.14286 26.4743 2.32858 28.1886 2.55429C29.9171 2.78286 30.8228 4.58286 30.1314 6.18286L27.4114 12.4971C29.7943 12.5829 31.6543 12.7743 33.3828 13.1257C33.7469 13.1906 34.0897 13.3428 34.382 13.5692C34.6743 13.7957 34.9072 14.0897 35.0609 14.426C35.2146 14.7623 35.2844 15.1308 35.2643 15.5C35.2443 15.8692 35.1349 16.2281 34.9457 16.5457C31.9171 21.7743 24.7743 30.3857 14.4428 37.8572C14.4428 32.2714 15.4228 28.4286 17.1428 22.8571H11.4286C11.0552 22.8629 10.6846 22.7924 10.3394 22.6499C9.99427 22.5074 9.68184 22.2959 9.4213 22.0284C9.16076 21.7609 8.95758 21.443 8.82421 21.0942C8.69084 20.7454 8.6301 20.3731 8.64571 20Z"
      stroke={color}
      strokeWidth="2.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
