import Button from "@mui/material/Button";
import {
  TextNormal,
  MainHead,
  MainSection,
  HeadSection,
  MainContainer,
} from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { Verified } from "assets/icons/Verified";
import { LargeLoad } from "assets/icons/LargeLoad";
import { Close } from "assets/icons/Close";
import { theme } from "ui/theme/theme";

interface SuccessPanelProps {
  onClose?: () => void;
  setResultPanel: (value: boolean) => void;
  title?: string;
  subtitle?: string;
  iconName: string;
}

const renderIcon = (iconName: string) => {
  switch (iconName) {
    case "verified":
      return (
        <Verified
          height="72"
          width="72"
          color={theme.colors.teal}
          type="filled"
          secondColor={theme.colors.black}
        />
      );
    case "largeLoad":
      return <LargeLoad />;
    case "error":
      return (
        <Close
          height="72"
          width="72"
          color={theme.colors.teal}
          type="filled"
          secondColor={theme.colors.black}
        />
      );
    default:
      return null;
  }
};
export default function SuccessPanel({
  onClose,
  setResultPanel,
  title,
  subtitle,
  iconName,
}: SuccessPanelProps) {
  return (
    <MainContainer>
      <HeadSection
        display="flex"
        justifyContent="end"
        alignItems="center"
        sx={{ paddingTop: "129px", paddingBottom: "129px" }}
      >
        <CloseIcon onClick={onClose} />
      </HeadSection>

      <MainSection>
        {renderIcon(iconName)}
        <MainHead>{title}</MainHead>

        <TextNormal>{subtitle} </TextNormal>
        <Button onClick={() => setResultPanel(false)} />
      </MainSection>
    </MainContainer>
  );
}
