import { useState } from "react";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";

import { NearLogo } from "assets/icons/NearLogo";
import { Coins } from "assets/icons/Coins";
import { Cart } from "assets/icons/Cart";
import { Bid } from "assets/icons";
import { TranslucentRegularButton } from "Components/Buttons/index";
import BuyPanelDrawer from "Components/BuyPanelDrawer";
import OfferPanelDrawer from "Components/OfferPanelDrawer";
import * as S from "./styles";
import { Token } from "api";
import { theme } from "ui/theme/theme";
import skeleton from "assets/skeleton.mp4";

export interface CardProps {
  name: string;
  collectionName?: string;
  image: string;
  total: string | null;
  listed: boolean;
  rarity: string;
  tokenId?: string;
  contractId?: string;
  owner: string | null;
  ftTokenId?: string;
}

interface CardPropsContainer {
  token: Token;
  floorPrice: string;
  filter?: boolean;
  onClick: () => void;
  rarity?: string;
  showAddToCartBtn?: boolean;
}

export const NftCard = ({
  token,
  floorPrice,
  filter,
  rarity = "",
  onClick,
  showAddToCartBtn = true,
}: CardPropsContainer) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const handleImageLoad = () => {
    setLoaded(true);
  };

  const handleImageError = () => {
    setError(true);
  };

  return (
    <S.StyledCard id={token.token_id}>
      <Box style={{ position: "relative" }} onClick={onClick}>
        {!error && (
          <CardMedia
            component="img"
            src={token.media}
            alt={token.token_name}
            sx={{ borderRadius: "5px 5px 0px 0px" }}
            loading="lazy"
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        )}
        {!loaded && (
          <CardMedia
            component="video"
            src={skeleton}
            sx={{ borderRadius: "5px 5px 0px 0px" }}
            loop
            muted
            autoPlay
            playsInline
          />
        )}

        {rarity && (
          <S.StyledDiv filter={filter}>
            <span>
              <b>Rarity:</b> {rarity}
            </span>
          </S.StyledDiv>
        )}
      </Box>

      <S.CardContentCard filter={(!!filter).toString()} onClick={onClick}>
        <S.CardBox sx={{ display: "flex" }} component="div">
          <S.ContentTitle filter={(!!filter).toString()}>
            {token.token_name}
          </S.ContentTitle>
          <S.ContentAmount
            filter={(!!filter).toString()}
            sx={{
              color: token.listed_price
                ? theme.colors.white
                : theme.colors.teal,
            }}
          >
            {token.listed_price ? token.listed_price : "Not listed"}

            {token.listed_price &&
              (filter ? (
                <NearLogo height="20" width="20" color="#FFFFFF" />
              ) : (
                <NearLogo height="23.12" width="23.12" color="#FFFFFF" />
              ))}
          </S.ContentAmount>
        </S.CardBox>
      </S.CardContentCard>
      <Divider sx={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }} />
      <S.CardAction>
        {token.listed_price ? (
          <>
            <BuyPanelDrawer
              panelButtonText="Buy"
              panelMainTitle="Cart"
              panelInputTitle="Your offer"
              panelInputPlaceHolder="Add more Items"
              buttonText="Buy Now"
              Icon={
                filter ? (
                  <Coins height="18" width="18" color="#FFFFFF" />
                ) : (
                  <Coins height="20.81" width="20.81" color="#FFFFFF" />
                )
              }
              token={token}
              buttonType="buy"
            />

            {showAddToCartBtn && (
              <>
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  flexItem
                  sx={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
                />

                <TranslucentRegularButton
                  color="secondary"
                  size="large"
                  filter={filter}
                >
                  Add to Cart{" "}
                  <S.CardSpanIconTypography>
                    {filter ? (
                      <Cart height="18" width="18" />
                    ) : (
                      <Cart height="20.81" width="20.81" />
                    )}
                  </S.CardSpanIconTypography>
                </TranslucentRegularButton>
              </>
            )}
          </>
        ) : (
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            component="div"
          >
            <OfferPanelDrawer
              panelButtonText="Make an offer"
              panelMainTitle="Make an offer"
              panelInputTitle="Your offer"
              panelInputPlaceHolder="Enter amount to offer"
              buttonText="Submit Offer"
              Icon={
                filter ? (
                  <Bid height="18" width="18" color="#000" />
                ) : (
                  <Bid height="20.81" width="20.81" color="#000" />
                )
              }
              token={token}
              buttonType="offer"
              floorPrice={floorPrice}
            />
          </Box>
        )}
      </S.CardAction>
    </S.StyledCard>
  );
};
