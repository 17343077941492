import { styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  padding: "41px 120px 145px",
  maxWidth: "1573px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "28px",
    paddingRight: "28px",
    paddingTop: 0,
  },
}));
