export const RELATIVE_ROUTES = {
  INDEX: "/",
  MARKETPLACE: "/marketplace",
  ABOUT: "/about",
  TERMS: "/terms",
  COLLECTIONS: "/collections",
  COLLECTIONS_NAME: "/collections/:collectionName",
  COLLECTIONS_SLUG_TOKENID: "/collections/:slug/:tokenID",
  DISCOVER: "/discover",
  // SELL: "/profile",
  PROFILE: "/profile",
  NOT_FOUND: "/404",
  CONNECT_WALLET: "/connect-wallet",
  PASSWORD_PROTECTED: "/password-protected",
};

const { INDEX } = RELATIVE_ROUTES;

export const ABSOLUTE_ROUTES = {
  INDEX,
};
export const WILDCARD_ROUTE = "*";
