import { FC } from "react";
import { SVGProps } from "interfaces";
import { ButtonProps } from "@mui/material";
import Box from "@mui/material/Box";

import { theme } from "ui/theme/theme";
import WalletContainer from "../../WalletContainer";
import { TealButton, WhiteButton } from "../../styles";
import {
  MenuContainer,
  MenuText,
  LinkText,
  LinkBox,
  ButtonLabel,
  IconContainer,
} from "../styles";

import { Bolt } from "assets/icons/Bolt";
import { Calendar } from "assets/icons/Calendar";
import { Cart } from "assets/icons/Cart";
import { Discord } from "assets/icons/Discord";
import { Discover } from "assets/icons/Discover";
import { Fire } from "assets/icons/Fire";
import { Heart } from "assets/icons/Heart";
import { ListIcon } from "assets/icons/List";
import { Twitter } from "assets/icons/Twitter";

interface MenuProps {
  iconSrc: FC<SVGProps>;
  menuName: string;
  linkName: string;
  isSection: boolean;
}
interface MainMenuProps extends MenuProps {
  setShowMainMenuDrawer: (value: boolean) => void;
}
interface MainMenuBasicProps {
  setShowMainMenuDrawer: (value: boolean) => void;
}
const MENUS: MenuProps[] = [
  {
    iconSrc: Discover,
    menuName: "Discover",
    linkName: "discover",
    isSection: false,
  },
  {
    iconSrc: ListIcon,
    menuName: "Sell",
    linkName: "profile",
    isSection: false,
  },
  {
    iconSrc: Heart,
    menuName: "Top collections",
    linkName: "top-collections",
    isSection: true,
  },
  {
    iconSrc: Fire,
    menuName: "New collections",
    linkName: "new-collections",
    isSection: true,
  },
  {
    iconSrc: Calendar,
    menuName: "Coming soon",
    linkName: "coming-soon",
    isSection: true,
  },
  {
    iconSrc: Bolt,
    menuName: "Today’s spotlight",
    linkName: "spotlight",
    isSection: true,
  },
];

function Menu({
  iconSrc,
  menuName,
  linkName,
  isSection,
  setShowMainMenuDrawer,
}: MainMenuProps) {
  const Icon = iconSrc;
  return (
    <MenuContainer>
      <IconContainer sx={{ marginRight: "12px" }}>
        <Icon color={theme.colors.teal} height="22" width="22" />
      </IconContainer>

      {isSection ? (
        <MenuText
          onClick={() => {
            setShowMainMenuDrawer(false);
          }}
          underline="none"
          href={isSection ? `/#${linkName}` : `/${linkName}`}
        >
          {menuName}
        </MenuText>
      ) : (
        <MenuText
          underline="none"
          href={isSection ? `/#${linkName}` : `/${linkName}`}
        >
          {menuName}
        </MenuText>
      )}
      {/* <MenuText
        underline="none"
        href={isSection ? `/#${linkName}` : `/${linkName}`}
      >
        {menuName}
      </MenuText> */}
    </MenuContainer>
  );
}

const Button = (props: ButtonProps) => (
  <TealButton variant="contained" sx={{ marginBottom: "20px" }} {...props} />
);

function MainMenu({ setShowMainMenuDrawer }: MainMenuBasicProps) {
  return (
    <>
      <Box sx={{ marginBottom: "19px" }}>
        {MENUS.map((item) => (
          <Menu
            {...item}
            key={item.menuName}
            setShowMainMenuDrawer={setShowMainMenuDrawer}
          />
        ))}
      </Box>
      <WalletContainer Button={Button} menuwidth="319px" />
      <WhiteButton sx={{ marginBottom: "24px" }}>
        <IconContainer sx={{ marginRight: "8px" }}>
          <Cart type="duotone" secondColor={theme.colors.teal} />
        </IconContainer>
        <ButtonLabel>View cart</ButtonLabel>
      </WhiteButton>
      <LinkBox
        href="https://twitter.com/acovamarket"
        target="_blank"
        rel="noreferrer"
        underline="none"
      >
        <IconContainer
          sx={{ height: "20px", marginRight: "8px", width: "20px" }}
        >
          <Twitter color={theme.colors.white} />
        </IconContainer>
        <LinkText>Twitter</LinkText>
      </LinkBox>
      <LinkBox
        href="https://discord.gg/YcXJ7bxPXc"
        target="_blank"
        rel="noreferrer"
        underline="none"
      >
        <IconContainer
          sx={{ height: "20px", marginRight: "8px", width: "20px" }}
        >
          <Discord color={theme.colors.white} />
        </IconContainer>
        <LinkText>Discord</LinkText>
      </LinkBox>
    </>
  );
}

export default MainMenu;
