import { useState } from "react";
import { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "ui/theme/theme";
import { Close } from "assets/icons/Close";
import {
  CardActionButton,
  CardActionText,
  MainBoxContainer,
  HeadSection,
  GreyButton,
  Title,
  TealButton,
  GreyLongButton,
  IconContainer,
  Logo,
  NavButton,
} from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import DetailCard from "./DetailCard";
import { Token } from "api";
import logo from "assets/logo.svg";
import useMarketplace from "hooks/marketPlace";

interface DelistPanelDrawerProps {
  token: Token;
  collectionName: string;
}

function DelistPanelDrawer({ token, collectionName }: DelistPanelDrawerProps) {
  const [state, setState] = useState(false);
  const smallScreen = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );
  const marketPlace = useMarketplace();

  async function delistToken() {
    try {
      await marketPlace.delistItem(token.contract_id, token.token_id);
    } catch (err) {
      let message;
      if (err instanceof Error) message = err.message;
      else message = String(err);
      return message;
    }
  }

  const handleOpen = () => setState(true);
  const handleClose = () => setState(false);

  return (
    <>
      <CardActionButton color="secondary" size="medium" onClick={handleOpen}>
        <Close height="13.89" width="13.83" />
        <CardActionText>Delist item</CardActionText>
      </CardActionButton>
      <Drawer
        anchor="right"
        open={state}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: theme.colors.black,
          },
        }}
      >
        <MainBoxContainer role="presentation">
          <HeadSection>
            {smallScreen ? (
              <>
                <Logo src={logo} alt="logo" />
                <Box>
                  <NavButton onClick={handleClose}>
                    <IconContainer sx={{ height: "24px", width: "24px" }}>
                      <Close
                        height="24"
                        width="24"
                        color={theme.colors.white}
                      />
                    </IconContainer>
                  </NavButton>
                </Box>
              </>
            ) : (
              <GreyButton onClick={handleClose}>
                <CloseIcon sx={{ height: "18px", width: "18px" }} />
              </GreyButton>
            )}
          </HeadSection>
          <Title>Are you sure you want to delist this item?</Title>
          <DetailCard
            image={token.media}
            title={token.token_name}
            subtitle={collectionName}
            price={token.listed_price!}
          />
          <TealButton onClick={() => delistToken()}>
            <IconContainer sx={{ marginRight: "8px" }}>
              <Close height="18" width="18" />
            </IconContainer>
            Delist this item
          </TealButton>
          <GreyLongButton onClick={handleClose}>Cancel</GreyLongButton>
        </MainBoxContainer>
      </Drawer>
    </>
  );
}

export default DelistPanelDrawer;
