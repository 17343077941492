import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { TextField, TealButton } from "./styles";
import { useNavigate, Navigate } from "react-router-dom";
import { LIVE_TIME } from "../../../routing/PasswordGuard";

function PasswordProtected() {
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleSubmit = () => {
    localStorage.setItem("password-protected", password);
    navigate("/");
  };
  if (Date.now() > LIVE_TIME * 1000) {
    return <Navigate to="/" />;
  }

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        marginTop={10}
        marginBottom={10}
      >
        <form onSubmit={handleSubmit}>
          <Typography variant="h5" marginBottom={1}>
            Enter a password
          </Typography>
          <TextField
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />

          <TealButton variant="contained" sx={{ marginTop: 1 }} type="submit">
            Submit
          </TealButton>
        </form>
      </Box>
    </Container>
  );
}

export default PasswordProtected;
