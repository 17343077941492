import {
  styled,
  Button,
  ButtonProps,
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  FormControl,
  FormControlProps,
} from "@mui/material";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";

export const TealButton = styled(Button)<ButtonProps>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.teal,
  borderRadius: 35,
  color: theme.colors.black,
  display: "flex",
  fontWeight: 600,
  fontSize: "1rem",
  height: 50,
  marginTop: "32px",
  textTransform: "none",
  width: "28.938rem",
  "&:hover": {
    backgroundColor: theme.colors.teal,
  },
  "&:disabled": {
    backgroundColor: theme.colors.grey,
  },
  "@media (max-width: 450px)": {
    width: "19.938rem",
    height: "3rem",
  },
}));

export const IconContainer = styled(Box)<BoxProps & { size?: string }>(
  ({ size = "18px" }) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: size,
    width: size,
  })
);
export const BoxMsgContainer = styled(Box)<BoxProps & { size?: string }>(
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 400,
    fontSize: "14px",
    gap: "7px",
    color: theme.colors.teal,
  })
);

export const GreyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  alignItems: "center",
  color: theme.colors.white,
  backgroundColor: `rgba(255, 255, 255, 0.1)`,
  borderRadius: 35,
  display: "flex",
  fontWeight: 600,
  fontSize: "1rem",
  height: 50,
  marginTop: "32px",
  textTransform: "none",
  width: "28.938rem",
  marginBottom: "180px",
  "&:hover": {
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
  },
  "@media (max-width: 450px)": {
    width: "19.938rem",
    height: "3rem",
    marginBottom: "1.75rem",
  },
}));

export const CustomOutlineInput = styled(OutlinedInput)<OutlinedInputProps>(
  ({ theme }) => ({
    backgroundColor: `rgba(255, 255, 255, 0.07)`,
    borderRadius: 50,
    position: "relative",
    width: "29.125rem",
    height: 64,
    color: theme.colors.white,
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      borderColor: theme.colors.white,
      fieldset: {
        borderColor: theme.colors.white,
        borderWidth: 0,
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 50,
      color: theme.colors.black,
      fontSize: "0.75rem",
      borderWidth: 0,
      fieldset: {
        height: 64,
        borderWidth: 0,
      },
      "& .MuiOutlinedInput-notchedOutline:hover": {
        borderWidth: 0,
        fieldset: {
          height: 64,
          borderWidth: 0,
        },
      },
      "& .MuiOutlinedInput-notchedOutline:focus": {
        borderWidth: 0,
        fieldset: {
          height: 64,
          borderWidth: 0,
        },
      },
      "& .css-gg3rcv-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused": {
        borderWidth: 0,
      },
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        borderWidth: 0,
      },
      ".css-1m975vh-MuiInputBase-root-MuiOutlinedInput-root:hover": {
        borderColor: theme.colors.white,
      },
    },
    "@media (max-width: 450px)": {
      width: "19.938rem",
    },
  })
);

export const StyledFormControl = styled(FormControl)<FormControlProps>(() => ({
  width: "29.125rem",
  "@media (max-width: 450px)": {
    width: "19.938rem",
  },
}));
export const TextNormal = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.white,
  fontWeight: 600,
  fontSize: "1.25rem",
  lineHeight: "120%",
  marginRight: "0.625rem",
}));

export const BoxTextContainer = styled(Box)<BoxProps>(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  marginTop: "12.5rem",
  marginBottom: "1.25rem",
  "@media (max-width: 450px)": {
    marginTop: "4.313rem",
  },
}));
