import * as React from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import { Typography } from "@mui/material";
import { theme } from "ui/theme/theme";
import { NearLogo } from "assets/icons/NearLogo";
import { ErrorIcon } from "assets/icons/Error";

import {
  TealButton,
  IconContainer,
  GreyButton,
  CustomOutlineInput,
  TextNormal,
  BoxMsgContainer,
  StyledFormControl,
  BoxTextContainer,
} from "./styles";

interface FormProps {
  onClose?: () => void;
  handleSubmit: (value: string) => void;
  panelInputPlaceHolder: string;
  buttonText: string;
  Icon: React.ReactElement;
  floorPrice?: string;
}

export default function FormItem({
  onClose,
  handleSubmit,
  panelInputPlaceHolder,
  buttonText,
  Icon,
  floorPrice,
}: FormProps) {
  const [amount, setAmount] = useState<string>("0.00");
  const [infoMsg, setInfoMsg] = useState<string>("Please enter an amount");
  const [error, setError] = useState<boolean>(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = parseFloat(event.target.value).toFixed(2);
    const newError = newAmount === "0.00";
    const numberOfAmount = Number(newAmount);
    const numericPrice = Number(floorPrice);

    if (newError) {
      setError(true);
    }
    if (numberOfAmount < numericPrice * 0.8) {
      setError(false);
      setAmount(newAmount);
      setInfoMsg(`FYI, your listing price is less than 80% of floor price`);
    } else if (numberOfAmount < numericPrice) {
      setError(false);
      setAmount(newAmount);
      setInfoMsg(
        `FYI, your listing price is lower than the floor price of ${numericPrice} NEAR`
      );
    } else if (Number.isNaN(parseFloat(newAmount)) || numberOfAmount === 0) {
      setError(true);
      setAmount(newAmount);
      setInfoMsg(`list price can't be empty`);
    } else {
      setAmount(newAmount);
      setError(false);
      setInfoMsg(`your listing price is ${newAmount} NEAR`);
    }
  };

  return (
    <Box
      m={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <StyledFormControl
        fullWidth
        sx={{
          m: 1,
          backgroundColor: `rgba(255, 255, 255, 0.07)`,
          color: "black",
          borderRadius: "50px",
          width: "29.125rem",
        }}
      >
        <CustomOutlineInput
          id="outlined-adornment-amount"
          onChange={handleChange}
          onWheel={(e: any) => {
            e.target.blur();
          }}
          autoComplete=" "
          type="number"
          error={parseFloat(amount) <= 0}
          inputProps={{
            maxLength: 13,
            step: "0.01",
            min: "0",
          }}
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                padding: "14px",
                height: "36px",
                borderRadius: "50px",
                backgroundColor: `rgba(255, 255, 255, 0.07)`,
              }}
            >
              {" "}
              <NearLogo
                height="12.6"
                width="12.4"
                color="white"
                type="filled"
              />
            </InputAdornment>
          }
          placeholder={panelInputPlaceHolder}
        />
      </StyledFormControl>

      <BoxMsgContainer>
        <ErrorIcon color={theme.colors.teal} secondColor={theme.colors.black} />
        <Typography variant="body2">{infoMsg}</Typography>
      </BoxMsgContainer>
      <BoxTextContainer>
        <TextNormal>List For: </TextNormal>
        <BoxMsgContainer style={{ color: theme.colors.teal }}>
          {" "}
          {amount} <NearLogo height="18" width="18" color={theme.colors.teal} />
        </BoxMsgContainer>{" "}
      </BoxTextContainer>
      <Box sx={{ width: "100%" }}>
        <Divider
          orientation="horizontal"
          sx={{ border: "1px solid #4E535B" }}
        />
      </Box>
      <Box
        m={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <TealButton
          variant="contained"
          sx={{ display: "flex", alignItems: "center", size: "large" }}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(amount);
          }}
          disabled={error}
        >
          <IconContainer marginRight="8px">{Icon}</IconContainer>
          <Typography sx={{ fontWeight: 600 }}>{buttonText}</Typography>
        </TealButton>
        <GreyButton
          sx={{ display: "flex", alignItems: "center", size: "large" }}
          onClick={onClose}
        >
          Cancel
        </GreyButton>
      </Box>
    </Box>
  );
}
