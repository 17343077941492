import { FC, ReactNode } from "react";
import * as S from "./StandardButton.style";

type CircularButtonProps = {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  children: ReactNode;
  size?: S.StyledCircularButtonSize;
  color?: S.StyledStandardButtonColor;
  type?: S.StyledCircularButtonType;
  onClick?: () => void;
  disabled?: boolean;
};

const StandardButton: FC<CircularButtonProps> = ({
  startIcon,
  endIcon,
  onClick,
  children,
  size = S.StyledCircularButtonSize.Large,
  type = S.StyledCircularButtonType.Solid,
  color = S.StyledStandardButtonColor.Black,
  disabled = false,
}) => (
  <S.ButtonContainer
    $size={size}
    $type={type}
    $color={color}
    onClick={onClick}
    disabled={disabled}
  >
    {startIcon && startIcon}
    {children}
    {endIcon && endIcon}
  </S.ButtonContainer>
);

export default StandardButton;
