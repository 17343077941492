import ReactDOM from "react-dom/client";
import App from "./App";
import { initializeContract, NearContext } from "./hooks/useNear";

import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HiddenOffersContextProvider } from "hooks/hiddenOffers";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

initializeContract().then((nearContext) => {
  root.render(
    <NearContext.Provider value={nearContext}>
      <HiddenOffersContextProvider>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </HiddenOffersContextProvider>
    </NearContext.Provider>
  );
});
