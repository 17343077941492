import { FC } from "react";
import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
} from "@mui/material";
import * as S from "./Breadcrumbs.style";
import { theme } from "ui/theme/theme";
import { DropdownIcon } from "assets/icons/Dropdown";

export type BreadcrumbsLink = {
  name: string;
  link: string;
  active?: boolean;
};
export interface BreadcrumbsProps extends MuiBreadcrumbsProps {
  links: BreadcrumbsLink[];
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ links, sx }) => (
  <MuiBreadcrumbs
    sx={sx}
    aria-label="breadcrumb"
    separator={
      <DropdownIcon
        color={theme.colors.white}
        style={{ transform: "rotate(-90deg)" }}
      />
    }
  >
    {links.map(({ link, active, name }) =>
      !active ? (
        <S.StyledBreadcrumbsLink to={link} key={link}>
          {name}
        </S.StyledBreadcrumbsLink>
      ) : (
        <S.StyledBreadcrumbsCurrentLink key={link}>
          {name}
        </S.StyledBreadcrumbsCurrentLink>
      )
    )}
  </MuiBreadcrumbs>
);

export default Breadcrumbs;
