import { Box, BoxProps, Typography } from "@mui/material";
import { Verified } from "assets/icons";
import { theme } from "ui/theme/theme";

export interface CollectionVerifiedProps extends BoxProps {
  name: string;
  verified?: boolean;
}

export const CollectionVerified = ({
  name,
  verified,
  sx,
  ...rest
}: CollectionVerifiedProps) => (
  <Box sx={{ ...sx, display: "inline-flex", alignItems: "center" }} {...rest}>
    <Typography fontSize="inherit" noWrap sx={{ maxWidth: "14ch" }}>
      <span style={{ marginRight: "0.1em" }}>{name}</span>
    </Typography>
    {verified ? (
      <Verified
        color={theme.colors.teal}
        secondColor={theme.colors.black}
        width="0.75em"
        type="filled"
      />
    ) : null}
  </Box>
);
