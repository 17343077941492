import ShowResult from "./ShowResult";
import Drawer from "@mui/material/Drawer";

import { MainBoxContainer } from "./styles";
import { theme } from "ui/theme/theme";

interface ModalMainProps {
  onClose: () => void;
  state: boolean;
  title: string;
  subTitle: string;
  iconName: string;
  setResultPanel: (val: boolean) => void;
}

export function ModalMain({
  onClose,
  state,
  title,
  subTitle,
  iconName,
  setResultPanel,
}: ModalMainProps) {
  const list = () => (
    <MainBoxContainer role="presentation">
      <ShowResult
        onClose={onClose}
        title={title}
        subtitle={subTitle}
        iconName={iconName}
        setResultPanel={setResultPanel}
      />
    </MainBoxContainer>
  );

  return (
    <Drawer
      anchor="right"
      open={state}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: theme.colors.black,
        },
      }}
    >
      {list()}
    </Drawer>
  );
}

export default ModalMain;
