import styled from "@emotion/styled";
import { Container } from "@mui/system";
import { theme } from "ui/theme/theme";

export const Footer = styled('footer')(() => ({
  backgroundColor: theme.colors.teal,
  color: theme.colors.black,
}))

export const FooterContainer = styled(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingTop: "115px",
  paddingBottom: "67px",
  gap: "10rem",
  [theme.breakpoints.down("md")]: {
    paddingTop: "104px",
    paddingBottom: "104px",
  },
}));

export const FooterContainerContent = styled.div`
  display: grid;
  grid-template-columns: 3fr 4fr;
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    row-gap: 56px;
  }
`;

export const FooterContainerBottom = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 720px) {
    flex-direction: column-reverse;
  }
`;

export const FooterContainerBottomCopyright = styled.p`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`;
export const AvocaLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
`;
export const AvocaSlogan = styled.h2`
  margin-top: 10px;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  max-width: 364px;
`;
export const SocialIconsContainer = styled.div`
  display: flex;
  gap: 12px;
`;
export const LinksSections = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 720px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 48px;
  }
`;

export const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const LinkSectionTitle = styled.h3`
  font-weight: 600;
  color: ${theme.colors.black};
  margin: 0;
  font-size: 25px;
  line-height: 30px;
`;

export const LinksSectionSublinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const SublinksLink = styled.a`
  font-weight: 400;
  color: ${theme.colors.black};
  cursor: pointer;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
`;
