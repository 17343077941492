import * as S from "./styles";

import { FC, useState } from "react";
import { Bolt } from "assets/icons/Bolt";
import { SpotFullDetail } from "Components/Cards/SpotFullDetailCard";
import { CircularProgress, Grid } from "@mui/material";
import { Collection } from "api";
import { Section } from "Components/Section";
import { SectionHeader } from "Components/Section/SectionHeader";

export interface SpotlightProps {
  collections?: Array<Collection>;
  isLoading?: boolean;
}

const Spotlight: FC<SpotlightProps> = ({ collections = [], isLoading }) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const itemsPerPage = 2;
  const totalPages = collections.length / itemsPerPage;

  const handleGoBack = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleGoForward = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <Section id="spotlight">
      <S.SectionHeaderContainer>
        <SectionHeader
          Icon={<Bolt />}
          title="Today’s spotlight"
          subtitle="Our picks of the day; updates daily."
          withPagination
          handleGoBack={handleGoBack}
          handleGoForward={handleGoForward}
        />
      </S.SectionHeaderContainer>
      {isLoading ? (
        <CircularProgress sx={{ margin: "0 auto" }} />
      ) : (
        <Grid container spacing="2rem">
          {collections
            .slice(itemsPerPage * currentPage, itemsPerPage * currentPage + 2)
            .map((collection) => (
              <Grid
                item
                key={collection.contract_id}
                xs={12}
                sm={6}
                // md={6}
                lg={6}
              >
                <SpotFullDetail {...collection} />
              </Grid>
            ))}
        </Grid>
      )}
    </Section>
  );
};

export default Spotlight;
