import { useState, MouseEvent } from "react";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";

import { IconContainer } from "../../styles";
import { MenuContainer } from "./styles";
import { theme } from "ui/theme/theme";
import { ThreeDots } from "assets/icons/ThreeDots";
import { Report } from "assets/icons/Report";
import { Link as LinkIcon } from "assets/icons/Link";

function MoreMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ height: 32, width: 32 }} onClick={handleClick}>
        <IconContainer>
          <ThreeDots height={18} width={18} color={theme.colors.white} />
        </IconContainer>
      </IconButton>
      <MenuContainer anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <IconContainer sx={{ marginRight: "8px" }}>
            <LinkIcon />
          </IconContainer>
          Visit collection website
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <IconContainer sx={{ marginRight: "8px" }}>
            <Report />
          </IconContainer>
          Report collection
        </MenuItem>
      </MenuContainer>
    </>
  );
}

export default MoreMenu;
