import styled from "@emotion/styled";
import { theme } from "ui/theme/theme";
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import CardContent, { CardContentProps } from "@mui/material/CardContent";
import { Card as MuiCard, Divider as MuiDivider } from "@mui/material";
import { MUIStyledCommonProps } from "@mui/system";

export const Card = styled(MuiCard)<{ filter?: boolean }>(({ filter }) => ({
  // maxWidth: filter ? 274 : 320,
  // maxHeight: 466,
  flex: 1,
  backgroundColor: "transparent",
  fontSize: filter ? "10px" : "14px",
}));

export const CardActionsCard = styled.div`
  background-color: #111723;
  justify-content: "center";
  color: ${theme.colors.white};
  padding: "1px";
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 320;
`;

export const LayoutDiv = styled("div")<
  MUIStyledCommonProps & { filter?: boolean }
>(({ filter }) => ({
  position: "absolute",
  color: theme.colors.white,
  top: filter ? "20.81px" : "24px",
  right: filter ? "21.3px" : "24px",
  fontSize: filter ? "9.5px" : "14px",
  span: {
    fontSize: filter ? "9.5px" : "14px",
  },
}));

export const DivOne = styled.div`
  position: absolute;
  color: ${theme.colors.white};
  top: 24px;
  left: 24px;
  width: 28px;
  height: 28px;
  background: ${theme.colors.white};
  border-radius: 40px;
`;

export const SpanOne = styled.span`
  position: absolute;
  width: 10px;
  height: 17px;
  left: 9px;
  top: 6px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${theme.colors.black};
`;

export const CardBox = styled(Box)<BoxProps & { filter?: boolean }>(
  ({ filter }) => ({
    justifyContent: "space-between",
    color: theme.colors.white,
    lineheight: "120%",
    display: "flex",
    "p > span": {
      fontSize: filter ? "10.5px" : "14px",
    },
  })
);

export const CardIconTypography = styled(Typography)<TypographyProps>(() => ({
  margin: "5px",
  display: "flex",
  justifyContent: "center",
}));

export const ContentDiv = styled.div`
  background-color: ${theme.colors.black};
`;

export const CardContentCard = styled(CardContent)<CardContentProps>(() => ({
  backgroundColor: "#111723",
}));

export const Divider = styled(MuiDivider)(() => ({
  backgroundColor: "white",
}));
