import Breadcrumbs, {
  BreadcrumbsLink,
} from "Components/Breadcrumbs/Breadcrumbs";
import * as S from "./styles";
import { FC } from "react";
import NftToken from "../components/NftToken";
import { useParams } from "react-router-dom";
import { useCollection, useCollectionToken } from "api";

type NftTokenPageProps = {};

const NftTokenPage: FC<NftTokenPageProps> = () => {
  const { slug: collectionId, tokenID } = useParams();
  const collection = useCollection(collectionId || "");
  const { data: NftTokenDetails } = useCollectionToken(
    collectionId || "",
    tokenID || ""
  );

  const breadcrumbs: BreadcrumbsLink[] = [
    {
      name: "Collections",
      link: `/discover`,
    },
    {
      name: collection.data?.collection_name || collectionId || "",
      link: `/collections/${collectionId}/`,
    },
    {
      name: `${
        collection.data?.collection_name || collectionId || ""
      } #${tokenID}`,
      link: `/collections/${collectionId}/${tokenID}/`,
      active: true,
    },
  ];
  return NftTokenDetails ? (
    <S.NftTokenPageContainer>
      <Breadcrumbs
        links={breadcrumbs}
        sx={{ marginBottom: "2rem", zIndex: 5 }}
      />
      <NftToken token={NftTokenDetails} />
    </S.NftTokenPageContainer>
  ) : null;
};

export default NftTokenPage;
