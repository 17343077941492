import { FC } from "react";
import { SVGProps } from "interfaces";

export const Bid: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
  type,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    style={{ color}}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {type === "duotone" ? (
      <>
        <path
          d="M12.3907 3.0166C12.0696 2.69547 12.1086 2.73449 11.873 2.51719C11.086 1.79133 9.86024 1.85391 9.0486 2.55209C8.67268 2.87546 8.29276 3.22816 7.67153 3.84941C7.05767 4.46326 6.7025 4.845 6.37868 5.22014C5.6705 6.04058 5.61822 7.28265 6.34855 8.08343C6.55871 8.31386 6.51277 8.27202 6.82404 8.58329C7.13532 8.89457 7.09336 8.84851 7.32379 9.05867C8.12458 9.789 9.36664 9.73672 10.1871 9.02854C10.5622 8.70472 10.944 8.34955 11.5578 7.73569C12.1791 7.11446 12.5318 6.73454 12.8552 6.35862C13.5533 5.54698 13.6158 4.32119 12.89 3.5342C12.6727 3.29862 12.7118 3.33767 12.3907 3.0166Z"
          fill="white"
          stroke="currentColor"
          strokeWidth="1.7"
        />
        <path
          d="M11.5684 7.72461L16.2895 12.4457"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.14893 13.7036V16.0013H9.46777V13.7036C9.46777 13.069 8.9534 12.5547 8.31889 12.5547H4.29781C3.6633 12.5547 3.14893 13.069 3.14893 13.7036Z"
          fill="white"
          stroke="currentColor"
          strokeWidth="1.7"
        />
        <path
          d="M2 16H10.6166"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
        />
      </>
    ) : (
      <>
        <path
          d="M13.2418 4.0166C12.9207 3.69547 12.9597 3.73449 12.7241 3.51719C11.9371 2.79133 10.7113 2.85391 9.89967 3.55209C9.52376 3.87546 9.14383 4.22816 8.5226 4.84941C7.90874 5.46326 7.55358 5.845 7.22975 6.22014C6.52157 7.04058 6.4693 8.28265 7.19963 9.08343C7.40978 9.31386 7.36385 9.27202 7.67512 9.58329C7.98639 9.89457 7.94444 9.84851 8.17487 10.0587C8.97565 10.789 10.2177 10.7367 11.0382 10.0285C11.4133 9.70472 11.795 9.34955 12.4089 8.73569C13.0301 8.11446 13.3828 7.73454 13.7062 7.35862C14.4044 6.54698 14.4669 5.32119 13.7411 4.5342C13.5238 4.29862 13.5629 4.33767 13.2418 4.0166Z"
          stroke="currentColor"
          strokeWidth="1.7"
        />
        <path
          d="M12.4189 8.72461L17.1401 13.4457"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 14.7036V17.0013H10.3188V14.7036C10.3188 14.069 9.80447 13.5547 9.16997 13.5547H5.14888C4.51438 13.5547 4 14.069 4 14.7036Z"
          stroke="currentColor"
          strokeWidth="1.7"
        />
        <path
          d="M2.85059 17H11.4672"
          stroke="currentColor"
          strokeWidth="1.7"
          strokeLinecap="round"
        />
      </>
    )}
  </svg>
);
