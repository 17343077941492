import { styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Button, { ButtonProps } from "@mui/material/Button";

export const FilterBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: "5px",
  color: theme.colors.black,
  width: "240px",
  padding: "24px",
  height: "fit-content",
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  [theme.breakpoints.down("md")]: {
    fontSize: "32px",
    color: theme.colors.white,
    lineHeight: "120%",
  },
}));

export const GreyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.grey,
  borderRadius: 40,
  color: theme.colors.black,
  display: "flex",
  fontSize: 9,
  lineHeight: "10.8px",
  height: "25px",
  minWidth: "40px",
  textTransform: "none",
  width: "44px",
  "&:hover": {
    backgroundColor: theme.colors.grey,
  },
  [theme.breakpoints.down("md")]: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    color: theme.colors.white,
    fontSize: 14,
    height: "35px",
    width: "72px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
}));

export const TealButton = styled(GreyButton)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.colors.teal,
  color: theme.colors.black,
  "&:hover": {
    backgroundColor: theme.colors.teal,
  },
  width: 64,
  [theme.breakpoints.down("md")]: {
    backgroundColor: theme.colors.teal,
    color: theme.colors.black,
    width: 111,
    "&:hover": {
      backgroundColor: theme.colors.teal,
    },
  },
}));
