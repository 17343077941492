import { SVGProps } from "interfaces";
import { FC } from "react";

const DotIcon: FC<SVGProps> = ({
  width = "3",
  height = "4",
  color = "#ffffff80",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 3 4"
  >
    <circle cx="1.5" cy="2" r="1.5" fill={color} />
  </svg>
);

export default DotIcon;
