export function numberFormat(value: number, decimals?: number): string {
  if (decimals) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(value);
  }
  return new Intl.NumberFormat("en-US").format(value);
}

export const formatNumberWhenExact = (number: string) => {
  const numberValue = parseFloat(number);
  if (Number.isInteger(numberValue)) {
    return numberValue.toFixed(0);
  }
  return numberValue.toFixed(2);
};
