import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Token, useCollection } from "api";
import { OfferCard } from "Components/Cards/OfferCard";
import { numberFormat } from "utils/number-format";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./styles";

type Props = {
  tokens?: Array<Token>;
  variant: "listed" | "unlisted";
  contractId?: string;
};

export const MyItems: FC<Props> = ({
  tokens = [],
  variant = "unlisted",
  contractId,
}) => {
  const offset = tokens.length % 3;
  const fillers = offset ? 3 - offset : 0;
  const { data } = useCollection(contractId || "");
  const navigate = useNavigate();

  const viewCollection = (id?: string) => {
    navigate(`/collections/${id}/`);
  };
  return (
    <Box>
      <S.StyledHeader>
        {tokens.length !== 0 && (
          <>
            <Box>
              <Typography variant="h6" fontWeight="bold">
                {data?.collection_name}
              </Typography>
              <p style={{ marginTop: 0, opacity: 0.6 }}>
                <b>Floor: {numberFormat(Number(data?.floor_price), 2)} NEAR</b>
              </p>
            </Box>
            <S.ModifiedTranslucentButton
              onClick={() => {
                viewCollection(data?.contract_id);
              }}
            >
              View all
            </S.ModifiedTranslucentButton>
          </>
        )}
      </S.StyledHeader>
      <S.CardGrid>
        {tokens.map((token) => (
          <OfferCard
            variant={variant}
            token={token}
            key={token.token_id}
            collectionName={data?.collection_name}
          />
        ))}
        {Array.from({ length: fillers }).map((_v, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={index} />
        ))}
      </S.CardGrid>
    </Box>
  );
};
