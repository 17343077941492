/* eslint-disable react/no-unused-prop-types */
import { useState } from "react";
import { Box, Theme } from "@mui/material";
// import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";

import { theme } from "ui/theme/theme";
import {
  IconContainer,
  Tag,
  TitleContainer,
  Title,
  // CreatedBy,
  // CreatorContainer,
  // CreatorText,
  DetailText,
  ReadMoreText,
  TagContainer,
} from "../styles";
import MoreMenu from "./MoreMenu";
import ShareMenu from "./ShareMenu";

import { Discord } from "assets/icons/Discord";
import { Verified } from "assets/icons/Verified";
import { Twitter } from "assets/icons/Twitter";
import { useEnabledFeatures } from "hooks/useFeatures";

interface LeftContainerProps {
  title: string;
  avatar: string;
  createdBy: string;
  detail: string;
  discordUrl: string;
  twitterUrl: string;
  labels: string[];
}

function LeftContainer({
  title,
  // avatar,
  // createdBy,
  detail,
  discordUrl,
  twitterUrl,
  labels,
}: LeftContainerProps) {
  const [showMore, setShowMore] = useState(true);
  const match = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );
  const features = useEnabledFeatures();

  const MAX_DETAIL_LENGTH = match ? 80 : 204;

  return (
    <Box sx={{ flex: 1 }}>
      {detail && (
        <TitleContainer>
          <Title>{title}</Title>
          <IconContainer
            size={match ? "28px" : "32px"}
            sx={{ marginRight: "13px" }}
          >
            <Verified
              height={match ? 28 : 32}
              width={match ? 28 : 32}
              type="filled"
              color={theme.colors.teal}
              secondColor={theme.colors.black}
            />
          </IconContainer>
          {features.collection.socials && <ShareMenu />}
          {features.collection.more && <MoreMenu />}
        </TitleContainer>
      )}

      {/* Hide creator for now */}
      {/* <Box
        sx={{
          marginTop: match ? "10px" : "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CreatedBy>Created by</CreatedBy>
        <CreatorContainer>
          <Avatar
            src={avatar}
            sx={{
              height: 22,
              width: 22,
              marginRight: "6px",
            }}
          />
          <CreatorText>{createdBy}</CreatorText>
          <IconContainer size="14px">
            <Verified height={14} width={14} type="filled" />
          </IconContainer>
        </CreatorContainer>
      </Box> */}

      <Box
        sx={{
          marginTop: "19px",
          maxWidth: match ? "none" : "534px",
        }}
      >
        {detail && (
          <DetailText>
            {showMore && detail.length !== undefined
              ? detail.slice(0, MAX_DETAIL_LENGTH) +
                (detail.length > MAX_DETAIL_LENGTH ? "..." : "")
              : detail}
            {detail.length > MAX_DETAIL_LENGTH &&
              (showMore ? (
                <ReadMoreText onClick={() => setShowMore(false)}>
                  read more
                </ReadMoreText>
              ) : (
                <ReadMoreText onClick={() => setShowMore(true)}>
                  show less
                </ReadMoreText>
              ))}
          </DetailText>
        )}
      </Box>

      {labels && (
        <TagContainer>
          <IconContainer sx={{ marginRight: "10px" }}>
            <a
              href={twitterUrl}
              target="_blank"
              rel="noreferrer"
              style={{ height: "inherit" }}
            >
              <Twitter color={theme.colors.white} height={18} width={18} />
            </a>
          </IconContainer>
          <IconContainer sx={{ marginRight: match ? "22px" : "10px" }}>
            <a
              href={discordUrl}
              target="_blank"
              rel="noreferrer"
              style={{ height: "inherit" }}
            >
              <Discord color={theme.colors.white} height={18} width={18} />
            </a>
          </IconContainer>
          <div style={{ overflow: "auto" }}>
            <div style={{ width: "max-content" }}>
              {labels &&
                labels.map((label) => <Tag key={label} label={label} />)}
            </div>
          </div>
        </TagContainer>
      )}
    </Box>
  );
}

export default LeftContainer;
