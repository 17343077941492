import { FC } from "react";
import { SVGProps } from "interfaces";

export const Cart: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
  secondColor = "white",
  type,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {type === "duotone" ? (
      <>
        <path
          d="M16.4997 1.46875H15.9268C14.7807 1.46875 13.7869 2.2608 13.5311 3.37791L12.8667 6.27952"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.50501 7.30561C1.67496 9.10247 2.18459 10.5978 2.61233 11.5218C2.83214 11.9966 3.21831 12.3652 3.72426 12.4986C4.34344 12.6618 5.40867 12.8368 7.13006 12.8368C8.85145 12.8368 9.91669 12.6618 10.5359 12.4986C11.0418 12.3652 11.428 11.9966 11.6478 11.5218C12.1697 10.3944 12.8135 8.41653 12.8135 6.07812H2.6755C1.99683 6.07812 1.44111 6.62995 1.50501 7.30561Z"
          fill={secondColor}
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.98259 16.5238C3.32192 16.5238 3.59701 16.2486 3.59701 15.9093C3.59701 15.5701 3.32192 15.2949 2.98259 15.2949C2.64325 15.2949 2.36816 15.5701 2.36816 15.9093C2.36816 16.2486 2.64325 16.5238 2.98259 16.5238Z"
          fill={color}
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9699 16.5238C11.3092 16.5238 11.5843 16.2486 11.5843 15.9093C11.5843 15.5701 11.3092 15.2949 10.9699 15.2949C10.6306 15.2949 10.3555 15.5701 10.3555 15.9093C10.3555 16.2486 10.6306 16.5238 10.9699 16.5238Z"
          fill={color}
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M17.5001 2.46875H16.9273C15.7812 2.46875 14.7873 3.2608 14.5316 4.37791L13.8672 7.27952"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.50501 8.30561C2.67496 10.1025 3.18459 11.5978 3.61233 12.5218C3.83214 12.9966 4.21831 13.3652 4.72426 13.4986C5.34344 13.6618 6.40867 13.8368 8.13006 13.8368C9.85145 13.8368 10.9167 13.6618 11.5359 13.4986C12.0418 13.3652 12.428 12.9966 12.6478 12.5218C13.1697 11.3944 13.8135 9.41653 13.8135 7.07812H3.6755C2.99683 7.07812 2.44111 7.62995 2.50501 8.30561Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.98259 17.5238C4.32192 17.5238 4.59701 17.2486 4.59701 16.9093C4.59701 16.5701 4.32192 16.2949 3.98259 16.2949C3.64325 16.2949 3.36816 16.5701 3.36816 16.9093C3.36816 17.2486 3.64325 17.5238 3.98259 17.5238Z"
          fill={color}
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9699 17.5238C12.3092 17.5238 12.5843 17.2486 12.5843 16.9093C12.5843 16.5701 12.3092 16.2949 11.9699 16.2949C11.6306 16.2949 11.3555 16.5701 11.3555 16.9093C11.3555 17.2486 11.6306 17.5238 11.9699 17.5238Z"
          fill={color}
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
