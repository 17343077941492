import { FC } from "react";
import { SVGProps } from "interfaces";

export const Verified: FC<SVGProps> = ({
  width = "20",
  color = "#020915",
  secondColor = "#FFFFFF",
  type,
}) => (
  <svg
    width={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {type === "filled" ? (
      <>
        <path
          d="M7.34655 3.11969C7.97111 2.19942 9.23251 1.977 10.1342 2.62816L11.2736 3.45103C11.6519 3.72429 12.1149 3.85456 12.5803 3.81869L13.9816 3.71067C15.0905 3.62519 16.0509 4.47272 16.104 5.58365L16.171 6.98752C16.1933 7.45372 16.3801 7.89694 16.6983 8.2384L17.6565 9.26665C18.4147 10.0803 18.3509 11.3596 17.5154 12.0937L16.4596 13.0215C16.109 13.3296 15.879 13.752 15.8104 14.2136L15.6039 15.6039C15.4405 16.704 14.4005 17.4517 13.3056 17.2562L11.922 17.0092C11.4625 16.9272 10.9888 17.0107 10.5851 17.2449L9.36946 17.9503C8.40746 18.5084 7.17448 18.1615 6.64465 17.1836L5.97511 15.9479C5.75276 15.5375 5.39213 15.2192 4.95729 15.0496L3.64789 14.539C2.61171 14.1348 2.11418 12.9546 2.54838 11.9306L3.09707 10.6367C3.27928 10.207 3.30326 9.72658 3.16474 9.28088L2.7476 7.93873C2.41751 6.87665 3.03009 5.75177 4.10136 5.45283L5.45511 5.07505C5.90467 4.9496 6.2952 4.66883 6.5573 4.28263L7.34655 3.11969Z"
          fill={color}
        />
        <path
          d="M7.3208 10.6705L9.26885 12.6794C10.1855 10.0462 10.949 8.89069 12.6779 7.32227"
          stroke={secondColor}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M7.34655 3.11969C7.97111 2.19942 9.23251 1.977 10.1342 2.62816L11.2736 3.45103C11.6519 3.72429 12.1149 3.85456 12.5803 3.81869L13.9816 3.71067C15.0905 3.62519 16.0509 4.47272 16.104 5.58365L16.171 6.98752C16.1933 7.45372 16.3801 7.89694 16.6983 8.2384L17.6565 9.26665C18.4147 10.0803 18.3509 11.3596 17.5154 12.0937L16.4596 13.0215C16.109 13.3296 15.879 13.752 15.8104 14.2136L15.6039 15.6039C15.4405 16.704 14.4005 17.4517 13.3056 17.2562L11.922 17.0092C11.4625 16.9272 10.9888 17.0107 10.5851 17.2449L9.36946 17.9503C8.40746 18.5084 7.17448 18.1615 6.64465 17.1836L5.97511 15.9479C5.75276 15.5375 5.39213 15.2192 4.95729 15.0496L3.64789 14.539C2.61171 14.1348 2.11418 12.9546 2.54838 11.9306L3.09707 10.6367C3.27928 10.207 3.30326 9.72658 3.16474 9.28088L2.7476 7.93873C2.41751 6.87665 3.03009 5.75177 4.10136 5.45283L5.45511 5.07505C5.90467 4.9496 6.2952 4.66883 6.5573 4.28263L7.34655 3.11969Z"
          fill={secondColor}
        />
        <path
          d="M7.3208 10.6705L9.26885 12.6794C10.1855 10.0462 10.949 8.89069 12.6779 7.32227"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
