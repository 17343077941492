import { styled } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Chip, { ChipProps } from "@mui/material/Chip";
import Button, { ButtonProps } from "@mui/material/Button";
import Box, { BoxProps } from "@mui/material/Box";

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-flexContainer": {
    borderBottom: "none",
    columnGap: "20px",
  },
  [theme.breakpoints.down("md")]: {
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
    marginBottom: "20px",
  },
}));

export const CustomTab = styled(Tab)(({ theme }) => ({
  color: theme.colors.white,
  fontSize: "24px",
  lineHeight: "28.8px",
  minHeight: "42px",
  height: "42px",
  opacity: 0.5,
  paddingLeft: 0,
  paddingRight: 0,
  textTransform: "none",
  "&.Mui-selected": {
    color: theme.colors.white,
    opacity: 1,
  },
}));

export const CustomChip = styled(Chip)<ChipProps>(() => ({
  "&.MuiChip-root": {
    fontSize: "9px",
    height: "22px",
  },
  "& .MuiChip-label": {
    fontWeight: 600,
    lineHeight: "normal",
    paddingLeft: "5px",
    paddingRight: "6px",
  },
}));

export const GreyButton = styled(Button)<ButtonProps & { width?: string }>(
  ({ theme, width = "40px" }) => ({
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: 35,
    color: theme.colors.white,
    display: "flex",
    fontWeight: 600,
    fontSize: 14,
    height: "40px",
    minWidth: "40px",
    textTransform: "none",
    width,
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  })
);

export const WhiteButton = styled(GreyButton)<ButtonProps & { width?: string }>(
  ({ theme }) => ({
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    "&:hover": {
      backgroundColor: theme.colors.white,
    },
  })
);

export const IconContainer = styled(Box)<BoxProps & { size?: string }>(
  ({ size = "18px" }) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: size,
    width: size,
  })
);

export const GridContainer = styled(Box)<BoxProps>(({ theme }) => ({
  marginTop: "46px",
  display: "flex",
  gap: "32px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
  },
}));

export const TabContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const ButtonContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  gap: "12px",
  justifyContent: "flex-end",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexWrap: "unset",
    justifyContent: "flex-start",
    width: "max-content",
  },
}));
