import { TokenActivity } from "api";
import { FC } from "react";
import { Box, Typography } from "@mui/material";
import HistoryItem from "../HistoryItem/HistoryItem";
import * as S from "./NftTokenHistoryTabPage.style";

type NftTokenHistoryTabPageProps = {
  NftTokenHistory?: TokenActivity[];
};

const NftTokenHistoryTabPage: FC<NftTokenHistoryTabPageProps> = ({
  NftTokenHistory,
}) => (
  <S.NftTokenHistoryTabContainer>
    {NftTokenHistory?.length ? (
      NftTokenHistory.map(({ action }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <HistoryItem key={index} action={action} />
      ))
    ) : (
      <Box sx={{ height: "250px" }}>
        <Typography variant="h6" fontWeight="bold">
          There is no history for this item
        </Typography>
      </Box>
    )}
  </S.NftTokenHistoryTabContainer>
);

export default NftTokenHistoryTabPage;
