import styled from "@emotion/styled";
import { Button, ButtonProps, TextField, TextFieldProps } from "@mui/material";
import { theme } from "ui/theme/theme";

export const MakeOfferModalContainer = styled.div`
  width: 706px;
  background: ${() => theme.colors.black};
  border-radius: 20px 0px 0px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  padding: 0 90px;
`;

export const MakeOfferModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;
  > button {
    width: auto;
  }
`;

export const MakeOfferModalTitle = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
`;

export const MakeOfferModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const MakeOfferItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const MakeOfferItemTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
`;

export const MakeOfferItemCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  gap: 24px;

  height: 152px;

  background: rgba(255, 255, 255, 0.07);
  border-radius: 10px;
`;

export const MakeOfferItemImage = styled.img`
  width: 104px;
  height: 104px;

  border-radius: 5px;
`;

export const MakeOfferItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MakeOfferItemInfoTitle = styled.div`
  font-weight: 400;
  font-size: 32px;
  line-height: 120%;
`;

export const MakeOfferItemInfoDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  color: ${() => theme.colors.white};
  opacity: 0.5;
`;

export const MakeOfferDeleteButton = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const CustomTextField = styled(TextField)<TextFieldProps>(() => ({
  background: `rgba(255, 255, 255, 0.07)`,
  borderRadius: 40,
  position: "relative",
  border: "none",
  height: 64,
  "input.MuiOutlinedInput-input": {
    padding: "14px",
  },
  "&:focus": {
    outline: "none",
  },
  "& .MuiOutlinedInput-root": {
    color: theme.colors.white,
    height: "100%",
    fontSize: "16px",

    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      borderColor: theme.colors.black,
    },
    "&:hover": {
      borderColor: theme.colors.black,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.colors.black,
    },
  },
}));

export const StyledDivider = styled.div`
  border: 1px solid #4e535b;
`;

export const MakeOfferCTA = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
  justify-content: center;
  > button {
    width: 100%;
  }
`;

export const OfferedAmountText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  > span {
    color: ${() => theme.colors.teal};
    align-self: flex-end;
    line-height: 100%;
  }
  > svg {
    align-self: flex-end;
  }
`;

export const TextFieldErrorContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const TextFieldError = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: ${() => theme.colors.teal};
`;

export const StyledWalletButton = styled(Button)<ButtonProps>(() => ({
  alignItems: "center",
  backgroundColor: `rgba(255, 255, 255, 0.07)`,
  borderRadius: 35,
  color: theme.colors.white,
  display: "flex",
  fontWeight: 600,
  fontSize: "0.875rem",
  height: 48,
  width: "11.25rem",
  "div > svg > path": {
    stroke: theme.colors.white,
  },
  padding: "10px 29px 15px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: theme.colors.teal,
    color: theme.colors.black,
  },
  "@media (max-width: 450px)": {
    width: "9.875rem",
    Height: "2.5rem",
    fontWeight: 400,
  },
}));
