import { FC } from "react";
import { SVGProps } from "interfaces";

export const Report: FC<SVGProps> = ({
  width = "18",
  height = "18",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.34766 15.3V4.65649"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.34766 7.35875V12.0176C7.81351 11.3501 11.2348 10.1313 13.3749 8.01852C13.745 7.65325 13.745 7.06423 13.3751 6.69894C11.2351 4.58616 7.81363 3.3674 4.34791 2.69995L4.34766 7.35875Z"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
