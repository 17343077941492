import { Discover } from "assets/icons/Discover";
import { ListIcon } from "assets/icons/List";
import { SolidButton } from "Components/Buttons";
import * as S from "./styles";
import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { GridProps } from "@mui/system";
import { Section } from "Components/Section";
import { RELATIVE_ROUTES } from "constants/routing";
import { CarouselCard, CarouselCardSlideProps } from "Components/Cards/CarouselCard";

export interface HeroProps extends GridProps {
  items?: Array<CarouselCardSlideProps>;
  isLoading?: boolean;
  isError?: boolean;
}

export const HeroSection: FC<HeroProps> = ({
  items = [],
  isLoading = false,
  isError = false,
}) => (
  <Section id="banner" sx={{ justifyContent: "center" }}>
    <S.Spotlight
      container
      spacing="1rem"
      marginBottom="2rem"
      alignItems="center"
    >
      <Grid item xs={12} sm={6} md={7} lg={8}>
        <Typography variant="h1">Discover. Collect. Sell.</Typography>
        <Typography marginBottom="1rem" variant="h3">
          Community-centric home of NEAR Protocol NFTs
        </Typography>
        <S.CTA>
          <SolidButton
            startIcon={<Discover />}
            size="large"
            href={`${RELATIVE_ROUTES.DISCOVER}`}
          >
            Discover
          </SolidButton>
          <SolidButton
            startIcon={<ListIcon />}
            color="secondary"
            href={`${RELATIVE_ROUTES.PROFILE}`}
          >
            Sell
          </SolidButton>
        </S.CTA>
      </Grid>
      {!isError && (
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <CarouselCard items={items} isLoading={isLoading} />
        </Grid>
      )}
    </S.Spotlight>
  </Section>
);
