import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

import { CollectionTokensRequestConfig, Token } from "api/models";
import { NftCard } from "Components/Cards/NftCards";
import NoResult from "Components/NoResult";
import { GridContainer } from "../styles";
import TokenFilterContainer from "../TokenFilter";
import CardSkeleton from "../CardSkeleton";

interface TokenGridContainerProps {
  isFetching: boolean;
  smallCollectionCard: boolean;
  showFilter: boolean;
  tokens: Token[] | null;
  filters: CollectionTokensRequestConfig;
  smallScreen: boolean;
  isFetchingNextPage: boolean;
  floorPrice: string;
  handleApplyFilters: (filters: CollectionTokensRequestConfig) => void;
}

function TokenGridContainer({
  isFetching,
  smallCollectionCard,
  showFilter,
  tokens,
  filters,
  smallScreen,
  isFetchingNextPage,
  handleApplyFilters,
  floorPrice,
}: TokenGridContainerProps) {
  const navigate = useNavigate();

  const handleClick = (id: string) => {
    navigate(`./${id}`);
  };

  return (
    <GridContainer>
      {tokens && tokens.length === 0 ? (
        <NoResult />
      ) : (
        <Grid container spacing={4}>
          {isFetching && !isFetchingNextPage ? (
            <CardSkeleton
              smallCollectionCard={smallCollectionCard}
              filterMode={showFilter}
            />
          ) : (
            tokens
              ?.filter(
                (item) => !(filters?.is_listed === true && !item.listed_price)
              )
              .map((item) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={smallCollectionCard ? 4 : 6}
                  lg={smallCollectionCard ? 3 : 4}
                  xl={smallCollectionCard ? 3 : 4}
                  key={item.token_name}
                >
                  <NftCard
                    token={item}
                    filter={showFilter}
                    showAddToCartBtn={false}
                    floorPrice={floorPrice}
                    onClick={() => handleClick(item.token_id)}
                  />
                </Grid>
              ))
          )}
          {isFetchingNextPage && (
            <CardSkeleton
              smallCollectionCard={smallCollectionCard}
              filterMode={showFilter}
              number={4}
            />
          )}
        </Grid>
      )}
      <TokenFilterContainer
        defaultFilters={filters}
        applyFilters={handleApplyFilters}
        show={!smallScreen && showFilter}
      />
    </GridContainer>
  );
}

export default TokenGridContainer;
