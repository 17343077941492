import { styled } from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { theme } from "ui/theme/theme";

export const ProfileHeader = styled(Typography)<TypographyProps>(() => ({
  fontSize: "3.5rem",
  ineHeight: "67.2px",
  color: theme.colors.white,
  [theme.breakpoints.down("md")]: {
    fontSize: "2rem",
    lineHeight: "38.4px",
    fontWeight: "600",
  },
}));
