import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Slider, { SliderProps } from "@mui/material/Slider";

import { styled } from "@mui/material";

export const CustomTextField = styled(TextField)<TextFieldProps>(
  ({ theme }) => ({
    background: theme.colors.white,
    borderRadius: 40,
    position: "relative",
    width: 72,
    height: 32,
    "input.MuiOutlinedInput-input": {
      padding: "4px 0 5px",
    },
    "&:focus": {
      outline: "none",
    },
    "& .MuiOutlinedInput-root": {
      borderColor: theme.colors.black,
      borderRadius: 40,
      color: theme.colors.black,
      height: 32,
      fontSize: "12px",
      "& fieldset": {
        borderColor: theme.colors.black,
      },
      "&:hover fieldset": {
        borderColor: theme.colors.black,
      },
      "&:hover": {
        borderColor: theme.colors.black,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.colors.black,
      },
    },
    [theme.breakpoints.down("md")]: {
      width: "auto",
      "& .MuiOutlinedInput-root": {
        borderColor: theme.colors.white,
        "& fieldset": {
          borderColor: theme.colors.white,
        },
        "&:hover fieldset": {
          borderColor: theme.colors.white,
        },
        "&:hover": {
          borderColor: theme.colors.white,
        },
        "&.Mui-focused fieldset": {
          borderColor: theme.colors.white,
        },
      },
    },
  })
);

export const CustomSlide = styled(Slider)<SliderProps>(() => ({
  "& .MuiSlider-thumb": {
    height: "12px",
    width: "12px",
  },
}));

export const Subtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "19.2px",
  marginBottom: "12px",
  [theme.breakpoints.down("md")]: {
    color: theme.colors.white,
    marginBottom: "16px",
  },
}));

export const Hyphen = styled(Box)<BoxProps>(({ theme }) => ({
  width: "8px",
  height: "1px",
  backgroundColor: theme.colors.black,
  [theme.breakpoints.down("md")]: {
    backgroundColor: theme.colors.white,
  },
}));
