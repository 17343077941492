import { Box, BoxProps, Link, Paper, PaperProps, styled } from "@mui/material";
import { theme } from "ui/theme/theme";

export const Card = styled(Box)<BoxProps>(() => ({
  position: "relative",
  aspectRatio: "1",
  display: "flex",
}));

export const CollectionNamePaper = styled(Paper)<PaperProps>(() => ({
  backgroundColor: "transparent",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  top: "1rem",
  left: 0,
  right: 0,
}));

export const BoxTag = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flexDirection: "row",
  gap: "6px",
  background: theme.colors.white,
  borderRadius: "60px",
  fontWeight: "600px",
  zIndex: "1",
}));

export const ImageFrame = styled(Link)(() => ({
  display: "block",
  objectFit: "cover",
  overflow: "hidden",
  width: "100%",
  height: "100%",
  position: "relative",
  backgroundSize: "cover",
  backgroundPosition: "center",
  aspectRatio: "1",
  cursor: "pointer",
  textDecoration: "none"
}));

export const AuthorPaper = styled(Paper)<PaperProps>(() => ({
  backgroundColor: "transparent",
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  bottom: "1rem",
  left: 0,
  right: 0,
  zIndex: "100",
}));
