import Box, { BoxProps } from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiPopover, { PopoverProps } from "@mui/material/Popover";
import { styled } from "@mui/material/styles";

import check from "assets/check.svg";

export const MenuButton = styled(Button)<
  ButtonProps & { selectedAll: boolean }
>(({ theme, selectedAll }) => ({
  backgroundColor: selectedAll
    ? theme.colors.white
    : "rgba(255, 255, 255, 0.12)",
  borderRadius: 25,
  color: selectedAll ? theme.colors.black : theme.colors.white,
  fontSize: 12,
  height: 26,
  textTransform: "none",
  ":hover": {
    backgroundColor: selectedAll
      ? theme.colors.white
      : "rgba(255, 255, 255, 0.12)",
  },
}));

export const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 18,
  height: 18,
  backgroundColor: theme.colors.teal,
}));

export const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.colors.teal,
  "&:before": {
    display: "block",
    width: 18,
    height: 18,
    backgroundImage: `url(${check})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    content: '""',
  },
}));

export const FormLabel = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    color: theme.colors.black,
    fontSize: 14,
  },
}));

export const CheckboxContainer = styled(Box)<BoxProps>(() => ({
  padding: "20px",
  width: "143px",
}));

export const Popover = styled(MuiPopover)<PopoverProps>(() => ({
  "& .MuiPaper-root": {
    marginTop: "4.33px",
  },
}));
