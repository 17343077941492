import * as S from "./styles";
import { SimpleCard } from "Components/Cards/SimpleCard";
import { FC, useState } from "react";
import { Fire } from "assets/icons/Fire";
import { BoxProps, CircularProgress, Grid } from "@mui/material";
import { Collection } from "api";
import { SectionHeader } from "Components/Section/SectionHeader";
import { Section } from "Components/Section";
import { useEnabledFeatures } from "hooks/useFeatures";

export interface NewCollectionsProps extends BoxProps {
  collections?: Array<Collection>;
  isLoading?: boolean;
}

const NewCollections: FC<NewCollectionsProps> = ({
  collections = [],
  isLoading,
}) => {
  const features = useEnabledFeatures();
  const [currentPage, setCurrentPage] = useState<number>(0);

  const itemsPerPage = 6;
  const totalPages = collections.length / itemsPerPage;

  const handleGoBack = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleGoForward = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <Section>
      <S.SectionHeaderContainer id="new-collections">
        <SectionHeader
          Icon={<Fire />}
          title="New collections"
          subtitle="Just dropped, show them some love."
          dropdownOptions={
            features.home.newCollectionsDropdown
              ? ["Today", "Popular", "New"]
              : undefined
          }
          withPagination
          handleGoBack={handleGoBack}
          handleGoForward={handleGoForward}
        />
      </S.SectionHeaderContainer>
      {isLoading ? (
        <CircularProgress sx={{ margin: "0 auto" }} />
      ) : (
        <Grid container spacing="2rem">
          {collections.map((collection, index) => {
            collection.index = index + 1
            return collection
          })
            .slice(itemsPerPage * currentPage, itemsPerPage * currentPage + 6)
            .map((collection) => (
              <Grid
                item
                key={collection.contract_id}
                xs={12}
                sm={6}
                md={12}
                lg={6}
              >
                <SimpleCard {...collection} index={collection.index} />
              </Grid>
            ))}
        </Grid>
      )}
    </Section>
  );
};

export default NewCollections;
