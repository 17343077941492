import { FC } from "react";
import { SVGProps } from "interfaces";

export const Close: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
  type,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {type === "duotone" ? (
      <>
        <path
          d="M9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16Z"
          fill="white"
          stroke={color}
          strokeWidth="1.7"
        />
        <path
          d="M7.05811 7.01953L11.0179 10.9793"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
        <path
          d="M11.0179 7.01953L7.05811 10.9793"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
      </>
    ) : (
      <>
        <path
          d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
          stroke={color}
          strokeWidth="1.7"
        />
        <path
          d="M8.05762 8.01953L12.0174 11.9793"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
        <path
          d="M12.0174 8.01953L8.05762 11.9793"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
      </>
    )}
  </svg>
);
