import Modal from "Components/Modal";
import React, { ReactElement, useState } from "react";

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const show = () => setIsVisible(true);
  const hide = () => setIsVisible(false);

  const RenderModal = ({ children }: { children: ReactElement }) =>
    isVisible ? <Modal onClose={hide}>{children}</Modal> : null;

  return {
    show,
    hide,
    RenderModal,
  };
};
