import { Typography, TypographyProps } from "@mui/material";
import { NearLogo } from "assets/icons";
import { formatNumberWhenExact } from "utils/number-format";

export interface CollectionPriceProps extends TypographyProps {
  price: string;
}

export const CollectionPrice = ({
  price = "0",
  sx,
  ...rest
}: CollectionPriceProps) => (
  <Typography
    lineHeight="1"
    className="collection-price"
    noWrap
    sx={{ ...sx, display: "flex", alignItems: "center" }}
    {...rest}
  >
    {price ? formatNumberWhenExact(price) : "Not listed"}
    {price && <NearLogo width="1em" color="inherit" />}
  </Typography>
);
