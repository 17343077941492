import Box from "@mui/material/Box";
import NoResult from "Components/NoResult";
import ActivityFilterContainer from "../ActivityFilter";
import ActivityList from "../ActivityList";
import { GridContainer } from "../styles";
import { ActivityStatus, Activity } from "../types";

interface ActivityContainerProps {
  showActivityFilter: boolean;
  smallScreen: boolean;
  activityStatus: ActivityStatus;
  activityData: Activity[] | null;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  handleSetStatus: (status: ActivityStatus) => void;
}

function ActivityContainer({
  showActivityFilter,
  smallScreen,
  activityStatus,
  activityData,
  isLoading,
  isFetchingNextPage,
  handleSetStatus,
}: ActivityContainerProps) {
  return (
    <GridContainer>
      <Box style={showActivityFilter ? { flexGrow: 1 } : { width: "100%" }}>
        {activityData && activityData.length === 0 ? (
          <NoResult />
        ) : (
          <ActivityList
            status={activityStatus}
            activityList={activityData}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
          />
        )}
      </Box>
      {showActivityFilter && !smallScreen && (
        <ActivityFilterContainer
          status={activityStatus}
          setStatus={handleSetStatus}
        />
      )}
    </GridContainer>
  );
}

export default ActivityContainer;
