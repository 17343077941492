import styled from "@emotion/styled";
import { theme } from "ui/theme/theme";
import {
  Box,
  BoxProps,
  Button as MuiButton,
  ButtonProps,
  Card as MuiCard,
  CardContent,
  CardContentProps,
  Divider as MuiDivider,
  Typography,
  TypographyProps,
} from "@mui/material";

export const Card = styled(MuiCard)<{ filter?: boolean }>(({ filter }) => ({
  flex: 1,
  backgroundColor: "rgba(255, 255, 255, 0.06)",
  fontSize: filter ? "10px" : "14px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

export const CardActions = styled(Box)<BoxProps>(() => ({
  color: theme.colors.white,
  display: "flex",
  alignItems: "center",
}));

export const CardHeader = styled(Box)(() => ({
  position: "absolute",
  color: theme.colors.white,
  inset: 0,
  padding: "1.5rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const Index = styled(Box)<BoxProps>(() => ({
  minWidth: "1.875em",
  aspectRatio: "1/1",
  backgroundColor: theme.colors.white,
  color: theme.colors.black,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
}));

export const ContractId = styled.span`
  position: absolute;
  width: 10px;
  height: 17px;
  left: 9px;
  top: 6px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${theme.colors.black};
`;

export const CardBox = styled(Box)<BoxProps & { filter?: boolean }>(
  ({ filter }) => ({
    justifyContent: "space-between",
    color: theme.colors.white,
    lineheight: "120%",
    display: "flex",
    "p > span": {
      fontSize: filter ? "10.5px" : "14px",
    },
  })
);

export const CardIconTypography = styled(Typography)<TypographyProps>(() => ({
  margin: "5px",
  display: "flex",
  justifyContent: "center",
}));

export const ContentDiv = styled.div`
  background-color: ${theme.colors.black};
`;

export const CardContentCard = styled(CardContent)<CardContentProps>(() => ({
  flex: "1",
  padding: "1.5rem",
}));

export const Divider = styled(MuiDivider)(() => ({
  backgroundColor: "rgba(255, 255, 255, 0.1)",
}));

export const Button = styled(MuiButton)<ButtonProps>(() => ({
  flex: "1 1 max-content",
  justifyContent: "center",
  padding: "1rem 1.5rem",
  boxShadow: "none",
  textTransform: "none",
  color: theme.colors.white,
  backgroundColor: "transparent",
  borderRadius: "0",
  fontWeight: "bold",
  fontSize: "0.875rem",
  lineHeight: "1",
  textAlign: "left",
  "&:hover": {
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
  },
  "&:not(:last-child)": {
    borderRight: "1px solid rgba(255, 255, 255, 0.1)",
  },
}));
