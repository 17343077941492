import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { theme } from "ui/theme/theme";

export const StyledBreadcrumbsLink = styled(Link)`
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;

  color: ${() => theme.colors.white};
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

export const StyledBreadcrumbsCurrentLink = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;

  color: ${() => theme.colors.white};
`;
