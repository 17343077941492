import { Theme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { format } from "timeago.js";

import { Bid } from "assets/icons/Bid";
import { Cart } from "assets/icons/Cart";
import { ListIcon } from "assets/icons/List";
import { theme } from "ui/theme/theme";
import { numberFormat } from "utils/number-format";
import {
  Bold,
  BoldTeal,
  ListItemContainer,
  Subtitle,
  Tag,
  TagList,
  TagOffer,
  Time,
  Title,
} from "./styles";

import { Activity, ActivityStatus, ActivityType } from "../types";

interface ActivityListItemProps {
  title: string;
  image: string;
  buyer?: string;
  seller?: string;
  price: string;
  time: number;
  type: ActivityType;
}

const renderTag = (type: ActivityType) => {
  switch (type) {
    case "sale":
      return <Tag icon={<Cart height="12" width="12" />} label="Sale" />;
    case "list":
      return (
        <TagList icon={<ListIcon height="12" width="12" />} label="List" />
      );
    case "offer":
      return (
        <TagOffer
          icon={<Bid height="12" width="12" color={theme.colors.white} />}
          label="Offer"
        />
      );
    default:
      return null;
  }
};

const renderSubtitle = (
  type: ActivityType,
  price: string,
  seller?: string,
  buyer?: string
) => {
  const formatPrice = numberFormat(Number(price));
  switch (type) {
    case "sale":
      return (
        <>
          Sold by <BoldTeal>{seller}</BoldTeal> to <BoldTeal>{buyer}</BoldTeal>{" "}
          for <Bold>{formatPrice} NEAR</Bold>
        </>
      );
    case "list":
      return (
        <>
          Listed by <BoldTeal>{seller}</BoldTeal> for{" "}
          <Bold>{formatPrice} NEAR</Bold>
        </>
      );
    case "offer":
      return (
        <>
          <BoldTeal>{buyer}</BoldTeal> offered <Bold>{formatPrice} NEAR</Bold>
        </>
      );
    default:
      return null;
  }
};

const ActivityListItem = ({
  title,
  image,
  buyer,
  seller,
  price,
  time,
  type,
}: ActivityListItemProps) => {
  const match = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  return (
    <ListItemContainer>
      <Stack direction="column" spacing={1.25}>
        {match && <Title>{title}</Title>}
        <img
          src={image}
          alt="don"
          height={match ? 88 : 129}
          width={match ? 88 : 129}
          style={{ borderRadius: 5, marginRight: match ? 18 : 24 }}
        />
      </Stack>
      <Stack
        direction="column"
        spacing={1}
        sx={{
          marginTop: match ? "7px" : "4.5px",
          marginBottom: match ? "7px" : "4.5px",
          overflow: "auto",
        }}
      >
        {renderTag(type)}
        <Stack direction="column" spacing={0.25}>
          {!match && <Title>{title}</Title>}
          <Subtitle>{renderSubtitle(type, price, seller, buyer)}</Subtitle>
        </Stack>
        <Time>{format(time)}</Time>
      </Stack>
    </ListItemContainer>
  );
};

function ActivityListItemSkeleton() {
  return (
    <>
      {Array.from(Array(4).keys()).map((item) => (
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          variant="rectangular"
          height={152}
          width="100%"
          key={item}
        />
      ))}
    </>
  );
}

interface ActivityListProps {
  status: ActivityStatus;
  activityList: Activity[] | null;
  isLoading: boolean;
  isFetchingNextPage: boolean;
}

const ActivityList = ({
  status,
  activityList,
  isLoading,
  isFetchingNextPage,
}: ActivityListProps) => (
  <Stack direction="column" spacing={4}>
    {isLoading && !isFetchingNextPage ? (
      <ActivityListItemSkeleton />
    ) : (
      activityList
        ?.filter((item) => item.type.includes(status))
        .map((item) => (
          <ActivityListItem key={item.title + item.time} {...item} />
        ))
    )}
    {isFetchingNextPage && <ActivityListItemSkeleton />}
  </Stack>
);

export default ActivityList;
