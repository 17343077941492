import { BoxProps } from "@mui/system";
import { Verified } from "assets/icons";
import { FC } from "react";
import * as S from "./styles";

export interface CollectionCreatorNameProps extends BoxProps {
  name?: string;
  avatar?: string;
  verified?: boolean;
  transparent?: boolean;
}

export const CollectionCreatorName: FC<CollectionCreatorNameProps> = ({
  avatar,
  name,
  verified,
  transparent,
  ...rest
}) =>
  name || verified || avatar ? (
    <S.Creator fontSize="1rem" {...rest}>
      {avatar && <S.CreatorAvatar alt={name} src={avatar} />}
      {name && <S.CreatorName>{name}</S.CreatorName>}
      {verified ? (
        <Verified width="1em" type={transparent ? "duotone" : "filled"} />
      ) : null}
    </S.Creator>
  ) : null;
