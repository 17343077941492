import { FC, useEffect, useState } from "react";
import ComingSoonCollections from "../components/ComingSoonCollections";
import { HeroSection } from "../components/HeroSection";
import NewCollections from "../components/NewCollections";
import Spotlight from "../components/Spotlight";
import TopCollections from "../components/TopCollections";
import AllCollections from "../components/AllCollections";
import { Container } from "@mui/system";
import { useHome } from "api";
import { Grid } from "@mui/material";
import { Section } from "Components/Section/styles";
import { CarouselCardSlideProps } from "Components/Cards/CarouselCard";
import { getHeroItems } from "../utilities/hero";

type Props = {};

const Home: FC<Props> = () => {
  const { data, isError, isLoading } = useHome();

  const [heroItems, setHeroItems] = useState<Array<CarouselCardSlideProps>>([]);

  useEffect(() => {
    if (data) {
      setHeroItems(getHeroItems(data));
    }
  }, [data]);

  return (
    <Container sx={{ paddingBottom: "1rem" }}>
      <HeroSection isError={isError} isLoading={isLoading} items={heroItems} />
      {!isError && (
        <>
          <TopCollections
            isLoading={isLoading}
            collections={data?.top_collections}
          />
          <Section>
            <Grid container spacing="1rem">
              <Grid item xs={12} md={6}>
                <NewCollections
                  isLoading={isLoading}
                  collections={data?.new_collections}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ComingSoonCollections
                  isLoading={isLoading}
                  collections={data?.upcoming_collections}
                />
              </Grid>
            </Grid>
          </Section>
          <Spotlight
            isLoading={isLoading}
            collections={data?.spotlighted_collection}
          />
        </>
      )}
      <AllCollections />
    </Container>
  );
};

export default Home;
