import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NoResultIcon } from "assets/icons/NoResult";
import { Container, MainText, SubText } from "./styles";

function NoResult() {
  const smallScreen = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  const size = smallScreen ? "110" : "160";

  return (
    <Container sx={{ display: "flex" }}>
      <NoResultIcon height={size} width={size} />
      <MainText>Damn, we couldn’t find any results for you.</MainText>
      <SubText>Try adjusting your search or removing some filters.</SubText>
    </Container>
  );
}

export default NoResult;
