import * as React from "react";
import Box from "@mui/material/Box";
import WalletContainer from "../../../ui/components/Header/WalletContainer";
import WalletButtonSizable from "Components/Buttons/WalletButtonSizable";
import ListCard from "Components/Cards/ListCard";
import FormItem from "../FormItem";
import useMarketplace from "../../../hooks/marketPlace";
import { useNearWallet } from "../../../hooks/useNear";
import {
  TextNormal,
  MainHead,
  StyledContainer,
  Logo,
  LogoView,
  DisplayControlledBox,
} from "./styles";
import CloseIcon from "@mui/icons-material/Close";

import logo from "assets/logo.svg";
import { Token } from "api";

import ConnectPanel from "Components/ConnectPanel";

interface MainPanelProps {
  onClose?: () => void;
  setResultPanel: (value: boolean) => void;
  setTitle: (value: string) => void;
  setSubTitle: (value: string) => void;
  setIconName: (value: string) => void;
  panelMainTitle: string;
  panelInputTitle: string;
  panelInputPlaceHolder?: string | null;
  buttonText: string;
  Icon: React.ReactElement;
  token: Token;
  isLoggedIn: boolean;
}
export default function MainPanel({
  onClose,
  setResultPanel,
  setSubTitle,
  setTitle,
  setIconName,
  panelMainTitle,
  panelInputPlaceHolder,
  buttonText,
  Icon,
  token,
  isLoggedIn,
}: MainPanelProps) {
  const marketPlace = useMarketplace();
  const { accountId } = useNearWallet();

  const fillResultPanel = (
    title: string,
    subTitle: string,
    iconName: string
  ) => {
    setTitle(title);
    setSubTitle(subTitle);
    setIconName(iconName);
    setResultPanel(false);
  };

  const handleClickBuy = async (value: string) => {
    if (accountId === token.owner) {
      fillResultPanel(accountId, "You can't buy your own token.", "largeLoad");
    } else {
      fillResultPanel(
        "Processing your payment",
        "Don’t close this window. This shouldn’t take long.",
        "largeLoad"
      );
      await marketPlace.buyToken(token.contract_id, token.token_id, value);
    }
  };

  return (
    <>
      {isLoggedIn && (
        <StyledContainer>
          <LogoView>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Logo src={logo} alt="logo" />
              <CloseIcon onClick={onClose} />
            </Box>
          </LogoView>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MainHead>{panelMainTitle}</MainHead>
            <WalletContainer Button={WalletButtonSizable} menuwidth="180px" />
            <DisplayControlledBox>
              <CloseIcon onClick={onClose} />
            </DisplayControlledBox>
          </Box>
          <TextNormal sx={{ paddingTop: "32px", paddingBottom: "24px" }}>
            Item
          </TextNormal>
          <ListCard {...token} />

          <FormItem
            onClose={onClose}
            panelInputPlaceHolder={panelInputPlaceHolder}
            buttonText={buttonText}
            Icon={Icon}
            handleSubmit={handleClickBuy}
            price={token.listed_price ? token.listed_price : "-"}
          />
        </StyledContainer>
      )}
      {!isLoggedIn && (
        <ConnectPanel
          setResultPanel={setResultPanel}
          onClose={onClose}
          title="Please Login first"
          subtitle="To be able to buy a token"
        />
      )}
    </>
  );
}
