import { FC } from "react";
import { SVGProps } from "interfaces";

export const LargeLoad: FC<SVGProps> = ({
  width = "40",
  height = "40",
  color = "#9CFFF8",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7799 38C9.39946 36.6448 3 29.3763 3 20.6133C3 10.8857 10.8857 3 20.6133 3C29.3723 3 36.638 9.39354 37.998 17.7683M37.3056 26.2495C36.8505 27.5971 36.2373 28.8717 35.4874 30.0512L37.3056 26.2495ZM26.2495 37.3056C27.5906 36.8527 28.8594 36.2432 30.034 35.4981L26.2495 37.3056Z"
      stroke={color}
      strokeWidth="2.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
