import { FC } from "react";
import { SVGProps } from "interfaces";

export const ArtStyle: FC<SVGProps> = ({
  width = "18",
  height = "18",
  color = "rgb(29, 27, 27)",
}) => (
  <svg
    width={width}
    height={height}
    style={{ color }}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8714 6.54467C15.8714 7.82802 15.6752 9.0779 15.501 10.2792C15.4074 10.9242 14.8572 11.4116 14.2053 11.4116H3.8027C3.15095 11.4116 2.60075 10.9242 2.5072 10.2792C2.33297 9.0779 2.13672 7.82802 2.13672 6.54467C2.13672 5.23399 2.34143 3.95821 2.51829 2.73347C2.60662 2.12189 3.10615 1.64686 3.72181 1.59419C5.41391 1.44945 7.18313 1.25977 9.00405 1.25977C10.825 1.25977 12.5942 1.44945 14.2862 1.59419C14.9019 1.64686 15.4014 2.12189 15.4898 2.73347C15.6667 3.95821 15.8714 5.23399 15.8714 6.54467Z"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
    <path
      d="M7.09766 11.4121C7.78875 9.27413 8.91613 7.24875 10.697 5.91544C11.0786 5.62974 11.6006 5.62975 11.9822 5.91547C13.5734 7.10691 14.6429 8.85073 15.3457 10.7338C15.1202 11.1402 14.69 11.4121 14.2032 11.4121H7.09766Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.91974 5.71848C5.66636 5.71848 5.46094 5.50167 5.46094 5.23424C5.46094 4.9668 5.66636 4.75 5.91974 4.75"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.92188 4.75C6.17528 4.75 6.3807 4.96681 6.3807 5.23424C6.3807 5.50168 6.17528 5.71848 5.92188 5.71848"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.7277 11.4121H1.27734"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.00391 16.734V11.4121"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.51953 16.4762L6.37881 11.4121"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4842 16.4762L11.625 11.4121"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
