import { styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Button, { ButtonProps } from "@mui/material/Button";
import Chip, { ChipProps } from "@mui/material/Chip";
import Stack, { StackProps } from "@mui/material/Stack";

export const FilterBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: "5px",
  color: theme.colors.black,
  minWidth: "240px",
  padding: "24px",
  height: "fit-content",
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  [theme.breakpoints.down("md")]: {
    fontSize: "32px",
    color: theme.colors.white,
    lineHeight: "120%",
  },
}));

export const Subtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "19.2px",
  marginBottom: "12px",
  [theme.breakpoints.down("md")]: {
    color: theme.colors.white,
    marginBottom: "16px",
  },
}));

export const GreyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.grey,
  borderRadius: 40,
  color: theme.colors.black,
  display: "flex",
  fontSize: 9,
  lineHeight: "10.8px",
  height: "25px",
  minWidth: "40px",
  textTransform: "none",
  width: "44px",
  "&:hover": {
    backgroundColor: theme.colors.grey,
  },
  [theme.breakpoints.down("md")]: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    color: theme.colors.white,
    fontSize: 14,
    height: "35px",
    width: "72px",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  },
}));

export const StatusChip = styled(Chip)<ChipProps>(({ theme }) => ({
  "&.MuiChip-root": {
    fontSize: "9px",
    height: "25px",
    minWidth: "56px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      height: "32px",
    },
  },
  "&.MuiChip-outlined": {
    borderColor: theme.colors.black,
    [theme.breakpoints.down("md")]: {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      borderColor: "rgba(255, 255, 255, 0.01)",
    },
  },
  "&.MuiChip-filled": {
    backgroundColor: theme.colors.teal,
    [theme.breakpoints.down("md")]: {
      backgroundColor: theme.colors.white,
    },
    "& .MuiChip-label": {
      [theme.breakpoints.down("md")]: {
        color: theme.colors.black,
      },
    },
  },
  "& .MuiChip-label": {
    color: theme.colors.black,
    lineHeight: "120%",
    paddingLeft: "3px",
    paddingRight: 0,
    [theme.breakpoints.down("md")]: {
      color: theme.colors.white,
    },
  },
}));

export const ChipStack = styled(Stack)<StackProps>(() => ({
  columnGap: "8px",
  flexWrap: "wrap",
  rowGap: "8px",
}));
