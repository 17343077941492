import { ChangeEvent, useState, useEffect, Fragment } from "react";
import { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { RequestConfig, CollectionLabel } from "api/models";
import MultipleChips, { StatusChipProps } from "Components/MultipleChips";
import PriceSelector from "Components/PriceSelector";
import { FilterBox, Title, GreyButton, TealButton } from "./styles";

const statusValues = [
  { name: "All", value: 156, checked: true },
  { name: "Buy now", value: 121, checked: false },
  { name: "Make an offer", value: 35, checked: false },
];

const MAX_PRICE = 1000;

interface TokenFilterProps {
  defaultFilters: RequestConfig;
  labels: CollectionLabel[];
  applyFilters: (filters: RequestConfig) => void;
}

function getAllLabels(
  labels: CollectionLabel[] | undefined,
  labelKey: string,
  defaultFilters: RequestConfig
) {
  const checkedValue = defaultFilters?.has_labels?.split(",") || [];
  const mappedLabels =
    labels
      ?.find((item) => item.label_type === labelKey)
      ?.label_values.map((item) => ({
        name: item,
        checked: checkedValue.includes(item),
      })) || [];

  return [
    { name: "All", checked: !mappedLabels.some((item) => item.checked) },
    ...mappedLabels,
  ];
}

function TokenFilter({
  defaultFilters,
  labels,
  applyFilters,
}: TokenFilterProps) {
  const [price, setPrice] = useState<number[]>([
    defaultFilters.floor_price_min || 0,
    defaultFilters.floor_price_max || MAX_PRICE,
  ]);
  const [lowerPrice, setLowerPrice] = useState<number>(
    defaultFilters.floor_price_min || 0
  );
  const [upperPrice, setUpperPrice] = useState<number>(
    defaultFilters.floor_price_max || MAX_PRICE
  );
  const [filters, setFilters] = useState<RequestConfig>(defaultFilters);
  const [selectedLabel, setSelectedLabel] = useState<string[]>(["", ""]);
  const [artLabels, setArtLabels] = useState<StatusChipProps[]>([]);
  const [categoryLabels, setCategoryLabels] = useState<StatusChipProps[]>([]);

  const smallScreen = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  const Container = smallScreen ? Fragment : FilterBox;

  useEffect(() => {
    if (labels?.length) {
      const defaultArtLabels = getAllLabels(
        labels,
        "art_style",
        defaultFilters
      );
      const defaultCategoryLabels = getAllLabels(
        labels,
        "category",
        defaultFilters
      );
      setArtLabels(defaultArtLabels);
      setCategoryLabels(defaultCategoryLabels);
      const selectedArtLabel =
        defaultArtLabels.find((item) => item.checked)?.name || "All";
      const selectedCategoryLabel =
        defaultCategoryLabels.find((item) => item.checked)?.name || "All";
      setSelectedLabel([
        selectedArtLabel === "All" ? "" : selectedArtLabel,
        selectedCategoryLabel === "All" ? "" : selectedCategoryLabel,
      ]);
    }
  }, [labels, defaultFilters]);

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      floor_price_min: price[0],
      floor_price_max: price[1],
    }));
  }, [price]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    const value = newValue as number[];
    setPrice(value);
    setLowerPrice(value[0]);
    setUpperPrice(value[1]);
  };

  const handleLowerPriceChange = (event: ChangeEvent) => {
    const newLowerPrice = Number((event.target as HTMLInputElement).value);
    setLowerPrice(newLowerPrice);
    setPrice([newLowerPrice, upperPrice]);
  };

  const handleUpperPriceChange = (event: ChangeEvent) => {
    const newUpperPrice = Number((event.target as HTMLInputElement).value);
    setUpperPrice(newUpperPrice);
    setPrice([lowerPrice, newUpperPrice]);
  };

  const handleApplyFilters = () => {
    if (filters?.floor_price_min === 0) {
      delete filters.floor_price_min;
    }

    if (filters?.floor_price_max === MAX_PRICE) {
      delete filters.floor_price_max;
    }

    delete filters.has_labels;
    const hasLabels = selectedLabel.filter(Boolean).join(",");

    applyFilters(hasLabels ? { ...filters, has_labels: hasLabels } : filters);
  };

  const handleResetFilters = () => {
    setLowerPrice(0);
    setUpperPrice(MAX_PRICE);
    setPrice([0, MAX_PRICE]);
    setArtLabels(
      artLabels.map((item) => {
        if (item.name === "All") {
          return { ...item, checked: true };
        }
        return { ...item, checked: false };
      })
    );
    setCategoryLabels(
      categoryLabels.map((item) => {
        if (item.name === "All") {
          return { ...item, checked: true };
        }
        return { ...item, checked: false };
      })
    );
    setSelectedLabel(["", ""]);
    setFilters({});
  };

  const handleArtLabelChange = (name: string) => {
    setArtLabels(
      artLabels.map((item) => {
        if (item.name === name) {
          return { ...item, checked: true };
        }
        return { ...item, checked: false };
      })
    );
    const categoryLabel = selectedLabel[1];
    if (name === "All") {
      setSelectedLabel(["", categoryLabel]);
    } else {
      setSelectedLabel([name, categoryLabel]);
    }
  };

  const handleCategoryLabelChange = (name: string) => {
    setCategoryLabels(
      categoryLabels.map((item) => {
        if (item.name === name) {
          return { ...item, checked: true };
        }
        return { ...item, checked: false };
      })
    );
    const artLabel = selectedLabel[0];
    if (name === "All") {
      setSelectedLabel([artLabel, ""]);
    } else {
      setSelectedLabel([artLabel, name]);
    }
  };

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" marginBottom="25px">
        <Title>Filter</Title>
        <Stack direction="row" spacing={0.5}>
          <TealButton onClick={handleApplyFilters}>Apply filters</TealButton>
          <GreyButton onClick={handleResetFilters}>Reset</GreyButton>
        </Stack>
      </Box>
      <MultipleChips
        multipleChipsTitle="Status"
        attributes={statusValues}
        handleClick={() => {}}
      />
      <PriceSelector
        priceSelectorTitle="Price"
        lowerPrice={lowerPrice}
        upperPrice={upperPrice}
        slideValue={MAX_PRICE}
        price={price}
        handleChange={handleChange}
        handleLowerPriceChange={handleLowerPriceChange}
        handleUpperPriceChange={handleUpperPriceChange}
      />

      <MultipleChips
        multipleChipsTitle="Art Style"
        attributes={artLabels}
        handleClick={handleArtLabelChange}
      />
      <MultipleChips
        multipleChipsTitle="Category"
        attributes={categoryLabels}
        handleClick={handleCategoryLabelChange}
      />
    </Container>
  );
}

export default TokenFilter;
