import environment from "hooks/useNear/config";
import {
  CollectionActivityRequestConfig,
  CollectionTokensRequestConfig,
  RequestConfig,
} from "./models";

const env = process?.env.REACT_APP_NEAR_ENV || "testnet";
const nearEnv = environment(env);

const base = () => nearEnv.apiUrl;

const getParams = (requestConfig: Object) => {
  const params = new URLSearchParams();
  Array.from(Object.entries(requestConfig)).forEach(([key, value]) => {
    params.append(key, String(value));
  });
  return params;
};

const collections = (requestConfig?: RequestConfig) => {
  let path = `/collections`;
  if (requestConfig) {
    const params = getParams(requestConfig);
    path = `${path}?${params}`;
  }
  return path;
};

const home = () => `/home`;

const homeLabels = () => `${home()}/labels`;

const collection = (collectionId: string) => `${collections()}/${collectionId}`;

const collectionActivity = (
  collectionId: string,
  requestConfig?: CollectionActivityRequestConfig
) => {
  let path = `${collection(collectionId)}/activity`;
  if (requestConfig) {
    const params = getParams(requestConfig);
    path = `${path}?${params}`;
  }
  return path;
};

const collectionTokens = (
  collectionId: string,
  requestConfig?: CollectionTokensRequestConfig
) => {
  let path = `${collection(collectionId)}/tokens`;
  if (requestConfig) {
    const params = getParams(requestConfig);
    path = `${path}?${params}`;
  }
  return path;
};

const collectionToken = (collectionId: string, tokenId: string) =>
  `${collectionTokens(collectionId)}/${tokenId}`;

const collectionTokenActivity = (collectionId: string, tokenId: string) =>
  `${collectionToken(collectionId, tokenId)}/activity`;

const collectionTokenOffers = (collectionId: string, tokenId: string) =>
  `${collectionToken(collectionId, tokenId)}/offers`;

const profile = (accountId: string) => `/profile/${accountId}`;

export default {
  base,
  home: {
    collections: home,
    labels: homeLabels,
  },
  collections,
  collection: {
    item: collection,
    activity: collectionActivity,
    tokens: collectionTokens,
    token: {
      item: collectionToken,
      activity: collectionTokenActivity,
      offers: collectionTokenOffers,
    },
  },
  profile,
};
