import { FC } from "react";
import { SVGProps } from "interfaces";

export const HomeIcon: FC<SVGProps> = ({
  width = "18",
  height = "18",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 9.91335L8.1517 2.77738C8.59849 2.2591 9.40151 2.2591 9.8483 2.77738L16 9.91335"
      fill="white"
    />
    <path
      d="M2 9.91335L8.1517 2.77738C8.59849 2.2591 9.40151 2.2591 9.8483 2.77738L16 9.91335"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.1542 7.43896L8.15049 2.78311C8.59742 2.2624 9.40327 2.2624 9.85022 2.78311L13.8465 7.43896L14.1381 9.97456C14.302 11.3987 14.2744 12.8383 14.0563 14.2551C13.9355 15.0386 13.2613 15.617 12.4685 15.617H5.53215C4.73937 15.617 4.06514 15.0386 3.94447 14.2551C3.72629 12.8383 3.69874 11.3987 3.86254 9.97456L4.1542 7.43896Z"
      fill="white"
      stroke={color}
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
  </svg>
);
