import InputAdornment from "@mui/material/InputAdornment";
import { Search } from "assets/icons/Search";
import { CustomTextField } from "./styles";
import { theme } from "ui/theme/theme";

interface SearchCollectionProps {
  value: string;
  onChange: (value: string) => void;
}

function SearchColection({ value, onChange }: SearchCollectionProps) {
  return (
    <CustomTextField
      autoComplete="off"
      variant="outlined"
      placeholder="Search for an item"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search height={18} width={18} color={theme.colors.white} />
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}

export default SearchColection;
