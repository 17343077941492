import { Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { theme } from "ui/theme/theme";

import * as S from "./styles";
import { Trash } from "assets/icons/Trash";
import { Offer } from "api";
import { numberFormat } from "utils/number-format";
import useMarketplace from "hooks/marketPlace";

export interface CardProps extends Offer {}

export const OfferedCard = ({
  price,
  token_id,
  collection_name,
  media,
  contract_id,
}: CardProps) => {
  const marketplace = useMarketplace()
  return (
    <S.StyledCard>
      <S.CardMediaAspect>
        <CardMedia
          component="img"
          image={media}
          alt={collection_name}
          sx={{ borderRadius: "5px" }}
          loading="lazy"
        />
      </S.CardMediaAspect>

      <S.ContentZone>
        <S.CardActionTitleBox>
          <Typography lineHeight={1} marginBottom="0.25rem" variant="h6">
            {collection_name} #{token_id}
          </Typography>
          <Typography
            variant="caption"
            display="block"
            color="primary"
            fontWeight="bold"
          >
            Offered: {numberFormat(Number(price), 2)} NEAR
          </Typography>
          <S.CollectionName variant="subtitle2" color={theme.colors.white}>
            {collection_name}
          </S.CollectionName>
        </S.CardActionTitleBox>
      </S.ContentZone>

      <S.CardAction>
        <S.CardActionButton onClick={() => marketplace.cancelTokenOffer(contract_id, token_id)}>
          <Trash color={theme.colors.white} height="13.89" width="13.83" />
        </S.CardActionButton>
      </S.CardAction>
    </S.StyledCard>
  );
};
