import { useNearWallet } from "hooks/useNear";

import Button from "@mui/material/Button";
import {
  TextNormal,
  MainHead,
  MainSection,
  HeadSection,
  MainContainer,
  SolidWhiteButton,
} from "./styles";

import CloseIcon from "@mui/icons-material/Close";
import { Wallet } from "assets/icons/Wallet";

interface ConnectPanelProps {
  onClose?: () => void;
  setResultPanel: (value: boolean) => void;
  title?: string;
  subtitle?: string;
}

export default function ConnectPanel({
  onClose,
  setResultPanel,
  title,
  subtitle,
}: ConnectPanelProps) {
  const { login } = useNearWallet();
  return (
    <MainContainer>
      <HeadSection
        display="flex"
        justifyContent="end"
        alignItems="center"
        sx={{ paddingTop: "129px", paddingBottom: "129px" }}
      >
        <CloseIcon onClick={onClose} />
      </HeadSection>

      <MainSection>
        <MainHead>{title}</MainHead>

        <TextNormal>{subtitle} </TextNormal>

        <SolidWhiteButton
          color="primary"
          size="large"
          variant="contained"
          startIcon={<Wallet />}
          onClick={login}
        >
          {" "}
          Connect Wallet{" "}
        </SolidWhiteButton>

        <Button onClick={() => setResultPanel(false)} />
      </MainSection>
    </MainContainer>
  );
}
