import { styled, Box, BoxProps } from "@mui/material";
import { SolidButton } from "Components/Buttons";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography, { TypographyProps } from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

export const MainBoxContainer = styled(Box)<BoxProps>(({ theme }) => ({
  width: "44.125rem",
  backgroundColor: theme.colors.black,
  borderRadius: "20px 0px 0px 20px",
  padding: "129px 120px",
  height: "auto",
  maxHeight: "100vh",
  color: "white",
  "@media (max-width: 500px)": {
    width: "23.438rem",
    padding: "28px",
    height: "812px",
  },
}));

export const CardActionButton = styled(SolidButton)<ButtonProps>(() => ({
  width: "auto",
  padding: "0.5rem 0.75rem",
  flex: "1 1 auto",
}));

export const CardActionText = styled("b")(() => ({
  fontSize: "80%",
  marginLeft: "0.25rem",
}));

export const HeadSection = styled(Box)<BoxProps>(({ theme }) => ({
  justifyContent: "end",
  alignItems: "center",
  display: "flex",
  marginBottom: "24px",
  [theme.breakpoints.down("md")]: {
    justifyContent: "space-between",
    marginBottom: "32px",
  },
}));

export const GreyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  borderRadius: 35,
  color: theme.colors.white,
  display: "flex",
  fontWeight: 600,
  fontSize: 14,
  height: "40px",
  minWidth: "40px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  fontSize: "32px",
  lineHeight: "120%",
  marginBottom: "40px",
  width: "418px",
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
    width: "100%",
  },
}));

export const TealButton = styled(Button)<ButtonProps>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.teal,
  borderRadius: 35,
  color: theme.colors.black,
  display: "flex",
  fontWeight: 600,
  fontSize: 16,
  height: 56,
  textTransform: "none",
  width: "100%",
  marginBottom: "20px",
  "&:hover": {
    backgroundColor: theme.colors.teal,
  },
  [theme.breakpoints.down("md")]: {
    height: 50,
  },
}));

export const GreyLongButton = styled(TealButton)<ButtonProps>(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  color: theme.colors.white,
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

export const IconContainer = styled(Box)<BoxProps>(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  height: "18px",
  width: "18px",
}));

export const NavButton = styled(IconButton)<ButtonProps>(() => ({
  height: "24px",
  width: "24px",
}));

export const Logo = styled("img")(() => ({
  height: "18.39px",
  marginRight: 0,
}));
