import { FC } from "react";
import { SVGProps } from "interfaces";

export const Twitter: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.31033 13.1945C5.46409 13.8556 4.49278 14.3406 3.45421 14.6206C3.3398 14.6619 3.24097 14.7372 3.17113 14.8364C3.10128 14.9355 3.06381 15.0535 3.06381 15.1746C3.06381 15.2956 3.10128 15.4138 3.17113 15.5128C3.24097 15.6119 3.3398 15.6872 3.45421 15.7285C11.6552 19.0759 16.8697 13.9016 16.0757 8.16174L17.7137 5.96555L15.1865 5.39186C14.8591 4.17 13.8539 3.45721 12.7287 3.25306C11.6035 3.0489 10.061 3.68616 9.19322 4.60463C8.6458 5.42942 8.4741 6.61517 9.01212 8.16164C9.01212 8.16164 6.96214 8.68035 3.4779 5.2741C3.39731 5.19497 3.29559 5.14045 3.18481 5.11704C3.07402 5.09361 2.95882 5.10225 2.85281 5.14194C2.74682 5.18163 2.65448 5.25069 2.58673 5.34096C2.51899 5.43123 2.47867 5.53891 2.47056 5.65127C2.10214 7.15738 2.28651 8.74481 2.99047 10.1277C3.69443 11.5107 4.87171 12.5982 6.31033 13.1945Z"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
