import { Collection } from "api/models";
import { SkeletonWrapper } from "Components/Skeleton";
import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import {
  CollectionsAvatar,
  DetailContainer,
  Container,
  ImageContainer,
  Image,
} from "./styles";
import coverPlaceholder from "assets/images/Default_Banner.png";

interface CollectionHeaderProps {
  data: Collection | null;
  isLoading: boolean;
}

function CollectionHeader({ data, isLoading }: CollectionHeaderProps) {
  const match = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("lg")
  );

  return (
    <Container>
      <ImageContainer>
        <SkeletonWrapper width="100%" height="100%" loading={isLoading}>
          {data?.banner_url ? (
            <Image src={data?.banner_url} alt="collection_header" />
          ) : (
            <Image src={coverPlaceholder} alt="collection_header" />
          )}
        </SkeletonWrapper>
      </ImageContainer>

      <DetailContainer>
        {data?.profile_picture_url && (
          <CollectionsAvatar src={data?.profile_picture_url} />
        )}
        <SkeletonWrapper
          width="100%"
          height={match ? "100px" : "inherit"}
          loading={isLoading}
        >
          {data && (
            <LeftContainer
              title={data.collection_name}
              avatar={data.profile_picture_url}
              createdBy={data.contract_id}
              detail={data.description}
              discordUrl={data.discord_url}
              twitterUrl={data.twitter_url}
              labels={data.labels}
            />
          )}
        </SkeletonWrapper>
        <SkeletonWrapper
          width="100%"
          height={match ? "150px" : "inherit"}
          loading={isLoading}
        >
          {data && (
            <RightContainer
              item={data.supply}
              uniqueHolders={data.unique_holder_count}
              floorPrice={data.floor_price}
              highestSale={data.highest_sale}
              lastSale={data.last_sale_ms_epoch}
              totalSales={data.total_volume}
              contractId={data.contract_id}
            />
          )}
        </SkeletonWrapper>
      </DetailContainer>
    </Container>
  );
}

export default CollectionHeader;
