import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Offer } from "api";
import { OfferedCard } from "Components/Cards/OfferedCard";
import { OfferReceivedCard } from "Components/Cards/OfferRecievedCard";
import { FC } from "react";
import { useHideReceivedOffers } from "hooks/hiddenOffers";

type Props = {
  offers?: Array<Offer>;
  variant: "incoming" | "outgoing";
  collectionName: string;
};

export const MyOffers: FC<Props> = ({
  offers = [],
  variant,
  collectionName,
}) => {
  const hiddenOffers = useHideReceivedOffers();

  return (
    <Box>
      {variant === "outgoing" && (
        <>
          <Typography variant="h6" fontWeight="bold" marginBottom="0.5rem">
            {collectionName}
          </Typography>
          {offers.map((offer) => (
            <OfferedCard key={offer.contract_id + offer.token_id} {...offer} />
          ))}
        </>
      )}

      {variant === "incoming" && (
        <>
          <Typography variant="h6" fontWeight="bold" marginBottom="0.5rem">
            {collectionName}
          </Typography>
          {offers
            .filter(
              (offer) =>
                !hiddenOffers.isOfferHidden(
                  offer.contract_id,
                  offer.token_id,
                  offer.price,
                  offer.buyer_id
                )
            )
            .map((offer) => (
              <OfferReceivedCard
                key={offer.contract_id + offer.token_id}
                {...offer}
              />
            ))}
        </>
      )}
    </Box>
  );
};
