import { Box } from "@mui/system";
import { DropdownIcon } from "assets/icons";
import { FilterMenu } from "Components/List/Control/Filter";
import { Pagination } from "Components/Pagination";
import { SectionTitle } from "Components/Section/SectionTitle";
import { FC, ReactElement } from "react";
import { SectionHeaderContainer, SectionHeaderCRAButtons } from "./styles";

type SectionHeaderProps = {
  title: string;
  subtitle?: string;
  Icon?: ReactElement;
  // totalPages?: number;
  // currentPage?: number;
  withPagination?: boolean;
  dropdownOptions?: Array<string>;
  handleGoBack?: () => void;
  handleGoForward?: () => void;
};

export const SectionHeader: FC<SectionHeaderProps> = ({
  title,
  subtitle,
  Icon,
  withPagination,
  // totalPages,
  // currentPage,
  handleGoBack,
  handleGoForward,
  dropdownOptions,
}) => (
  <SectionHeaderContainer>
    {Icon && <Box>{Icon}</Box>}
    <SectionTitle title={title} subtitle={subtitle}>
      <SectionHeaderCRAButtons>
        {!!dropdownOptions && (
          <FilterMenu
            options={dropdownOptions}
            endIcon={<DropdownIcon color="currentColor" />}
          />
        )}
        {!!withPagination && (
          <Pagination
            handleGoBack={handleGoBack}
            handleGoForward={handleGoForward}
            // totalPages={totalPages}
            // currentPage={currentPage}
          />
        )}
      </SectionHeaderCRAButtons>
    </SectionTitle>
  </SectionHeaderContainer>
);
