import {
  Token,
  useCollectionTokenActivity,
  useCollectionTokenOffers,
  useCollection,
} from "api";
import * as Icons from "assets/icons";
import CircularButton from "Components/Buttons/CircularButton/CircularButton";

import { useModal } from "hooks/useModal";
import { FC, useState, useEffect } from "react";
import { theme } from "ui/theme/theme";
import MakeOfferModal from "../MakeOfferModal";
import NftTokenCollection from "../NftTokenCollection/NftTokenCollection";
import NftTokenDetailsTabPage from "../NftTokenDetailsTabPage/NftTokenDetailsTabPage";
import NftTokenHistoryTabPage from "../NftTokenHistoryTabPage/NftTokenHistoryTabPage";
import * as S from "./styles";
import { BuyPanelDrawer } from "Components/BuyPanelDrawer";
import { OfferPanelDrawer } from "Components/OfferPanelDrawer";
import useMarketplace from "../../../../hooks/marketPlace";
import { useNearWallet } from "../../../../hooks/useNear";
import { utils } from "near-api-js";
import { checkResultTransaction } from "utils/transaction-results";
import ModalMain from "Components/ModalMain";
import { Box, Grid, Typography } from "@mui/material";
import ListPanelDrawer from "Components/ListPanelDrawer";
import { AcovaTab, AcovaTabs } from "Components/AcovaTabs";
import { OfferReceivedSlimCard } from "Components/Cards/OfferRecievedSlimCard";
import { useHideReceivedOffers } from "hooks/hiddenOffers";

const NftToken: FC<{ token: Token }> = ({ token }) => {
  const { data: nftTokenHistory } = useCollectionTokenActivity(
    token.contract_id,
    token.token_id
  );

  const { data: nftTokenOffers } = useCollectionTokenOffers(
    token.contract_id,
    token.token_id
  );

  const { data: collectionData } = useCollection(token.contract_id || "");

  const marketPlace = useMarketplace();

  const { accountId, walletConnection } = useNearWallet();

  const [hasOffer, setHasOffer] = useState(false);
  const [offerPrice, setOfferPrice] = useState("0");

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [iconName, setIconName] = useState("");
  const [resultPanel, setResultPanel] = useState(false);
  const [state, setState] = useState(false);

  const handleOpen = () => setState(true);
  const handleClose = () => setState(false);

  const { isOfferHidden } = useHideReceivedOffers();

  const OfferModal = useModal();
  const ImageModal = useModal();

  const isOwner = token.owner === accountId;

  useEffect((): void => {
    if (isInitialRender) {
      setIsInitialRender(false);
      const { contract_id: contractID, token_id: tokenID } = token;
      marketPlace
        .getDataOffer(contractID, tokenID, accountId)
        .then((res) => {
          if (typeof res === "string") {
            setHasOffer(false);
          } else {
            setHasOffer(true);
            setOfferPrice(utils.format.formatNearAmount(res.price));
          }
        })
        .catch(() => {});
    }
  }, [accountId, marketPlace, token, isInitialRender]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      const urlParams = new URLSearchParams(window.location.search);
      const txhash = urlParams.get("transactionHashes");
      if (txhash !== null) {
        setState(true);
        setResultPanel(true);
        checkResultTransaction(
          txhash,
          walletConnection.getAccountId(),
          setTitle,
          setSubTitle,
          setIconName,
          setResultPanel,
          handleOpen,
          handleClose
        );
      }
    }
  }, [isInitialRender, resultPanel, state, walletConnection]);

  const offersToDisplay = nftTokenOffers?.filter(
    (offer) =>
      !isOfferHidden(
        offer.contract_id,
        offer.token_id,
        offer.price,
        offer.user_id
      )
  );

  return (
    <>
      <ImageModal.RenderModal>
        <S.NftTokenFullScreenImageWrapper>
          <S.NftTokenFullScreenImage src={token.media} />
          <S.NftFullScreenCloseImage onClick={ImageModal.hide}>
            <Icons.Close color="#fff" />
          </S.NftFullScreenCloseImage>
        </S.NftTokenFullScreenImageWrapper>
      </ImageModal.RenderModal>
      <OfferModal.RenderModal>
        <MakeOfferModal onClose={OfferModal.hide} />
      </OfferModal.RenderModal>
      <Grid
        container
        spacing={{
          xs: "1rem",
          sm: "2rem",
          md: "3rem",
          lg: "5rem",
        }}
      >
        <Grid item xs={12} sm={5} md={6}>
          <S.NtfTokenImageContainer>
            <S.NftTokenImage src={token.media || ""} />
            <S.FullScreenButton onClick={ImageModal.show}>
              <Icons.ExpandIcon color="#fff" />
            </S.FullScreenButton>
          </S.NtfTokenImageContainer>
        </Grid>
        <S.NftTokenDetails item xs={12} sm={7} md={6}>
          <S.NftTokenDetailsTopContainer>
            <S.NftTokenName>
              {Number.isNaN(+token.token_name)
                ? token.token_name
                : `#${token.token_name}`}
            </S.NftTokenName>
            <S.ButtonsContainer>
              <CircularButton
                Icon={<Icons.Refresh color={theme.colors.white} />}
              />
              <CircularButton
                Icon={<Icons.Share color={theme.colors.white} />}
              />
              <CircularButton
                Icon={<Icons.ThreeDots color={theme.colors.white} />}
              />
            </S.ButtonsContainer>
          </S.NftTokenDetailsTopContainer>

          <S.NftTokenPriceContainer>
            {token.listed_price ? (
              <>
                <S.NftTokenPriceLabel>Price:</S.NftTokenPriceLabel>
                <S.NftTokenPrice>{token.listed_price}</S.NftTokenPrice>

                <Icons.NearLogo
                  height={28}
                  width={28}
                  color={theme.colors.teal}
                />
              </>
            ) : (
              <S.NftTokenPrice>Unlisted</S.NftTokenPrice>
            )}
          </S.NftTokenPriceContainer>

          <S.NftTokenCreatorCollectionContainer>
            {token.contract_id && (
              <NftTokenCollection collectionName={token.contract_id} />
            )}
          </S.NftTokenCreatorCollectionContainer>

          <S.NtfTokenTabsContainer>
            <AcovaTabs>
              <AcovaTab label="Offers">
                <Box paddingTop="1rem">
                  {offersToDisplay?.length ? (
                    offersToDisplay.map((item) => (
                      <OfferReceivedSlimCard
                        key={item.token_id + item.user_id}
                        isOwner={isOwner}
                        {...item}
                        floorPrice={collectionData?.floor_price}
                      />
                    ))
                  ) : (
                    <Box sx={{ height: "270px" }}>
                      <Typography variant="h6" fontWeight="bold">
                        There are no offers
                      </Typography>
                    </Box>
                  )}
                </Box>
              </AcovaTab>

              <AcovaTab label="Details">
                <NftTokenDetailsTabPage {...token} />
              </AcovaTab>
              {nftTokenHistory && (
                <AcovaTab label="History">
                  <NftTokenHistoryTabPage NftTokenHistory={nftTokenHistory} />
                </AcovaTab>
              )}
            </AcovaTabs>
          </S.NtfTokenTabsContainer>

          <S.NtfTokenCTAButtons>
            {!token.listed_price && isOwner && (
              <ListPanelDrawer
                panelButtonText="List Token"
                panelMainTitle="List Your Item"
                panelInputTitle="Listing Price"
                panelInputPlaceHolder="Enter amount to list at"
                buttonText="List Now"
                Icon={
                  <Icons.ListIcon height={13.89} width={13.83} type="duotone" />
                }
                token={token}
                floorPrice={collectionData?.floor_price}
              />
            )}

            {token.listed_price && !isOwner && (
              <BuyPanelDrawer
                panelButtonText={`Buy for ${token.listed_price} NEAR`}
                panelMainTitle="Cart"
                panelInputTitle="Your offer"
                panelInputPlaceHolder="Add more Items"
                buttonText="Buy Now"
                Icon={<Icons.Coins height={18} width={18} color="#000" />}
                token={token}
                buttonType="buy"
                isToken
              />
            )}

            {hasOffer && !isOwner && (
              <OfferPanelDrawer
                panelButtonText="Update your offer"
                panelMainTitle="Your offer"
                panelInputTitle="Offered amount"
                panelInputPlaceHolder="Enter amount to offer"
                buttonText="Submit Offer"
                Icon={<Icons.Bid height={18} width={18} color="#000" />}
                token={token}
                isToken
                buttonType="offer"
                hasOffer={hasOffer}
                offerPrice={offerPrice}
                floorPrice={collectionData?.floor_price}
              />
            )}

            {!hasOffer && !isOwner && (
              <OfferPanelDrawer
                panelButtonText="Make an offer"
                panelMainTitle="Make an offer"
                panelInputTitle="Your offer"
                panelInputPlaceHolder="Enter amount to offer"
                buttonText="Submit Offer"
                Icon={<Icons.Bid height={18} width={18} color="#000" />}
                token={token}
                isToken
                hasOffer={hasOffer}
                buttonType="offer"
                floorPrice={collectionData?.floor_price}
              />
            )}
          </S.NtfTokenCTAButtons>
          <ModalMain
            state={state}
            onClose={handleClose}
            title={title}
            subTitle={subTitle}
            iconName={iconName}
            setResultPanel={setResultPanel}
          />
        </S.NftTokenDetails>
      </Grid>
    </>
  );
};

export default NftToken;
