import { TokenAttribute } from "api";
import { FC } from "react";
import * as S from "./NftTokenAttributeCard.style";

type NftTokenAttributeCardProps = {
  attribute: TokenAttribute;
};

const NftTokenAttributeCard: FC<NftTokenAttributeCardProps> = ({
  attribute,
}) => (
  <S.NftTokenAttributeCardContainer>
    <S.NftTokenAttributeType>
      {attribute.attribute_name}
    </S.NftTokenAttributeType>
    <S.NftTokenAttributeName>
      {attribute.attribute_value}
    </S.NftTokenAttributeName>
    {/* Rarity not disponible on API */}
    {/* <S.NftTokenAttributeRarity>{`${0}% rarity`}</S.NftTokenAttributeRarity>  */}
  </S.NftTokenAttributeCardContainer>
);

export default NftTokenAttributeCard;
