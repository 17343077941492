import { CardActionArea, Box, CardMedia } from "@mui/material";

import * as S from "./styles";
import { CollectionCreatorName } from "Components/Collection/Creator/Name";
import { CollectionVerified } from "Components/Collection/Verified";
import { theme } from "ui/theme/theme";
// import { useNavigate } from "react-router-dom";

export interface ComingSoonCardProps {
  collection_name: string;
  profile_picture_url: string;
  featured_image_url: string;
  creator: string;
}

export const ComingSoonCard = ({
  collection_name,
  featured_image_url,
  profile_picture_url,
  creator,
}: // eslint-disable-next-line arrow-body-style
ComingSoonCardProps) => {
  // const navigate = useNavigate()

  return (
    <S.NewCard sx={{ position: "relative" }}>
      <CardActionArea
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Box sx={{ width: "100%" }}>
          {featured_image_url && (
            <CardMedia
              component="img"
              image={featured_image_url}
              alt={collection_name}
              loading="lazy"
            />
          )}
        </Box>

        <Box
          sx={{
            position: "relative",
            padding: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              left: 0,
              right: 0,
              top: "-1rem",
              flex: "1",
            }}
          >
            <CollectionCreatorName
              avatar={profile_picture_url}
              name={creator}
            />
          </Box>
          <CollectionVerified
            color={theme.colors.white}
            name={collection_name}
            fontSize="1.375rem"
            textAlign="center"
            verified
          />
        </Box>
      </CardActionArea>
    </S.NewCard>
  );
};
