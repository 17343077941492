import { theme } from "ui/theme/theme";
import Box, { BoxProps } from "@mui/material/Box";
import { styled, TypographyProps, Typography } from "@mui/material";

export const StyledContainer = styled(Box)<BoxProps>(() => ({
  width: "30.625rem",
  backgroundColor: theme.colors.black,
  color: theme.colors.white,
  "@media (max-width: 450px)": {
    width: "19.938rem",
  },
}));

export const TextNormal = styled(Typography)<TypographyProps>(() => ({
  color: theme.colors.white,
  fontWeight: 600,
  fontSize: "1.25rem",
  lineHeight: "120%",
}));

export const MainHead = styled(Typography)<TypographyProps>(() => ({
  color: theme.colors.white,
  fontWeight: 600,
  fontSize: "2rem",
  lineHeight: "120%",
  "@media (max-width: 450px)": {
    fontSize: "1.5rem",
  },
}));

export const Logo = styled("img")(() => ({
  marginRight: "38px",
  height: "30px",
  [theme.breakpoints.down("lg")]: {
    height: "18.39px",
    marginRight: "168px",
  },
}));

export const LogoView = styled(Box)<BoxProps>(() => ({
  backgroundColor: theme.colors.black,
  color: theme.colors.white,
  marginBottom: "18px",
  display: "none",
  "@media (max-width: 450px)": {
    width: "19.938rem",
    display: "flex",
  },
}));

export const DisplayControlledBox = styled(Box)<BoxProps>(() => ({
  "@media (max-width: 450px)": {
    display: "none",
  },
}));
