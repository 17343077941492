import { FC } from "react";
import { SVGProps } from "interfaces";

export const Telegram: FC<SVGProps> = ({
  width = "18",
  height = "18",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.184 2.4937C14.4421 1.75188 13.0136 1.59826 7.60674 3.81831C3.83845 5.36557 1.66177 6.28181 2.04303 8.64019C2.21046 9.67583 3.27603 11.2658 4.47433 12.5444V14.8679C4.47433 15.8488 5.63836 16.3638 6.36412 15.704L7.28045 14.871C7.92931 15.2733 8.54586 15.5552 9.03751 15.6347C11.3959 16.0159 12.3121 13.8392 13.8594 10.071C16.0795 4.66409 15.9258 3.23552 15.184 2.4937Z"
      stroke={color}
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
    <path
      d="M15.2866 2.60566L4.17138 12.2113C3.10096 10.9989 2.19619 9.58759 2.04303 8.64019C1.66177 6.28181 3.83845 5.36557 7.60673 3.81831C13.0136 1.59826 14.4422 1.75188 15.184 2.4937C15.2196 2.52932 15.2539 2.56651 15.2866 2.60566Z"
      stroke={color}
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
  </svg>
);
