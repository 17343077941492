import { FC } from "react";
import { SVGProps } from "interfaces";

export const NoResultIcon: FC<SVGProps> = ({
  width = "160",
  height = "160",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 160 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1310_30707)">
      <path
        d="M125.608 41.2069C124.728 37.861 124.436 37.1823 126.263 33.9563C129.477 28.2801 136.15 27.24 134.062 19.2719C131.913 11.074 122.864 8.83203 115.568 13.7633C109.021 18.1883 109.606 27.8131 114.514 31.1217"
        stroke="#9CFFF8"
        strokeWidth="4.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.593 53.547C129.047 53.7403 128.531 54.0202 128.17 54.4885C127.895 54.8444 127.718 55.2486 127.631 55.6899C127.567 56.0144 127.512 56.3689 127.588 56.6964C128.306 59.8023 133.005 56.3203 130.553 54.6018"
        stroke="#9CFFF8"
        strokeWidth="4.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M88.5468 46.9094C73.2411 34.9319 50.5041 37.1651 37.6241 50.2546C24.3557 63.7377 22.9945 89.9515 39.1541 102.803C48.4962 110.233 64.3387 114.501 76.1833 111.019C98.2645 104.53 109.783 77.5677 97.4312 56.5217"
      stroke="white"
      strokeWidth="4.5"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.3615 108.555C78.7008 110.529 74.5018 111.498 72.5996 111.821C74.4172 119.158 77.668 128.016 80.5348 134.987C82.3243 139.34 84.5417 143.317 86.5395 147.578C88.1193 150.95 92.8471 155.279 96.0029 150.815C98.4186 147.398 96.3324 142.191 95.1464 138.701C91.8717 129.059 86.164 117.952 82.3615 108.555Z"
      fill="white"
      stroke="white"
      strokeWidth="4.5"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.6621 56.3664C69.9238 56.3495 72.3256 56.8609 74.4763 57.8646C76.8831 58.9881 78.9756 60.7284 80.2048 63.0356"
      stroke="white"
      strokeWidth="4.5"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <clipPath id="clip0_1310_30707">
        <rect
          width="56.3969"
          height="56.3969"
          fill="white"
          transform="translate(91.2852 14.2017) rotate(-14.4919)"
        />
      </clipPath>
    </defs>
  </svg>
);
