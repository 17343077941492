import { FC } from "react";
import { SVGProps } from "interfaces";

export const ListIcon: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
  type,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {type === "duotone" ? (
      <>
        <path
          d="M9.33632 15.4172C10.1326 16.1444 11.3472 16.1922 12.2166 15.5543C12.8432 15.0945 13.4717 14.608 14.0399 14.0398C14.6081 13.4716 15.0945 12.8432 15.5543 12.2166C16.1922 11.3472 16.1443 10.1326 15.4171 9.33632C13.3467 7.06918 11.2134 4.96762 8.89783 2.91234C8.6176 2.66362 8.28047 2.49105 7.91267 2.41954C6.57927 2.16029 3.25329 1.61428 2.43378 2.43378C1.61428 3.25329 2.16028 6.57927 2.41954 7.91267C2.49105 8.28047 2.66362 8.6176 2.91234 8.89783C4.96762 11.2134 7.06918 13.3467 9.33632 15.4172Z"
          fill="white"
          stroke={color}
          strokeWidth="1.7"
        />
        <path
          d="M7.34043 7.34199C7.87125 6.81117 7.87125 5.95055 7.34043 5.41973C6.80961 4.88891 5.94898 4.88891 5.41816 5.41973C4.88735 5.95055 4.88735 6.81117 5.41816 7.34199C5.94898 7.87281 6.80961 7.87281 7.34043 7.34199Z"
          fill="white"
          stroke={color}
          strokeWidth="1.7"
        />
      </>
    ) : (
      <>
        <path
          d="M10.3363 16.4172C11.1326 17.1444 12.3472 17.1922 13.2166 16.5543C13.8432 16.0945 14.4717 15.608 15.0399 15.0398C15.6081 14.4716 16.0945 13.8432 16.5543 13.2166C17.1922 12.3472 17.1443 11.1326 16.4171 10.3363C14.3467 8.06918 12.2134 5.96762 9.89783 3.91234C9.6176 3.66362 9.28047 3.49105 8.91267 3.41954C7.57927 3.16029 4.25329 2.61428 3.43378 3.43378C2.61428 4.25329 3.16028 7.57926 3.41954 8.91267C3.49105 9.28047 3.66362 9.6176 3.91234 9.89783C5.96762 12.2134 8.06918 14.3467 10.3363 16.4172Z"
          stroke={color}
          strokeWidth="1.7"
        />
        <path
          d="M8.34043 8.34199C8.87125 7.81117 8.87125 6.95055 8.34043 6.41973C7.80961 5.88891 6.94898 5.88891 6.41816 6.41973C5.88735 6.95055 5.88735 7.81117 6.41816 8.34199C6.94898 8.87281 7.80961 8.87281 8.34043 8.34199Z"
          stroke={color}
          strokeWidth="1.7"
        />
      </>
    )}
  </svg>
);
