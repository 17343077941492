import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const Divider = styled(Box)<BoxProps>(({ theme }) => ({
  borderColor: theme.colors.grey,
  borderWidth: 1,
  borderStyle: "solid",
  marginTop: 20,
  opacity: 0.4,
}));

export default Divider;
