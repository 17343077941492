import { Box, Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Container, Title, Subtitle, Price } from "./styles";

interface DetailCardProps {
  image: string;
  title: string;
  subtitle: string;
  price: string;
}

function DetailCard({ image, title, subtitle, price }: DetailCardProps) {
  const smallScreen = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  const imageSize = smallScreen ? "80" : "104";

  return (
    <Container spacing={3} direction="row">
      <img src={image} alt={title} height={imageSize} width={imageSize} />
      <Box>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Price>Listed price: {price} NEAR</Price>
      </Box>
    </Container>
  );
}

export default DetailCard;
