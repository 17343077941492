import { FC } from "react";
import { SVGProps } from "interfaces";

export const Cross: FC<SVGProps> = ({
  width = "10",
  height = "10",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    style={{color}}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 1.5L11.5 11.5"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
    />
    <path
      d="M11.5 1.5L1.5 11.5"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
    />
  </svg>
);
