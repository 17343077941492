import CollectionTabs from "./CollectionTabs";
import { Container } from "./styles";

interface CollectionGridProps {
  collectionName: string;
}

function CollectionGrid({ collectionName }: CollectionGridProps) {
  return (
    <Container>
      <CollectionTabs collectionName={collectionName} />
    </Container>
  );
}

export default CollectionGrid;
