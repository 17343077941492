import { FC } from "react";
import { SVGProps } from "interfaces";

export const Trash: FC<SVGProps> = ({
  width = "18",
  height = "18",
  color = "#fff",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 5.35565H16"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
    />
    <path
      d="M14.0279 5.35565H3.96395L3.89193 6.65782C3.75393 9.15272 3.87118 11.6552 4.24184 14.1263C4.40348 15.2039 5.32915 16.001 6.41877 16.001H11.573C12.6627 16.001 13.5884 15.2039 13.75 14.1263C14.1206 11.6552 14.2379 9.15272 14.0999 6.65782L14.0279 5.35565Z"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.20312 5.35464V4.79553C6.20312 4.0541 6.49765 3.34306 7.02192 2.81879C7.54618 2.29453 8.25724 2 8.99866 2C9.74007 2 10.4511 2.29453 10.9754 2.81879C11.4996 3.34306 11.7942 4.0541 11.7942 4.79553V5.35464"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
