import * as Models from "./models";
import routes from "./routes";

export class AcovaService {
  public static routes = routes;

  public static async getResource(path: string) {
    const url = new URL(path, AcovaService.routes.base());

    const response = await fetch(url);

    if (response.ok) {
      const data = await response.json();
      return data;
    }

    throw new Error(`${response.status} ${response.statusText}`);
  }

  private static async getHomeCollections() {
    const path = AcovaService.routes.home.collections();
    return (await AcovaService.getResource(path)) as Models.HomeCollections;
  }

  private static async getHomeLabels() {
    const path = AcovaService.routes.home.labels();
    return (await AcovaService.getResource(
      path
    )) as Array<Models.CollectionLabel>;
  }

  private static async getCollections([, requestConfig]: Array<
    string | Models.RequestConfig | undefined
  >) {
    const path = AcovaService.routes.collections(
      requestConfig as Models.RequestConfig | undefined
    );
    return (await AcovaService.getResource(path)) as Array<Models.Collection>;
  }

  private static async getCollection([, collectionId]: Array<string>) {
    const path = AcovaService.routes.collection.item(collectionId);
    return (await AcovaService.getResource(path)) as Models.Collection;
  }

  private static async getCollectionActivity(
    [, collectionId, requestConfig]: Array<
      string | Models.CollectionActivityRequestConfig | undefined
    >,
    pageParam = 0
  ) {
    const newConfig = {
      ...(requestConfig as Models.CollectionActivityRequestConfig),
      offset: pageParam,
    };
    const path = AcovaService.routes.collection.activity(
      collectionId as string,
      newConfig as Models.CollectionActivityRequestConfig | undefined
    );
    return (await AcovaService.getResource(
      path
    )) as Array<Models.TokenActivity>;
  }

  private static async getCollectionTokens(
    [, collectionId, requestConfig]: Array<
      string | Models.CollectionTokensRequestConfig | undefined
    >,
    pageParam = 0
  ) {
    const newConfig = {
      ...(requestConfig as Models.CollectionTokensRequestConfig),
      offset: pageParam,
    };
    const path = AcovaService.routes.collection.tokens(
      collectionId as string,
      newConfig as Models.CollectionTokensRequestConfig | undefined
    );
    return (await AcovaService.getResource(path)) as Array<Models.Token>;
  }

  private static async getCollectionToken([
    ,
    collectionId,
    tokenId,
  ]: Array<string>) {
    const path = AcovaService.routes.collection.token.item(
      collectionId,
      tokenId
    );
    return (await AcovaService.getResource(path)) as Models.Token;
  }

  private static async getCollectionTokenActivity([
    ,
    collectionId,
    tokenId,
  ]: Array<string>) {
    const path = AcovaService.routes.collection.token.activity(
      collectionId,
      tokenId
    );
    return (await AcovaService.getResource(
      path
    )) as Array<Models.TokenActivity>;
  }

  private static async getCollectionTokenOffers([
    ,
    collectionId,
    tokenId,
  ]: Array<string>) {
    const path = AcovaService.routes.collection.token.offers(
      collectionId,
      tokenId
    );
    return (await AcovaService.getResource(path)) as Array<Models.TokenOffer>;
  }

  private static async getProfile([, accountId]: Array<string>) {
    const path = AcovaService.routes.profile(accountId);
    return (await AcovaService.getResource(path)) as Models.Profile;
  }

  public static get = {
    home: {
      collections: AcovaService.getHomeCollections,
      labels: AcovaService.getHomeLabels,
    },
    collections: AcovaService.getCollections,
    collection: {
      item: AcovaService.getCollection,
      activity: AcovaService.getCollectionActivity,
      tokens: AcovaService.getCollectionTokens,
      token: {
        item: AcovaService.getCollectionToken,
        activity: AcovaService.getCollectionTokenActivity,
        offers: AcovaService.getCollectionTokenOffers,
      },
    },
    profile: AcovaService.getProfile,
  };
}
