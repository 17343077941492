import React from "react";
import { Navigate } from "react-router-dom";

type Props = {
  children: React.ReactNode;
  destination?: string;
};

export const LIVE_TIME = parseInt(process.env.REACT_APP_LIVE_AT || "0", 10);

const PasswordGuard: React.FC<Props> = ({
  children,
  destination = "/password-protected",
}) => {
  const password = localStorage.getItem("password-protected");
  console.log("live", LIVE_TIME)
  console.log(Date.now(), LIVE_TIME, LIVE_TIME > 1666266900 * 1000);

  if ((password && password === process.env.REACT_APP_PROTECTED_PASSWORD) || (Date.now() > LIVE_TIME * 1000)) {
    return (
      <>
        {children}
        <span />
      </>
    );
  }

  return <Navigate to={destination} />;
};

export default PasswordGuard;
