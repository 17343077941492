import { MouseEventHandler } from "react";
import * as S from "./styles";

export interface CollectionCardAction {
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  href?: string;
  Icon?: React.ReactNode;
}

export interface CollectionCardActionsProps {
  actions?: Array<CollectionCardAction>;
  children?: React.ReactNode;
}

export const CollectionCardActions: React.FC<CollectionCardActionsProps> = ({
  actions = [],
  children
}) => (
  <>
    <S.Divider />
    <S.CardActions>
      {actions.map(({ label, href, onClick, Icon }) => (
        <S.Button
          color="secondary"
          size="large"
          key={label}
          onClick={onClick}
          href={href}
        >
          {label}
          {Icon && <S.CardIconTypography>{Icon}</S.CardIconTypography>}
        </S.Button>
      ))}
      {children}
    </S.CardActions>
  </>
);
