import { Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { Token, useCollection } from "api";
import { ListIcon } from "assets/icons/List";
import { ListPanelDrawer } from "Components/ListPanelDrawer";
import DelistPanelDrawer from "Components/DelistPanelDrawer";

import { useNavigate } from "react-router-dom";

import * as S from "./styles";

export interface OfferCardProps {
  rarity?: string;
  filter?: boolean;
  variant?: "listed" | "unlisted";
  collectionName?: string;
  token: Token;
}

export const OfferCard = ({
  rarity,
  filter,
  variant,
  collectionName,
  token,
}: OfferCardProps) => {
  const navigate = useNavigate();
  const { data } = useCollection(token.contract_id || "");

  const onClick = (id: string) => {
    navigate(`/collections/${token.contract_id}/${id}`);
  };

  return (
    <S.StyledCard>
      <S.CardMediaAspect onClick={() => onClick(token.token_id)}>
        <CardMedia
          component="img"
          image={token.media}
          alt={token.token_name}
          sx={{ borderRadius: "5px 5px 0px 0px" }}
          loading="lazy"
        />
        {rarity && (
          <S.Rarity filter={filter}>
            <span>
              <b>Rarity:</b> {rarity}
            </span>
          </S.Rarity>
        )}
      </S.CardMediaAspect>
      <S.CardAction>
        <S.CardActionTitleBox>
          <S.TitleCard variant="subtitle1">
            {collectionName} #{token.token_id}
          </S.TitleCard>
          {variant === "listed" && token.listed_price && (
            <>
              <S.TitleListed variant="subtitle2" display="block">
                Listed for:
              </S.TitleListed>
              <Typography
                variant="body1"
                display="block"
                color="primary"
                fontWeight="bold"
                fontSize="0.875rem"
              >
                {token.listed_price} NEAR
              </Typography>
            </>
          )}
        </S.CardActionTitleBox>

        {!token.listed_price && (
          <ListPanelDrawer
            panelButtonText="List item"
            panelMainTitle="List Your Item"
            panelInputTitle="Listing Price"
            panelInputPlaceHolder="Enter amount to list at"
            buttonText="List Now"
            Icon={<ListIcon height={13.89} width={13.83} type="duotone" />}
            token={token}
            floorPrice={data?.floor_price}
          />
        )}

        {token.listed_price && (
          <DelistPanelDrawer
            token={token}
            collectionName={collectionName || ""}
          />
        )}
      </S.CardAction>
    </S.StyledCard>
  );
};
