import { FC } from "react";
import { SVGProps } from "interfaces";

export const ThreeDots: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.72094 10.0003C4.72094 10.3015 4.63887 10.4167 4.59809 10.4575C4.55732 10.4982 4.44208 10.5803 4.14094 10.5803C3.83979 10.5803 3.72455 10.4982 3.68378 10.4575C3.643 10.4167 3.56094 10.3015 3.56094 10.0003C3.56094 9.69917 3.643 9.58393 3.68378 9.54315C3.72455 9.50238 3.83979 9.42031 4.14094 9.42031C4.44208 9.42031 4.55732 9.50238 4.59809 9.54315C4.63887 9.58393 4.72094 9.69917 4.72094 10.0003Z"
      fill={color}
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5803 10.0003C10.5803 10.3015 10.4982 10.4167 10.4575 10.4575C10.4167 10.4982 10.3015 10.5803 10.0003 10.5803C9.69917 10.5803 9.58393 10.4982 9.54315 10.4575C9.50238 10.4167 9.42031 10.3015 9.42031 10.0003C9.42031 9.69917 9.50238 9.58393 9.54315 9.54315C9.58393 9.50238 9.69917 9.42031 10.0003 9.42031C10.3015 9.42031 10.4167 9.50238 10.4575 9.54315C10.4982 9.58393 10.5803 9.69917 10.5803 10.0003Z"
      fill={color}
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.4397 10.0003C16.4397 10.3015 16.3576 10.4167 16.3168 10.4575C16.2761 10.4982 16.1608 10.5803 15.8597 10.5803C15.5585 10.5803 15.4433 10.4982 15.4025 10.4575C15.3618 10.4167 15.2797 10.3015 15.2797 10.0003C15.2797 9.69917 15.3618 9.58393 15.4025 9.54315C15.4433 9.50238 15.5585 9.42031 15.8597 9.42031C16.1608 9.42031 16.2761 9.50238 16.3168 9.54315C16.3576 9.58393 16.4397 9.69917 16.4397 10.0003Z"
      fill={color}
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
