import { FC } from "react";
import { SVGProps } from "interfaces";

export const Copy: FC<SVGProps> = ({
  width = "18",
  height = "18",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.68304 14.0339C5.8112 15.2097 6.75488 16.1531 7.93011 16.2863C8.92416 16.3991 9.94683 16.5 10.9907 16.5C12.0345 16.5 13.0571 16.3991 14.0513 16.2863C15.2264 16.1531 16.1701 15.2097 16.2983 14.0339C16.4061 13.0451 16.5009 12.028 16.5009 10.9898C16.5009 9.95167 16.4061 8.93452 16.2983 7.94576C16.1701 6.76997 15.2264 5.82648 14.0513 5.69325C13.0572 5.58056 12.0345 5.47961 10.9907 5.47961C9.94683 5.47961 8.92416 5.58056 7.93011 5.69325C6.75488 5.82648 5.8112 6.76997 5.68304 7.94576C5.57526 8.93452 5.48047 9.95167 5.48047 10.9898C5.48047 12.028 5.57526 13.0451 5.68304 14.0339Z"
      stroke={color}
      strokeWidth="1.7"
    />
    <path
      d="M1.68502 9.89184C1.82091 11.162 2.84247 12.1835 4.11197 12.325C5.05493 12.4301 6.02309 12.5204 7.0102 12.5204C7.99731 12.5204 8.96548 12.4301 9.90844 12.325C11.1779 12.1835 12.1995 11.162 12.3354 9.89184C12.4357 8.95422 12.5204 7.9916 12.5204 7.0102C12.5204 6.02881 12.4357 5.06619 12.3354 4.12856C12.1995 2.85844 11.1779 1.83688 9.90844 1.69538C8.96548 1.59027 7.99731 1.5 7.0102 1.5C6.02309 1.5 5.05493 1.59027 4.11197 1.69538C2.84247 1.83688 1.82091 2.85844 1.68502 4.12856C1.58471 5.06619 1.5 6.02881 1.5 7.0102C1.5 7.9916 1.58471 8.95422 1.68502 9.89184Z"
      fill="white"
      stroke={color}
      strokeWidth="1.7"
    />
  </svg>
);
