/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import { Typography } from "@mui/material";
import { theme } from "ui/theme/theme";
import { NearLogo } from "assets/icons/NearLogo";
import { Search } from "assets/icons/Search";

import {
  TealButton,
  IconContainer,
  GreyButton,
  CustomOutlineInput,
  TextNormal,
  BoxMsgContainer,
  StyledFormControl,
  BoxTextContainer,
} from "./styles";

interface FormProps {
  onClose?: () => void;
  handleSubmit: (value: string) => void;
  panelInputPlaceHolder?: string | null;
  buttonText: string;
  Icon: React.ReactElement;
  price?: string | null;
}

export default function FormItem({
  onClose,
  handleSubmit,
  panelInputPlaceHolder,
  buttonText,
  Icon,
  price,
}: FormProps) {
  const [error, setError] = useState<boolean>(false);

  const handleClickChange = () => {
    setError(false);
  };

  return (
    <Box
      m={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <StyledFormControl
        fullWidth
        sx={{
          m: 1,
          backgroundColor: `rgba(255, 255, 255, 0.07)`,
          color: "black",
          borderRadius: "50px",
          width: "29.125rem",
        }}
      >
        {panelInputPlaceHolder && (
          <CustomOutlineInput
            id="outlined-adornment-text"
            onChange={handleClickChange}
            autoComplete=" "
            type="text"
            startAdornment={
              <InputAdornment
                position="start"
                sx={{
                  padding: "14px",
                  height: "36px",
                  borderRadius: "50px",
                  backgroundColor: `rgba(255, 255, 255, 0.07)`,
                }}
              >
                {" "}
                <Search
                  height="12.6"
                  width="12.4"
                  color="white"
                  type="filled"
                />
              </InputAdornment>
            }
            placeholder={panelInputPlaceHolder}
          />
        )}
      </StyledFormControl>
      <BoxTextContainer>
        <TextNormal>Subtotal: </TextNormal>
        <BoxMsgContainer style={{ color: theme.colors.teal }}>
          {" "}
          {price} <NearLogo height="18" width="18" color={theme.colors.teal} />
        </BoxMsgContainer>{" "}
      </BoxTextContainer>

      <Box sx={{ width: "100%" }}>
        <Divider
          orientation="horizontal"
          sx={{ border: "1px solid #4E535B" }}
        />
      </Box>

      <Box
        m={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <TealButton
          variant="contained"
          sx={{ display: "flex", alignItems: "center", size: "large" }}
          onClick={() => handleSubmit(price || "")}
          disabled={error}
        >
          <IconContainer marginRight="8px">{Icon}</IconContainer>
          <Typography sx={{ fontWeight: 600 }}>{buttonText}</Typography>
        </TealButton>
        <GreyButton
          sx={{ display: "flex", alignItems: "center", size: "large" }}
          onClick={onClose}
        >
          Cancel
        </GreyButton>
      </Box>
    </Box>
  );
}
