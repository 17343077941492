import { FC, useEffect, useState, Fragment } from "react";
import Container from "@mui/material/Container";
import { Divider, Grid, Box, Typography } from "@mui/material";
import { AcovaTab, AcovaTabs } from "Components/AcovaTabs";

import { MyItems } from "../Components/MyItems/index";
import { MyOffers } from "../Components/MyOffers";
import { Token, useProfile } from "api";
import { useNearWallet } from "hooks/useNear";
import { ProfileHeader } from "./styles";

import { checkResultTransaction } from "utils/transaction-results";
import ModalMain from "Components/ModalMain";
import { groupByCategory, groupByCollection } from "utils/group-by-category";
import { SolidButton } from "Components/Buttons";
import { Search } from "assets/icons/Search";

const ProfilePage: FC = () => {
  const near = useNearWallet();
  const profile = useProfile(near.accountId);
  const tokens = [...(profile.data?.supported_user_tokens || []), ...(profile.data?.unsupported_user_tokens || [])];
  const result = groupByCategory(tokens);

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [iconName, setIconName] = useState("");
  const [resultPanel, setResultPanel] = useState(false);
  const [state, setState] = useState(false);

  const OffersReceived = profile.data?.offers_received || [];
  const OffersMade = profile.data?.offers_made || [];
  const groupedOffers = groupByCollection(OffersReceived);
  const groupedOffersMade = groupByCollection(OffersMade);

  const handleOpen = () => setState(true);
  const handleClose = () => setState(false);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      const urlParams = new URLSearchParams(window.location.search);
      const txhash = urlParams.get("transactionHashes");
      if (txhash !== null) {
        setState(true);
        setResultPanel(true);
        checkResultTransaction(
          txhash,
          near.walletConnection.getAccountId(),
          setTitle,
          setSubTitle,
          setIconName,
          setResultPanel,
          handleOpen,
          handleClose
        );
      }
    }
  }, [isInitialRender, near.walletConnection, resultPanel, state]);
  return (
    <Container>
      <ProfileHeader>My profile</ProfileHeader>

      <Grid container columnSpacing="3rem">
        <Grid item xs={12} md={7} lg={8}>
          <AcovaTabs>
            <AcovaTab label="My items">
              {result.length !== 0 && (
                <Box>
                  {tokens.filter((token: Token) => token.listed_price === null)
                    .length > 0 ? (
                    result.map((item) => (
                      <Fragment key={item[0]}>
                        <MyItems
                          variant="unlisted"
                          tokens={item[1].filter(
                            (token: Token) => token.listed_price === null
                          )}
                          contractId={item[0]}
                        />
                        {item[1].filter(
                          (token: Token) => token.listed_price === null
                        ).length > 0 ? (
                          <Divider
                            sx={{
                              backgroundColor: "rgba(255, 255, 255, 0.1)",
                              marginBottom: "8px",
                            }}
                          />
                        ) : null}
                      </Fragment>
                    ))
                  ) : (
                    <Box>
                      <Typography variant="h6" fontWeight="bold">
                        All of your tokens have been listed
                      </Typography>
                      <p style={{ marginTop: 0, opacity: 0.6 }}>
                        check your listed item tab
                      </p>
                    </Box>
                  )}
                </Box>
              )}
            </AcovaTab>

            <AcovaTab label="Listed items">
              {result.length !== 0 && (
                <Box>
                  {tokens.filter((token: Token) => token.listed_price).length >
                  0 ? (
                    result.map((item) => (
                      <Fragment key={item[0]}>
                        <MyItems
                          variant="listed"
                          tokens={item[1].filter(
                            (token: Token) => token.listed_price
                          )}
                          contractId={item[0]}
                        />
                        {item[1].filter((token: Token) => token.listed_price)
                          .length > 0 ? (
                          <Divider
                            sx={{
                              backgroundColor: "rgba(255, 255, 255, 0.1)",
                              marginBottom: "8px",
                            }}
                          />
                        ) : null}
                      </Fragment>
                    ))
                  ) : (
                    <Box>
                      <Typography variant="h6" fontWeight="bold">
                        You haven&apos;t listed any token yet
                      </Typography>
                      <p style={{ marginTop: 0, opacity: 0.6 }}>
                        check your items tab to list some
                      </p>
                    </Box>
                  )}
                </Box>
              )}
            </AcovaTab>

            <AcovaTab
              label="Offers received"
              sx={{ display: { sm: "inline-flex", md: "none" } }}
            >
              {groupedOffers.map((item) => (
                <MyOffers
                  variant="incoming"
                  collectionName={item[0]}
                  offers={item[1]}
                  key={item[0]}
                />
              ))}
            </AcovaTab>
            <AcovaTab
              label="Offers made"
              sx={{ display: { sm: "inline-flex", md: "none" } }}
            >
              {groupedOffersMade.map((item) => (
                <MyOffers
                  variant="outgoing"
                  collectionName={item[0]}
                  offers={item[1]}
                  key={item[0]}
                />
              ))}
            </AcovaTab>
          </AcovaTabs>
          {result.length === 0 && (
            <Box mt={1} mb={1}>
              <Typography variant="h6" fontWeight="bold">
                You don&apos;t have any items yet
              </Typography>
              <p style={{ marginTop: 0, opacity: 0.6 }}>
                You can discover the collections page
              </p>
              <SolidButton startIcon={<Search />} href="/discover">
                Discover
              </SolidButton>
            </Box>
          )}
        </Grid>
        <ModalMain
          state={state}
          onClose={handleClose}
          title={title}
          subTitle={subTitle}
          iconName={iconName}
          setResultPanel={setResultPanel}
        />
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <AcovaTabs sx={{ display: { sm: "none", md: "block" } }}>
            <AcovaTab label="Offers made">
              {groupedOffersMade.map((item) => (
                <MyOffers
                  variant="outgoing"
                  collectionName={item[0]}
                  offers={item[1]}
                  key={item[0]}
                />
              ))}
            </AcovaTab>

            <AcovaTab label="Offers received">
              {groupedOffers.map((item) => (
                <MyOffers
                  variant="incoming"
                  collectionName={item[0]}
                  offers={item[1]}
                  key={item[0]}
                />
              ))}
            </AcovaTab>
          </AcovaTabs>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfilePage;
