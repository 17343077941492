const CONTRACT_NAME =
  process.env.REACT_APP_CONTRACT_NAME || "acova-marketplace.aslabs.testnet";

function environment(env: string) {
  switch (env) {
    case "mainnet":
      return {
        networkId: "mainnet",
        nodeUrl: "https://rpc.mainnet.near.org",
        contractName: CONTRACT_NAME,
        walletUrl: "https://wallet.near.org",
        helperUrl: "https://helper.mainnet.near.org",
        explorerUrl: "https://explorer.mainnet.near.org",
        apiUrl: "https://api.acova.io/",
        headers: {},
      };
    case "testnet":
      return {
        networkId: "testnet",
        nodeUrl: "https://rpc.testnet.near.org",
        contractName: CONTRACT_NAME,
        walletUrl: "https://wallet.testnet.near.org",
        helperUrl: "https://helper.testnet.near.org",
        explorerUrl: "https://explorer.testnet.near.org",
        apiUrl: "https://testnet.acova.antisociallabs.io",
        headers: {},
      };
    default:
      throw Error(`Unknown environment '${env}'.`);
  }
}

export default environment;
