import styled from "@emotion/styled";
import { theme } from "ui/theme/theme";

export const NftTokenCollectionSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const NftTokenCollectionLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: ${() => theme.colors.white};
`;

export const CollectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CollectionText = styled.div`
  display: flex;
  gap: 3px;
  :hover {
    cursor: pointer;
    color: ${() => theme.colors.teal};
  }
`;
