import { FC } from "react";
import { SVGProps } from "interfaces";

export const ArrowRight: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    style={{ color }}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.96 9.61719H15"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0366 14.2355C12.8095 12.8323 13.6346 11.8766 14.7137 10.4523C15.0954 9.94836 15.0954 9.2872 14.7137 8.78321C13.6346 7.35892 12.8095 6.40319 11.0366 5"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
