import { styled } from "@mui/material/styles";
import { theme } from "ui/theme/theme";
import Button, { ButtonProps } from "@mui/material/Button";
import CardContent, { CardContentProps } from "@mui/material/CardContent";
import Box, { BoxProps } from "@mui/material/Box";
import Card, { CardProps } from "@mui/material/Card";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { MUIStyledCommonProps } from "@mui/system";

export const StyledDiv = styled("div")<
  MUIStyledCommonProps & { filter?: boolean }
>(({ filter }) => ({
  position: "absolute",
  color: theme.colors.black,
  backgroundColor: theme.colors.white,
  bottom: filter ? "24px" : "28.46px",
  left: filter ? "24px" : "27.75px",
  borderRadius: "40px",
  padding: filter ? "6px 10px 8px" : "6.94px 11.56px 9.25px",
  fontSize: filter ? "9px" : "10.4px",
  [theme.breakpoints.down("xl")]: {
    bottom: filter ? "18px" : "24px",
    left: filter ? "18px" : "24px",
    padding: "6px 10px 8px",
    fontSize: "9px",
  },
  [theme.breakpoints.down("md")]: {
    bottom: "27.75px",
    left: "27.26px",
    padding: "6.81px 11.36px 9.09px",
    fontSize: "10.2214px",
    lineHeight: "120%",
  },
}));

export const CardContentCard = styled(CardContent)<
  CardContentProps & { filter?: string }
>(({ filter }) => ({
  backgroundColor: `rgba(255, 255, 255, 0.06)`,
  padding: filter === "true" ? "24px 20px" : "23.44px 22.82px 26.48px 27.75px",
  opacity: 0.93,
  [theme.breakpoints.down("xl")]: {
    padding: filter === "true" ? "18px 16px" : "24px 20px",
  },
}));

export const ContentTitle = styled(Typography)<
  TypographyProps & { filter?: string }
>(({ filter }) => ({
  fontSize: filter === "true" ? "22px" : "25.43px",
  lineHeight: filter === "true" ? "26.4px" : "30.52px",
  [theme.breakpoints.down("xl")]: {
    fontSize: filter === "true" ? "18px" : "22px",
    lineHeight: filter === "true" ? "20px" : "26.4px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "24.9857px",
    lineHeight: "120%",
  },
}));

export const ContentAmount = styled(Typography)<
  TypographyProps & { filter?: string }
>(({ filter }) => ({
  fontSize: filter === "true" ? "18px" : "20.81px",
  lineHeight: "normal",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  [theme.breakpoints.down("xl")]: {
    fontSize: filter === "true" ? "16px" : "18px",
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: "20.4429px",
    lineHeight: "25px",
  },
}));

export const StyledCard = styled(Card)<CardProps>(() => ({
  maxWidth: 400,
  borderRadius: "5px",
  backgroundColor: "transparent",
  cursor: "pointer",
}));

export const CardBox = styled(Box)<BoxProps>(() => ({
  justifyContent: " space-between",
  color: theme.colors.white,
  fontSize: "22px",
  lineheight: "120%",
}));

export const CardAction = styled("div")(() => ({
  backgroundColor: "rgba(255, 255, 255, 0.06)",
  justifyContent: "center",
  color: theme.colors.white,
  padding: "1px",
  display: "flex",
  alignItems: "center",
  opacity: 0.93,
}));

export const CardSpanIconTypography = styled("span")(() => ({
  margin: "5px",
  display: "flex",
  justifyContent: "center",
}));

export const OfferButton = styled(Button)<ButtonProps & { filter?: boolean }>(
  ({ color = "primary", filter }) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: filter ? "14px" : "16.18px",
    lineHeight: filter ? "16.8px" : "19.42px",
    color: theme.colors.white,
    height: "57px",
    border: `none`,
    borderRadius: "0px",
    cursor: "pointer",
    width: "100%",
    fontWeight: 600,
    "span > svg > path": {
      stroke: theme.colors.white,
    },
    "&:hover": {
      transition: `background-color 1s`,
      backgroundColor:
        color === "primary" ? theme.colors.teal : theme.colors.white,
      "span > svg > path": {
        stroke: theme.colors.black,
      },
      "span > svg": {
        color: theme.colors.black,
      },
      color: theme.colors.black,
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: filter ? "12px" : "14px",
      lineHeight: filter ? "12.5px" : "16.8px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "15.9px",
      lineHeight: "120%",
    },
  })
);
