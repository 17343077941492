import { Transfer } from "assets/icons";
import { Bid } from "assets/icons/Bid";
import { Cart } from "assets/icons/Cart";
import { ListIcon } from "assets/icons/List";
import { FC } from "react";
import { SystemColorKey } from "ui/theme/models/Colors";
import { HistoryItemType } from "../HistoryItem/HistoryItem";
import * as S from "./HistoryItemTypeTag.style";

type HistoryItemTypeProps = {
  type: string;
};

const HistoryItemTypeTag: FC<HistoryItemTypeProps> = ({ type }) => {
  const renderHistoryItemType = () => {
    switch (type) {
      case HistoryItemType.Sale:
        return (
          <S.StyledHistoryItemTypeTag
            $color={S.StyledHistoryItemTypeTagColor.Teal}
          >
            <Cart /> Sale
          </S.StyledHistoryItemTypeTag>
        );
      case HistoryItemType.Transfer:
        return (
          <S.StyledHistoryItemTypeTag
            $color={S.StyledHistoryItemTypeTagColor.Grey}
          >
            <Transfer /> Transfer
          </S.StyledHistoryItemTypeTag>
        );
      case HistoryItemType.List:
        return (
          <S.StyledHistoryItemTypeTag
            $color={S.StyledHistoryItemTypeTagColor.Lilac}
          >
            <ListIcon /> List
          </S.StyledHistoryItemTypeTag>
        );
      case HistoryItemType.Offer:
        return (
          <S.StyledHistoryItemTypeTag
            $color={S.StyledHistoryItemTypeTagColor.Navy}
          >
            <Bid color={SystemColorKey.White} /> Offer
          </S.StyledHistoryItemTypeTag>
        );
      case HistoryItemType.Minted:
        return (
          <S.StyledHistoryItemTypeTag
            $color={S.StyledHistoryItemTypeTagColor.White}
          >
            <Bid /> Minted
          </S.StyledHistoryItemTypeTag>
        );
      default:
        return "";
    }
  };

  return <>{renderHistoryItemType()}</>;
};

export default HistoryItemTypeTag;
