import InputAdornment from "@mui/material/InputAdornment";

import { theme } from "ui/theme/theme";
import Divider from "Components/Divider";
import UntypedSuggestion from "../UntypedSuggestion";
import AutoCompleteSuggestion from "../AutoCompleteSuggestion";

import { TextField } from "./styles";
import { Search } from "assets/icons/Search";
import { SearchBarProps } from "../SearchBar";

interface SearchMobileProps extends SearchBarProps {
  closeDrawer: () => void;
}

function SearchMobile({
  searchValue,
  searchResults,
  isFetching,
  setSearchValue,
  closeDrawer,
}: SearchMobileProps) {
  const handleClose = () => closeDrawer();

  return (
    <>
      <TextField
        autoComplete="off"
        variant="outlined"
        placeholder="Start typing to search"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search height={32} width={32} color={theme.colors.white} />
            </InputAdornment>
          ),
        }}
      />
      <Divider />
      {!isFetching && searchValue && searchResults.length > 0 ? (
        <AutoCompleteSuggestion results={searchResults} onClose={handleClose} />
      ) : (
        <UntypedSuggestion resultText="" />
      )}
    </>
  );
}

export default SearchMobile;
