import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import { NearLogo } from "assets/icons/NearLogo";
import { Subtitle, CustomTextField, CustomSlide, Hyphen } from "./styles";
import { TextFieldProps } from "@mui/material/TextField";
import { theme } from "ui/theme/theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material";

function PriceTextField(props: TextFieldProps) {
  return (
    <CustomTextField
      autoComplete="off"
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <NearLogo height="10" width="10" />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

export interface PriceSelectorProps {
  priceSelectorTitle: string;
  lowerPrice: number;
  upperPrice: number;
  slideValue: number;
  price: number[];
  handleChange: (e: Event, value: number | number[]) => void;
  handleLowerPriceChange: (e: React.ChangeEvent) => void;
  handleUpperPriceChange: (e: React.ChangeEvent) => void;
}

function PriceSelector({
  priceSelectorTitle,
  lowerPrice,
  upperPrice,
  slideValue,
  price,
  handleChange,
  handleLowerPriceChange,
  handleUpperPriceChange,
}: PriceSelectorProps) {
  const match = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  return (
    <Box marginBottom={2.5}>
      <Subtitle>{priceSelectorTitle}</Subtitle>
      <Box marginBottom={1.5} display="flex" alignItems="center">
        <PriceTextField
          sx={{ marginRight: 1 }}
          value={lowerPrice}
          onChange={handleLowerPriceChange}
        />
        <Hyphen />
        <PriceTextField
          sx={{ marginLeft: 1 }}
          value={upperPrice}
          onChange={handleUpperPriceChange}
        />
      </Box>
      <Box sx={{ margin: "0 6px" }}>
        <CustomSlide
          value={price}
          max={slideValue}
          onChange={handleChange}
          valueLabelDisplay="auto"
          sx={{ color: match ? theme.colors.teal : theme.colors.black }}
        />
      </Box>
    </Box>
  );
}

export default PriceSelector;
