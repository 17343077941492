import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

export const CustomTextField = styled(TextField)<TextFieldProps>(
  ({ theme }) => ({
    background: "rgba(255, 255, 255, 0.12)",
    borderRadius: 35,
    borderWidth: 0,
    position: "relative",
    width: 188,
    height: 40,
    "&:focus": {
      outline: "none",
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
      color: theme.colors.white,
      height: 40,
      fontSize: "14px",
      [theme.breakpoints.down("md")]: {
        height: 46,
      },
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&:hover": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      height: 46,
      width: "100%",
    },
  })
);
