import Menu, { MenuProps } from "@mui/material/Menu";
import { styled } from "@mui/material/styles";

export const MenuContainer = styled(Menu)<MenuProps & { menuwidth: string }>(
  ({ menuwidth }) => ({
    "& .MuiPaper-root": {
      marginTop: "8px",
      padding: "20px",
      width: menuwidth,
    },
    "& .MuiMenu-list": {
      padding: 0,
      overflow: "hidden",
    },
    "& .MuiMenuItem-root": {
      display: "flex",
      alignItem: "center",
      height: "30px",
      padding: "10px 0",
      fontSize: "14px",
    },
  })
);
