import { useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/system/Box";
import {
  ListItemContainer,
  Title,
  Subtitle,
  RegularButton,
  ImgContainer,
  StackTextContainer,
  ItemContainer,
  DetailsContainer,
} from "./styles";
import { Token } from "api";

const ListCard = ({ token_name, contract_id, token_id, media }: Token) => {
  const [showCard, setShowCard] = useState(true);
  const smallScreen = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  const handleDelete = () => {
    setShowCard(!showCard);
  };
  return (
    <>
      <Box> </Box>
      {showCard && (
        <ListItemContainer>
          <ImgContainer>
            <img
              src={media}
              alt={`${contract_id}#${token_id}`}
              style={{ borderRadius: 5 }}
            />
          </ImgContainer>
          <ItemContainer
            direction="row"
            alignItems="center"
            spacing={smallScreen ? 0 : 1}
            justifyContent="space-between"
          >
            <StackTextContainer direction="column" spacing={0.25}>
              <Title>{token_name}</Title>
              <Subtitle>{contract_id}</Subtitle>
            </StackTextContainer>
            <DetailsContainer>
              <RegularButton
                color="secondary"
                size="medium"
                sx={{ marginRight: 0 }}
              >
                <DeleteOutlineOutlinedIcon
                  width="18"
                  height="18"
                  onClick={handleDelete}
                />{" "}
              </RegularButton>
            </DetailsContainer>
          </ItemContainer>
        </ListItemContainer>
      )}
    </>
  );
};
export default ListCard;
