import { styled } from "@mui/material/styles";
import { theme } from "ui/theme/theme";
import { ButtonProps } from "@mui/material/Button";
import Card, { CardProps } from "@mui/material/Card";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Box, BoxProps, MUIStyledCommonProps } from "@mui/system";
import { SolidButton } from "Components/Buttons";

export const Rarity = styled("span")<
  MUIStyledCommonProps & { filter?: boolean }
>(({ filter }) => ({
  position: "absolute",
  color: theme.colors.black,
  backgroundColor: theme.colors.white,
  bottom: "1.25rem",
  left: "1.25rem",
  borderRadius: "40px",
  padding: "0.25rem 0.5rem",
  fontSize: filter ? "9px" : "10.4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("xl")]: {
    fontSize: "9px",
  },
}));

export const ContentAmount = styled(Typography)<
  TypographyProps & { filter?: boolean }
>(({ filter }) => ({
  fontSize: filter ? "18px" : "20.81px",
  lineHeight: "normal",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {
    fontSize: filter ? "16px" : "18px",
  },
}));

export const StyledCard = styled(Card)<CardProps>(() => ({
  borderRadius: "5px",
  backgroundColor: "transparent",
  display: "flex",
  flexDirection: "column",
  maxWidth: "17.5rem",
}));

export const CardAction = styled("div")(() => ({
  backgroundColor: "rgba(255, 255, 255, 0.06)",
  justifyContent: "space-between",
  color: theme.colors.white,
  padding: "17px 24px 20px 17px",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  flex: "1 1 auto",
}));

export const CardActionButton = styled(SolidButton)<ButtonProps>(() => ({
  maxWidth: "110px",
  padding: "0.5rem 0.75rem",
  flex: "1 1 auto",
}));

export const CardActionTitleBox = styled(Box)<BoxProps>(() => ({
  fontSize: "80%",
  marginLeft: "0.25rem",
  flex: "2 1 min-content",
}));

export const CardActionText = styled("b")(() => ({
  fontSize: "80%",
  marginLeft: "0.25rem",
}));

export const CardMediaAspect = styled(Box)<BoxProps>(() => ({
  position: "relative",
  aspectRatio: "1/1",
}));

export const TitleCard = styled(Typography)<TypographyProps>(() => ({
  fontSize: "1rem",
  lineHeight: "120%",
  wordWrap: "break-word",
  whiteSpace: "normal",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
}));
export const TitleListed = styled(Typography)<TypographyProps>(() => ({
  fontSize: "0.875rem",
  lineHeight: "120%",
  marginTop: "2px",
  color: theme.colors.white,
  [theme.breakpoints.down("md")]: {
    fontSize: "0.75rem",
  },
}));
