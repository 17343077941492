import Grid from "@mui/material/Grid";
import { Collection } from "api/models";
import { CardFull } from "Components/Cards/CardFull";
import NoResult from "Components/NoResult";
import DiscoverCardSkeleton from "../../DiscoverCardSkeleton";

interface CollectionGridProps {
  data: Collection[] | undefined;
  smallCollectionCard: boolean;
  showFilter: boolean;
  isFetching: boolean;
  handleView: (contractId: string) => void;
}

function CollectionGrid({
  data,
  smallCollectionCard,
  showFilter,
  isFetching,
  handleView,
}: CollectionGridProps) {
  return data && data.length === 0 ? (
    <NoResult />
  ) : (
    <Grid container spacing={4}>
      {isFetching ? (
        <DiscoverCardSkeleton
          smallCollectionCard={smallCollectionCard}
          filterMode={showFilter}
        />
      ) : (
        data
          ?.map((item) => ({
            collectionName: item.collection_name,
            total: item.floor_price,
            collectionDescription: item.description,
            collectionCover: item.featured_image_url,
            contractId: item.contract_id,
          }))
          .map((item) => (
            <Grid
              item
              xs={12}
              sm={smallCollectionCard ? 4 : 6}
              lg={smallCollectionCard ? 3 : 4}
              xl={smallCollectionCard ? 3 : 4}
              key={item.collectionName}
            >
              <CardFull
                {...item}
                filter={showFilter}
                onView={() => handleView(item.contractId)}
                onClick={() => handleView(item.contractId)}
              />
            </Grid>
          ))
      )}
    </Grid>
  );
}

export default CollectionGrid;
