import styled from "@emotion/styled";
import { theme } from "ui/theme/theme";

export const Container = styled.div`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageNotFoundText = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: ${theme.colors.white};
`;
