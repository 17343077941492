import Menu, { MenuProps } from "@mui/material/Menu";
import { styled } from "@mui/material/styles";

export const StyledMenu = styled(Menu)<MenuProps>(() => ({
  "& .MuiPaper-root": {
    marginTop: "8px",
    padding: "20px",
    width: 152,
  },
  "& .MuiMenu-list": {
    padding: 0,
  },
  "& .MuiMenuItem-root": {
    display: "flex",
    alignItem: "center",
    height: "30px",
    padding: 0,
    fontSize: "14px",
  },
}));
