import { useNearWallet } from "hooks/useNear";
import React from "react";
import { Navigate } from "react-router-dom";

type Props = {
  children: React.ReactNode;
  destination?: string;
};

const AuthGuard: React.FC<Props> = ({
  children,
  destination = "/connect-wallet",
}) => {
  const { isLoggedIn } = useNearWallet();

  if (isLoggedIn) {
    return (
      <>
        {children}
        <span />
      </>
    );
  }

  return <Navigate to={destination} replace />;
};

export default AuthGuard;
