import styled from "@emotion/styled";

export const TwoCollectionsContainer = styled.div`
  padding: 164px 0px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  @media (max-width: 1700px) {
    flex-direction: column;
  }
`;

export const SectionHeaderContainer = styled.div`
  margin-bottom: 48px;
`;