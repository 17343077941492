import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { ButtonProps } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Link, { LinkProps } from "@mui/material/Link";

const TOP_HEIGHT = 84;

export const DrawerBox = styled(Box)<BoxProps>(() => ({
  width: "100vw",
  maxWidth: "600px",
}));

export const BlackBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.black,
  minHeight: `calc(100vh - ${TOP_HEIGHT}px)`,
  padding: "28px",
  paddingTop: 0,
  display: "flex",
  flexDirection: "column",
}));

export const MenuContainer = styled(Box)<BoxProps>(() => ({
  alignItems: "center",
  display: "flex",
  cursor: "pointer",
  marginBottom: "25px",
}));

export const MenuText = styled(Link)<LinkProps>(({ theme }) => ({
  color: theme.colors.white,
  fontSize: "24px",
}));

export const LinkText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.white,
  fontWeight: 600,
}));

export const LinkBox = styled(Link)<LinkProps>(() => ({
  alignItems: "center",
  marginBottom: "17px",
  display: "flex",
  justifyContent: "center",
}));

export const ButtonLabel = styled(Box)<BoxProps>(() => ({
  fontWeight: 400,
}));

export const IconContainer = styled(Box)<BoxProps>(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  height: "24px",
  width: "24px",
}));

export const HeaderContainer = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.black,
  display: "flex",
  height: TOP_HEIGHT,
  justifyContent: "space-between",
  padding: "16px",
  paddingTop: "44px",
  width: "100vw",
  position: "fixed",
  maxWidth: "600px",
  zIndex: 1,
}));

export const Logo = styled("img")(() => ({
  height: "18.39px",
  marginRight: 0,
}));

export const NavButtonWithMargin = styled(IconButton)<ButtonProps>(() => ({
  height: "24px",
  marginRight: "8px",
  width: "24px",
}));

export const NavButton = styled(IconButton)<ButtonProps>(() => ({
  height: "24px",
  width: "24px",
}));
