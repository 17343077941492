import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import { theme } from "ui/theme/theme";
import {
  DrawerBox,
  BlackBox,
  HeaderContainer,
  Logo,
  IconContainer,
  NavButton,
} from "./styles";

import logo from "assets/logo.svg";
import { Close } from "assets/icons/Close";

interface DrawerProps {
  showDrawer: boolean;
  children: React.ReactNode;
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

function DrawerMenu({ showDrawer, toggleDrawer, children }: DrawerProps) {
  return (
    <Drawer anchor="right" open={showDrawer} onClose={toggleDrawer(false)}>
      <DrawerBox>
        <HeaderContainer>
          <Logo src={logo} alt="logo" />
          <Box>
            <NavButton onClick={toggleDrawer(false)}>
              <IconContainer sx={{ height: "24px", width: "24px" }}>
                <Close height="24" width="24" color={theme.colors.white} />
              </IconContainer>
            </NavButton>
          </Box>
        </HeaderContainer>
        <Box sx={{ height: "84px" }} />
        <BlackBox>{children}</BlackBox>
      </DrawerBox>
    </Drawer>
  );
}

export default DrawerMenu;
