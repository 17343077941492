import styled from "@emotion/styled";
import { Avatar, AvatarProps, Typography } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import { TypographyProps } from "@mui/system";
import { theme } from "ui/theme/theme";

export const Creator = styled(Box)<BoxProps>(() => ({
  display: "inline-flex",
  alignItems: "center",
  backgroundColor: theme.colors.white,
  color: theme.colors.black,
  padding: "0 calc((1.875em - 1.35rem) / 2)",
  borderRadius: "calc(1.875em / 2)",
  height: "1.875em",
  boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.05)",
  gap: "0.75rem"
}));

export const CreatorAvatar = styled(Avatar)<AvatarProps>(() => ({
  width: "1.35em",
  height: "1.35em",
  fontSize: "inherit",
}));

export const CreatorName = styled(Typography)<TypographyProps>(() => ({
  fontSize: "0.6875em",
}));
