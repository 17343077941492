import Home from "features/Home/modules";
import ProfilePage from "features/Profile/Modules";
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RELATIVE_ROUTES, WILDCARD_ROUTE } from "constants/routing";
import Collections from "features/Collections/modules";
import DiscoverPage from "features/Discover/modules";
import Legal from "ui/components/Legal";
import NotFoundPage from "static pages/404";
import NftTokenPage from "features/NftToken/modules";
import AuthGuard from "../authGuard";
import ConnectWallet from "static pages/ConnectWallet";
import PasswordProtected from "features/PasswordProtected/modules";
import PasswordGuard from "routing/PasswordGuard";

function About() {
  return <h2>This is About page</h2>;
}

const RootRoutes: FC = () => (
  <Routes>
    <Route
      path={RELATIVE_ROUTES.INDEX}
      element={
        <PasswordGuard>
          <Home />
        </PasswordGuard>
      }
    />
    <Route
      path={RELATIVE_ROUTES.ABOUT}
      element={
        <PasswordGuard>
          <About />
        </PasswordGuard>
      }
    />
    <Route
      path={RELATIVE_ROUTES.TERMS}
      element={
        <PasswordGuard>
          <Legal />
        </PasswordGuard>
      }
    />
    <Route
      path={RELATIVE_ROUTES.COLLECTIONS}
      element={
        <PasswordGuard>
          <Collections />
        </PasswordGuard>
      }
    />
    <Route
      path={RELATIVE_ROUTES.COLLECTIONS_NAME}
      element={
        <PasswordGuard>
          <Collections />
        </PasswordGuard>
      }
    />
    <Route
      path={`${RELATIVE_ROUTES.COLLECTIONS_SLUG_TOKENID}/*`}
      element={
        <PasswordGuard>
          <NftTokenPage />
        </PasswordGuard>
      }
    />
    <Route
      path={RELATIVE_ROUTES.DISCOVER}
      element={
        <PasswordGuard>
          <DiscoverPage />
        </PasswordGuard>
      }
    />

    <Route
      path={RELATIVE_ROUTES.PROFILE}
      element={
        <PasswordGuard>
          <AuthGuard>
            <ProfilePage />
          </AuthGuard>
        </PasswordGuard>
      }
    />
    <Route path={RELATIVE_ROUTES.NOT_FOUND} element={<NotFoundPage />} />
    <Route
      path={RELATIVE_ROUTES.CONNECT_WALLET}
      element={
        <PasswordGuard>
          <ConnectWallet />
        </PasswordGuard>
      }
    />
    <Route
      path={RELATIVE_ROUTES.PASSWORD_PROTECTED}
      element={<PasswordProtected />}
    />
    <Route
      path={WILDCARD_ROUTE}
      element={<Navigate to={RELATIVE_ROUTES.NOT_FOUND} />}
    />
  </Routes>
);

export default RootRoutes;
