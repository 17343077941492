import { Box, BoxProps, styled } from "@mui/system";

export const Modal = styled(Box)<BoxProps>(() => ({
  position: "fixed",
  inset: "0",
  zIndex: "2000",
  display: "flex",
  justifyContent: "flex-end",
}))

export const ModalOverlay = styled(Box)<BoxProps>(() => ({
  position: "absolute",
  inset: "0",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  cursor: "pointer"
}))

export const ModalContent = styled(Box)<BoxProps>(() => ({
  position: "relative"
}))