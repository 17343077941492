import { css } from "@emotion/react";

export const scrollLargeMixin = css`
  ::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #20242b;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #262b33;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: #2f343e;
  }

  ::-webkit-scrollbar-button {
    height: 0;
  }
`;

export const scrollMixin = css`
  ${scrollLargeMixin}

  ::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: padding-box;
  }
`;
