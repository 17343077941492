import styled from "@emotion/styled";
import { scrollMixin } from "ui/theme/misc/scrollMixins";

export const NftTokenHistoryTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  padding-right: 20px;
  gap: 16px;
  max-height: 362px;
  overflow-y: auto;
  ${scrollMixin}
`;
