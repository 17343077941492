import { ReactNode, ChangeEvent, SyntheticEvent, useState } from "react";
import { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useCollections, useHomeLabels } from "api/hooks";
import { RequestConfigOrdering, RequestConfig } from "api/models";
import {
  CustomTabs,
  CustomTab,
  GreyButton,
  IconContainer,
  WhiteButton,
  GridContainer,
  TabContainer,
  ButtonContainer,
} from "./styles";
import DrawerMenu from "ui/components/Header/Drawer";
import { theme } from "ui/theme/theme";
import GridSwitch from "Components/GridSwitch";
import TokenFilter from "./TokenFilter";
import OrderButton from "../OrderButton";
import CollectionGrid from "./CollectionGrid";

import { Filter } from "assets/icons/Filter";
import { Refresh } from "assets/icons/Refresh";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function CollectionTabs() {
  const [value, setValue] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [smallCollectionCard, setSmallColectionCard] = useState(true);
  const [order, setOrder] = useState<RequestConfigOrdering>("recently_added");
  const [filters, setFilters] = useState<RequestConfig>({});
  const navigate = useNavigate();

  const { data, isFetching } = useCollections(
    {
      order_by: order,
      ...filters,
    },
    true,
    false
  );

  const { data: labels } = useHomeLabels();

  const smallScreen = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  const queryClient = useQueryClient();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSmallColectionCard(event.target.checked);
  };

  const handleRefresh = () => {
    queryClient.invalidateQueries(["collections"]);
  };

  const handleApplyFilters = (appliedFilters: RequestConfig) => {
    setFilters(appliedFilters);
    if (smallScreen) {
      setShowFilter(false);
    }
  };

  const handleView = (contractId: string) => {
    navigate(`../collections/${contractId}`);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setShowFilter(open);
    };

  const FilterButton = showFilter ? WhiteButton : GreyButton;

  return (
    <>
      {smallScreen && (
        <DrawerMenu showDrawer={showFilter} toggleDrawer={toggleDrawer}>
          <TokenFilter
            defaultFilters={filters}
            labels={labels}
            applyFilters={handleApplyFilters}
          />
        </DrawerMenu>
      )}
      <Box sx={{ width: "100%" }}>
        <TabContainer>
          <CustomTabs value={value} onChange={handleChange} aria-label="tabs">
            <CustomTab label="Collections" {...a11yProps(0)} />
          </CustomTabs>
          <Box sx={{ overflow: smallScreen ? "auto" : "hidden" }}>
            <ButtonContainer>
              <GreyButton onClick={handleRefresh}>
                <IconContainer>
                  <Refresh height={18} width={18} color={theme.colors.white} />
                </IconContainer>
              </GreyButton>
              <OrderButton onSetOrder={setOrder} />
              {!smallScreen && (
                <GridSwitch
                  checked={smallCollectionCard}
                  onChange={handleSwitchChange}
                />
              )}
              <FilterButton
                width="115px"
                onClick={() => setShowFilter(!showFilter)}
              >
                <IconContainer marginRight="5px">
                  {showFilter ? (
                    <Filter height={18} width={18} color={theme.colors.black} />
                  ) : (
                    <Filter
                      type="duotone"
                      height={18}
                      width={18}
                      color={theme.colors.white}
                      secondColor={theme.colors.black}
                    />
                  )}
                </IconContainer>
                Filter
              </FilterButton>
            </ButtonContainer>
          </Box>
        </TabContainer>

        <TabPanel value={value} index={0}>
          <GridContainer>
            <CollectionGrid
              data={data}
              isFetching={isFetching}
              smallCollectionCard={smallCollectionCard}
              showFilter={showFilter}
              handleView={handleView}
            />
            {showFilter && !smallScreen && (
              <TokenFilter
                defaultFilters={filters}
                labels={labels}
                applyFilters={handleApplyFilters}
              />
            )}
          </GridContainer>
        </TabPanel>
      </Box>
    </>
  );
}

export default CollectionTabs;
