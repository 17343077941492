import styled from "@emotion/styled";
import { theme } from "ui/theme/theme";
import { Box, BoxProps } from "@mui/material";

export const SectionHeaderContainer = styled(Box)<BoxProps>(() => ({
  backgroundColor: `${theme.colors.black}`,
  display: "flex",
  padding: "40px 5px 72px",
  gap: "20px",

  justifyContent: "space-between",
  " @media (max-width: 768px)": {
    flexDirection: "column",
    gridTemplateColumns: " 1fr",
  },
}));

export const SectionButtonContainer = styled(Box)<BoxProps>(() => ({
  display: "grid",
  justifyContent: "center",
  paddingBottom: "254px",
  paddingTop: "50px",
}));

export const Filters = styled(Box)<BoxProps>(() => ({
  display: "flex",
  gap: "0.5rem",
  overflowX: "auto",
}));
