import { styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Chip, { ChipProps } from "@mui/material/Chip";
import Typography, { TypographyProps } from "@mui/material/Typography";

export const ListItemContainer = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.07)",
  borderRadius: "10px",
  display: "flex",
  minHeight: 152,
  padding: "24px",
  [theme.breakpoints.down("md")]: {
    padding: "16px 20px",
  },
}));

export const Tag = styled(Chip)<ChipProps>(({ theme }) => ({
  background: theme.colors.teal,
  color: theme.colors.black,
  fontSize: "9px",
  height: "25px",
  width: "64px",
  "&.MuiChip-root": {
    borderRadius: "40px",
  },
  "& .MuiChip-label": {
    fontWeight: 400,
    lineHeight: "normal",
    paddingLeft: "3px",
    paddingRight: 0,
  },
}));

export const TagList = styled(Tag)<ChipProps>(() => ({
  background: "#C9D6F8",
}));

export const TagOffer = styled(Tag)<ChipProps>(({ theme }) => ({
  background: "#0E2958",
  color: theme.colors.white,
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.white,
  fontWeight: 400,
  fontSize: "32px",
  lineHeight: "120%",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
  },
}));

export const Subtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.white,
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "120%",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

export const Bold = styled("span")(() => ({
  fontWeight: 600,
}));

export const BoldTeal = styled("span")(({ theme }) => ({
  color: theme.colors.teal,
  fontWeight: 600,
}));

export const Time = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: "rgba(255, 255, 255, 0.5)",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "120%",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
}));
