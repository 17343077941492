import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { NearLogo } from "assets/icons/NearLogo";
import { ArrowRight } from "assets/icons/ArrowRight";
import { Verified } from "assets/icons/Verified";
import { CreatorName } from "Components/Pills/CreatorName";
import { TranslucentRegularButton } from "Components/Buttons/index";
import { numberFormat } from "utils/number-format";

import * as S from "./styles";

const MAX_DESCRIPTION_LENGTH = 110;

type CardProps = {
  collectionName: string;
  total: string;
  id?: number;
  collectionDescription: string;
  collectionCover: string;
  collectionCreator?: {
    creatorName: string;
    avatar: string;
  };
  filter?: boolean;
  showId?: boolean;
  onView?: () => void;
  onClick?: () => void;
};

export const CardFull = ({
  collectionName,
  total,
  collectionCreator,
  id,
  collectionCover,
  collectionDescription,
  filter,
  showId,
  onView,
  onClick,
}: CardProps) => (
  <S.Card>
    <div style={{ position: "relative" }}>
      <CardMedia
        component="img"
        image={collectionCover}
        alt={collectionName}
        sx={{ borderRadius: "5px 5px 0px 0px", aspectRatio: "1" }}
        onClick={onClick}
      />
      {collectionCreator && (
        <S.LayoutDiv filter={filter}>
          {" "}
          {filter ? (
            <CreatorName
              isVerified
              avatar={collectionCreator.avatar}
              widthCreator="19.08px"
              heightCreator="19.08px"
            >
              {collectionCreator.creatorName}
            </CreatorName>
          ) : (
            <CreatorName
              isVerified
              avatar={collectionCreator.avatar}
              widthCreator="22px"
              heightCreator="22px"
            >
              {collectionCreator.creatorName}
            </CreatorName>
          )}
        </S.LayoutDiv>
      )}
      {id && showId ? (
        <S.DivOne>
          <S.SpanOne>{id}</S.SpanOne>
        </S.DivOne>
      ) : null}
    </div>

    <S.CardContentCard onClick={onClick}>
      <S.CardBox filter={filter}>
        <Box component="div" sx={{ display: "block" }}>
          <Typography gutterBottom variant="body2">
            <span style={{ marginRight: "5px" }}>{collectionName}</span>
            <Verified
              height="13"
              width="13"
              color="#9CFFF8"
              secondColor="#000000"
              type="filled"
            />
          </Typography>
          <Typography
            gutterBottom
            variant="caption"
            component="div"
            title={collectionDescription}
            sx={{ height: "60px", overflow: "hidden" }}
          >
            {collectionDescription.length > MAX_DESCRIPTION_LENGTH
              ? // eslint-disable-next-line prefer-template
                collectionDescription.slice(0, MAX_DESCRIPTION_LENGTH) + "..."
              : collectionDescription}
          </Typography>
        </Box>
        <Box component="div" sx={{ display: "flex" }}>
          {filter ? (
            <Typography
              variant="body2"
              sx={{ display: "flex", alignItems: "center", height: "14px" }}
            >
              {total ? numberFormat(Number(total)) : "-"}{" "}
              <NearLogo height="11.69" width="11.14" color="#FFFFFF" />
            </Typography>
          ) : (
            <Typography
              variant="body2"
              sx={{ display: "flex", alignItems: "center", height: "14px" }}
            >
              {total ? numberFormat(Number(total)) : "-"}{" "}
              <NearLogo height="13.47" width="14" color="#FFFFFF" />
            </Typography>
          )}
        </Box>
      </S.CardBox>
    </S.CardContentCard>

    <S.Divider />
    <S.CardActionsCard>
      <TranslucentRegularButton
        color="secondary"
        size="large"
        fullWidth
        onClick={onView}
      >
        View{" "}
        <S.CardIconTypography>
          <ArrowRight height="13.89" width="13.83" />
        </S.CardIconTypography>
      </TranslucentRegularButton>
    </S.CardActionsCard>
  </S.Card>
);
