export const useEnabledFeatures = () => ({
    collection: {
        socials: false,
        more: false,
    },
    home: {
        newCollectionsDropdown: false,
        topCollectionsDropdown: false,
    },
    buy: {
        cart: false
    }
})
