import styled from "@emotion/styled";
import { FC } from "react";
import { theme } from "ui/theme/theme";

type Props = {
  children: React.ReactNode;
  Icon: React.ReactElement;
};

const StyledTag = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "35px",
  padding: "11.5px 17px",

  fontWeight: 600,
  fontSize: 16,
  gap: 7,
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.colors.white
      : theme.colors.darkWhite,
  color:
    theme.palette.mode === "light" ? theme.colors.black : theme.colors.white,

  // " > svg > path": {
  //   stroke:
  //     theme.palette.mode === "light" ? theme.colors.black : theme.colors.white,
  // },
}));

export const Tag: FC<Props> = ({ Icon, children }) => (
  <StyledTag>
    {!!Icon && Icon}
    {children}
  </StyledTag>
);
