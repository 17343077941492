import React, { useEffect, useState } from "react";
import {
  Account,
  connect,
  Contract,
  keyStores,
  Near,
  WalletConnection,
  utils,
} from "near-api-js";
import environment from "./config";
import environmentnft from "./confignft";

const env = process?.env.REACT_APP_NEAR_ENV || "testnet";
export const nearEnv = environment(env);

export async function initializeContract() {
  const near = await connect(
    // eslint-disable-next-line prefer-object-spread
    Object.assign(
      { deps: { keyStore: new keyStores.BrowserLocalStorageKeyStore() } },
      nearEnv
    )
  );
  return near;
}

export const NearContext = React.createContext<Near | null>(null);

export function useNear() {
  const nearContext = React.useContext(NearContext);
  if (nearContext === null) {
    throw new Error("useNear must be used within a NearProvider.");
  }
  return nearContext;
}

export function useNearWallet() {
  const near = useNear();
  const walletConnection = new WalletConnection(near, null);
  const accountId = walletConnection.getAccountId();

  const [isLoggedIn, setLoggedIn] = useState(walletConnection.isSignedIn());

  useEffect(() => {
    if (accountId) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [accountId]);

  const login = () => {
    walletConnection.requestSignIn(nearEnv.contractName);
  };

  const logout = () => {
    walletConnection.signOut();
    setLoggedIn(false);
  };

  return { walletConnection, accountId, isLoggedIn, login, logout };
}

export interface MarketDataJson {
  owner_id: string;
  approval_id: number;
  nft_contract_id: string;
  token_id: string;
  ft_token_id: string;
  price: string;
}

export interface GetMarketDataArgs {
  nft_contract_id: string;
  token_id: string;
}

export interface StorageBalanceOfArgs {
  account_id: string;
}

export interface BuyArgs {
  nft_contract_id: string;
  token_id: string;
  ft_token_id: string;
  price: string;
}

export interface AddOfferArgs {
  nft_contract_id: string;
  token_id: string;
  ft_token_id: string;
  price: string;
}

export interface StorageDepositArgs {
  account_id?: string;
}

export interface GetOfferArgs {
  nft_contract_id: string;
  buyer_id: string;
  token_id: string;
}
export interface OfferDataJson {
  nft_contract_id: string;
  buyer_id: string;
  token_id: string;
  ft_token_id: string;
  price: string;
}

export interface CancelOfferArgs {
  nft_contract_id: string;
  token_id: string;
}

// Delist an item is equal to delete the market data for that item.
export interface DelistItemArgs {
  nft_contract_id: string;
  token_id: string;
}

export interface MarketplaceContract {
  contractId: string;
  account: Account;

  get_market_data: (args: GetMarketDataArgs) => MarketDataJson;
  storage_balance_of: (args: StorageBalanceOfArgs) => string;

  buy: (args: BuyArgs, amount: string, gas?: string) => string;
  add_offer: (args: AddOfferArgs, amount: string, gas?: string) => void;
  storage_deposit: (
    args: StorageDepositArgs,
    amount: string,
    gas?: string
  ) => void;
  get_offer: (args: GetOfferArgs) => OfferDataJson;
  delete_offer: (args: CancelOfferArgs, amount: string, gas?: string) => string;
  delete_market_data: (
    args: DelistItemArgs,
    amount: string,
    gas?: string
  ) => void;
}

export function useMarketplaceContract(): MarketplaceContract {
  const { walletConnection } = useNearWallet();
  const contract = new Contract(
    walletConnection.account(),
    nearEnv.contractName,
    {
      viewMethods: ["get_market_data", "storage_balance_of", "get_offer"],
      changeMethods: [
        "buy",
        "add_offer",
        "storage_deposit",
        "delete_offer",
        "delete_market_data",
      ],
    }
  );
  return contract as unknown as MarketplaceContract;
}
export const nearEnvd = environmentnft(env, "thedons.nft.acova.aslabs.testnet");

// NFT contract

// Get media and attributes for a token
interface NftToken {
  token_id: string;
}
interface NftMedia {
  title: string;
  description: string;
  media: string;
  media_hash: string;
  copies: string;
  issued_at: string;
  expires_at: string;
  starts_at: string;
  updated_at: string;
  extra: string;
  reference: string;
  reference_hash: string;
}

export interface NftDataJson {
  owner_id: string;
  token_id: string;
  metadata: NftMedia;
  approved_account_ids: string[];
}

// List token at price (using approval)

interface ListTokenArgs {
  token_id: string;
  account_id: string;
  msg: string;
}

// Accept offer

export interface NftContract {
  contractId: string;
  account: Account;
  nft_token: (args: NftToken) => NftDataJson;
  nft_approve: (args: ListTokenArgs, amount: string, gas?: string) => void;
}

export function useNftContract() {
  const { walletConnection } = useNearWallet();

  const getNftContract = async (contract_id: string) => {
    const nftContract = new Contract(walletConnection.account(), contract_id, {
      viewMethods: ["nft_token"],
      changeMethods: ["nft_approve"],
    });
    return nftContract as unknown as NftContract;
  };

  return getNftContract;
}

interface NftFaucetContract {
  contractId: string;
  account: Account;
  get_nft: (args: object, amount: string, gas?: string) => void;
}

const faucetGas = utils.format.parseNearAmount("0.0000000003")!;

export function useTestnet() {
  const { walletConnection } = useNearWallet();
  const isTestnet = nearEnv.networkId === "testnet";

  const getFaucet = (contractId: string) => {
    const nftContract = new Contract(
      walletConnection.account(),
      `faucet.${contractId}`,
      {
        viewMethods: [],
        changeMethods: ["get_nft"],
      }
    );
    return nftContract as unknown as NftFaucetContract;
  };

  const getToken = async (contractId: string) => {
    const faucet = getFaucet(contractId);
    await faucet.get_nft({}, faucetGas, "1");
  };

  return {
    isTestnet,
    getToken,
  };
}
