import styled from "@emotion/styled";

export const SectionHeaderContainer = styled.div`
  margin-bottom: 48px;
`;
export const CollectionsContainer = styled.div`
  display: flex;
  gap: 38px;
  flex-wrap: wrap;
`;
