import * as React from "react";
import Box from "@mui/material/Box";
import WalletContainer from "../../../ui/components/Header/WalletContainer";
import WalletButtonSizable from "Components/Buttons/WalletButtonSizable";
import ListCard from "Components/Cards/ListCard";
import FormItem from "../FormItem";
import useMarketplace from "../../../hooks/marketPlace";
import { useNearWallet } from "../../../hooks/useNear";
import {
  TextNormal,
  MainHead,
  StyledContainer,
  Logo,
  LogoView,
  DisplayControlledBox,
} from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import logo from "assets/logo.svg";
import { utils } from "near-api-js";
import { Token } from "api";
import ConnectPanel from "Components/ConnectPanel";

interface MainPanelProps {
  onClose?: () => void;
  setResultPanel: (value: boolean) => void;
  setTitle: (value: string) => void;
  setSubTitle: (value: string) => void;
  setIconName: (value: string) => void;
  panelMainTitle: string;
  panelInputTitle: string;
  panelInputPlaceHolder: string;
  buttonText: string;
  Icon: React.ReactElement;
  offerPrice?: string;
  hasOffer?: boolean;
  token: Token;
  setUpdateOffer?: (value: boolean) => void;
  isLoggedIn: boolean;
  floorPrice?: string;
}
export default function MainPanel({
  onClose,
  setResultPanel,
  setSubTitle,
  setTitle,
  setIconName,
  panelMainTitle,
  panelInputTitle,
  panelInputPlaceHolder,
  buttonText,
  Icon,
  offerPrice,
  hasOffer,
  token,
  setUpdateOffer,
  isLoggedIn,
  floorPrice,
}: MainPanelProps) {
  const marketPlace = useMarketplace();
  const { walletConnection } = useNearWallet();

  const fillResultPanel = (
    title: string,
    subTitle: string,
    iconName: string
  ) => {
    setTitle(title);
    setSubTitle(subTitle);
    setIconName(iconName);
    setResultPanel(false);
  };

  const handleClickOffer = async (value: string) => {
    const balance = (await walletConnection.account().state()).amount;
    if (Number(utils.format.parseNearAmount(value)) < Number(balance)) {
      await marketPlace.placeOffer(
        token.contract_id,
        token.token_id,
        "near",
        value
      );
      if (setUpdateOffer) {
        setUpdateOffer(true);
      }
    } else {
      fillResultPanel(
        "Offer does not sent",
        "You don't have enough money.",
        "error"
      );
    }
  };

  const cancelOffer = async () => {
    const result = await marketPlace.cancelTokenOffer(
      token.contract_id,
      token.token_id
    );
    if (setUpdateOffer) {
      setUpdateOffer(false);
    }
    if (result !== undefined) {
      fillResultPanel(
        "Offer cancelled",
        "Your offer has been cancelled.",
        "verified"
      );
    }
  };

  return (
    <>
      {isLoggedIn && (
        <StyledContainer>
          <LogoView>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Logo src={logo} alt="logo" />
              <CloseIcon onClick={onClose} />
            </Box>
          </LogoView>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MainHead>{panelMainTitle}</MainHead>
            <WalletContainer Button={WalletButtonSizable} menuwidth="180px" />
            <DisplayControlledBox>
              <CloseIcon onClick={onClose} />
            </DisplayControlledBox>
          </Box>
          <TextNormal sx={{ paddingTop: "32px", paddingBottom: "24px" }}>
            Item
          </TextNormal>
          <ListCard {...token} />
          <Box>
            <TextNormal sx={{ paddingTop: "32px", paddingBottom: "20px" }}>
              {panelInputTitle}
            </TextNormal>
          </Box>
          <FormItem
            onClose={onClose}
            panelInputPlaceHolder={panelInputPlaceHolder}
            buttonText={buttonText}
            Icon={Icon}
            handleSubmit={handleClickOffer}
            hasOffer={hasOffer}
            cancelOffer={cancelOffer}
            offerPrice={offerPrice}
            listedPrice={token.listed_price || "0"}
            floorPrice={floorPrice}
          />
        </StyledContainer>
      )}
      {!isLoggedIn && (
        <ConnectPanel
          setResultPanel={setResultPanel}
          onClose={onClose}
          title="Please Login first"
          subtitle="To be able to make an offer"
        />
      )}
    </>
  );
}
