import styled from "@emotion/styled";
import { Box, BoxProps } from "@mui/material";
import { theme } from "ui/theme/theme";

export const SectionHeaderContainer = styled.div(() => ({
  display: "flex",
  justifyContent: "flex-start",
  maxWidth: "694px",
  alignContent: "center",
  gap: "1rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "0.5rem",
  },
}));

export const SectionHeaderCRAButtons = styled(Box)<BoxProps>(() => ({
  display: "flex",
  gap: "12px",
  lineHeight: "0",
}));
