import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material";

import Tags from "../Tags";
import Divider from "Components/Divider";

const MOCK_CATEGORIES = ["Art", "Photography", "Games", "Metaverse", "NSFW"];
const MOCK_CATEGORIES_2 = [
  "3D",
  "AI",
  "Algorithmic",
  "Animation",
  "Banner",
  "Collage",
  "Drawing",
  "Generative",
  "Illustration",
  "Music",
  "Paint",
  "Photography",
  "Pixel",
  "Profile Picture",
];

interface UntypedSuggestionProps {
  resultText: string;
}

function UntypedSuggestion({ resultText }: UntypedSuggestionProps) {
  const match = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  return (
    <>
      {!match && (
        <>
          <Typography>{resultText}</Typography>
          <Divider />
        </>
      )}

      <Tags title="Categories" tags={MOCK_CATEGORIES} />
      <Divider />
      <Tags title="Art style" tags={MOCK_CATEGORIES_2} />
    </>
  );
}

export default UntypedSuggestion;
