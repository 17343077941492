import { FC } from "react";
import { SVGProps } from "interfaces";

export const Discord: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9113 16.4146C14.7369 16.1638 14.5734 15.9055 14.4212 15.6407C14.8083 15.4714 15.185 15.2788 15.5491 15.0638L16.2291 14.6624L15.5755 14.2194C15.4603 14.1413 15.3432 14.0521 15.2246 13.9553L14.9806 13.7562L14.6957 13.8902C13.3005 14.5463 11.7777 14.8865 10.236 14.8865C8.69422 14.8865 7.17146 14.5463 5.77628 13.8902L5.50069 13.7606L5.25909 13.946C5.13413 14.0419 5.00963 14.137 4.88347 14.2285L4.26629 14.6761L4.9228 15.0638C5.28599 15.2783 5.6617 15.4704 6.04781 15.6393C5.89551 15.9044 5.73199 16.1629 5.5577 16.4141C4.12904 15.9436 2.77984 15.2588 1.55635 14.3829C1.29165 11.1702 1.9265 7.91914 4.15297 4.57232C5.1812 4.11363 6.25795 3.77319 7.3625 3.55737C7.46879 3.76441 7.56785 3.97512 7.65949 4.18913L7.81157 4.54431L8.19363 4.48674C9.54611 4.28293 10.9215 4.28293 12.274 4.48674L12.6561 4.54432L12.8082 4.18903C12.8998 3.9749 12.9989 3.76407 13.1053 3.55694C14.2093 3.77136 15.2856 4.11117 16.313 4.56993C18.2518 7.45151 19.224 10.6758 18.9158 14.3843C17.6919 15.2611 16.3414 15.9457 14.9113 16.4146ZM5.00393 10.7003C5.00393 11.9452 5.93449 13.044 7.16748 13.044C8.428 13.044 9.31024 11.9351 9.32952 10.7081C9.3492 9.45632 8.42042 8.34931 7.16458 8.34931C5.90058 8.34931 5.00393 9.46402 5.00393 10.7003ZM11.1424 10.7003C11.1424 11.9443 12.0692 13.044 13.3045 13.044C14.5655 13.044 15.4458 11.9345 15.4651 10.7081C15.4847 9.4584 14.5637 8.34931 13.3045 8.34931C12.0393 8.34931 11.1424 9.46372 11.1424 10.7003Z"
      fill={color}
      stroke={color}
    />
  </svg>
);
