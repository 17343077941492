import React, { FC, useState, useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, BoxProps } from "@mui/system";
import { AcovaTabProps } from "./AcovaTab";

export * from "./AcovaTab";

export interface AcovaTabsProps extends BoxProps {
  defaultSelected?: string;
}

export const AcovaTabs: FC<AcovaTabsProps> = ({
  children,
  defaultSelected,
  ...rest
}) => {
  const items: Array<AcovaTabProps> =
    React.Children.map(children, (item) => {
      if (React.isValidElement(item)) {
        return {
          ...item.props,
          label: item.props.label as string,
          children: item.props.children,
        } as AcovaTabProps;
      }
    }) || [];

  const selectDefault = () => {
    if (
      defaultSelected &&
      items.find((item) => item.label === defaultSelected)
    ) {
      return defaultSelected;
    }

    return items && items.length ? items[0].label : "";
  };

  const [active, setActive] = useState(selectDefault());

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActive(newValue);
  };

  useEffect(() => {
    const activeChild = items.find((i) => i.label === active);
    if (!activeChild) {
      setActive(selectDefault());
    }
  }, [children]);

  return (
    <Box className="acova-tabs" {...rest}>
      <Tabs
        value={active}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
      >
        {items && items.length
          ? items.map((item) => (
              <Tab
                key={item.label}
                label={item.label}
                value={item.label}
                sx={item.sx}
              />
            ))
          : null}
      </Tabs>
      <TabContext value={active}>
        {items && items.length
          ? items.map((item) => (
              <TabPanel
                key={item.label}
                sx={{ ...item.sx, padding: "0.5rem 0" }}
                value={item.label}
              >
                {item.children}
              </TabPanel>
            ))
          : null}
      </TabContext>
    </Box>
  );
};
