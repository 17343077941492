import Box from "@mui/material/Box";
import { Subtitle, StatusChip, ChipStack } from "./styles";

export interface StatusChipProps {
  name: string;
  value?: number;
  checked: boolean;
}

export interface MultipleChipsProps {
  multipleChipsTitle: string;
  attributes: StatusChipProps[];
  handleClick: (name: string) => void;
}

function MultipleChips({
  multipleChipsTitle,
  attributes,
  handleClick,
}: MultipleChipsProps) {
  return (
    <Box marginBottom={4}>
      <Subtitle>{multipleChipsTitle}</Subtitle>
      <ChipStack direction="row">
        {attributes.map((value) =>
          value.checked ? (
            <StatusChip
              key={value.name}
              label={`${value.name}`}
              // color="primary"
              onClick={() => handleClick(value.name)}
            />
          ) : (
            <StatusChip
              key={value.name}
              label={`${value.name}`}
              variant="outlined"
              onClick={() => handleClick(value.name)}
            />
          )
        )}
      </ChipStack>
    </Box>
  );
}

export default MultipleChips;
