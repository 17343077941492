import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

export const TextField = styled(MuiTextField)<TextFieldProps>(({ theme }) => ({
  background: theme.colors.black,
  borderRadius: 35,
  borderWidth: 0,

  position: "relative",
  width: "100%",
  "&:focus": {
    outline: "none",
  },
  "& .MuiOutlinedInput-root": {
    border: "none",
    color: theme.colors.white,
    // height: 50,
    fontSize: "24px",
    lineHeight: "110%",
    paddingLeft: 0,
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
}));
