import { TranslucentRegularButton } from "Components/Buttons/index";
import StandardButton from "Components/Buttons/StandardButton/StandardButton";
import { StyledStandardButtonColor } from "Components/Buttons/StandardButton/StandardButton.style";

import {
  CardSpanIconTypography,
  CardActionButton,
  CardActionText,
  OfferButton,
} from "./styles";

type ButtonType = "buy" | "list" | "offer";

interface RenderButtonProps {
  panelButtonText: string;
  Icon: React.ReactElement;
  buttonType: ButtonType;
  isToken?: boolean;
  handleOpen: () => void;
}
const renderButton = ({
  panelButtonText,
  Icon,
  buttonType,
  isToken,
  handleOpen,
}: RenderButtonProps) => {
  switch (buttonType) {
    case "buy":
      if (isToken) {
        return (
          <StandardButton
            color={StyledStandardButtonColor.Teal}
            onClick={handleOpen}
          >
            <CardSpanIconTypography>{Icon}</CardSpanIconTypography>
            {panelButtonText}
          </StandardButton>
        );
      }
      return (
        <TranslucentRegularButton
          color="secondary"
          size="large"
          fullWidth
          onClick={handleOpen}
        >
          <CardSpanIconTypography>{Icon}</CardSpanIconTypography>
          {panelButtonText}
        </TranslucentRegularButton>
      );
    case "list":
      return (
        <CardActionButton color="secondary" size="medium" onClick={handleOpen}>
          {Icon}
          <CardActionText>{panelButtonText}</CardActionText>
        </CardActionButton>
      );
    case "offer":
      if (isToken) {
        return (
          <StandardButton
            color={StyledStandardButtonColor.White}
            onClick={handleOpen}
          >
            <CardSpanIconTypography>{Icon}</CardSpanIconTypography>
            {panelButtonText}
          </StandardButton>
        );
      }
      return (
        <OfferButton color="secondary" size="large" onClick={handleOpen}>
          {panelButtonText}
          <CardSpanIconTypography>{Icon}</CardSpanIconTypography>
        </OfferButton>
      );
    default:
      return (
        <OfferButton color="secondary" size="large" onClick={handleOpen}>
          {panelButtonText}
          <CardSpanIconTypography>{Icon}</CardSpanIconTypography>
        </OfferButton>
      );
  }
};

export default function ButtonItem({
  panelButtonText,
  Icon,
  buttonType,
  isToken,
  handleOpen,
}: RenderButtonProps) {
  const items = {
    panelButtonText,
    Icon,
    buttonType,
    isToken,
    handleOpen,
  };
  return <>{renderButton(items)}</>;
}
