import { MutableRefObject } from "react";
import { useNavigate } from "react-router-dom";
import Box, { BoxProps } from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

import { MenuList, Title, ResultName } from "./styles";
import {ResultItem} from '../types'

import { Verified } from "assets/icons/Verified";
// In case for future use
// import artist1 from "assets/mocks/artist_1.png";
// import artist2 from "assets/mocks/artist_2.png";

interface ResultListProps {
  title: string;
  results: ResultItem[];
  menuItemRef?: MutableRefObject<HTMLLIElement | null>;
  onClose: () => void;
}

interface AutoCompleteSuggestionProps {
  results: ResultItem[];
  menuItemRef?: MutableRefObject<HTMLLIElement | null>;
  onClose: () => void;
}

// In case for future use
// const MOCK_RESULTS = {
//   title: "Artists",
//   results: [
//     {
//       name: "thedonsproject",
//       imagePath: artist1,
//       isCertified: true,
//     },
//     {
//       name: "thebullishbulls",
//       imagePath: artist2,
//       isCertified: true,
//     },
//   ],
// };

const ResultContainer = styled(Box)<BoxProps>(() => ({
  alignItems: "center",
  display: "flex",
}));

function ResultList({ title, results, menuItemRef, onClose }: ResultListProps) {
  const naviagate = useNavigate();
  const handleMenuClick = (path: string) => {
    naviagate(`/collections/${path}`);
    onClose();
  };

  return (
    <>
      <Title>{title}</Title>
      <MenuList>
        {results.map((result, index) => (
          <MenuItem
            key={result.name}
            ref={(instance) => {
              if (index === 0 && menuItemRef) {
                menuItemRef.current = instance;
              }
            }}
            onClick={() => handleMenuClick(result.path)}
          >
            <ResultContainer>
              <>
                <img
                  style={{ marginRight: 12 }}
                  src={result.imagePath}
                  height="32"
                  width="32"
                  alt={result.name}
                />
                <ResultName>{result.name}</ResultName>
                {result.isCertified && (
                  <Box sx={{ marginLeft: "4.54px" }}>
                    <Verified height={14} width={14} type="filled" />
                  </Box>
                )}
              </>
            </ResultContainer>
          </MenuItem>
        ))}
      </MenuList>
    </>
  );
}

function AutoCompleteSuggestion({
  results,
  menuItemRef,
  onClose,
}: AutoCompleteSuggestionProps) {
  return (
    <>
      <ResultList
        title="Collections"
        results={results}
        menuItemRef={menuItemRef}
        onClose={onClose}
      />
      {/* In case for future use */}
      {/* <Divider sx={{ marginBottom: "20px" }} />
      <ResultList {...MOCK_RESULTS} /> */}
    </>
  );
}

export default AutoCompleteSuggestion;
