import styled from "@emotion/styled";
import { ButtonProps } from "@mui/material/Button";
import { Box, BoxProps } from "@mui/system";
import { TranslucentButton } from "Components/Buttons";
import { theme } from "ui/theme/theme";

export const StyledHeader = styled(Box)<BoxProps>(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
}));

export const CardGrid = styled(Box)<BoxProps>(() => ({
  display: "grid",
  gap: "2rem",
  marginBottom: "2rem",
  gridTemplateColumns: "repeat(auto-fit, minmax(12rem, 1fr))",
  gridTemplateRows: "auto",
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(auto-fit, minmax(12.5rem, 1fr))",
  },
  [theme.breakpoints.down("md")]: {
    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    display: "block",
    "& .MuiPaper-root": {
      display: "inline-block",
      marginRight: "20px",
    },
  },
}));

export const ModifiedTranslucentButton = styled(TranslucentButton)<ButtonProps>(
  () => ({
    width: "5.5rem",
    height: "2.25rem",
    fontSize: "0.813rem",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  })
);
