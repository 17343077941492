import styled from "@emotion/styled";
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuProps,
  Button,
  ButtonProps,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";

import { theme } from "ui/theme/theme";

export const Menu = styled(MuiMenu)<MenuProps>({
  overflow: "hidden",
  borderRadius: "0.5rem",
  marginTop: "0.875rem",
});

export const MenuItem = styled(MuiMenuItem)({
  flex: "1",
  padding: "0",
});

export const Label = styled(FormControlLabel)<FormControlLabelProps>({
  display: "block",
  width: "100%",
  cursor: "pointer",
  padding: "0.35rem 1rem",
  marginRight: "0",
  textTransform: "capitalize",

  "&.single": {
    paddingLeft: "1.5rem",
  },
});

export const FilterButton = styled(Button)<ButtonProps>(() => ({
  borderRadius: "2.25rem",
  boxShadow: "none",
  fontWeight: 600,
  minHeight: "2.5rem",
  fontSize: "0.875rem  ",
  width: 'max-content',
  color: theme.colors.white,
  padding: "0.625rem 1rem",
  backgroundColor: theme.colors.darkWhite,
  border: "none",
  cursor: "pointer",
  textTransform: "capitalize",

  "&:hover, &.active": {
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
  },

  "&.transparent": {
    color: theme.colors.black,
    border: `1px solid`,
  },
  ".MuiButton-endIcon": {
    marginLeft: "4px",
  },
}));
