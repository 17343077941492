import { ArrowIconDirection, CircleButton } from "Components/Circle";
import { FC } from "react";
import { PaginationContainer } from "./styles";

type PaginationProps = {
  // totalPages?: number;
  // currentPage?: number;
  // onPageChange?: (page: number) => void;
  handleGoBack?: () => void;
  handleGoForward?: () => void;
};

export const Pagination: FC<PaginationProps> = ({
  // totalPages,
  // currentPage,
  // onPageChange,
  handleGoBack,
  handleGoForward,
}) => (
  <PaginationContainer>
    <CircleButton onClick={handleGoBack} direction={ArrowIconDirection.Left} />
    <CircleButton
      onClick={handleGoForward}
      direction={ArrowIconDirection.Right}
    />
  </PaginationContainer>
);
