import { FC } from "react";
import { SVGProps } from "interfaces";

export const Search: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.11306 14.4487C12.0588 14.4487 14.4468 12.0608 14.4468 9.11501C14.4468 6.16925 12.0588 3.78125 9.11306 3.78125C6.1673 3.78125 3.7793 6.16925 3.7793 9.11501C3.7793 12.0608 6.1673 14.4487 9.11306 14.4487Z"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.208 16.2109L12.8877 12.8906"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
