import { InputAdornment } from "@mui/material";
import { Coins, Cross, NearLogo } from "assets/icons";
import { BinIcon } from "assets/icons/BinIcon";
import { ErrorIcon } from "assets/icons/ErrorIcon";
import CircularButton from "Components/Buttons/CircularButton/CircularButton";
import { StyledCircularButtonSize } from "Components/Buttons/CircularButton/CircularButton.style";
import StandardButton from "Components/Buttons/StandardButton/StandardButton";
import { StyledStandardButtonColor } from "Components/Buttons/StandardButton/StandardButton.style";
import WalletButtonSizable from "Components/Buttons/WalletButtonSizable";
import { FC, useState } from "react";
import WalletContainer from "ui/components/Header/WalletContainer";
import { theme } from "ui/theme/theme";
import * as S from "./styles";

type MakeOfferModalProps = {
  onClose: () => void;
};

const MakeOfferModal: FC<MakeOfferModalProps> = ({ onClose }) => {
  const [offerValue, setOfferValue] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmit = () => {
    if (offerValue === undefined || offerValue === 0) {
      setErrorMessage("Please enter an amount");
    } else if (offerValue && offerValue < 0) {
      setErrorMessage("Please enter a positive amount");
    } else if (offerValue && offerValue > 100) {
      setErrorMessage("Your offer is higher than the listing price of 40 NEAR");
    } else {
      setErrorMessage("");
    }
  };
  return (
    <S.MakeOfferModalContainer>
      <S.MakeOfferModalHeader>
        <S.MakeOfferModalTitle>Make an offer</S.MakeOfferModalTitle>
        <WalletContainer
          Button={WalletButtonSizable}
          menuwidth="280px"
          withIcon={false}
        />
        <CircularButton
          onClick={onClose}
          Icon={<Cross width="18" height="18" color={theme.colors.white} />}
        />
      </S.MakeOfferModalHeader>
      <S.MakeOfferModalContent>
        <S.MakeOfferItemContainer>
          <S.MakeOfferItemTitle>Item</S.MakeOfferItemTitle>
          <S.MakeOfferItemCard>
            <S.MakeOfferItemImage src="https://picsum.photos/200" />
            <S.MakeOfferItemInfo>
              <S.MakeOfferItemInfoTitle>
                The Dons #1234
              </S.MakeOfferItemInfoTitle>
              <S.MakeOfferItemInfoDescription>
                The Dons
              </S.MakeOfferItemInfoDescription>
            </S.MakeOfferItemInfo>
            <S.MakeOfferDeleteButton>
              <CircularButton Icon={<BinIcon color={theme.colors.white} />} />
            </S.MakeOfferDeleteButton>
          </S.MakeOfferItemCard>
        </S.MakeOfferItemContainer>
        <S.MakeOfferItemContainer>
          <S.MakeOfferItemTitle>Your offer</S.MakeOfferItemTitle>
          <S.CustomTextField
            autoComplete="off"
            variant="outlined"
            type="number"
            value={offerValue}
            onChange={(e: any) => {
              setErrorMessage("");
              setOfferValue(Number(e.target.value));
            }}
            placeholder="Enter amount to offer"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CircularButton
                    size={StyledCircularButtonSize.Small}
                    Icon={
                      <NearLogo
                        height="16"
                        width="16"
                        color={theme.colors.white}
                      />
                    }
                  />
                </InputAdornment>
              ),
            }}
          />
          {errorMessage && (
            <S.TextFieldErrorContainer>
              <ErrorIcon />
              <S.TextFieldError>{errorMessage}</S.TextFieldError>
            </S.TextFieldErrorContainer>
          )}
        </S.MakeOfferItemContainer>
      </S.MakeOfferModalContent>
      <S.MakeOfferCTA>
        <S.StyledDivider />
        {offerValue && offerValue !== null && offerValue > 0 ? (
          <S.OfferedAmountText>
            Offer: <span>{offerValue}</span>
            <NearLogo height="28" width="28" color={theme.colors.teal} />
          </S.OfferedAmountText>
        ) : null}
        <StandardButton
          color={StyledStandardButtonColor.Teal}
          startIcon={<Coins />}
          onClick={handleSubmit}
        >
          Submit Offer
        </StandardButton>
        <StandardButton
          color={StyledStandardButtonColor.DarkWhite}
          onClick={onClose}
        >
          Cancel
        </StandardButton>
      </S.MakeOfferCTA>
    </S.MakeOfferModalContainer>
  );
};

export default MakeOfferModal;
