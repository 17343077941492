import styled from "@emotion/styled";
import { ButtonProps } from "@mui/material/Button";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { theme } from "ui/theme/theme";
import { SolidButton } from "Components/Buttons";
import Box, { BoxProps } from "@mui/system/Box";

export const Container = styled.div`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ConnectWalletText = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  color: ${theme.colors.white};
`;

export const TextNormal = styled(Typography)<TypographyProps>(() => ({
  color: theme.colors.white,
  fontWeight: 500,
  fontSize: "1.25rem",
  lineHeight: "120%",
  paddingTop: "16px",
  textAlign: "center",
  paddingBottom: "32px",
}));

export const SolidWhiteButton = styled(SolidButton)<ButtonProps>(() => ({
  height: 50,
  marginLeft: "10px",
  marginTop: "10px",
  [theme.breakpoints.down("md")]: {
    marginBottom: "10px",
  },
}));

export const ContainerBox = styled(Box)<BoxProps>(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    justifyContent: "center",
  },
}));
