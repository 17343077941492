import styled from "@emotion/styled";
import { theme } from "ui/theme/theme";
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import MuiCard, { CardProps } from "@mui/material/Card";
import {
  CollectionCreatorName,
  CollectionCreatorNameProps,
} from "Components/Collection/Creator/Name";
import { Grid } from "@mui/material";
import { GridProps } from "@mui/system";

export const BoxTag = styled(Box)<BoxProps>(() => ({}));

export const Card = styled(MuiCard)<CardProps>(() => ({
  display: "flex",
  backgroundColor: "rgba(255, 255, 255, 0.06)",
  borderRadius: "0.5rem",
  color: theme.colors.white,
  height: "100%",
  cursor: "pointer"
}));

export const CreatorName = styled(
  CollectionCreatorName
)<CollectionCreatorNameProps>(() => ({
  position: "absolute",
  top: "1.5rem",
  left: "1.5rem",
}));

export const Content = styled(Box)<BoxProps>(() => ({
  padding: "1.5rem",
  flex: "1",
}));

export const Body = styled(Box)<BoxProps>(() => ({
  display: "block",
  marginTop: "1.625rem",
  flex: "1",
}));

export const Header = styled(Box)<BoxProps>(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const Media = styled(Grid)<GridProps>(() => ({
  position: "relative",
  backgroundPosition: "center",
  backgroundSize: "cover",
}));

export const Description = styled(Typography)<TypographyProps>(() => ({
  fontSize: "1rem",
  fontWeight: "normal",
  lineHeight: "1.3",
  height: "4em",
  overflow: "hidden",
}));

export const Category = styled(Typography)<TypographyProps>(() => ({
  lineHeight: "1",
  textAlign: "center",
  fontWeight: "600",
}));
