import { Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { theme } from "ui/theme/theme";

import useMarketplace from "hooks/marketPlace";
import { useHideReceivedOffers } from "hooks/hiddenOffers";

import * as S from "./styles";
import * as Icons from "assets/icons";

import { Offer, useCollection } from "api";
import { numberFormat } from "utils/number-format";

export interface CardProps extends Offer {}

export const OfferReceivedCard = ({
  collection_name,
  contract_id,
  token_id,
  buyer_id,
  price = "12.34",
  media,
}: CardProps) => {
  const marketPlace = useMarketplace();
  const hiddenOffers = useHideReceivedOffers();
  const { data: collectionData } = useCollection(contract_id || "");
  const isLowOffer = Number(collectionData?.floor_price) * 0.8 > Number(price);

  async function acceptOffer() {
    try {
      await marketPlace.acceptOffer(token_id, contract_id, buyer_id, price);
    } catch (err) {
      let message;
      if (err instanceof Error) message = err.message;
      else message = String(err);
      return message;
    }
  }

  function hideOffer() {
    hiddenOffers.hideOffer(contract_id, token_id, price, buyer_id);
  }

  return (
    <S.StyledCard>
      <S.CardMediaContainer>
        <S.CardMediaAspect>
          <CardMedia
            component="img"
            image={media}
            alt={collection_name}
            sx={{ borderRadius: "5px" }}
            loading="lazy"
          />
        </S.CardMediaAspect>

        <Typography
          lineHeight={1}
          fontSize="0.85rem"
          marginTop="0.5rem"
          variant="subtitle1"
        >
          {collection_name} #{token_id}
        </Typography>
        <S.CollectionName variant="subtitle2" color={theme.colors.white}>
          {collection_name}
        </S.CollectionName>
      </S.CardMediaContainer>

      <S.ContentZone>
        <S.CardActionTitleBox>
          You received an offer of <b>{numberFormat(Number(price), 2)} NEAR</b>
          {isLowOffer && (
            <S.CardActionInfoText>
              Offer price is less than 80% of floor price
            </S.CardActionInfoText>
          )}
        </S.CardActionTitleBox>

        <S.CardAction>
          <S.CardActionButton color="primary" onClick={() => acceptOffer()}>
            <Icons.Tick />
            <S.CardActionText>Accept</S.CardActionText>
          </S.CardActionButton>
          <S.CardActionButton color="secondary" onClick={() => hideOffer()}>
            <Icons.Cross />
            <S.CardActionText>Reject</S.CardActionText>
          </S.CardActionButton>
        </S.CardAction>
      </S.ContentZone>
    </S.StyledCard>
  );
};
