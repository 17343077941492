import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { SystemColorKey } from "ui/theme/models/Colors";
import { DefaultThemeColorKey } from "ui/theme/models/DefaultThemeColors";
import { theme } from "ui/theme/theme";

export enum StyledCircularButtonSize {
  Small = 0,
  //   Medium = 1,
  Large = 2,
}
export enum StyledCircularButtonType {
  Outlined = 1,
  Solid = 2,
}

export enum StyledCircularButtonColor {
  Teal = SystemColorKey.Teal,
  White = SystemColorKey.White,
  Black = SystemColorKey.DarkWhite,
}
type StyledCircularButtonProps = {
  $size?: StyledCircularButtonSize;
  $color?: StyledCircularButtonColor;
  $type: StyledCircularButtonType;
};

const sizeMixin = css`
  width: 36px;
  height: 36px;
  min-width: 36px;
`;

const largeMixin = css`
  width: 48px;
  height: 48px;
  min-width: 48px;
`;

const CircularButtonSizeMixinMap: Record<StyledCircularButtonSize, any> = {
  [StyledCircularButtonSize.Small]: sizeMixin,
  [StyledCircularButtonSize.Large]: largeMixin,
};

export const ButtonContainer = styled.button<StyledCircularButtonProps>`
  display: flex;
  align-items: center;
  border-radius: 35px;
  padding: 0;
  cursor: pointer;
  justify-content: center;
  border: none;
  &:hover {
    background-color: rgba(156, 255, 248, 0.04);
  }

  ${({ $size = StyledCircularButtonSize.Large }) =>
    CircularButtonSizeMixinMap[$size]}

  background-color: ${({ $type, $color }) => {
    if ($type === StyledCircularButtonType.Outlined) {
      return "transparent";
    }

    if (
      $type === StyledCircularButtonType.Solid &&
      $color === StyledCircularButtonColor.Teal
    ) {
      return theme.colors.teal;
    }
    if (
      $type === StyledCircularButtonType.Solid &&
      $color === StyledCircularButtonColor.White
    ) {
      return theme.colors.white;
    }

    return theme.colors[
      ($color as DefaultThemeColorKey | undefined) || SystemColorKey.Black
    ];
  }};

  /* > svg > path {
    stroke: ${({ $color }) => {
    if ($color === StyledCircularButtonColor.Teal) {
      return theme.colors.black;
    }
    if ($color === StyledCircularButtonColor.Black) {
      return theme.colors.white;
    }
    if ($color === StyledCircularButtonColor.White) {
      return theme.colors.black;
    }

    return theme.colors[
      ($color as DefaultThemeColorKey | undefined) || SystemColorKey.Black
    ];
  }};
  } */
`;
