import Box, { BoxProps } from "@mui/material/Box";
import Link, { LinkProps } from "@mui/material/Link";
import Button, { ButtonProps } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu, { MenuProps } from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";

export const TealButton = styled(Button)<ButtonProps>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.teal,
  borderRadius: 35,
  color: theme.colors.black,
  display: "flex",
  fontWeight: 600,
  fontSize: 16,
  height: 50,
  textTransform: "none",
  "&:hover": {
    backgroundColor: theme.colors.teal,
  },
}));

export const WhiteButton = styled(Button)<ButtonProps>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.white,
  borderRadius: 35,
  color: theme.colors.black,
  display: "flex",
  fontWeight: 600,
  fontSize: 16,
  height: 50,
  textTransform: "none",
  "&:hover": {
    backgroundColor: theme.colors.white,
  },
}));

export const HeaderContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
}));

export const BlackBox = styled(Box)<BoxProps>(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  paddingTop: "44px",
  paddingBottom: "12px",
}));

export const WalletButton = styled(TealButton)<ButtonProps>(({ theme }) => ({
  width: 232,
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const TagBox = styled(Link)<LinkProps>(({ theme }) => ({
  alignItems: "center",
  color: theme.colors.white,
  cursor: "pointer",
  display: "flex",
  fontSize: 18,
  fontWeight: 600,
  marginRight: 30,
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const ButtonsContainer = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));

export const NavButton = styled(IconButton)<ButtonProps>(() => ({
  height: "24px",
  width: "24px",
}));

export const NavButtonWithMargin = styled(IconButton)<ButtonProps>(() => ({
  height: "24px",
  marginRight: "8px",
  width: "24px",
}));

export const CartButton = styled(IconButton)<ButtonProps>(({ theme }) => ({
  marginLeft: 20,
  width: 50,
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

export const Logo = styled("img")(({ theme }) => ({
  marginRight: "38px",
  height: "30px",
  [theme.breakpoints.down("md")]: {
    height: "18.39px",
    marginRight: 0,
  },
}));

export const Nav = styled("nav")(({ theme }) => ({
  position: "sticky",
  top: 0,
  width: "100%",
  zIndex: 100,
  backgroundColor: theme.colors.black,
}));

export const NavLeftContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
  alignItems: "center",
}));

export const ImageWithMargin = styled("img")(() => ({
  marginRight: "8px",
}));

export const NavRightContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
}));

export const ButtonLabelContainer = styled(Box)<BoxProps>`
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const IconContainer = styled(Box)<BoxProps>(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  height: "20px",
  width: "20px",
}));

export const SearchIconContainer = styled(IconContainer)<BoxProps>(
  ({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  })
);

export const MenuContainer = styled(Menu)<MenuProps>(() => ({
  "& .MuiPaper-root": {
    marginTop: "8px",
    padding: "20px",
    width: "232px",
  },
  "& .MuiMenu-list": {
    padding: 0,
  },
  "& .MuiMenuItem-root": {
    display: "flex",
    alignItem: "center",
    height: "30px",
    padding: 0,
    fontSize: "14px",
  },
}));
