import Box, { BoxProps } from "@mui/material/Box";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

export const CustomTextField = styled(TextField)<TextFieldProps>(
  ({ theme }) => ({
    background: "rgba(255, 255, 255, 0.12)",
    borderRadius: 35,
    borderWidth: 0,
    marginRight: 40,
    position: "relative",
    width: 447,
    "&:focus": {
      outline: "none",
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
      color: theme.colors.white,
      height: 50,
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&:hover": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {
      width: "auto",
      minWidth: 300,
      marginRight: 20,
    },
  })
);

export const SuggestBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: 5,
  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.06)",
  color: theme.colors.black,
  marginTop: 8,
  padding: 24,
  position: "absolute",
  width: 447,
}));
