import { styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";

export const DividerContainer = styled(Box)<BoxProps>(({ theme }) => ({
  paddingLeft: "120px",
  paddingRight: "120px",
  maxWidth: "1573px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
