import { ReactNode, SyntheticEvent, useEffect, useState, useRef } from "react";
import { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useQueryClient } from "@tanstack/react-query";

import {
  useCollection,
  useCollectionActivity,
  useInfiniteCollectionTokens,
  DEFAULT_PAGE_SIZE,
} from "api/hooks";
import {
  CollectionTokensRequestConfig,
  CollectionActivityRequestConfig,
  CollectionTokensRequestConfigOrdering,
  CollectionActivityRequestConfigOrdering,
  Token,
} from "api/models";
import SearchCollection from "Components/SearchCollection";
import useDebounce from "hooks/useDebounce";
import DrawerMenu from "ui/components/Header/Drawer";
import ActivityContainer from "./ActivityContainer";
import { ActivityFilter } from "./ActivityFilter";
import ButtonGroup from "./ButtonGroup";
import { CustomTab, CustomTabs, LoadMoreButton, TabContainer } from "./styles";
import { TokenFilter } from "./TokenFilter";
import TokenGridContainer from "./TokenGridContainer";
import { Activity, ActivityStatus, ActivityType, Tabs } from "./types";
import { checkResultTransaction } from "utils/transaction-results";
import { useNearWallet } from "hooks/useNear";
import ModalMain from "Components/ModalMain";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

interface CollectionTabsProps {
  collectionName: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

function getActivityType(activity: string): ActivityType {
  switch (activity) {
    case "List":
      return "list";
    case "Sale":
      return "sale";
    case "Offer":
      return "offer";
    default:
      throw Error("Unknown activity type");
  }
}

function CollectionTabs({ collectionName }: CollectionTabsProps) {
  const [value, setValue] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [smallCollectionCard, setSmallCollectionCard] = useState(true);
  const [tokens, setTokens] = useState<Token[] | null>(null);
  const [activityStatus, setActivityStatus] = useState<ActivityStatus>("");
  const [showActivityFilter, setShowActivityFilter] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [activityData, setActivityData] = useState<Activity[] | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchTerm: string = useDebounce<string>(searchValue, 500);
  const [filters, setFilters] = useState<CollectionTokensRequestConfig>({});
  const [activityRequestConfig, setActivityRequestConfig] =
    useState<CollectionActivityRequestConfig>({});
  const [order, setOrder] = useState<
    CollectionTokensRequestConfigOrdering | ""
  >("");

  const [isInitialRender, setIsInitialRender] = useState(true);
  const { walletConnection } = useNearWallet();

  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [iconName, setIconName] = useState("");
  const [resultPanel, setResultPanel] = useState(false);
  const [state, setState] = useState(false);

  const handleOpen = () => setState(true);
  const handleClose = () => setState(false);

  const [activityOrder, setActivityOrder] =
    useState<CollectionActivityRequestConfigOrdering>("latest_action");

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const prevCollectionName = useRef("");

  useEffect(() => {
    setOrder("");
    setShowFilter(false);
    setActivityStatus("");
    setShowActivityFilter(false);
    setShowSearchBar(false);
    setSearchValue("");
    setFilters({});
    setActivityRequestConfig({});
    setActivityOrder("latest_action");
    prevCollectionName.current = collectionName;
  }, [collectionName]);

  const { data, isFetching, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteCollectionTokens({
      collectionId: collectionName,
      requestConfig: {
        ...(debouncedSearchTerm !== "" &&
          order === "" && { order_by: "best_match" }),
        ...(order !== "" &&
          prevCollectionName.current === collectionName && { order_by: order }),
        limit: DEFAULT_PAGE_SIZE,
        ...(debouncedSearchTerm !== "" && { search: debouncedSearchTerm }),
        ...filters,
      },
    });

  const {
    data: activity,
    isFetching: isLoading,
    fetchNextPage: fetchActivityNextPage,
    hasNextPage: hasActivityNextPage,
    isFetchingNextPage: isFetchingActivityNextPage,
  } = useCollectionActivity(collectionName, {
    order_by: activityOrder,
    limit: DEFAULT_PAGE_SIZE,
    ...activityRequestConfig,
  });

  const { data: collectionData } = useCollection(collectionName || "");

  useEffect(() => {
    if (activityStatus) {
      setActivityRequestConfig((prevState) => ({
        ...prevState,
        action_type: activityStatus,
      }));
    } else {
      setActivityRequestConfig((prevState) => {
        delete prevState.action_type;
        return {
          ...prevState,
        };
      });
    }
  }, [activityStatus]);

  useEffect(() => {
    if (data?.pages?.length) {
      setTokens(data.pages.reduce((acc, cur) => [...acc, ...cur], []));
    } else {
      setTokens(null);
    }
  }, [data]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      const urlParams = new URLSearchParams(window.location.search);
      const txhash = urlParams.get("transactionHashes");

      if (txhash !== null) {
        setState(true);
        setResultPanel(true);
        checkResultTransaction(
          txhash,
          walletConnection.getAccountId(),
          setTitle,
          setSubTitle,
          setIconName,
          setResultPanel,
          handleOpen,
          handleClose
        );
      }
    }
  }, [isInitialRender, resultPanel, state, walletConnection]);

  useEffect(() => {
    if (activity?.pages?.length) {
      setActivityData(
        activity.pages.reduce(
          (acc, cur) => [
            ...acc,
            ...cur.map((item) => ({
              type: getActivityType(item.action.action_type),
              title: item.token.token_name,
              image: item.token.media,
              buyer: item.action.buyer,
              seller: item.action.seller,
              time: item.action.timestamp,
              price: item.action.price,
            })),
          ],
          [] as Activity[]
        )
      );
    } else {
      setActivityData(null);
    }
  }, [activity]);

  const smallScreen = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setShowActivityFilter(open);
      setShowFilter(open);
    };

  const queryClient = useQueryClient();

  const handleRefresh = () => {
    queryClient.invalidateQueries(["collection.tokens"]);
  };

  const handleActivityRefresh = () => {
    queryClient.invalidateQueries(["collection.activity"]);
  };

  const handleSearch = (searchKeyword: string) => setSearchValue(searchKeyword);

  const handleApplyFilters = (
    appliedFilters: CollectionTokensRequestConfig
  ) => {
    setFilters(appliedFilters);
    if (smallScreen) {
      setShowFilter(false);
    }
  };

  const handleSetStatus = (status: ActivityStatus) => {
    setActivityStatus(status);
    if (smallScreen) {
      setShowActivityFilter(false);
    }
  };

  const handleLoadMore = () => {
    fetchNextPage();
  };

  const handleLoadMoreActivity = () => {
    fetchActivityNextPage();
  };

  return (
    <>
      {smallScreen && (
        <DrawerMenu
          showDrawer={showActivityFilter || showFilter}
          toggleDrawer={toggleDrawer}
        >
          {showActivityFilter && (
            <ActivityFilter
              status={activityStatus}
              setStatus={handleSetStatus}
            />
          )}
          <Box sx={{ display: showFilter ? "block" : "none" }}>
            <TokenFilter
              defaultFilters={filters}
              applyFilters={handleApplyFilters}
            />
          </Box>
        </DrawerMenu>
      )}
      <Box sx={{ width: "100%" }}>
        <TabContainer>
          <CustomTabs value={value} onChange={handleChange} aria-label="tabs">
            <CustomTab label="Items" iconPosition="end" {...a11yProps(0)} />
            <CustomTab label="Activity" {...a11yProps(1)} />
          </CustomTabs>
          <ButtonGroup
            tabValue={value}
            showFilter={showFilter}
            showSearchBar={showSearchBar}
            showActivityFilter={showActivityFilter}
            smallCollectionCard={smallCollectionCard}
            searchValue={searchValue}
            defaultOrder={order}
            setSmallCollectionCard={setSmallCollectionCard}
            setShowSearchBar={setShowSearchBar}
            setShowFilter={setShowFilter}
            setShowActivityFilter={setShowActivityFilter}
            onRefresh={handleRefresh}
            onActivityRefresh={handleActivityRefresh}
            handleSearch={handleSearch}
            onSetOrder={setOrder}
            onSetActivityOrder={setActivityOrder}
          />
        </TabContainer>
        {value === Tabs.ITEMS && smallScreen && showSearchBar && (
          <Box sx={{ marginTop: "16px", width: "100%" }}>
            <SearchCollection value={searchValue} onChange={handleSearch} />
          </Box>
        )}
        <TabPanel value={value} index={0}>
          <TokenGridContainer
            isFetching={isFetching}
            smallCollectionCard={smallCollectionCard}
            showFilter={showFilter}
            tokens={tokens}
            filters={filters}
            smallScreen={smallScreen}
            isFetchingNextPage={isFetchingNextPage}
            handleApplyFilters={handleApplyFilters}
            floorPrice={collectionData?.floor_price || ""}
          />
          {hasNextPage && !!tokens?.length && (
            <LoadMoreButton onClick={handleLoadMore}>
              Loading more...
            </LoadMoreButton>
          )}

          <ModalMain
            state={state}
            onClose={handleClose}
            title={title}
            subTitle={subTitle}
            iconName={iconName}
            setResultPanel={setResultPanel}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ActivityContainer
            showActivityFilter={showActivityFilter}
            smallScreen={smallScreen}
            activityStatus={activityStatus}
            activityData={activityData}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingActivityNextPage}
            handleSetStatus={handleSetStatus}
          />
          {hasActivityNextPage && !!activityData?.length && (
            <LoadMoreButton onClick={handleLoadMoreActivity}>
              Loading more...
            </LoadMoreButton>
          )}
        </TabPanel>
      </Box>
    </>
  );
}

export default CollectionTabs;
