import { TokenActivityAction } from "api";
import DotIcon from "assets/icons/DotIcon";
import { FC, useMemo } from "react";

import {
  dateTimeSpan,
  getTwoDaysBeforeMidnight,
  timeSince,
} from "ui/utils/timeUtils";
import { truncate } from "ui/utils/truncate";
import HistoryItemTypeTag from "../HistoryItemTypeTag/HistoryItemTypeTag";
import * as S from "./HistoryItem.style";
import environment from "hooks/useNear/config";

export enum HistoryItemType {
  Transfer = "Transfer",
  Sale = "Sale",
  List = "List",
  Offer = "Offer",
  Minted = "Minted",
}

type HistoryItemProps = {
  action: TokenActivityAction;
};

const HistoryItem: FC<HistoryItemProps> = ({ action }) => {
  const timeAgo = dateTimeSpan(action.timestamp);
  const tokenPrice = (Math.round(parseFloat(action.price) * 100) / 100).toFixed(
    2
  );
  const isBeforeTwoDays = useMemo(
    () => action.timestamp < getTwoDaysBeforeMidnight(),
    [action]
  );
  const env = process?.env.REACT_APP_NEAR_ENV || "testnet";
  const nearEnv = environment(env);
  const renderHistoryItemContent = () => {
    switch (action.action_type) {
      case HistoryItemType.Transfer:
        return (
          <p>
            Transfered by{" "}
            <a
              href={`${nearEnv.explorerUrl}/accounts/${action.sender}`}
              target="_blank"
              rel="noreferrer"
            >
              {truncate(action.sender || "", 17)}
            </a>{" "}
            to{" "}
            <a
              href={`${nearEnv.walletUrl}/profile/${action.receiver}`}
              target="_blank"
              rel="noreferrer"
            >
              {truncate(action.receiver || "", 17)}
            </a>
          </p>
        );
      case HistoryItemType.Sale:
        return (
          <p>
            Sold by{" "}
            <a
              href={`${nearEnv.explorerUrl}/accounts/${action.seller}`}
              target="_blank"
              rel="noreferrer"
            >
              {truncate(action.seller || "", 17)}
            </a>{" "}
            to{" "}
            <a
              href={`${nearEnv.walletUrl}/profile/${action.buyer}`}
              target="_blank"
              rel="noreferrer"
            >
              {truncate(action.buyer || "", 17)}
            </a>
          </p>
        );
      case HistoryItemType.List:
        return (
          <p>
            Listed by{" "}
            <a
              href={`${nearEnv.walletUrl}/profile/${action.seller}`}
              target="_blank"
              rel="noreferrer"
            >
              {truncate(action.seller || "", 17)}
            </a>{" "}
            for {tokenPrice} NEAR
          </p>
        );
      case HistoryItemType.Offer:
        return (
          <p>
            <a
              href={`${nearEnv.walletUrl}/profile/${action.buyer}`}
              target="_blank"
              rel="noreferrer"
            >
              {truncate(action.buyer || "", 17)}
            </a>{" "}
            offered {tokenPrice} NEAR
          </p>
        );
      case HistoryItemType.Minted:
        return (
          <p>
            Minted by{" "}
            <a
              href={`${nearEnv.explorerUrl}/accounts/${action.seller}`}
              target="_blank"
              rel="noreferrer"
            >
              {truncate(action.buyer || "", 17)}
            </a>
          </p>
        );
      default:
        return null;
    }
  };
  const renderTimestamp = () => {
    if (!isBeforeTwoDays) {
      return `${timeSince(action.timestamp)} ago`;
    }
    return (
      <>
        {`${timeAgo[0]} ${timeAgo[1]} ${timeAgo[2]} `}
        <DotIcon />
        {`${timeAgo[4]} `}
      </>
    );
  };
  return (
    <S.HistoryItemContainer>
      <HistoryItemTypeTag type={action?.action_type} />
      <S.HistoryItemDetails>
        <S.HistoryItemText>{renderHistoryItemContent()}</S.HistoryItemText>
        <S.HistoryItemTimestamp>{renderTimestamp()}</S.HistoryItemTimestamp>
      </S.HistoryItemDetails>
    </S.HistoryItemContainer>
  );
};

export default HistoryItem;
