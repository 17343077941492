import { FC } from "react";
import { SVGProps } from "interfaces";

export const DropdownIcon: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#fff",
  style
}) => (
  <svg
    width={width}
    height={height}
    style={{ ...style, color }}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.50781 7.75L9.64746 12.0933C9.69383 12.1398 9.74892 12.1767 9.80957 12.2019C9.87022 12.227 9.93525 12.24 10.0009 12.24C10.0666 12.24 10.1316 12.227 10.1923 12.2019C10.2529 12.1767 10.308 12.1398 10.3544 12.0933L14.494 7.75"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
