import { useState, useRef, useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";

import FilterMenu from "../FilterMenu";
import UntypedSuggestion from "../UntypedSuggestion";
import AutoCompleteSuggestion from "../AutoCompleteSuggestion";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { CustomTextField, SuggestBox } from "./styles";
import { ResultItem } from "../types";

export interface SearchBarProps {
  searchValue: string;
  searchResults: ResultItem[];
  isFetching: boolean;
  debouncedSearchValue?: string;
  setSearchValue: (value: string) => void;
}

function SearchBar({
  searchValue,
  searchResults,
  isFetching,
  debouncedSearchValue,
  setSearchValue,
}: SearchBarProps) {
  const ref = useRef();
  const ref2 = useRef();
  const menuItemRef = useRef<HTMLLIElement | null>(null);
  const [showSuggestBox, setShowSuggestBox] = useState(false);
  const [resultText, setResultText] = useState(
    "Start typing to load some results"
  );

  useEffect(() => {
    if (!isFetching && !searchValue) {
      setResultText("Start typing to load some results");
    } else if (
      !isFetching &&
      searchResults.length === 0 &&
      searchValue === debouncedSearchValue
    ) {
      setResultText("Not found");
    } else {
      setResultText("Loading...");
    }
  }, [searchResults, isFetching, searchValue, debouncedSearchValue]);

  useOnClickOutside(ref, ref2, () => setShowSuggestBox(false));

  const handleClose = () => setShowSuggestBox(false);

  return (
    <div>
      <CustomTextField
        autoComplete="off"
        inputRef={ref2}
        variant="outlined"
        placeholder="Search Acova"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FilterMenu />
            </InputAdornment>
          ),
        }}
        onFocus={() => {
          setShowSuggestBox(true);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !!menuItemRef.current) {
            menuItemRef.current.focus();
          }
        }}
      />
      {showSuggestBox && (
        <SuggestBox ref={ref}>
          {!isFetching && searchValue && searchResults.length > 0 ? (
            <AutoCompleteSuggestion
              results={searchResults}
              menuItemRef={menuItemRef}
              onClose={handleClose}
            />
          ) : (
            <UntypedSuggestion resultText={resultText} />
          )}
        </SuggestBox>
      )}
    </div>
  );
}

export default SearchBar;
