import { styled } from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Chip, { ChipProps } from "@mui/material/Chip";
import Stack, { StackProps } from "@mui/material/Stack";

export const Subtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "19.2px",
  marginBottom: "12px",
  [theme.breakpoints.down("md")]: {
    color: theme.colors.white,
    marginBottom: "16px",
  },
}));

export const StatusChip = styled(Chip)<ChipProps>(({ theme }) => ({
  "&.MuiChip-root": {
    fontSize: "9px",
    height: "25px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      height: "32px",
    },
  },
  "&.MuiChip-outlined": {
    borderColor: theme.colors.black,
    [theme.breakpoints.down("md")]: {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      borderColor: "rgba(255, 255, 255, 0.01)",
    },
  },
  "&.MuiChip-filled": {
    backgroundColor: theme.colors.teal,
    [theme.breakpoints.down("md")]: {
      backgroundColor: theme.colors.white,
    },
    "& .MuiChip-label": {
      [theme.breakpoints.down("md")]: {
        color: theme.colors.black,
      },
    },
  },
  "& .MuiChip-label": {
    color: theme.colors.black,
    lineHeight: "10.8px",
    [theme.breakpoints.down("md")]: {
      color: theme.colors.white,
    },
  },
}));

export const ChipStack = styled(Stack)<StackProps>(() => ({
  columnGap: "8px",
  flexWrap: "wrap",
  rowGap: "8px",
}));
