import { Typography } from "@mui/material";
import { Tag } from "Components/Pills/Tags";
import { CarouselCardSlideProps } from "..";

import * as S from "../styles";

export const CarouselCardSlide: React.FC<CarouselCardSlideProps> = ({
  collection,
  label,
  Icon,
}) => (
  <S.ImageFrame
    href={collection.website_url || `/collections/${collection.contract_id}`}
    sx={{
      backgroundImage: `url(${collection.featured_image_url})`,
    }}
  >
    <S.CollectionNamePaper square elevation={0}>
      <S.BoxTag>
        <Tag Icon={Icon}>
          <Typography variant="body2" sx={{ fontWeight: "600" }}>
            {label}
          </Typography>
        </Tag>
      </S.BoxTag>
    </S.CollectionNamePaper>
  </S.ImageFrame>
);
