import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { CollectionTokensRequestConfigOrdering } from "api/models";
import { Sort } from "assets/icons/Sort";
import { theme } from "ui/theme/theme";
import { GreyButton, IconContainer, WhiteButton } from "../styles";
import { StyledMenu } from "./styles";

interface TokenOrderOption {
  name: string;
  value: CollectionTokensRequestConfigOrdering;
}

interface TokenOrderButtonProps {
  defaultOrder: CollectionTokensRequestConfigOrdering | "";
  onSetOrder: (value: CollectionTokensRequestConfigOrdering) => void;
}

export const tokenOrderOptions: TokenOrderOption[] = [
  {
    name: "Recently listed",
    value: "recently_listed",
  },
  {
    name: "Price: High to Low",
    value: "price_high",
  },
  {
    name: "Price: Low to High",
    value: "price_low",
  },
];

function TokenOrderButton({ defaultOrder, onSetOrder }: TokenOrderButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [state, setState] = useState<
    CollectionTokensRequestConfigOrdering | ""
  >(defaultOrder);
  const showLabel =
    tokenOrderOptions.find(({ value }) => value === state)?.name ||
    "Recently listed";

  useEffect(() => {
    setState(defaultOrder);
  }, [defaultOrder]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSetState = (value: CollectionTokensRequestConfigOrdering) => {
    setState(value);
    onSetOrder(value);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Button = state !== "" ? WhiteButton : GreyButton;

  return (
    <>
      <Button width="160px" onClick={handleClick}>
        <IconContainer marginRight="5px">
          <Sort
            height={18}
            width={18}
            color={state !== "" ? theme.colors.black : theme.colors.white}
          />
        </IconContainer>
        {showLabel}
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {tokenOrderOptions.map(({ name, value }) => (
          <MenuItem key={value} onClick={() => handleSetState(value)}>
            {name}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
}

export default TokenOrderButton;
