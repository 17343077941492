import * as nearAPI from "near-api-js";
import { PublicKey } from "near-api-js/lib/utils/key_pair";
import { base_decode } from "near-api-js/lib/utils/serialize";
import { transactions } from "near-api-js";
import BN from "bn.js";

import {
  useMarketplaceContract,
  useNftContract,
  GetMarketDataArgs,
  MarketDataJson,
  NftDataJson,
  useNearWallet,
  BuyArgs,
  OfferDataJson,
} from "../useNear";

const { utils } = nearAPI;

const gas = utils.format.parseNearAmount("0.00000000003")!;

export const useMarketplace = () => {
  const contractMarketPlace = useMarketplaceContract();

  const nftContractFactory = useNftContract();
  const wallet = useNearWallet();
  const { walletConnection } = wallet;

  const getStorageBalance = async (accountId: string): Promise<string> => {
    const response = await contractMarketPlace.storage_balance_of({
      account_id: accountId,
    });

    return response;
  };

  const depositStorage = async (accountId: string): Promise<void> => {
    const response = await contractMarketPlace.storage_deposit(
      {
        account_id: accountId,
      },
      gas,
      "214750000000000000000000"
    );
    return response;
  };

  const getBalance = async (): Promise<string> => {
    const response = await walletConnection.account().state();

    return response.amount;
  };

  // 1. Get market data for a token
  const getMarketData = async (
    contract_id: string,
    token_id: string
  ): Promise<MarketDataJson | null> => {
    const args: GetMarketDataArgs = {
      nft_contract_id: contract_id,
      token_id,
    };
    try {
      const response = await contractMarketPlace.get_market_data(args);
      return response;
    } catch (e) {
      return null;
    }
  };

  // 2. Get media and attributes for a token
  const getTokenData = async (
    contract_id: string,
    token_id: string
  ): Promise<NftDataJson> => {
    const contract = await nftContractFactory(contract_id);

    const response = await contract.nft_token({ token_id });

    return response;
  };

  const executeBatchTransaction = async (actions: transactions.Action[]) => {
    if (!wallet) {
      throw new Error("no wallet");
    }
    const connectedAccount = await wallet.walletConnection.account();
    const localKey = await connectedAccount.connection.signer.getPublicKey(
      connectedAccount.accountId,
      connectedAccount.connection.networkId
    );
    const accessKey = await wallet.walletConnection
      .account()
      .accessKeyForTransaction(
        contractMarketPlace.contractId,
        actions,
        localKey
      );
    if (!accessKey) {
      throw new Error(
        `Cannot find matching key for transaction sent to ${contractMarketPlace.contractId}`
      );
    }
    const block = await connectedAccount.connection.provider.block({
      finality: "final",
    });

    const blockHash = base_decode(block.header.hash);
    const publicKey = PublicKey.from(accessKey.public_key);
    const nonce = accessKey.access_key.nonce + 1;

    const nearTransactions = transactions.createTransaction(
      wallet.accountId,
      publicKey,
      contractMarketPlace.contractId,
      nonce,
      actions,
      blockHash
    );

    return wallet.walletConnection.requestSignTransactions([nearTransactions]);
  };

  // 3. list token
  // 3.1 checkstorage
  // 3.2 list the token

  const listToken = async (
    contract_id: string,
    token_id: string,
    price: string
  ): Promise<void> => {
    const deposit = utils.format.parseNearAmount(price) as string;

    const nftContract = await nftContractFactory(contract_id);

    const msg = {
      market_type: "sale",
      price: deposit,
      ft_token_id: "near",
    };
    const msgJson = JSON.stringify(msg);

    const args = {
      token_id,
      account_id: contractMarketPlace.contractId,
      msg: msgJson,
    };

    await getStorageBalance(wallet.accountId).then(async (balance) => {
      if (balance === "0") {
        depositStorage(wallet.accountId).then(async () => {
          await nftContract.nft_approve(args, gas, "261000000000000000000000");
        });
      } else {
        await nftContract.nft_approve(args, gas, "261000000000000000000000");
      }
    });
  };

  // 4. buy  listed token
  // contract_id:nft_contract_id

  const buyToken = async (
    contract_id: string,
    token_id: string,
    price: string
  ): Promise<void> => {
    const deposit = utils.format.parseNearAmount(price) as string;
    const args: BuyArgs = {
      nft_contract_id: contract_id,
      token_id,
      ft_token_id: "near",
      price: deposit,
    };

    // For future:
    // this soultion require ignore the following error
    // Property 'signAndSendTransaction' is protected
    // and only accessible within class 'ConnectedWalletAccount' and its subclasse
    // @ts-ignore
    // return await walletConnection.account().signAndSendTransaction({
    //   receiverId: "acova-marketplace.aslabs.testnet",
    //   actions: [
    //     transactions.functionCall(
    //       "storage_deposit",
    //       { account_id: wallet.accountId },
    //       "150000000000000",
    //       "8590000000000000000000"
    //     ),
    //     transactions.functionCall("buy", args, "150000000000000", deposit),
    //   ],
    // });
    const actions = [
      transactions.functionCall(
        "storage_deposit",
        { account_id: wallet.accountId },
        new BN("150000000000000"),
        new BN("8590000000000000000000")
      ),
      transactions.functionCall(
        "buy",
        args,
        new BN("150000000000000"),
        new BN(deposit)
      ),
    ];
    await executeBatchTransaction(actions);
  };

  // 5. place offer for token
  // we need to check the account if it has deposit storage or not in the marketplae contract
  const addOffer = async (
    contract_id: string,
    token_Id: string,
    ft_token_id: string,
    price: string
  ): Promise<void> => {
    const deposit = utils.format.parseNearAmount(price) as string;

    await contractMarketPlace.add_offer(
      {
        nft_contract_id: contract_id,
        token_id: token_Id,
        ft_token_id,
        price: deposit,
      },
      gas,
      deposit
    );
  };

  // place offer for token main function
  const placeOffer = async (
    contract_id: string,
    token_Id: string,
    ft_token_id: string,
    price: string
  ): Promise<void> => {
    await getStorageBalance(wallet.accountId).then((balance) => {
      if (balance === "0") {
        // depositStorage(wallet.accountId).then(async (res) => {
        depositStorage(wallet.accountId).then(async () => {
          addOffer(contract_id, token_Id, ft_token_id, price);
        });
      } else {
        addOffer(contract_id, token_Id, ft_token_id, price);
      }
    });
  };

  // 6. Accept offer
  const acceptOffer = async (
    token_id: string,
    contract_id: string,
    buyer_id: string,
    price: string
  ): Promise<void> => {
    const deposit = utils.format.parseNearAmount(price) as string;

    const nftContract = await nftContractFactory(contract_id);

    const msg = {
      market_type: "accept_offer",
      buyer_id,
      price: deposit,
      ft_token_id: "near",
    };
    const msgJson = JSON.stringify(msg);

    const args = {
      token_id,
      account_id: contractMarketPlace.contractId,
      msg: msgJson,
    };

    await nftContract.nft_approve(
      args,
      "300000000000000",
      "8590000000000000000000"
    );
  };

  // 7. get offer of buyer to a specific token
  const getDataOffer = async (
    contract_id: string,
    token_id: string,
    buyer_id: string
  ): Promise<OfferDataJson> => {
    const response = await contractMarketPlace.get_offer({
      nft_contract_id: contract_id,
      token_id,
      buyer_id,
    });

    return response;
  };

  // 8. delete offer
  const cancelTokenOffer = async (
    contract_id: string,
    token_id: string
  ): Promise<string> => {
    const result = contractMarketPlace.delete_offer(
      {
        nft_contract_id: contract_id,
        token_id,
      },
      "300000000000000",
      "1"
    );
    return result;
  };

  // 9. Delist an item
  const delistItem = async (
    contract_id: string,
    token_id: string
  ): Promise<void> => {
    await contractMarketPlace.delete_market_data(
      {
        nft_contract_id: contract_id,
        token_id,
      },
      "300000000000000",
      "1"
    );
  };

  return {
    storage: {
      getStorageBalance,
      depositStorage,
    },
    getMarketData,
    getTokenData,
    listToken,
    buyToken,
    placeOffer,
    acceptOffer,
    getBalance,
    getDataOffer,
    cancelTokenOffer,
    delistItem,
    executeBatchTransaction,
  };
};

export default useMarketplace;
