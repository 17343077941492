export function timeSince(date: number) {
  const seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return `${Math.floor(interval)} ${
      Math.floor(interval) === 1 ? "year" : "years"
    }`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return `${Math.floor(interval)} ${
      Math.floor(interval) === 1 ? "month" : "months"
    }`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return `${Math.floor(interval)} ${
      Math.floor(interval) === 1 ? "day" : "days"
    }`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return `${Math.floor(interval)} ${
      Math.floor(interval) === 1 ? "hour" : "hours"
    }`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    return `${Math.floor(interval)} ${
      Math.floor(interval) === 1 ? "minute" : "minutes"
    }`;
  }
  return `${Math.floor(seconds)} ${
    Math.floor(interval) === 1 ? "second" : "seconds"
  }`;
}

export const dateTimeSpan = (timestamp: number) => {
  const date = new Date(timestamp).toLocaleString("en-GB", {
    timeZone: "UTC",
    hour: "numeric",
    minute: "numeric",
    month: "long",
    day: "2-digit",
    year: "numeric",
  });
  const splited = date.split(" ");

  // const time = `${splited[0]} ${splited[1]} ${splited[2]} . ${splited[4]}`;
  return splited;
};

export const getTwoDaysBeforeMidnight = () => {
  const now = new Date();
  const midnight = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 2
  );
  return midnight.getTime();
};
