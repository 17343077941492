import * as React from "react";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import { Typography } from "@mui/material";
import { theme } from "ui/theme/theme";
import { NearLogo } from "assets/icons/NearLogo";
import { ErrorIcon } from "assets/icons/Error";
import { utils } from "near-api-js";
import { useNearWallet } from "../../../hooks/useNear";

import {
  TealButton,
  IconContainer,
  GreyButton,
  CustomOutlineInput,
  TextNormal,
  BoxMsgContainer,
  StyledFormControl,
  BoxTextContainer,
} from "./styles";

interface FormProps {
  onClose?: () => void;
  handleSubmit: (value: string) => void;
  panelInputPlaceHolder: string;
  buttonText: string;
  Icon: React.ReactElement;
  hasOffer?: boolean;
  offerPrice?: string;
  cancelOffer?: () => void;
  listedPrice?: string;
  floorPrice?: string;
}

export default function FormItem({
  onClose,
  handleSubmit,
  panelInputPlaceHolder,
  buttonText,
  Icon,
  hasOffer,
  offerPrice,
  cancelOffer,
  listedPrice,
  floorPrice,
}: FormProps) {
  const [amount, setAmount] = useState<string>(offerPrice || "0.00");
  const [infoMsg, setInfoMsg] = useState<string>("Please enter an amount");
  const [error, setError] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const { walletConnection } = useNearWallet();

  const handleChangeOffer = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newAmount = parseFloat(event.target.value).toFixed(2);
    const newError = newAmount === "0.00";
    const numberOfAmount = Number(utils.format.parseNearAmount(newAmount));
    const numericOffer = Number(newAmount);
    const balance = (await walletConnection.account().state()).amount;
    const balanceAmount = Number(balance);
    const numericFloorPrice = Number(floorPrice);

    if (newError) {
      setError(true);
    } else if (numericFloorPrice * 0.8 > numericOffer) {
      setError(true);
      setInfoMsg(`Your offer must be higher than 80% of floor price`);
    } else if (numberOfAmount > balanceAmount) {
      setError(true);
      setAmount(newAmount);
      setInfoMsg(
        `You don’t seem to have enough funds in your wallet to offer this`
      );
    } else if (listedPrice !== "0" && numericOffer > Number(listedPrice)) {
      setError(true);
      setAmount(newAmount);
      setInfoMsg(
        `Your offer is higher than the listing price of ${Number(
          listedPrice
        )} NEAR`
      );
    } else if (listedPrice !== "0" && numericOffer < Number(listedPrice) / 2) {
      setError(true);
      setAmount(newAmount);
      setInfoMsg(
        `Your offer must be higher than 50% of the listing price: ${Number(
          listedPrice
        )} NEAR`
      );
    } else if (Number.isNaN(parseFloat(newAmount))) {
      setError(true);
      setAmount(newAmount);
      setInfoMsg(`Offer can't be empty`);
    } else {
      setAmount(newAmount);
      setError(false);
      setInfoMsg(`your offer price is ${newAmount} NEAR`);
      setIsChanged(true);
    }
  };

  return (
    <Box
      m={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <StyledFormControl
        fullWidth
        sx={{
          m: 1,
          backgroundColor: `rgba(255, 255, 255, 0.07)`,
          color: "black",
          borderRadius: "50px",
          width: "29.125rem",
        }}
      >
        <CustomOutlineInput
          id="outlined-adornment-amount"
          onChange={handleChangeOffer}
          autoComplete=" "
          type="number"
          error={parseFloat(amount) <= 0}
          inputProps={{
            maxLength: 13,
            step: "0.01",
            min: "0",
          }}
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                padding: "14px",
                height: "36px",
                borderRadius: "50px",
                backgroundColor: `rgba(255, 255, 255, 0.07)`,
              }}
            >
              {" "}
              <NearLogo
                height="12.6"
                width="12.4"
                color="white"
                type="filled"
              />
            </InputAdornment>
          }
          placeholder={panelInputPlaceHolder}
        />
      </StyledFormControl>
      <BoxMsgContainer>
        <ErrorIcon color={theme.colors.teal} secondColor={theme.colors.black} />
        <Typography variant="body2">{infoMsg}</Typography>
      </BoxMsgContainer>
      <BoxTextContainer>
        <TextNormal>{hasOffer ? "Offered: " : "Offer: "}</TextNormal>
        <BoxMsgContainer style={{ color: theme.colors.teal }}>
          {hasOffer ? offerPrice : amount}
          <NearLogo height="18" width="18" color={theme.colors.teal} />
        </BoxMsgContainer>{" "}
      </BoxTextContainer>

      <Box sx={{ width: "100%" }}>
        <Divider
          orientation="horizontal"
          sx={{ border: "1px solid #4E535B" }}
        />
      </Box>
      {hasOffer ? (
        <Box m={1} display="block" justifyContent="center" alignItems="center">
          <TealButton
            variant="contained"
            sx={{ display: "flex", alignItems: "center", size: "large" }}
            onClick={isChanged ? () => handleSubmit(amount) : onClose}
            disabled={error}
          >
            <IconContainer marginRight="8px">{Icon}</IconContainer>
            <Typography sx={{ fontWeight: 600 }}>
              {isChanged ? "Update your offer" : "Keep Offer"}
            </Typography>
          </TealButton>
          <GreyButton
            sx={{ display: "flex", alignItems: "center", size: "large" }}
            onClick={cancelOffer}
          >
            Cancel Offer
          </GreyButton>
        </Box>
      ) : (
        <Box
          m={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <TealButton
            variant="contained"
            sx={{ display: "flex", alignItems: "center", size: "large" }}
            onClick={() => handleSubmit(amount)}
            disabled={error}
          >
            <IconContainer marginRight="8px">{Icon}</IconContainer>
            <Typography sx={{ fontWeight: 600 }}>{buttonText}</Typography>
          </TealButton>
          <GreyButton
            sx={{ display: "flex", alignItems: "center", size: "large" }}
            onClick={onClose}
          >
            Cancel
          </GreyButton>
        </Box>
      )}
    </Box>
  );
}
