import { ReactNode, useRef } from "react";
import ReactDOM from "react-dom";

import * as S from "./styles";

interface ModalProps {
  children: ReactNode;
  onClose: () => void;
}
const Modal = ({ children, onClose }: ModalProps) => {
  const domEl = document.getElementById("modal-root");
  const outsideRef = useRef(null);

  if (!domEl) return null;
  const handleCloseOnOverlay = (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (e.target === outsideRef.current) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <S.Modal>
      <S.ModalOverlay ref={outsideRef} onClick={handleCloseOnOverlay} />

      {children}
    </S.Modal>,
    domEl
  );
};
export default Modal;
