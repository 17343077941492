import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

interface TagsProps {
  title: string;
  tags: string[];
}

const TagContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
  flexWrap: "wrap",
  marginTop: "12px",
  rowGap: "8px",
}));

const Tag = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.colors.grey,
  borderRadius: 40,
  cursor: "pointer",
  fontSize: 9,
  padding: "6px 10px",
  "&:not(:last-child)": {
    marginRight: 8,
  },
  [theme.breakpoints.down("md")]: {
    color: theme.colors.white,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  [theme.breakpoints.down("md")]: {
    color: theme.colors.white,
    fontSize: 14,
  },
}));

function Tags({ title, tags }: TagsProps) {
  return (
    <Box sx={{ marginTop: "20px" }}>
      <Title>{title}</Title>
      <TagContainer>
        {tags.map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </TagContainer>
    </Box>
  );
}

export default Tags;
