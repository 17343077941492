import { useState, useEffect, createContext } from "react";
import Box from "@mui/material/Box";
import { useParams, useNavigate } from "react-router-dom";

import CollectionHeader from "../components/CollectionHeader";
import Divider from "Components/Divider";
import CollectionGrid from "../components/CollectionGrid";
import { useCollection } from "api/hooks";
import { Collection } from "api/models";

import { theme } from "ui/theme/theme";
import { DividerContainer } from "./styles";

export const CollectionContext = createContext<Collection | null>(null);

function Collections() {
  const params = useParams();
  const navigate = useNavigate();
  const [currentCollection, setCurrentCollection] = useState<Collection | null>(
    null
  );

  const { data, isLoading } = useCollection(params.collectionName || "");

  if (!data && !isLoading) {
    navigate("/404");
  }

  useEffect(() => {
    if (data) {
      setCurrentCollection(data);
    }
  }, [data]);

  useEffect(() => {
    if (!params.collectionName) {
      navigate("/404");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <CollectionHeader data={currentCollection} isLoading={isLoading} />

      <DividerContainer>
        <Divider
          sx={{
            borderColor: theme.colors.white,
            opacity: 0.3,
            marginTop: 0,
          }}
        />
      </DividerContainer>
      <CollectionContext.Provider value={currentCollection}>
        {params.collectionName && (
          <CollectionGrid collectionName={params.collectionName} />
        )}
      </CollectionContext.Provider>
    </Box>
  );
}

export default Collections;
