import { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  FilterBox,
  Title,
  GreyButton,
  Subtitle,
  StatusChip,
  ChipStack,
} from "./styles";
import { ActivityStatus } from "../types";

import { Cart } from "assets/icons/Cart";
import { ListIcon } from "assets/icons/List";
import { Bid } from "assets/icons/Bid";
import { theme } from "ui/theme/theme";

interface ActivityFilterProps {
  status: ActivityStatus;
  setStatus: (status: ActivityStatus) => void;
}

export function ActivityFilter({ status, setStatus }: ActivityFilterProps) {
  const handleClick = (type: ActivityStatus) => {
    setStatus(type);
  };

  const match = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  const iconColor = (checked: boolean) => {
    if (match) {
      return checked ? theme.colors.black : theme.colors.white;
    }
    return undefined;
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        marginBottom="25px"
        alignItems="center"
      >
        <Title>Filter</Title>
        <GreyButton onClick={() => handleClick("")}>Reset</GreyButton>
      </Box>
      <Box marginBottom={4}>
        <Subtitle>Status</Subtitle>
        <ChipStack direction="row">
          <StatusChip
            label="All"
            variant={status === "" ? undefined : "outlined"}
            onClick={() => handleClick("")}
          />
          <StatusChip
            label="Sale"
            icon={
              <Cart
                height="12"
                width="12"
                color={iconColor(status === "sale")}
              />
            }
            variant={status === "sale" ? undefined : "outlined"}
            onClick={() => handleClick("sale")}
          />
          <StatusChip
            label="Offer"
            icon={
              <ListIcon
                height="12"
                width="12"
                color={iconColor(status === "offer")}
              />
            }
            variant={status === "offer" ? undefined : "outlined"}
            onClick={() => handleClick("offer")}
          />
          <StatusChip
            label="List"
            icon={
              <Bid
                height="12"
                width="12"
                color={iconColor(status === "list")}
              />
            }
            variant={status === "list" ? undefined : "outlined"}
            onClick={() => handleClick("list")}
          />
        </ChipStack>
      </Box>
    </>
  );
}

function ActivityFilterContainer(props: ActivityFilterProps) {
  return (
    <FilterBox>
      <ActivityFilter {...props} />
    </FilterBox>
  );
}

export default ActivityFilterContainer;
