import { FC } from "react";
import { SVGProps } from "interfaces";

export const AcovaLogo: FC<SVGProps> = ({
  width = "246",
  height = "34",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 246 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M86.5368 0H62.6589C59.8186 0 57.7485 2.08857 57.7485 4.95429V34H72.1908V20.7886H77.0049V34H91.4472V4.95429C91.4472 2.08857 89.3771 0 86.5368 0ZM72.1908 15.9314V8.35429C72.1908 6.94571 73.2018 5.87714 74.5978 5.87714C75.9939 5.87714 77.0049 6.94571 77.0049 8.35429V15.9314H72.1908Z"
      fill={color}
    />
    <path
      d="M129.95 12.1429V4.95429C129.95 2.08857 127.88 0 125.04 0H101.162C98.3219 0 96.2519 2.08857 96.2519 4.95429V29.0457C96.2519 31.9114 98.3219 34 101.162 34H125.04C127.88 34 129.95 31.9114 129.95 29.0457V21.8571H115.508V26.6657C115.508 28.0743 114.497 29.1429 113.101 29.1429C111.705 29.1429 110.694 28.0743 110.694 26.6657V7.33429C110.694 5.92572 111.705 4.85714 113.101 4.85714C114.497 4.85714 115.508 5.92572 115.508 7.33429V12.1429H129.95Z"
      fill={color}
    />
    <path
      d="M139.666 34H163.543C166.384 34 168.454 31.9114 168.454 29.0457V4.95429C168.454 2.08857 166.384 0 163.543 0H139.666C136.825 0 134.755 2.08857 134.755 4.95429V29.0457C134.755 31.9114 136.825 34 139.666 34ZM151.604 29.1429C150.208 29.1429 149.197 28.0743 149.197 26.6657V7.33429C149.197 5.92572 150.208 4.85714 151.604 4.85714C153.001 4.85714 154.012 5.92572 154.012 7.33429V26.6657C154.012 28.0743 153.001 29.1429 151.604 29.1429Z"
      fill={color}
    />
    <path
      d="M182.02 34H198.195C200.506 34 202.287 32.4943 202.769 30.1629L208.883 0H192.515V26.6657C192.515 28.0743 191.504 29.1429 190.108 29.1429C188.712 29.1429 187.701 28.0743 187.701 26.6657V0H171.333L177.447 30.1629C177.928 32.4943 179.709 34 182.02 34Z"
      fill={color}
    />
    <path
      d="M240.55 0H216.672C213.832 0 211.762 2.08857 211.762 4.95429V34H226.204V20.7886H231.018V34H245.46V4.95429C245.46 2.08857 243.39 0 240.55 0ZM226.204 15.9314V8.35429C226.204 6.94571 227.215 5.87714 228.611 5.87714C230.007 5.87714 231.018 6.94571 231.018 8.35429V15.9314H226.204Z"
      fill={color}
    />
    <path
      d="M13.7303 2.8692C15.9194 -0.9564 21.3922 -0.956398 23.5814 2.8692L36.4699 25.3924C38.659 29.218 35.9226 34 31.5443 34H5.7673C1.38904 34 -1.34737 29.218 0.84176 25.3924L13.7303 2.8692Z"
      fill="#9CFFF8"
    />
    <path
      d="M13.7303 2.8692C15.9194 -0.9564 21.3922 -0.956398 23.5814 2.8692L36.4699 25.3924C38.659 29.218 35.9226 34 31.5443 34H5.7673C1.38904 34 -1.34737 29.218 0.84176 25.3924L13.7303 2.8692Z"
      fill={color}
      fillOpacity="0.2"
    />
    <path
      d="M19.4178 2.8692C21.6069 -0.9564 27.0797 -0.956398 29.2689 2.8692L42.1574 25.3924C44.3465 29.218 41.6101 34 37.2318 34H11.4548C7.07654 34 4.34013 29.218 6.52926 25.3924L19.4178 2.8692Z"
      fill="#9CFFF8"
    />
    <path
      d="M19.4178 2.8692C21.6069 -0.9564 27.0797 -0.956398 29.2689 2.8692L42.1574 25.3924C44.3465 29.218 41.6101 34 37.2318 34H11.4548C7.07654 34 4.34013 29.218 6.52926 25.3924L19.4178 2.8692Z"
      fill={color}
      fillOpacity="0.6"
    />
    <path
      d="M25.1053 2.8692C27.2944 -0.9564 32.7672 -0.956398 34.9564 2.8692L47.8449 25.3924C50.034 29.218 47.2976 34 42.9193 34H17.1423C12.764 34 10.0276 29.218 12.2168 25.3924L25.1053 2.8692Z"
      fill={color}
    />
  </svg>
);
