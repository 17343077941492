import { Token, useCollection } from "api";
import { Share } from "assets/icons/Share";
import { TranslucentButton } from "Components/Buttons";
import { FC } from "react";
import NftTokenAttributeCard from "../NftTokenAttributeCard/NftTokenAttributeCard";
import * as S from "./NftTokenDetailsTabPage.style";

const NftTokenDetailsTabPage: FC<Token> = ({
  attributes,
  owner,
  contract_id,
}) => {
  const { data: collection } = useCollection(contract_id);

  return (
    <S.NftTokenDetailsTabContainer>
      <S.NftTokenOwnerContainer>
        <S.NftTokenSectionLabel>Owner</S.NftTokenSectionLabel>
        <TranslucentButton endIcon={<Share />}>{owner}</TranslucentButton>
      </S.NftTokenOwnerContainer>
      {attributes.length && (
        <S.NftTokenAttributesContainer>
          <S.NftTokenSectionLabel>Attributes</S.NftTokenSectionLabel>
          <S.NftTokenAttributes>
            {attributes.map((attribute) => (
              <NftTokenAttributeCard
                attribute={attribute}
                key={attribute.attribute_name}
              />
            ))}
          </S.NftTokenAttributes>
        </S.NftTokenAttributesContainer>
      )}

      <S.NftTokenCategories>
        <S.NftTokenSectionLabel>Categories</S.NftTokenSectionLabel>
        <S.NftTokenCategoriesWrapper>
          {collection?.labels.map((label) => (
            <S.NftTokenCategoriesItem key={label}>
              {label}
            </S.NftTokenCategoriesItem>
          ))}
        </S.NftTokenCategoriesWrapper>
      </S.NftTokenCategories>
    </S.NftTokenDetailsTabContainer>
  );
};

export default NftTokenDetailsTabPage;
