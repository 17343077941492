import { styled, Button, ButtonProps } from "@mui/material";
import { theme } from "ui/theme/theme";

export const WalletButton = styled(Button)<ButtonProps>(() => ({
  alignItems: "center",
  backgroundColor: `rgba(255, 255, 255, 0.07)`,
  borderRadius: 35,
  color: theme.colors.white,
  display: "flex",
  fontWeight: 600,
  fontSize: "0.875rem",
  height: 48,
  width: "11.25rem",
  "div > svg > path": {
    stroke: theme.colors.white,
  },
  padding: "10px 29px 15px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: theme.colors.teal,
    color: theme.colors.black,
  },
  "@media (max-width: 450px)": {
    width: "9.875rem",
    Height: "2.5rem",
    fontWeight: 400,
  },
}));
