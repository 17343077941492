import { styled } from "@mui/material/styles";
import { theme } from "ui/theme/theme";
import { ButtonProps } from "@mui/material/Button";
import Card, { CardProps } from "@mui/material/Card";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Box, BoxProps } from "@mui/system";
import { TranslucentButton } from "Components/Buttons";

export const ContentAmount = styled(Typography)<
  TypographyProps & { filter?: boolean }
>(({ filter }) => ({
  fontSize: filter ? "18px" : "20.81px",
  lineHeight: "normal",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {
    fontSize: filter ? "16px" : "18px",
  },
}));

export const StyledCard = styled(Card)<CardProps>(() => ({
  borderRadius: "5px",
  backgroundColor: "transparent",
  display: "flex",
  padding: '1rem',
  paddingRight: '2rem',
  background: 'rgba(255, 255, 255, 0.06)',
  color: theme.colors.white,
  marginBottom: '1rem'
}));

export const CardAction = styled("div")(() => ({
  justifyContent: "space-between",
  color: theme.colors.white,
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  flex: "1 0 auto",
}));

export const ContentZone = styled(Box)<BoxProps>(() => ({
  flex: '20 0 min-content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

export const CardActionButton = styled(TranslucentButton)<ButtonProps>(() => ({
  width: "auto",
  padding: "0",
  flex: "0 0 1.75rem",
  height: "1.75rem",
  minWidth: "0",
  borderRadius: "50%"
}));

export const CardActionTitleBox = styled(Box)<BoxProps>(() => ({
  fontSize: "80%",
}));

export const CardMediaAspect = styled(Box)<BoxProps>(() => ({
  position: "relative",
  aspectRatio: "1/1",
  flex: '0 0 6rem',
  marginRight: '1rem'
}));

export const CollectionName = styled(Typography)<TypographyProps>(() => ({
  opacity: 0.5,
  fontSize: '0.85rem'
}));
