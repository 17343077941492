import { useState, useEffect } from "react";
import { ButtonProps } from "@mui/material";
import { Link } from "react-router-dom";

import { useNearWallet } from "hooks/useNear";
import SearchBar from "./SearchBar";
import Drawer from "./Drawer";
import WalletContainer from "./WalletContainer";
import {
  BlackBox,
  ButtonsContainer,
  WalletButton,
  TagBox,
  NavButton,
  NavButtonWithMargin,
  CartButton,
  Logo,
  Nav,
  NavLeftContainer,
  NavRightContainer,
  IconContainer,
  HeaderContainer,
  SearchIconContainer,
} from "./styles";
import { ResultItem } from "./types";
import { theme } from "ui/theme/theme";
import MainMenu from "./Drawer/MainMenu";
import SearchMobile from "./SearchMobile";
import { useCollections } from "api/hooks";
import useDebounce from "hooks/useDebounce";

import { Cart } from "assets/icons/Cart";
import { Close } from "assets/icons/Close";
import { Discover } from "assets/icons/Discover";
import { ListIcon } from "assets/icons/List";
import { MenuIcon } from "assets/icons/Menu";
import { Search } from "assets/icons/Search";
import logo from "assets/logo.svg";
import { useEnabledFeatures } from "hooks/useFeatures";

const Button = (props: ButtonProps) => (
  <WalletButton variant="contained" {...props} />
);

function Header() {
  const features = useEnabledFeatures();
  const { isLoggedIn } = useNearWallet();
  const [showMainMenuDrawer, setShowMainMenuDrawer] = useState<boolean>(false);
  const [showSearchDrawer, setShowSearchDrawer] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<ResultItem[]>([]);
  const debouncedSearchValue: string = useDebounce<string>(searchValue, 500);

  const { data, isFetching } = useCollections(
    { search: debouncedSearchValue },
    debouncedSearchValue !== "",
    false
  );

  useEffect(() => {
    if (searchValue && data) {
      setSearchResults(
        data.map((item) => ({
          name: item.collection_name,
          imagePath: item.featured_image_url,
          path: item.contract_id,
        }))
      );
    } else {
      setSearchResults([]);
    }
  }, [searchValue, data]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setShowMainMenuDrawer(open);
      setShowSearchDrawer(open);
    };

  return (
    <>
      <Drawer
        showDrawer={showMainMenuDrawer || showSearchDrawer}
        toggleDrawer={toggleDrawer}
      >
        {showMainMenuDrawer && (
          <MainMenu setShowMainMenuDrawer={setShowMainMenuDrawer} />
        )}
        {showSearchDrawer && (
          <SearchMobile
            searchValue={searchValue}
            searchResults={searchResults}
            isFetching={isFetching}
            setSearchValue={setSearchValue}
            closeDrawer={() => setShowSearchDrawer(false)}
          />
        )}
      </Drawer>
      <Nav>
        <HeaderContainer sx={{ maxWidth: "1333px !important" }}>
          <BlackBox>
            <NavLeftContainer>
              <Link to="/">
                <Logo src={logo} alt="logo" />
              </Link>
              <SearchBar
                searchValue={searchValue}
                searchResults={searchResults}
                isFetching={isFetching}
                debouncedSearchValue={debouncedSearchValue}
                setSearchValue={setSearchValue}
              />

              <TagBox underline="none" href="/discover">
                <IconContainer sx={{ marginRight: "8px" }}>
                  <Discover color={theme.colors.white} />
                </IconContainer>
                Discover
              </TagBox>

              <TagBox underline="none" href="/profile">
                <IconContainer sx={{ marginRight: "8px" }}>
                  <ListIcon color={theme.colors.white} />
                </IconContainer>
                Sell
              </TagBox>
            </NavLeftContainer>
            <NavRightContainer>
              <WalletContainer Button={Button} />
              {features.buy.cart && isLoggedIn && (
                <CartButton>
                  <IconContainer>
                    <Cart color={theme.colors.white} />
                  </IconContainer>
                </CartButton>
              )}
            </NavRightContainer>
            <ButtonsContainer>
              <NavButtonWithMargin onClick={() => setShowSearchDrawer(true)}>
                <SearchIconContainer sx={{ height: "24px", width: "24px" }}>
                  <Search height="24" width="24" color={theme.colors.white} />
                </SearchIconContainer>
              </NavButtonWithMargin>
              {showMainMenuDrawer ? (
                <NavButton onClick={() => setShowMainMenuDrawer(false)}>
                  <IconContainer sx={{ height: "24px", width: "24px" }}>
                    <Close height="24" width="24" color={theme.colors.white} />
                  </IconContainer>
                </NavButton>
              ) : (
                <NavButton onClick={() => setShowMainMenuDrawer(true)}>
                  <IconContainer sx={{ height: "24px", width: "24px" }}>
                    <MenuIcon
                      height="24"
                      width="24"
                      color={theme.colors.white}
                    />
                  </IconContainer>
                </NavButton>
              )}
            </ButtonsContainer>
          </BlackBox>
        </HeaderContainer>
      </Nav>
    </>
  );
}

export default Header;
