import styled from "@emotion/styled";
import { theme } from "ui/theme/theme";

export const SectionTitle = styled.div(() => ({
  display: "flex",
  flex: 1,
  justifyContent: "flex-start",
  flexDirection: "column",
  alignContent: "center",
  gap: "0.5rem",
}));

export const SectionTitleText = styled.h2(() => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "2.625rem",
  lineHeight: "1",
  margin: 0,
  color: theme.colors.white,
  display: "inline-flex",
  justifyContent: "space-between",
  gap: "0.5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

export const SectionSubTitleText = styled.p(() => ({
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 20,
  margin: 0,
  lineHeight: "24px",
  color: theme.colors.white,
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
  },
}));
