import { CardProps } from "Components/Cards/NftCards";

export type ActivityStatus = "" | "sale" | "offer" | "list";

export enum Tabs {
  ITEMS = 0,
  ACTIVITY = 1,
}

export type ActivityType = "sale" | "list" | "offer";

export interface Activity {
  title: string;
  image: string;
  buyer?: string;
  seller?: string;
  price: string;
  time: number;
  type: ActivityType;
}

export interface CardItem extends Omit<CardProps, "owner"> {
  id: string;
}
