import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

export const TextField = styled(MuiTextField)<TextFieldProps>(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.12)",
  borderRadius: 35,
  borderWidth: 0,
  position: "relative",
  "&:focus": {
    outline: "none",
  },
  "& .MuiOutlinedInput-root": {
    border: "none",
    color: theme.colors.white,
    height: 50,
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
}));

export const TealButton = styled(Button)<ButtonProps>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.teal,
  borderRadius: 35,
  color: theme.colors.black,
  display: "flex",
  fontWeight: 600,
  fontSize: 16,
  height: 40,
  width: 190,
  textTransform: "none",
  "&:hover": {
    backgroundColor: theme.colors.teal,
  },
}));
