import Box from "@mui/material/Box";
import { Bolt } from "assets/icons/Bolt";
import { Tag } from "Components/Pills/Tags";
import { theme } from "ui/theme/theme";

import * as S from "./styles";
import { CollectionPrice } from "Components/Collection/Price";
import { CollectionVerified } from "Components/Collection/Verified";
import { Collection } from "api";
import { CollectionCardActions } from "../CollectionCard/actions";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface SpotFullDetailProps extends Collection {}

export const SpotFullDetail = ({
  collection_name,
  contract_id,
  floor_price,
  description,
  featured_image_url,
  profile_picture_url,
}: SpotFullDetailProps) => {
  const navigate = useNavigate();

  return (
    <S.Card
      id={contract_id}
      onClick={() => navigate(`/collections/${contract_id}`)}
    >
      <Grid container>
        <S.Media
          item
          xs={12}
          md={5}
          sx={{
            backgroundImage: `url(${featured_image_url})`,
          }}
        >
          <Box sx={{ aspectRatio: "1/1" }} />
          <S.CreatorName avatar={profile_picture_url} />
        </S.Media>
        <Grid
          item
          sm={12}
          md={7}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <S.Content>
            <S.Header>
              <Tag
                Icon={
                  <Bolt
                    color={theme.colors.black}
                    secondColor={theme.colors.black}
                    width="14"
                    height="14"
                  />
                }
              >
                <S.Category variant="body2">Spotlight</S.Category>
              </Tag>
              <Box component="div" sx={{ display: "flex" }}>
                <CollectionPrice
                  price={floor_price}
                  color={theme.colors.teal}
                  fontSize="1.375rem"
                />
              </Box>
            </S.Header>
            <S.Body>
              <CollectionVerified
                fontSize="1.75rem"
                lineHeight="1"
                name={collection_name}
                verified
              />
              <S.Description>{description}</S.Description>
            </S.Body>
          </S.Content>
          <CollectionCardActions />
        </Grid>
      </Grid>
    </S.Card>
  );
};
