import { Link } from "react-router-dom";

import { AcovaLogo } from "assets/icons/AcovaLogo";
import { Discord } from "assets/icons/Discord";
import { Twitter } from "assets/icons/Twitter";
import * as S from "./styles";
import { FilterMenu } from "Components/List/Control/Filter";
import { DropdownIcon } from "assets/icons";

const footerLinks = [
  {
    name: "Acova",
    subLinks: [
      {
        name: "Explore",
        url: "/explore",
      },
      {
        name: "Sell an NFT",
        url: "/profile",
      },
      // {
      //   name: "Connect wallet",
      //   url: "/wallet",
      // },
      // {
      //   name: "List a collection",
      //   url: "/list",
      // },
    ],
  },
  {
    name: "Socials",
    subLinks: [
      {
        name: "Twitter",
        url: "https://twitter.com/acovamarket",
      },
      {
        name: "Discord",
        url: "https://discord.gg/YcXJ7bxPXc",
      },
      {
        name: "Instagram",
        url: "https://www.instagram.com/acovamarket",
      },
    ],
  },
  {
    name: "Legal",
    subLinks: [
      {
        name: "Terms & Privacy",
        url: "/terms",
      },
    ],
  },
];

function Footer() {
  return (
    <S.Footer>
      <S.FooterContainer>
        <S.FooterContainerContent>
          <S.AvocaLogoContainer>
            <Link to="/">
              <AcovaLogo />
            </Link>
            <S.AvocaSlogan>
              Your go-to community-centric home of NEAR Protocol NFTs.
            </S.AvocaSlogan>
            <S.SocialIconsContainer>
              <a
                href="https://twitter.com/acovamarket"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter />
              </a>
              <a
                href="https://discord.gg/YcXJ7bxPXc"
                target="_blank"
                rel="noreferrer"
              >
                <Discord />
              </a>
            </S.SocialIconsContainer>
          </S.AvocaLogoContainer>
          <S.LinksSections>
            {footerLinks.map(({ name, subLinks }) => (
              <S.LinksSection key={name}>
                <S.LinkSectionTitle>{name}</S.LinkSectionTitle>
                <S.LinksSectionSublinks>
                  {subLinks.map(({ name: sublinkName, url }) => (
                    <S.SublinksLink
                      key={sublinkName}
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {sublinkName}
                    </S.SublinksLink>
                  ))}
                </S.LinksSectionSublinks>
              </S.LinksSection>
            ))}
          </S.LinksSections>
        </S.FooterContainerContent>
        <S.FooterContainerBottom>
          <S.FooterContainerBottomCopyright>
            © {new Date().getFullYear()} Acova Inc. All rights reserved.
          </S.FooterContainerBottomCopyright>
          <FilterMenu
            endIcon={<DropdownIcon color="inherit" />}
            options={["English"]}
            transparent
          />
        </S.FooterContainerBottom>
      </S.FooterContainer>
    </S.Footer>
  );
}

export default Footer;
