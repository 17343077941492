import { createTheme } from "@mui/material/styles";

const themeBase = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  colors: {
    teal: "#9CFFF8",
    white: "#FFFFFF",
    black: "#020915",
    blue: "#0505E2",
    navy: "#0E2958",
    lilac: "#C9D6F8",
    grey: "#E3E3E8",
    darkWhite: "#ffffff1a",
  },
  typography: {
    fontFamily: ["Roobert", "Roboto", "Helvetica", "Arial", "sans-serif"].join(
      ","
    ),
    h1: {
      fontSize: "3.5rem",
    },
    h2: {
      fontSIze: "2.75rem"
    },
    h3: {
      fontSize: "2rem"
    }
  },
  palette: {
    mode: "light",
    primary: {
      main: "#9CFFF8",
    },
  },
});

export const theme = createTheme(themeBase, {
  components: {
    MuiContainer: {
      styleOverrides: {
          maxWidthLg: {
              [themeBase.breakpoints.up('lg')]: {
                  maxWidth: '1333px'
              },
          }
      }
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: "1px solid rgba(255, 255, 255, 0.15)",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "rgba(255, 255, 255, 0.5)",
          fontSize: "135%",
          padding: "0",
          marginRight: "1rem",
          textTransform: "initial",
          alignItems: "flex-start",
          fontWeight: "bold",
          "&.Mui-selected": {
            color: "#FFFFFF",
          },
        },
      },
    },
  },
})