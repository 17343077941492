import { FC } from "react";
import { SVGProps } from "interfaces";

export const Facebook: FC<SVGProps> = ({
  width = "18",
  height = "18",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.31817 15.7518C3.70543 15.572 2.40768 14.2742 2.23505 12.6607C2.10761 11.4696 2 10.2467 2 8.99999C2 7.75325 2.10761 6.53038 2.23505 5.33925C2.40768 3.72571 3.70543 2.42796 5.31817 2.2482C6.51607 2.11467 7.74601 2 9 2C10.254 2 11.4839 2.11467 12.6818 2.2482C14.2946 2.42796 15.5924 3.72571 15.7649 5.33925C15.8924 6.53038 16 7.75325 16 8.99999C16 10.2467 15.8924 11.4696 15.7649 12.6607C15.5924 14.2742 14.2946 15.572 12.6818 15.7518C12.0238 15.8251 11.3561 15.8928 10.68 15.9388V12.36H12.92V9.56H10.68V8.44C10.68 7.82143 11.1814 7.32 11.8 7.32H12.92V5.64C12.92 5.02143 12.4186 4.52 11.8 4.52H11.24C9.38432 4.52 7.88 6.02432 7.88 7.88V9.56H5.64V12.36H7.88V15.9717C7.01216 15.9296 6.15731 15.8453 5.31817 15.7518Z"
      stroke={color}
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
  </svg>
);
