import styled from "@emotion/styled";
import { theme } from "ui/theme/theme";

export const HistoryItemContainer = styled.div`
  display: flex;
  gap: 20px;
  display: flex;
  align-items: center;
  padding: 16px 20px;

  height: 79px;

  background: rgba(255, 255, 255, 0.07);
  border-radius: 10px;
  button {
    width: 64px;
  }
  @media (max-width: 425px) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }
`;

export const HistoryItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HistoryItemText = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: ${() => theme.colors.white};
  > p {
    margin: 0;
    > a {
      color: ${() => theme.colors.teal};
    }
  }
`;

export const HistoryItemTimestamp = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  color: ${() => theme.colors.white};

  opacity: 0.5;
`;
