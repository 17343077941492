import { useState, ChangeEvent } from "react";
import Typography from "@mui/material/Typography";
import { theme } from "ui/theme/theme";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import { TextFieldProps, Theme } from "@mui/material";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import useMediaQuery from "@mui/material/useMediaQuery";

import { DropdownIcon } from "assets/icons/Dropdown";
import { Search } from "assets/icons/Search";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  BpCheckedIcon,
  BpIcon,
  FormLabel,
} from "./styles";

interface LabelProps {
  attributeName: string;
  percentage?: string;
}

export interface AttributeAccordionProps {
  accordionTitle: string;
  attributes: LabelProps[];
  filterQuery?: string;
  onChange?: (value: string) => void;
}

function BpCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

function SearchAttribute(props: TextFieldProps) {
  const match = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  return (
    <TextField
      autoComplete="off"
      variant="outlined"
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search
              height={12}
              width={12}
              color={match ? theme.colors.white : theme.colors.black}
            />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

function Label({ attributeName, percentage }: LabelProps) {
  const showPercentage = percentage ? `${percentage}%` : "";
  return (
    <Box display="flex" justifyContent="space-between">
      {attributeName}
      <Typography
        fontSize="10px"
        display="flex"
        alignItems="center"
        lineHeight="normal"
      >
        {showPercentage}
      </Typography>
    </Box>
  );
}

function bindAttributeToSelectState(
  accordionTitle: string,
  attributes: LabelProps[],
  filterQuery?: string
) {
  if (filterQuery) {
    const checkedAttributes = Array.from(
      new Set(
        filterQuery
          .split(",")
          .map((item) => item.split(":"))
          .filter((item) => item[0] === accordionTitle)
          .map((item) => item[1])
      )
    );

    return attributes.map((item) => ({
      name: item.attributeName,
      checked: checkedAttributes.includes(item.attributeName),
    }));
  }
  return attributes.map((item) => ({
    name: item.attributeName,
    checked: false,
  }));
}

function AttributeAccordion({
  accordionTitle,
  attributes,
  filterQuery,
  onChange,
}: AttributeAccordionProps) {
  const match = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );
  const [searchValue, setSearchValue] = useState("");
  const [selectedState, setSelectedState] = useState(
    bindAttributeToSelectState(accordionTitle, attributes, filterQuery)
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const foundControl = selectedState.find(
      (item) => item.name === event.target.name
    );
    if (foundControl) {
      foundControl.checked = event.target.checked;
    }
    setSelectedState([...selectedState]);
    onChange?.(
      selectedState
        .map((item) => (item.checked ? `${accordionTitle}:${item.name}` : ""))
        .filter(Boolean)
        .join(",")
    );
  };

  return (
    <Box width="100%" marginBottom={2}>
      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={
            <DropdownIcon
              color={match ? theme.colors.white : theme.colors.black}
              height={18}
              width={18}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography fontSize="14px">{accordionTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SearchAttribute
            sx={{ marginBottom: 2, width: "100%" }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Box>
            <FormGroup>
              {selectedState.map((item) => (
                <FormLabel
                  control={
                    <BpCheckbox
                      name={item.name}
                      checked={item.checked}
                      onChange={handleChange}
                    />
                  }
                  label={<Label attributeName={item.name} />}
                  key={item.name}
                  sx={
                    !item.name
                      .replaceAll(" ", "")
                      .toLowerCase()
                      .includes(searchValue.replaceAll(" ", "").toLowerCase())
                      ? { display: "none" }
                      : {}
                  }
                />
              ))}
            </FormGroup>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default AttributeAccordion;
