import { styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "152px",
  marginBottom: "233px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    marginTop: "33px",
    marginBottom: "3px",
  },
}));

export const MainText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.white,
  fontWeight: 600,
  fontSize: "30px",
  lineHeight: "120%",
  marginTop: "34px",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
    marginTop: "20px",
    maxWidth: "300px",
    textAlign: "center",
  },
}));

export const SubText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.white,
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "120%",
  marginTop: "10px",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    marginTop: "8px",
    maxWidth: "227px",
    textAlign: "center",
  },
}));
