import styled from "@emotion/styled";
import { theme } from "ui/theme/theme";

export const NftTokenAttributeCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 10px;
  color: ${() => theme.colors.white};
  height: 94px;
`;

export const NftTokenAttributeType = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
`;
export const NftTokenAttributeName = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
`;
export const NftTokenAttributeRarity = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: ${() => theme.colors.grey};
`;
