/* eslint-disable no-nested-ternary */
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";
import { DropdownIcon } from "assets/icons/Dropdown";
import { FC } from "react";
import { theme } from "ui/theme/theme";

export enum ArrowIconDirection {
  Up = 0,
  Right = 1,
  Down = 2,
  Left = 3,
}
type Props = {
  direction?: ArrowIconDirection;
  onClick?: () => void;
};

const CircledButton = styled(IconButton)(() => ({
  height: "32px",
  width: "32px",
  backgroundColor: `rgba(255, 255, 255, 0.1);`,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.colors.white,
    "svg>path": {
      stroke: theme.colors.black,
    },
  },
  "@media (min-width: 768px)": {
    height: "40px",
    width: "40px",
  },
}));

export const CircleButton: FC<Props> = ({ direction, onClick }) => (
  <CircledButton
    onClick={onClick}
    sx={{
      transform:
        direction === ArrowIconDirection.Right
          ? `rotate(-90deg)`
          : direction === ArrowIconDirection.Left
          ? `rotate(90deg)`
          : `rotate(0deg)`,
    }}
  >
    <DropdownIcon />
  </CircledButton>
);
