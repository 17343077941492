import { styled } from "@mui/material/styles";
import { theme } from "ui/theme/theme";
import { ButtonProps } from "@mui/material/Button";
import Card, { CardProps } from "@mui/material/Card";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Box, BoxProps } from "@mui/system";
import { SolidButton } from "Components/Buttons";

export const ContentAmount = styled(Typography)<
  TypographyProps & { filter?: boolean }
>(({ filter }) => ({
  fontSize: filter ? "18px" : "20.81px",
  lineHeight: "normal",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {
    fontSize: filter ? "16px" : "18px",
  },
}));

export const StyledCard = styled(Card)<CardProps>(() => ({
  borderRadius: "5px",
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  padding: "1rem",
  background: "rgba(255, 255, 255, 0.06)",
  color: theme.colors.white,
  marginBottom: "1rem",
}));

export const CardAction = styled("div")(() => ({
  justifyContent: "space-between",
  color: theme.colors.white,
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  flex: "1 1 auto",
  gap: "0.5rem",
}));

export const ContentZone = styled(Box)<BoxProps>(() => ({
  flex: "1 1 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const CardActionButton = styled(SolidButton)<ButtonProps>(() => ({
  width: "auto",
  padding: "0.5rem 0.75rem",
  flex: "1 1 auto",
}));

export const CardActionTitleBox = styled(Box)<BoxProps>(() => ({
  fontSize: "1.25rem",
  marginBottom: "1rem",
}));

export const CardActionInfoText = styled(Typography)<TypographyProps>(() => ({
  color: theme.colors.teal,
  fontSize: "0.75rem",
}));

export const CardActionText = styled("b")(() => ({
  fontSize: "80%",
  marginLeft: "0.25rem",
}));

export const CardMediaAspect = styled(Box)<BoxProps>(() => ({
  position: "relative",
  aspectRatio: "1/1",
}));

export const CardMediaContainer = styled(Box)<BoxProps>(() => ({
  flex: "0 0 6rem",
  marginRight: "1rem",
}));

export const CollectionName = styled(Typography)<TypographyProps>(() => ({
  opacity: 0.5,
  fontSize: "0.75rem",
  lineHeight: 1,
}));
