import Header from "../Header";
import Footer from "../Footer";
import { theme } from "ui/theme/theme";
import { Box } from "@mui/system";
import React from "react";

type Props = {
  children?: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      position: "relative",
    }}
  >
    <Header />
    <Box sx={{ flexGrow: 1, backgroundColor: theme.colors.black }}>
      {children}
    </Box>
    <Footer />
    <div id="modal-root" />
  </div>
);

export default Layout;
