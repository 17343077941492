import { FC } from "react";
import { SVGProps } from "interfaces";

export const Fire: FC<SVGProps> = ({
  width = "40",
  height = "40",
  color = "#020915",
  secondColor = "#FFF",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill={secondColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0857 2.29429C16.9203 2.19566 16.7312 2.14359 16.5386 2.14359C16.3459 2.14359 16.1569 2.19566 15.9914 2.29429C15.8554 2.40301 15.7638 2.55768 15.7338 2.7292C15.7038 2.90072 15.7375 3.07729 15.8286 3.22572C19.2 9.06286 19.9114 17.0086 15.3086 21.3914C13.5369 19.9033 12.1304 18.0286 11.1971 15.9114C9.49497 16.8566 8.08426 18.2501 7.11818 19.9405C6.15209 21.6309 5.66754 23.5536 5.71714 25.5C6.00572 32.6286 10.5943 37.8314 19.4171 37.8314C28.24 37.8314 32.8171 32.3514 33.1171 25.5C33.4714 17.28 27.6371 7.17143 17.0857 2.29429Z"
      stroke={color}
      strokeWidth="2.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.2657 25.5C26.2657 26.2196 26.124 26.9322 25.8486 27.5971C25.5732 28.262 25.1695 28.8661 24.6606 29.3749C24.1518 29.8838 23.5477 30.2875 22.8828 30.5629C22.2179 30.8383 21.5053 30.98 20.7857 30.98"
      stroke={color}
      strokeWidth="2.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
