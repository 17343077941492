import { useRef } from "react";
import MenuItem from "@mui/material/MenuItem";

import { useNearWallet } from "hooks/useNear";

import { MenuContainer } from "./styles";
import { IconContainer } from "../styles";
import { theme } from "ui/theme/theme";
import { Copy } from "assets/icons/Copy";
import { Close } from "assets/icons/Close";
import { Wallet } from "assets/icons/Wallet";

type MenuType = "connect" | "disconnect" | "copy" | "";

interface WalletMenuProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: (anchorEl: null | HTMLElement) => void;
  menuwidth: string;
}

function WalletMenu({ anchorEl, setAnchorEl, menuwidth }: WalletMenuProps) {
  const { login, logout, accountId } = useNearWallet();
  const open = Boolean(anchorEl);
  const ref = useRef() as any;

  const handleClose = (type: MenuType) => () => {
    setAnchorEl(null);
    if (type === "disconnect") {
      logout();
      window.location.reload()
    } else if (type === "connect") {
      logout();
      login();
    } else if (type === "copy") {
      navigator.clipboard.writeText(accountId);
    }
  };

  return (
    <div ref={ref}>
      <MenuContainer
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose("")}
        menuwidth={menuwidth}
        MenuListProps={{
          "aria-labelledby": "connect-button",
        }}
      >
        <MenuItem onClick={handleClose("copy")}>
          <IconContainer sx={{ marginRight: "10px" }}>
            <Copy height={18} width={18} color={theme.colors.black} />
          </IconContainer>
          Copy address
        </MenuItem>
        <MenuItem onClick={handleClose("connect")}>
          <IconContainer sx={{ marginRight: "10px" }}>
            <Wallet height={18} width={18} />
          </IconContainer>
          Connect a different wallet
        </MenuItem>
        <MenuItem onClick={handleClose("disconnect")}>
          <IconContainer sx={{ marginRight: "10px" }}>
            <Close height={18} width={18} />
          </IconContainer>
          Disconnect
        </MenuItem>
      </MenuContainer>
    </div>
  );
}

export default WalletMenu;
