import MuiMenuList, { MenuListProps } from "@mui/material/MenuList";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

export const MenuList = styled(MuiMenuList)<MenuListProps>(() => ({
  "& .MuiMenu-list": {
    padding: 0,
  },
  "& .MuiMenuItem-root": {
    height: 44,
    padding: 0,
  },
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  marginBottom: "4px",
  [theme.breakpoints.down("md")]: {
    color: theme.colors.white,
    fontSize: 14,
    marginTop: "20px",
  },
}));

export const ResultName = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: 12,
  [theme.breakpoints.down("md")]: {
    color: theme.colors.white,
  },
}));
