import Card from "@mui/material/Card";
import styled from "@emotion/styled";
import { theme } from "ui/theme/theme";

export const NewCardSimple = styled(Card)(() => ({
  backgroundColor: "#111723",
  cursor: "pointer",
  borderRadius: "5px",
  color: theme.colors.white,
  display: "flex",
  alignItems: "center",
  position: "relative",
  padding: "0.75rem",
  height: 86,
  transition:
    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  ".collection-price": {
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },

  "div.css-k008qs": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "0 0 2rem",
    aspectRatio: "1/1",
    background: "rgba(255, 255, 255, 0.05)",
    borderRadius: "1rem",
    marginRight: "0.5rem",
    color: theme.colors.white,
  },
  "&:hover": {
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    ".collection-price": {
      color: theme.colors.black,
    },
  },
}));
