import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Markdown from "../Markdown";
// eslint-disable-next-line import/no-webpack-loader-syntax
import legal from "!!raw-loader!./legal.md";

function Legal() {
  return (
    <Container>
      <Box sx={{ mt: 7, mb: 12 }}>
        <Typography variant="h3" gutterBottom textAlign="center">
          Acova.io Terms and Conditions of Use
        </Typography>
        <Markdown>{legal}</Markdown>
      </Box>
    </Container>
  );
}

export default Legal;
