import { FC } from "react";
import { SVGProps } from "interfaces";

export const Email: FC<SVGProps> = ({
  width = "18",
  height = "18",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.31547 12.9649C2.44865 14.0377 3.31527 14.8851 4.39039 14.9975C5.87809 15.153 7.41995 15.3172 9 15.3172C10.5801 15.3172 12.1219 15.153 13.6096 14.9975C14.6847 14.8851 15.5513 14.0377 15.6845 12.9649C15.8435 11.6841 16 10.3596 16 9.0038C16 7.648 15.8435 6.32347 15.6845 5.04271C15.5513 3.96997 14.6847 3.12254 13.6096 3.01014C12.1219 2.85463 10.5801 2.69043 9 2.69043C7.41995 2.69043 5.87808 2.85463 4.39039 3.01014C3.31527 3.12254 2.44865 3.96997 2.31546 5.04271C2.15645 6.32347 2 7.648 2 9.0038C2 10.3596 2.15645 11.6841 2.31547 12.9649Z"
      fill="white"
      stroke={color}
      strokeWidth="1.7"
    />
    <path
      d="M2.34766 4.75293L7.61403 8.90528C8.42742 9.54662 9.57446 9.54662 10.3878 8.90528L15.6542 4.75293"
      stroke={color}
      strokeWidth="1.7"
      strokeLinejoin="round"
    />
  </svg>
);
