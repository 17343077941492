import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useNearWallet } from "hooks/useNear";

import { HomeIcon } from "assets/icons/Home";
import { Wallet } from "assets/icons/Wallet";
import * as S from "./styles";

type Props = {};

const ConnectWallet: FC<Props> = () => {
  const { login, isLoggedIn } = useNearWallet();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/profile");
    }
  }, [isLoggedIn, navigate]);

  return (
    <S.Container>
      <S.ConnectWalletText>You need to login first!</S.ConnectWalletText>
      <S.TextNormal> To access your profile</S.TextNormal>
      <S.ContainerBox m={2} pt={3}>
        <S.SolidWhiteButton
          color="secondary"
          size="large"
          variant="contained"
          startIcon={<HomeIcon />}
          href="/"
        >
          Back to home
        </S.SolidWhiteButton>
        <S.SolidWhiteButton
          color="primary"
          size="large"
          variant="contained"
          startIcon={<Wallet />}
          onClick={login}
        >
          {" "}
          Connect Wallet{" "}
        </S.SolidWhiteButton>
      </S.ContainerBox>
    </S.Container>
  );
};
export default ConnectWallet;
