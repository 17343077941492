import { FC } from "react";
import { SVGProps } from "interfaces";

export const Link: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_1275_26185" fill="white">
      <path d="M7.70355 9.79322C6.99117 9.08083 5.86875 8.98634 5.04728 9.5696L4.6902 9.82316C3.92488 10.3666 3.25441 11.0324 2.70577 11.794C1.97167 12.813 2.08478 14.214 2.97285 15.1021L4.89822 17.0274C5.78629 17.9156 7.18729 18.0287 8.20632 17.2945C8.96789 16.7459 9.63378 16.0754 10.1772 15.3101L10.4557 14.9179" />
    </mask>
    <path
      d="M7.70355 9.79322C6.99117 9.08083 5.86875 8.98634 5.04728 9.5696L4.6902 9.82316C3.92488 10.3666 3.25441 11.0324 2.70577 11.794C1.97167 12.813 2.08478 14.214 2.97285 15.1021L4.89822 17.0274C5.78629 17.9156 7.18729 18.0287 8.20632 17.2945C8.96789 16.7459 9.63378 16.0754 10.1772 15.3101L10.4557 14.9179"
      fill="white"
    />
    <path
      d="M5.04728 9.5696L4.06308 8.18347L4.06304 8.1835L5.04728 9.5696ZM4.6902 9.82316L5.67439 11.2093L5.67443 11.2093L4.6902 9.82316ZM2.70577 11.794L1.32643 10.8003L1.32642 10.8003L2.70577 11.794ZM2.97285 15.1021L4.17492 13.9L4.1749 13.9L2.97285 15.1021ZM4.89822 17.0274L6.10036 15.8254L6.10029 15.8253L4.89822 17.0274ZM8.20632 17.2945L7.21267 15.9151L7.21257 15.9152L8.20632 17.2945ZM10.1772 15.3101L11.5633 16.2943L11.5633 16.2943L10.1772 15.3101ZM8.90564 8.59115C7.60691 7.2924 5.56066 7.12015 4.06308 8.18347L6.03147 10.9557C6.17683 10.8525 6.37542 10.8693 6.50146 10.9953L8.90564 8.59115ZM4.06304 8.1835L3.70596 8.43705L5.67443 11.2093L6.03151 10.9557L4.06304 8.1835ZM3.706 8.43702C2.78829 9.08862 1.98431 9.88711 1.32643 10.8003L4.08512 12.7877C4.52451 12.1778 5.06147 11.6445 5.67439 11.2093L3.706 8.43702ZM1.32642 10.8003C0.105066 12.4957 0.293218 14.8267 1.77079 16.3042L4.1749 13.9C3.87633 13.6014 3.83828 13.1303 4.08513 12.7877L1.32642 10.8003ZM1.77077 16.3042L3.69614 18.2295L6.10029 15.8253L4.17492 13.9L1.77077 16.3042ZM3.69607 18.2294C5.17362 19.7071 7.50463 19.8953 9.20008 18.6738L7.21257 15.9152C6.86996 16.162 6.39896 16.124 6.10036 15.8254L3.69607 18.2294ZM9.19998 18.6739C10.1132 18.016 10.9117 17.212 11.5633 16.2943L8.79106 14.3259C8.35586 14.9388 7.82257 15.4758 7.21267 15.9151L9.19998 18.6739ZM11.5633 16.2943L11.8418 15.9021L9.06954 13.9337L8.79105 14.3259L11.5633 16.2943Z"
      fill={color}
      mask="url(#path-1-inside-1_1275_26185)"
    />
    <path
      d="M3.57387 14.501L3.57388 14.501L5.49925 16.4264L5.49929 16.4264C6.09262 17.0198 7.02862 17.0954 7.70945 16.6048L7.7095 16.6048C8.39523 16.1108 8.99482 15.5071 9.48411 14.818L3.57387 14.501ZM3.57387 14.501C2.98055 13.9077 2.90497 12.9717 3.39545 12.2908C3.88946 11.6051 4.49318 11.0055 5.18229 10.5162L5.18231 10.5162L5.53937 10.2627C5.53938 10.2627 5.53938 10.2627 5.53939 10.2627C6.02281 9.91943 6.6833 9.97505 7.1025 10.3943L7.10251 10.3943L9.63654 12.9283C10.0382 13.3299 10.0914 13.9626 9.76261 14.4258C9.7626 14.4258 9.7626 14.4258 9.76259 14.4258L9.48412 14.818L3.57387 14.501Z"
      fill="white"
      stroke={color}
      strokeWidth="1.7"
    />
    <path
      d="M7.70355 9.79322C6.99117 9.08083 5.86875 8.98634 5.04728 9.5696L4.6902 9.82316C3.92488 10.3666 3.25441 11.0324 2.70577 11.794C1.97167 12.813 2.08478 14.214 2.97285 15.1021L4.89822 17.0274C5.78629 17.9156 7.18729 18.0287 8.20632 17.2945C8.96789 16.7459 9.63378 16.0754 10.1772 15.3101L10.4557 14.9179C11.0245 14.1167 10.9324 13.022 10.2376 12.3272L7.70355 9.79322Z"
      fill="white"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0287 4.89773L15.1033 2.97236C14.2153 2.08429 12.8143 1.97118 11.7952 2.70529C11.0337 3.25392 10.3678 3.92439 9.82438 4.68971L9.57082 5.04679C8.98756 5.86826 9.08205 6.99068 9.79444 7.70306L12.3285 10.2371C13.0233 10.9319 14.118 11.024 14.9192 10.4552L15.3114 10.1767C16.0767 9.63329 16.7472 8.96741 17.2958 8.20584C18.0298 7.1868 17.9167 5.7858 17.0287 4.89773Z"
      fill="white"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.3635 7.63672L7.64844 12.3517L12.3635 7.63672Z" fill="white" />
    <path
      d="M12.3635 7.63672L7.64844 12.3517"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
