import { ChangeEvent } from "react";
import { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  CollectionTokensRequestConfigOrdering,
  CollectionActivityRequestConfigOrdering,
} from "api/models";
import GridSwitch from "Components/GridSwitch";
import SearchCollection from "Components/SearchCollection";
import { theme } from "ui/theme/theme";
import {
  GreyButton,
  IconContainer,
  ButtonContainer,
  WhiteButton,
} from "../styles";
import { Tabs } from "../types";
import TokenOrderButton from "../TokenOrderButton";
import ActivityOrderButton from "../ActivityOrderButton";

import { Filter } from "assets/icons/Filter";
import { Refresh } from "assets/icons/Refresh";
import { Search } from "assets/icons/Search";

interface ButtonGroupProps {
  tabValue: Tabs;
  showFilter: boolean;
  showSearchBar: boolean;
  showActivityFilter: boolean;
  smallCollectionCard: boolean;
  searchValue: string;
  defaultOrder: CollectionTokensRequestConfigOrdering | "";
  setSmallCollectionCard: (value: boolean) => void;
  setShowSearchBar: (value: boolean) => void;
  setShowFilter: (value: boolean) => void;
  setShowActivityFilter: (value: boolean) => void;
  onRefresh: () => void;
  handleSearch: (value: string) => void;
  onActivityRefresh: () => void;
  onSetOrder: (value: CollectionTokensRequestConfigOrdering) => void;
  onSetActivityOrder: (value: CollectionActivityRequestConfigOrdering) => void;
}

function ButtonGroup({
  tabValue,
  showFilter,
  showSearchBar,
  showActivityFilter,
  smallCollectionCard,
  searchValue,
  defaultOrder,
  setSmallCollectionCard,
  setShowSearchBar,
  setShowFilter,
  setShowActivityFilter,
  onRefresh,
  handleSearch,
  onActivityRefresh,
  onSetOrder,
  onSetActivityOrder,
}: ButtonGroupProps) {
  const match = useMediaQuery((mediaQueryTheme: Theme) =>
    mediaQueryTheme.breakpoints.down("md")
  );

  const FilterButton = showFilter ? WhiteButton : GreyButton;
  const FilterActivityButton = showActivityFilter ? WhiteButton : GreyButton;
  const SearchButton = showSearchBar ? WhiteButton : GreyButton;

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSmallCollectionCard(event.target.checked);
  };

  return (
    <Box sx={{ overflow: match ? "auto" : "hidden" }}>
      {tabValue === Tabs.ITEMS && (
        <ButtonContainer>
          <GreyButton onClick={onRefresh}>
            <IconContainer>
              <Refresh height={18} width={18} color={theme.colors.white} />
            </IconContainer>
          </GreyButton>
          {match ? (
            <SearchButton onClick={() => setShowSearchBar(!showSearchBar)}>
              <IconContainer>
                <Search
                  height={18}
                  width={18}
                  color={
                    showSearchBar ? theme.colors.black : theme.colors.white
                  }
                />
              </IconContainer>
            </SearchButton>
          ) : (
            <SearchCollection value={searchValue} onChange={handleSearch} />
          )}
          <TokenOrderButton
            defaultOrder={defaultOrder}
            onSetOrder={onSetOrder}
          />
          {!match && (
            <GridSwitch
              checked={smallCollectionCard}
              onChange={handleSwitchChange}
            />
          )}
          <FilterButton
            width="115px"
            onClick={() => setShowFilter(!showFilter)}
          >
            <IconContainer marginRight="5px">
              {showFilter ? (
                <Filter height={18} width={18} color={theme.colors.black} />
              ) : (
                <Filter
                  type="duotone"
                  height={18}
                  width={18}
                  color={theme.colors.white}
                  secondColor={theme.colors.black}
                />
              )}
            </IconContainer>
            Filter
          </FilterButton>
        </ButtonContainer>
      )}
      {tabValue === Tabs.ACTIVITY && (
        <ButtonContainer>
          <GreyButton onClick={onActivityRefresh}>
            <IconContainer>
              <Refresh height={18} width={18} color={theme.colors.white} />
            </IconContainer>
          </GreyButton>
          <ActivityOrderButton onSetOrder={onSetActivityOrder} />
          <FilterActivityButton
            width="115px"
            onClick={() => setShowActivityFilter(!showActivityFilter)}
          >
            <IconContainer marginRight="5px">
              {showActivityFilter ? (
                <Filter height={18} width={18} color={theme.colors.black} />
              ) : (
                <Filter
                  type="duotone"
                  height={18}
                  width={18}
                  color={theme.colors.white}
                  secondColor={theme.colors.black}
                />
              )}
            </IconContainer>
            Filter
          </FilterActivityButton>
        </ButtonContainer>
      )}
    </Box>
  );
}

export default ButtonGroup;
