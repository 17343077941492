import Popover, { PopoverProps } from "@mui/material/Popover";
import Menu, { MenuProps } from "@mui/material/Menu";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { styled } from "@mui/material";

export const PopoverContainer = styled(Popover)<PopoverProps>(() => ({
  "& .MuiPaper-root": {
    marginTop: "6px",
    backgroundColor: `rgba(255, 255, 255, 0.1)`,
  },
}));

export const MenuContainer = styled(Menu)<MenuProps>(() => ({
  "& .MuiPaper-root": {
    marginTop: "9px",
    width: "231px",
  },
  "& .MuiMenu-list": {
    padding: "20px 0",
  },
  "& .MuiMenuItem-root": {
    display: "flex",
    alignItem: "center",
    height: "30px",
    padding: "0 20px",
    fontSize: "14px",
  },
}));

export const PopoverText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.white,
  fontSize: "7px",
  lineHeight: "8.4px",
  padding: "6px",
}));
