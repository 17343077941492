import * as S from "./styles";
import { FC, useState } from "react";
import { Calendar } from "assets/icons/Calendar";
import { ComingSoonCard } from "Components/Cards/ComingSoonCard";
import { CircularProgress, Grid } from "@mui/material";
import { UpcomingCollection } from "api";
import { SectionHeader } from "Components/Section/SectionHeader";
import { Section } from "Components/Section";

export interface ComingSoonCollectionsProps {
  collections?: Array<UpcomingCollection>;
  isLoading?: boolean;
}

const ComingSoonCollections: FC<ComingSoonCollectionsProps> = ({
  collections = [],
  isLoading,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const itemsPerPage = 2;
  const totalPages = collections.length / itemsPerPage;

  const handleGoBack = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleGoForward = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <Section>
      <S.SectionHeaderContainer id="coming-soon">
        <SectionHeader
          Icon={<Calendar color="white" />}
          title="Coming soon"
          subtitle="Keep an eye out, these are on their way."
          withPagination
          handleGoBack={handleGoBack}
          handleGoForward={handleGoForward}
        />
      </S.SectionHeaderContainer>

      {isLoading ? (
        <CircularProgress sx={{ margin: "0 auto" }} />
      ) : (
        <Grid container spacing="2rem">
          {collections
            .slice(itemsPerPage * currentPage, itemsPerPage * currentPage + 2)
            .map((collection) => (
              <Grid
                item
                key={collection.collection_name}
                xs={12}
                sm={6}
                md={6}
                lg={6}
              >
                <ComingSoonCard {...collection} />
              </Grid>
            ))}
        </Grid>
      )}
    </Section>
  );
};

export default ComingSoonCollections;
