import { styled } from "@mui/material";
import Stack, { StackProps } from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";

export const Container = styled(Stack)<StackProps>(() => ({
  alignItems: "center",
  borderRadius: "10px",
  backgroundColor: "rgba(255, 255, 255, 0.07)",
  padding: "24px",
  marginBottom: "56px",
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.white,
  fontWeight: 400,
  fontSize: "32px",
  lineHeight: "120%",
  marginBottom: "2px",
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
  },
}));

export const Subtitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.white,
  opacity: 0.5,
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "120%",
  marginBottom: "8px",
  [theme.breakpoints.down("md")]: {
    fontSize: "13px",
  },
}));

export const Price = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.teal,
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "120%",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
  },
}));
