import { ChangeEvent, useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { CollectionTokensRequestConfig } from "api/models";
import MultipleChips from "Components/MultipleChips";
import PriceSelector from "Components/PriceSelector";
import AttributeAccordion from "./AttributeAccordion";
import { FilterBox, GreyButton, Subtitle, TealButton, Title } from "./styles";
import { CollectionContext } from "features/Collections/modules";

const statusChoices = [
  { name: "All", value: 156, checked: true },
  { name: "Buy now", value: 121, checked: false },
  { name: "Make an offer", value: 35, checked: false },
];

const MAX_PRICE = 1000;

interface TokenFilterProps {
  defaultFilters: CollectionTokensRequestConfig;
  applyFilters: (filters: CollectionTokensRequestConfig) => void;
}

function getStatusChoiceFromDefaultValue(
  filters: CollectionTokensRequestConfig
) {
  // eslint-disable-next-line no-prototype-builtins
  if (filters.hasOwnProperty("is_listed")) {
    if (filters.is_listed) {
      return statusChoices.map((item) => ({
        ...item,
        checked: item.name === "Buy now",
      }));
    }
    return statusChoices.map((item) => ({
      ...item,
      checked: item.name === "Make an offer",
    }));
  }
  return statusChoices;
}

function getFilterAttributesFromDefaultValue(filterQuery?: string) {
  if (filterQuery) {
    const filterAttributes: { [key: string]: string } = {};
    filterQuery.split(",").forEach((item) => {
      const [key] = item.split(":");
      filterAttributes[key] = item;
    });
    return filterAttributes;
  }
  return {};
}

export function TokenFilter({
  defaultFilters,
  applyFilters,
}: TokenFilterProps) {
  const [price, setPrice] = useState<number[]>([
    defaultFilters.price_min || 0,
    defaultFilters.price_max || MAX_PRICE,
  ]);
  const [lowerPrice, setLowerPrice] = useState<number>(
    defaultFilters.price_min || 0
  );
  const [upperPrice, setUpperPrice] = useState<number>(
    defaultFilters.price_max || MAX_PRICE
  );
  const collection = useContext(CollectionContext);
  const [status, setStatus] = useState(
    getStatusChoiceFromDefaultValue(defaultFilters)
  );
  const [filters, setFilters] =
    useState<CollectionTokensRequestConfig>(defaultFilters);
  const [filterAttributes, setFilterAttributes] = useState(
    getFilterAttributesFromDefaultValue(defaultFilters.filter_attributes)
  );

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      price_min: price[0],
      price_max: price[1],
    }));
  }, [price]);

  useEffect(() => {
    const filterAttributeText = Object.values(filterAttributes)
      .filter(Boolean)
      .join(",");

    if (filterAttributeText) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        filter_attributes: filterAttributeText,
      }));
    } else {
      setFilters((prevFilters) => {
        delete prevFilters.filter_attributes;
        return { ...prevFilters };
      });
    }
  }, [filterAttributes]);

  const handleClick = (name: string) => {
    setStatus(
      statusChoices.map((item) => ({ ...item, checked: item.name === name }))
    );

    if (name === "All") {
      delete filters.is_listed;
      setFilters({ ...filters });
    } else if (name === "Buy now") {
      setFilters({
        ...filters,
        is_listed: true,
      });
    } else if (name === "Make an offer") {
      setFilters({
        ...filters,
        is_listed: false,
      });
    }
  };

  const handleChange = (event: Event, newValue: number | number[]) => {
    const value = newValue as number[];
    setPrice(value);
    setLowerPrice(value[0]);
    setUpperPrice(value[1]);
  };

  const handleLowerPriceChange = (event: ChangeEvent) => {
    const newLowerPrice = Number((event.target as HTMLInputElement).value);
    setLowerPrice(newLowerPrice);
    setPrice([newLowerPrice, upperPrice]);
  };

  const handleUpperPriceChange = (event: ChangeEvent) => {
    const newUpperPrice = Number((event.target as HTMLInputElement).value);
    setUpperPrice(newUpperPrice);
    setPrice([lowerPrice, newUpperPrice]);
  };

  const handleApplyFilters = () => {
    if (filters?.price_min === 0) {
      delete filters.price_min;
    }

    if (filters?.price_max === MAX_PRICE) {
      delete filters.price_max;
    }

    applyFilters(filters);
  };

  const handleResetFilters = () => {
    setStatus([...statusChoices]);
    setLowerPrice(0);
    setUpperPrice(MAX_PRICE);
    setPrice([0, MAX_PRICE]);
    setFilters({});
  };

  const handleAttributeSelect = (key: string, value: string) => {
    setFilterAttributes({
      ...filterAttributes,
      [key]: value,
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" marginBottom="25px">
        <Title>Filter</Title>
        <Stack direction="row" spacing={1.5}>
          <TealButton onClick={handleApplyFilters}>Apply filters</TealButton>
          <GreyButton onClick={handleResetFilters}>Reset</GreyButton>
        </Stack>
      </Box>

      <MultipleChips
        multipleChipsTitle="Status"
        attributes={status}
        handleClick={handleClick}
      />
      <PriceSelector
        priceSelectorTitle="Price"
        lowerPrice={lowerPrice}
        upperPrice={upperPrice}
        slideValue={MAX_PRICE}
        price={price}
        handleChange={handleChange}
        handleLowerPriceChange={handleLowerPriceChange}
        handleUpperPriceChange={handleUpperPriceChange}
      />
      <Box>
        <Subtitle marginBottom={2.5}>Attributes</Subtitle>
        {collection?.attributes
          .map((item) => ({
            accordionTitle: item.attribute_name,
            attributes: item.attribute_values.map((value) => ({
              attributeName: value,
            })),
          }))
          .map((attribute) => (
            <AttributeAccordion
              {...attribute}
              key={attribute.accordionTitle}
              filterQuery={defaultFilters.filter_attributes}
              onChange={(value) =>
                handleAttributeSelect(attribute.accordionTitle, value)
              }
            />
          ))}
      </Box>
    </>
  );
}

interface TokenFIlterContainerProps extends TokenFilterProps {
  show: boolean;
}

const TokenFilterContainer = ({
  applyFilters,
  defaultFilters,
  show,
}: TokenFIlterContainerProps) => (
  <FilterBox
    sx={{
      display: show ? "block" : "none",
    }}
  >
    <TokenFilter defaultFilters={defaultFilters} applyFilters={applyFilters} />
  </FilterBox>
);

export default TokenFilterContainer;
