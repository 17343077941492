import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { TranslucentButton } from "Components/Buttons";
import { theme } from "ui/theme/theme";

export const NftTokenDetailsTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
  padding: 20px 0;
  gap: 1rem;
`;

export const NftTokenOwnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.75rem;
  > button {
    width: max-content;
    padding: 0.5rem 1rem;
    height: 48px;
  }
`;

export const NftTokenSectionLabel = styled.div`
  color: ${() => theme.colors.white};
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  > span {
    margin-left: 12px;
    color: ${() => theme.colors.teal};
  }
`;

export const NftTokenAttributesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.75rem;
`;

export const NftTokenAttributes = styled(Box)({
  display: "flex",
  gap: "1rem",
  flexWrap: "wrap",
  [theme.breakpoints.down("sm")]: {
    flexWrap: "nowrap",
    overflowX: "auto",
  }
})

export const NftTokenCategories = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const NftTokenCategoriesWrapper = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: "0.5rem",
});

export const NftTokenCategoriesItem = styled(TranslucentButton)({
  flex: "0 0 max-content",
  padding: "0.5rem 1rem",
  fontSize: "0.6875rem",
  fontWeight: "bold",
  transition: `unset`,
  cursor: "default",

  "span > svg > path": {
    stroke: "inherit",
  },
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    color: "inherit",
  },
});
