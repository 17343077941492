import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

import grid4 from "assets/grid4.svg";
import grid4White from "assets/grid4-white.svg";
import grid9 from "assets/grid9.svg";
import grid9White from "assets/grid9-white.svg";

const GridSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 74,
  height: 40,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 4,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      color: theme.colors.black,
      transform: "translateX(36px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url(${grid9})`,
      },
      "& + .MuiSwitch-track": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },

    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.colors.white,
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url(${grid4})`,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 44,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    opacity: 1,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url(${grid4White})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      left: 14,
    },
    "&:after": {
      backgroundImage: `url(${grid9White})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      right: 14,
    },
  },
}));

export default GridSwitch;
