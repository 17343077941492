import { nearEnv } from "hooks/useNear";
import { providers } from "near-api-js";

const getTransactionStatus = async (txhash: string, accountId: string) => {
  const provider = new providers.JsonRpcProvider(nearEnv.nodeUrl);
  const result = await provider.txStatus(txhash, accountId);
  return result;
};

const removeTxHash = (setResultPanel: (value: boolean) => void) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete("transactionHashes");
  window.history.pushState(
    {},
    "",
    `${window.location.pathname}?${urlParams.toString()}`
  );
  setResultPanel(true);
};

export const checkResultTransaction = async (
  txhash: string,
  accountId: string,
  setTitle: (value: string) => void,
  setSubTitle: (value: string) => void,
  setIconName: (value: string) => void,
  setResultPanel: (value: boolean) => void,
  handleOpen: () => void,
  handleClose: () => void
) => {
  // Get result from the transaction
  try {
    const transactionStaus = await getTransactionStatus(txhash, accountId);

    if (transactionStaus?.status !== undefined) {
      const { transaction } = transactionStaus;
      const { actions } = transaction;

      for (const action of actions) {
        const { FunctionCall } = action;
        if (FunctionCall.method_name === "buy") {
          setTitle("Purchase complete");
          setSubTitle(
            "You should see the items you bought in your wallet in a few minutes."
          );
          setIconName("verified");
          setResultPanel(false);
          handleOpen();
          setTimeout(() => {
            handleClose();
            removeTxHash(setResultPanel);
          }, 5000);
        }
        if (FunctionCall.method_name === "storage_deposit") {
          setTitle("Storage deposit paid");
          setSubTitle(
            "You are now able to list your NFTs and add offers."
          );
          setIconName("verified");
          setResultPanel(false);
          handleOpen();
          setTimeout(() => {
            handleClose();
            removeTxHash(setResultPanel);
          }, 5000);
        }
        if (FunctionCall.method_name === "add_offer") {
          setTitle("Offer sent");
          setSubTitle("We’ll let you know if your offer has been accepted.");
          setIconName("verified");
          setResultPanel(false);
          handleOpen();
          setTimeout(() => {
            handleClose();
            removeTxHash(setResultPanel);
          }, 5000);
        }
        if (FunctionCall.method_name === "delete_market_data") {
          setTitle("Item delisted successfully");
          setSubTitle("Your item is now available to list!");
          setIconName("verified");
          setResultPanel(false);
          handleOpen();
          setTimeout(() => {
            handleClose();
            removeTxHash(setResultPanel);
          }, 5000);
        }
        if (FunctionCall.method_name === "nft_approve") {
          // We need to check if it is for list or for accept as they both use nft_approve
          const { args } = FunctionCall;
          const argsBase64ToString = JSON.parse(
            Buffer.from(args, "base64").toString()
          );
          const { msg } = argsBase64ToString;
          const msgJson = JSON.parse(msg);
          const marketType = msgJson.market_type;
          const tokenId = argsBase64ToString.token_id;
          if (marketType === "accept_offer") {
            setTitle("Offer Accepted");
            setSubTitle(`for the token : ${tokenId}`);
            setIconName("verified");
            setResultPanel(false);
            handleOpen();
            setTimeout(() => {
              handleClose();
              removeTxHash(setResultPanel);
            }, 5000);
          } else if (marketType === "sale") {
            setTitle("Item listed successfully");
            setSubTitle("Your item is now available to purchase!");
            setIconName("verified");
            setResultPanel(false);
            handleOpen();
            setTimeout(() => {
              handleClose();
              removeTxHash(setResultPanel);
            }, 5000);
          }
        } else if (FunctionCall.method_name === "delete_offer") {
          setTitle("Offer cancelled");
          setSubTitle("Your offer has been cancelled.");
          setIconName("verified");
          setResultPanel(false);
          handleOpen();
          setTimeout(() => {
            handleClose();
            removeTxHash(setResultPanel);
          }, 5000);
        }
      }
    }
  } catch (err) {
    let message;
    if (err instanceof Error) message = err.message;
    else message = String(err);
    setTitle("Error");
    setSubTitle(message);
    setResultPanel(false);
  }
};
