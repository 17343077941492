import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { Box, BoxProps, GridProps } from "@mui/system";
import { theme } from "ui/theme/theme";

export const Spotlight = styled(Grid)<GridProps>(() => ({
  "& h1": {
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem"
    },
  },
  "& h3": {
    [theme.breakpoints.down("md")]: {
      fontSize: "1.1rem"
    },
  },
}));

export const CTA = styled(Box)<BoxProps>(() => ({
  display: "flex",
  gap: "1rem",
  [theme.breakpoints.down("md")]: {
    marginBottom: "1rem"
  },
}));

export const HeroImage = styled.img``;
