import CollectionTabs from "../components/CollectionTabs";
import { Container, TitleContainer } from "./styles";
import { SectionHeader } from "Components/Section/SectionHeader";

function DiscoverPage() {
  return (
    <Container>
      <TitleContainer>
        <SectionHeader title="Discover" />
      </TitleContainer>

      <CollectionTabs />
    </Container>
  );
}

export default DiscoverPage;
