import { FC } from "react";
import { SVGProps } from "interfaces";

export const ErrorIcon: FC<SVGProps> = ({
  width = "18",
  height = "18",
  color = "#9CFFF8",
  type,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {type === "duotone" ? (
      <>
        <path
          d="M2.60176 7.35355C4.01781 5.60013 5.54958 4.06408 7.2974 2.64501C8.36016 1.78216 9.63435 1.78784 10.6981 2.64501C12.4548 4.0606 13.9832 5.59232 15.3965 7.35355C16.2102 8.36752 16.2157 9.63806 15.3965 10.6511C13.9656 12.4206 12.4164 13.9686 10.6456 15.3988C9.64948 16.2031 8.37943 16.1977 7.38241 15.3988C5.59114 13.9633 4.03723 12.4094 2.60176 10.6182C1.80213 9.62045 1.7967 8.3504 2.60176 7.35355Z"
          fill="white"
          stroke="#020915"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00586 6.03516V8.99368"
          stroke="#020915"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00586 11.6582V11.9541"
          stroke="#020915"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M2.60176 7.35355C4.01781 5.60013 5.54958 4.06408 7.2974 2.64501C8.36016 1.78216 9.63435 1.78784 10.6981 2.64501C12.4548 4.0606 13.9832 5.59232 15.3965 7.35355C16.2102 8.36752 16.2157 9.63806 15.3965 10.6511C13.9656 12.4206 12.4164 13.9686 10.6456 15.3988C9.64948 16.2031 8.37943 16.1977 7.38241 15.3988C5.59114 13.9633 4.03723 12.4094 2.60176 10.6182C1.80213 9.62045 1.7967 8.3504 2.60176 7.35355Z"
          fill={color}
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00391 6.03516V8.99368"
          stroke="#020915"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00391 11.6575V11.9533"
          stroke="#020915"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
