import { FC } from "react";
import { SVGProps } from "interfaces";

export const Calendar: FC<SVGProps> = ({
  width = "40",
  height = "40",
  color = "#9CFFF8",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5801 2.14285V9.4"
      stroke={color}
      strokeWidth="2.7"
      strokeLinecap="round"
    />
    <path
      d="M25.4004 2.14285V9.4"
      stroke={color}
      strokeWidth="2.7"
      strokeLinecap="round"
    />
    <path
      d="M2.1204 30.0714C2.5804 34.1857 5.92326 37.4486 10.0575 37.6486C12.9147 37.7857 15.8318 37.8572 19.0004 37.8572C22.169 37.8572 25.0861 37.7857 27.9433 37.6486C32.0775 37.4486 35.4204 34.1829 35.8804 30.0714C36.1918 27.3 36.4461 24.4629 36.4461 21.5686C36.4461 18.6772 36.189 15.8372 35.8804 13.0686C35.4204 8.9543 32.0775 5.69144 27.9433 5.49144C25.0861 5.3543 22.169 5.28287 19.0004 5.28287C15.8318 5.28287 12.9147 5.3543 10.0575 5.49144C5.92326 5.69144 2.5804 8.95715 2.1204 13.0686C1.80897 15.84 1.55469 18.6772 1.55469 21.5686C1.55469 24.4629 1.81183 27.3029 2.1204 30.0714V30.0714Z"
      stroke={color}
      strokeWidth="2.7"
    />
    <path
      d="M10.8207 17.5886C10.6313 17.5886 10.4496 17.5133 10.3157 17.3794C10.1817 17.2454 10.1064 17.0637 10.1064 16.8743C10.1064 16.6848 10.1817 16.5032 10.3157 16.3692C10.4496 16.2353 10.6313 16.16 10.8207 16.16"
      stroke={color}
      strokeWidth="2.7"
    />
    <path
      d="M10.8203 17.5886C11.0098 17.5886 11.1914 17.5133 11.3254 17.3794C11.4593 17.2454 11.5346 17.0637 11.5346 16.8743C11.5346 16.6848 11.4593 16.5032 11.3254 16.3692C11.1914 16.2353 11.0098 16.16 10.8203 16.16"
      stroke={color}
      strokeWidth="2.7"
    />
    <path
      d="M10.8207 26.92C10.6313 26.92 10.4496 26.8447 10.3157 26.7108C10.1817 26.5768 10.1064 26.3951 10.1064 26.2057C10.1064 26.0163 10.1817 25.8346 10.3157 25.7006C10.4496 25.5667 10.6313 25.4914 10.8207 25.4914"
      stroke={color}
      strokeWidth="2.7"
    />
    <path
      d="M10.8203 26.92C11.0098 26.92 11.1914 26.8447 11.3254 26.7108C11.4593 26.5768 11.5346 26.3951 11.5346 26.2057C11.5346 26.0163 11.4593 25.8346 11.3254 25.7006C11.1914 25.5667 11.0098 25.4914 10.8203 25.4914"
      stroke={color}
      strokeWidth="2.7"
    />
    <path
      d="M19.0004 17.5886C18.811 17.5886 18.6293 17.5133 18.4953 17.3794C18.3614 17.2454 18.2861 17.0637 18.2861 16.8743C18.2861 16.6848 18.3614 16.5032 18.4953 16.3692C18.6293 16.2353 18.811 16.16 19.0004 16.16"
      stroke={color}
      strokeWidth="2.7"
    />
    <path
      d="M19 17.5886C19.1894 17.5886 19.3711 17.5133 19.5051 17.3794C19.639 17.2454 19.7143 17.0637 19.7143 16.8743C19.7143 16.6848 19.639 16.5032 19.5051 16.3692C19.3711 16.2353 19.1894 16.16 19 16.16"
      stroke={color}
      strokeWidth="2.7"
    />
    <path
      d="M27.1801 17.5886C26.9907 17.5886 26.809 17.5133 26.675 17.3794C26.5411 17.2454 26.4658 17.0637 26.4658 16.8743C26.4658 16.6848 26.5411 16.5032 26.675 16.3692C26.809 16.2353 26.9907 16.16 27.1801 16.16"
      stroke={color}
      strokeWidth="2.7"
    />
    <path
      d="M27.1797 17.5886C27.3691 17.5886 27.5508 17.5133 27.6848 17.3794C27.8187 17.2454 27.894 17.0637 27.894 16.8743C27.894 16.6848 27.8187 16.5032 27.6848 16.3692C27.5508 16.2353 27.3691 16.16 27.1797 16.16"
      stroke={color}
      strokeWidth="2.7"
    />
  </svg>
);
