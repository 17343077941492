import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { SystemColorKey } from "ui/theme/models/Colors";
import { DefaultThemeColorKey } from "ui/theme/models/DefaultThemeColors";
import { theme } from "ui/theme/theme";

export enum StyledCircularButtonSize {
  //   Small = 0,
  //   Medium = 1,
  Large = 2,
}
export enum StyledCircularButtonType {
  Outlined = 1,
  Solid = 2,
}

export enum StyledStandardButtonColor {
  Teal = SystemColorKey.Teal,
  Navy = SystemColorKey.Navy,
  Blue = SystemColorKey.Blue,
  DarkWhite = SystemColorKey.DarkWhite,
  Lilac = SystemColorKey.Lilac,
  Grey = SystemColorKey.Grey,
  White = SystemColorKey.White,
  Black = SystemColorKey.DarkWhite,
}
type StyledStandardButtonProps = {
  $size?: StyledCircularButtonSize;
  $color?: StyledStandardButtonColor;
  $type: StyledCircularButtonType;
};

const largeMixin = css`
  width: 181px;
`;

const CircularButtonSizeMixinMap: Record<StyledCircularButtonSize, any> = {
  [StyledCircularButtonSize.Large]: largeMixin,
};

export const ButtonContainer = styled.button<StyledStandardButtonProps>`
  display: flex;
  box-shadow: none;
  text-transform: none;
  font-size: 16;
  color: ${() => theme.colors.black};
  background-color: ${({ $color }) => {
    if ($color) {
      return theme.colors[
        ($color as unknown as DefaultThemeColorKey | undefined) ||
          SystemColorKey.Black
      ];
    }

    return theme.colors[SystemColorKey.Black];
  }};
  color: ${({ $color }) => {
    if (
      $color === StyledStandardButtonColor.Navy ||
      $color === StyledStandardButtonColor.DarkWhite
    ) {
      return theme.colors.white;
    }

    return theme.colors.black;
  }};

  border: none;
  padding: 6px 8px;
  align-items: center;
  border-radius: 35px;
  cursor: pointer;
  justify-content: center;
  line-height: 1.75;
  font-size: 16px;

  min-width: 64px;
  &:hover {
    border-radius: 4px;
  }
  border-radius: 35px;

  ${({ $size = StyledCircularButtonSize.Large }) =>
    CircularButtonSizeMixinMap[$size]}

  > svg {
    margin-right: 8px;
    margin-left: -4px;
  }

  min-width: 64px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-radius 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;
