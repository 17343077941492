import { HomeCollections } from "api";
import { CarouselCardSlideProps } from "Components/Cards/CarouselCard";
import { theme } from "ui/theme/theme";
import * as Icons from "assets/icons";

export function getHeroItems(
  { new_collections, spotlighted_collection, top_collections }: HomeCollections,
  limitPer = 1,
  shuffle = true
) {
  const unique = new Map<string, CarouselCardSlideProps>();

  function routine(items: Array<CarouselCardSlideProps>, limit = 1) {
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      if (!unique.has(item.collection.contract_id)) {
        unique.set(item.collection.contract_id, item);
        limit -= 1;
      }

      if (!limit) {
        break;
      }
    }
  }

  routine(
    spotlighted_collection.map((collection) => ({
      collection,
      label: "Spotlight",
      Icon: (
        <Icons.Bolt
          color={theme.colors.black}
          secondColor={theme.colors.teal}
          width="15.18"
          height="13.7"
        />
      ),
    })),
    limitPer
  );

  routine(
    new_collections.map((collection) => ({
      collection,
      label: "New",
      Icon: (
        <Icons.Fire
          color={theme.colors.black}
          secondColor={theme.colors.teal}
          width="15.18"
          height="13.7"
        />
      ),
    })),
    limitPer
  );

  routine(
    top_collections.map((collection) => ({
      collection,
      label: "Top",
      Icon: (
        <Icons.Heart
          color={theme.colors.black}
          secondColor={theme.colors.teal}
          width="15.18"
          height="13.7"
        />
      ),
    })),
    limitPer
  );

  const values = Array.from(unique.entries()).map((value) =>
    value.pop()
  ) as Array<CarouselCardSlideProps>;
  return !shuffle
    ? values
    : (values.sort(() => Math.random() - 0.5) as Array<CarouselCardSlideProps>);
}
