import styled from "@emotion/styled";
import Button, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import { MouseEventHandler, ReactNode } from "react";
import { theme } from "ui/theme/theme";

export interface ButtonProps extends MuiButtonProps {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  size?: "medium" | "large";
  color?: "primary" | "secondary";
  fullWidth?: boolean;
};

export const SolidButton = styled(Button)<ButtonProps>(
  ({ color = "primary", size = "medium" }) => ({
    borderRadius: "35px",
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    color: theme.colors.black,
    backgroundColor:
      color === "primary" ? theme.colors.teal : theme.colors.white,
    border: "none",
    cursor: "pointer",
    width: size === "large" ? "200px" : "181px",
    transition: `border-radius 1s`,

    "&:hover": {
      borderRadius: "5px",
      backgroundColor:
        color === "primary" ? theme.colors.teal : theme.colors.white,
    },
  })
);

export const OutlinedButton = styled(Button)<ButtonProps>(
  ({ color = "primary", size = "medium" }) => ({
    borderRadius: "35px",
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    color: color === "primary" ? theme.colors.teal : theme.colors.white,
    backgroundColor: "transparent",
    border: `1px solid  ${
      color === "primary" ? theme.colors.teal : theme.colors.white
    }`,
    cursor: "pointer",
    width: size === "large" ? "200px" : "181px",
    "span > svg > path": {
      stroke: color === "primary" ? theme.colors.teal : theme.colors.white,
    },
    transition: `background-color 1s, color 1s`,

    "&:hover": {
      backgroundColor:
        color === "primary" ? theme.colors.teal : theme.colors.white,
      "span > svg > path": {
        stroke: theme.colors.black,
      },
      color: theme.colors.black,
    },
  })
);

export const TranslucentButton = styled(Button)<ButtonProps>(
  ({ color = "primary", size = "medium", style }) => ({
    borderRadius: "35px",
    boxShadow: "none",
    textTransform: "none",
    fontSize: style?.fontSize || "1rem",
    color: theme.colors.white,
    backgroundColor: `rgba(255, 255, 255, 0.1)`,
    border: `none`,
    cursor: "pointer",
    width: style?.width || (size === "large" ? "200px" : "181px"),
    transition: `background-color 1s, color 1s`,

    "span > svg > path": {
      stroke: theme.colors.white,
    },
    "&:hover": {
      backgroundColor:
        color === "primary" ? theme.colors.teal : theme.colors.white,
      "span > svg > path": {
        stroke: theme.colors.black,
      },
      color: theme.colors.black,
    },
  })
);

const buttonSizes = {
  medium: "160px",
  large: "194px",
};

export const TranslucentRegularButton = styled(Button)<
  ButtonProps & { filter?: boolean }
>(({ color = "primary", size = "medium", filter, fullWidth }) => ({
  boxShadow: "none",
  textTransform: "none",
  fontSize: filter ? "14px" : "16.18px",
  lineHeight: filter ? "16.8px" : "19.42px",
  color: theme.colors.white,
  height: "57px",
  border: `none`,
  borderRadius: "0px",
  cursor: "pointer",
  width: fullWidth ? "100%" : buttonSizes[size],
  fontWeight: 600,
  transition: `background-color 1s`,

  "span > svg > path": {
    stroke: theme.colors.white,
  },
  "svg > path": {
    stroke: theme.colors.white,
  },
  "&:hover": {
    backgroundColor:
      color === "primary" ? theme.colors.teal : theme.colors.white,
    "span > svg > path": {
      stroke: theme.colors.black,
    },
    "span > svg": {
      color: theme.colors.black,
    },
    "svg > path": {
      stroke: theme.colors.black,
    },
    color: theme.colors.black,
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: filter ? "12px" : "14px",
    lineHeight: filter ? "12.5px" : "16.8px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "15.9px",
    lineHeight: "120%",
  },
}));
