import { FC } from "react";
import { SVGProps } from "interfaces";

export const AllCollection: FC<SVGProps> = ({
  width = "40",
  height = "40",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.55506 19.682C2.73787 22.3129 4.11216 24.429 5.81999 24.7221C7.08853 24.9399 8.39099 25.1269 9.71893 25.1269C11.0469 25.1269 12.3493 24.9399 13.6178 24.7221C15.3257 24.429 16.7 22.3129 16.8828 19.682C17.0177 17.7397 17.1317 15.7457 17.1317 13.7128C17.1317 11.6799 17.0177 9.68594 16.8828 7.74371C16.7 5.11274 15.3257 2.99666 13.6178 2.70354C12.3493 2.48582 11.0469 2.29883 9.71893 2.29883C8.39099 2.29883 7.08853 2.48582 5.81999 2.70354C4.11216 2.99666 2.73787 5.11274 2.55506 7.74371C2.42011 9.68594 2.30615 11.6799 2.30615 13.7128C2.30615 15.7457 2.42011 17.7397 2.55506 19.682Z"
      stroke={color}
      strokeWidth="2.7"
    />
    <path
      d="M37.5077 20.4214C37.3251 17.7905 35.9508 15.6744 34.2428 15.3813C32.9743 15.1636 31.672 14.9766 30.344 14.9766C29.016 14.9766 27.7135 15.1636 26.445 15.3813C24.7372 15.6744 23.3629 17.7905 23.1801 20.4214C23.0451 22.3637 22.9312 24.3577 22.9312 26.3906C22.9312 28.4235 23.0451 30.4174 23.1801 32.3597C23.3629 34.9905 24.7372 37.1068 26.445 37.4C27.7135 37.6177 29.016 37.8045 30.344 37.8045C31.672 37.8045 32.9743 37.6177 34.2428 37.4C35.9508 37.1068 37.3251 34.9905 37.5077 32.3597C37.6428 30.4174 37.7568 28.4235 37.7568 26.3906C37.7568 24.3577 37.6428 22.3637 37.5077 20.4214Z"
      stroke={color}
      strokeWidth="2.7"
    />
    <path
      d="M2.55506 35.9959C2.73787 36.8705 4.11216 37.5739 5.81999 37.6714C7.08853 37.7439 8.39099 37.8059 9.71893 37.8059C11.0469 37.8059 12.3493 37.7439 13.6178 37.6714C15.3257 37.5739 16.7 36.8705 16.8828 35.9959C17.0177 35.3502 17.1317 34.6871 17.1317 34.0114C17.1317 33.3354 17.0177 32.6725 16.8828 32.0268C16.7 31.1522 15.3257 30.4488 13.6178 30.3514C12.3493 30.2788 11.0469 30.2168 9.71893 30.2168C8.39099 30.2168 7.08853 30.2788 5.81999 30.3514C4.11216 30.4488 2.73787 31.1522 2.55506 32.0268C2.42011 32.6725 2.30615 33.3354 2.30615 34.0114C2.30615 34.6871 2.42011 35.3502 2.55506 35.9959Z"
      stroke={color}
      strokeWidth="2.7"
    />
    <path
      d="M37.5077 4.109C37.3251 3.23431 35.9508 2.53083 34.2428 2.43338C32.9743 2.36099 31.672 2.29883 30.344 2.29883C29.016 2.29883 27.7135 2.36099 26.445 2.43337C24.7372 2.53082 23.3629 3.23431 23.1801 4.109C23.0451 4.75471 22.9312 5.41763 22.9312 6.09346C22.9312 6.76931 23.0451 7.43223 23.1801 8.07794C23.3629 8.9526 24.7372 9.65611 26.445 9.75357C27.7135 9.82594 29.016 9.88811 30.344 9.88811C31.672 9.88811 32.9743 9.82594 34.2428 9.75357C35.9508 9.65611 37.3251 8.9526 37.5077 8.07794C37.6428 7.43223 37.7568 6.76931 37.7568 6.09346C37.7568 5.41763 37.6428 4.75471 37.5077 4.109Z"
      stroke={color}
      strokeWidth="2.7"
    />
  </svg>
);
