import { Checkbox } from "@mui/material";
import { Box } from "@mui/system";

import { FC, useEffect, useRef, useState } from "react";
import * as S from "./styles";

export interface FilterMenuProps {
  options?: Array<string>;
  label?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  multiple?: boolean;
  prefix?: string;
  suffix?: string;
  transparent?: boolean;
  allowAll?: boolean;
  onChange?: (options: Array<string>) => void;
}

export const FilterMenu: FC<FilterMenuProps> = ({
  options = [],
  label,
  startIcon,
  endIcon,
  onChange,
  multiple,
  transparent,
  prefix = "",
  suffix = "",
  allowAll
}) => {
  const [selected, setSelected] = useState(new Array<string>());
  const [title, setTitle] = useState(label || selected[0]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const anchorRef =
    useRef<HTMLButtonElement>() as React.MutableRefObject<HTMLButtonElement>;

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (anchorRef.current) {
      setAnchorEl(anchorRef.current);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateActive = (key: string) => {
    let newSelected = [...selected];
    if (key) {
      if (selected.includes(key)) {
        newSelected = selected.filter((item) => item !== key);
      } else if (multiple) {
        newSelected.push(key);
      } else {
        newSelected = [key];
      }
    } else {
      newSelected = [];
    }

    switch(newSelected.length) {
      case 0:
        setTitle(label || "All");
        break;
      case 1:
        setTitle(newSelected[0]);
        break;
      default:
        setTitle(`${newSelected.length} selected`);
    }

    if (!multiple) {
      handleClose()
    }

    setSelected(newSelected);

    if (onChange) {
      onChange(newSelected);
    }
  };

  useEffect(() => {
    if (!multiple && !label) {
      if (!selected.length) {
        setSelected([options[0]]);
        setTitle(options[0]);
      }
    }
  }, [multiple, label, options, selected.length]);

  return (
    <Box>
      <S.FilterButton
        className={`${anchorEl ? "active" : undefined} ${
          transparent ? "transparent" : undefined
        }`}
        ref={anchorRef}
        onClick={handleClick}
        startIcon={startIcon}
        endIcon={endIcon}
      >
        {prefix}
        {(title || "").replace("_", " ")}
        {suffix}
      </S.FilterButton>

      <S.Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {allowAll && Boolean(selected.length) && !multiple && (<S.MenuItem>
          <S.Label
            onChange={() => updateActive('')}
            className="single"
            control={
              <Checkbox
                sx={{ display: multiple ? undefined : "none" }}
                value="all"
                checked={selected.includes('all')}
              />
            }
            label="All"
          />
        </S.MenuItem>)}
        {options.map((key) => (
          <S.MenuItem onChange={() => updateActive(key)} key={key}>
            <S.Label
              className={`${!multiple ? "single" : undefined}`}
              control={
                <Checkbox
                  sx={{ display: multiple ? undefined : "none" }}
                  value={key}
                  checked={selected.includes(key)}
                />
              }
              label={`${prefix}${(key || "").replace("_", " ")}${suffix}`}
            />
          </S.MenuItem>
        ))}
      </S.Menu>
    </Box>
  );
};
