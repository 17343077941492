import { useState, ComponentType } from "react";
import { ButtonProps, Typography } from "@mui/material";

import { useNearWallet } from "hooks/useNear";
import { truncate } from "ui/utils/truncate";
import WalletMenu from "../WalletMenu";

import { ButtonLabelContainer, IconContainer } from "../styles";
import { theme } from "ui/theme/theme";
import { DropdownIcon } from "assets/icons/Dropdown";
import { Wallet } from "assets/icons/Wallet";

interface WalletContainerProps {
  Button: ComponentType<ButtonProps>;
  menuwidth?: string;
  withIcon?: boolean;
}

function WalletContainer({
  Button,
  menuwidth = "232px",
  withIcon = true,
}: WalletContainerProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { login, isLoggedIn, accountId } = useNearWallet();
  const buttonLabel = isLoggedIn ? accountId : `Connect Wallet`;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoggedIn) {
      setAnchorEl(event.currentTarget);
    } else {
      login();
    }
  };

  return (
    <>
      <Button onClick={handleClick}>
        {isLoggedIn ? (
          <Typography sx={{ fontWeight: 600, marginRight: "4px" }}>
            Wallet:
          </Typography>
        ) : (
          withIcon && (
            <IconContainer sx={{ marginRight: "8px" }}>
              <Wallet />
            </IconContainer>
          )
        )}
        <ButtonLabelContainer>{truncate(buttonLabel, 17)}</ButtonLabelContainer>
        {isLoggedIn && (
          <IconContainer sx={{ marginLeft: "8px" }}>
            {anchorEl ? (
              <DropdownIcon
                color={theme.colors.black}
                style={{ transform: `rotate(180deg)` }}
              />
            ) : (
              <DropdownIcon color={theme.colors.black} />
            )}
          </IconContainer>
        )}
      </Button>
      <WalletMenu {...{ anchorEl, setAnchorEl, menuwidth }} />
    </>
  );
}

export default WalletContainer;
