import Menu, { MenuProps } from "@mui/material/Menu";
import { styled } from "@mui/material";

export const MenuContainer = styled(Menu)<MenuProps>(() => ({
  "& .MuiPaper-root": {
    marginTop: "9px",
  },
  "& .MuiMenu-list": {
    padding: "20px 0",
  },
  "& .MuiMenuItem-root": {
    display: "flex",
    alignItem: "center",
    height: "30px",
    padding: "0 20px",
    fontSize: "14px",
  },
}));
