import { styled, Button, ButtonProps, Stack, StackProps } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { theme } from "ui/theme/theme";

export const ListItemContainer = styled(Box)<BoxProps>(() => ({
  backgroundColor: "rgba(255, 255, 255, 0.07)",
  borderRadius: "10px",
  display: "flex",
  minHeight: "152px",
  padding: "24px",
  minWidth: "29.125rem",
  "@media (max-width: 450px)": {
    minWidth: "14rem",
    padding: "16px",
    minHeight: "112px",
  },
}));
export const StackTextContainer = styled(Stack)<StackProps>(() => ({
  marginLeft: "24px",
  "@media (max-width: 450px)": {
    marginRight: "16px",
    marginLeft: "16px",
    width: "calc(100% - 72px)",
  },
}));

export const Title = styled(Typography)<TypographyProps>(() => ({
  color: theme.colors.white,
  fontWeight: 400,
  fontSize: "2rem",
  lineHeight: "120%",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  marginBottom: "8px",
  "@media (max-width: 450px)": {
    fontSize: "1.5rem",
    marginBottom: "4px",
  },
}));

export const Subtitle = styled(Typography)<TypographyProps>(() => ({
  color: theme.colors.grey,
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "120%",
  opacity: 0.5,
  "@media (max-width: 450px)": {
    fontSize: "0.813rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export const RegularButton = styled(Button)<ButtonProps>(
  ({ color = "primary" }) => ({
    borderRadius: "35px",
    boxShadow: "none",
    textTransform: "none",
    padding: "15px 29px",
    fontSize: "1rem",
    color: theme.colors.white,
    backgroundColor: `rgba(255, 255, 255, 0.07)`,
    border: `none`,
    cursor: "pointer",
    width: "48px",
    height: "48px",
    marginRight: "24px",
    "span > svg > path": {
      stroke: theme.colors.white,
    },
    "&:hover": {
      transition: `background-color 1s`,
      backgroundColor:
        color === "primary" ? theme.colors.teal : theme.colors.white,
      "span > svg > path": {
        stroke: theme.colors.black,
      },
      color: theme.colors.black,
    },
    "@media (max-width: 450px)": {
      width: "40px",
      height: "40px",
      padding: "14px 16px",
      marginRight: "16px",
      minWidth: "40px",
    },
  })
);

export const ImgContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
  height: "104px",
  width: "104px",

  "@media (max-width: 450px)": {
    width: "80px",
    height: "80px",
  },
}));

export const ItemContainer = styled(Stack)<StackProps>(() => ({
  marginTop: "4.5px",
  marginBottom: "4.5px",
  width: "100%",
  "@media (max-width: 450px)": {
    width: "calc(100% - 80px)",
  },
}));

export const DetailsContainer = styled(Box)<BoxProps>(() => ({
  marginRight: "14px",
  marginLeft: "0px",
  "@media (max-width: 450px)": {
    marginRight: 0,
  },
}));
