import useMarketplace from "../../../hooks/marketPlace";

import * as S from "./styles";
import * as Icons from "assets/icons";

import { TokenOffer } from "api";
import { numberFormat } from "utils/number-format";
import { Box } from "@mui/system";
import { theme } from "ui/theme/theme";
import { useHideReceivedOffers } from "hooks/hiddenOffers";

export interface CardProps extends TokenOffer {
  isOwner?: boolean;
  floorPrice?: string;
}

export const OfferReceivedSlimCard = ({
  user_id,
  contract_id,
  token_id,
  price = "12.34",
  isOwner,
  floorPrice,
}: CardProps) => {
  const marketPlace = useMarketplace();
  const hiddenOffers = useHideReceivedOffers();
  const isLowOffer = Number(floorPrice) * 0.8 > Number(price);

  async function acceptOffer() {
    try {
      await marketPlace.acceptOffer(token_id, contract_id, user_id, price);
    } catch (err) {
      let message;
      if (err instanceof Error) message = err.message;
      else message = String(err);
      return message;
    }
  }

  function hideOffer() {
    hiddenOffers.hideOffer(contract_id, token_id, price, user_id);
  }

  return (
    <S.StyledCard>
      <S.ContentZone>
        <Box marginRight="1rem">
          <S.CardActionTitleBox>
            {isOwner ? "You r" : "R"}eceived an offer of{" "}
            <b>{numberFormat(Number(price), 2)} NEAR</b>
          </S.CardActionTitleBox>

          <span>
            Offered by{" "}
            <span style={{ color: theme.colors.teal }}>{user_id}</span>
          </span>

          {isLowOffer && (
            <S.CardActionInfoText>
              Offer price is less than 80% of floor price
            </S.CardActionInfoText>
          )}
        </Box>

        {isOwner && (
          <S.CardAction>
            <S.CardActionButton color="primary" onClick={() => acceptOffer()}>
              <Icons.Tick />
              <S.CardActionText>Accept</S.CardActionText>
            </S.CardActionButton>

            <S.CardActionButton color="secondary" onClick={() => hideOffer()}>
              <Icons.Cross />
              <S.CardActionText>Reject</S.CardActionText>
            </S.CardActionButton>
          </S.CardAction>
        )}
      </S.ContentZone>
    </S.StyledCard>
  );
};
