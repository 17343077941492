import { useCollection } from "api";
import { Verified } from "assets/icons";
import { RELATIVE_ROUTES } from "constants/routing";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./NftTokenCollection.styles";

type NftTokenCollectionProps = {
  collectionName: string;
};

const NftTokenCollection: FC<NftTokenCollectionProps> = ({
  collectionName,
}) => {
  const { data: collection } = useCollection(collectionName);
  const navigate = useNavigate();
  return (
    <S.NftTokenCollectionSection>
      <S.NftTokenCollectionLabel>Collection</S.NftTokenCollectionLabel>

      <S.CollectionContainer>
        <img
          src={collection?.profile_picture_url}
          alt="avatar"
          style={{
            borderRadius: "2px",
            width: 32,
            height: 32,
          }}
        />
        <S.CollectionText
          onClick={() =>
            navigate(`${RELATIVE_ROUTES.COLLECTIONS}/${collectionName}`)
          }
        >
          <span>{collection?.collection_name}</span>
          <Verified type="duotone" />
        </S.CollectionText>
      </S.CollectionContainer>
    </S.NftTokenCollectionSection>
  );
};

export default NftTokenCollection;
