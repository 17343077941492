import { FC } from "react";
import { SVGProps } from "interfaces";

export const Heart: FC<SVGProps> = ({
  width = "40",
  height = "40",
  color = "#020915",
  secondColor = "#FFF",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill={secondColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 9.09427C11.3714 -1.49144 2.26285 6.03141 2.14285 14.14C2.14285 26.2057 16.5857 36.1143 20 36.1143C23.4143 36.1143 37.8571 26.2057 37.8571 14.1371C37.7371 6.02856 28.6286 -1.49144 20 9.09427Z"
      stroke={color}
      strokeWidth="2.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99995 15.48C7.99995 14.7604 8.14169 14.0478 8.41709 13.3829C8.69249 12.718 9.09614 12.1139 9.60501 11.6051C10.1139 11.0962 10.718 10.6925 11.3828 10.4172C12.0477 10.1418 12.7603 10 13.48 10"
      stroke={color}
      strokeWidth="2.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
