import * as React from "react";
import Drawer from "@mui/material/Drawer";
import MainPanel from "./MainPanel";
import { MainBoxContainer } from "./styles";
import { theme } from "ui/theme/theme";
import SuccessPanel from "Components/SuccessPanel";
import ButtonItem from "Components/ButtonItem";
import { Token } from "api";
import { useNearWallet } from "hooks/useNear";
import { useEnabledFeatures } from "hooks/useFeatures";

type ButtonType = "buy" | "list" | "offer";
interface BuyPanelDrawerProps {
  panelMainTitle: string;
  panelInputTitle: string;
  panelInputPlaceHolder: string;
  panelButtonText: string;
  buttonText: string;
  Icon: React.ReactElement;
  isToken?: boolean;
  buttonType: ButtonType;
  token: Token;
}

export function BuyPanelDrawer({
  panelMainTitle,
  panelInputTitle,
  panelInputPlaceHolder,
  panelButtonText,
  buttonText,
  Icon,
  isToken,
  buttonType,
  token,
}: BuyPanelDrawerProps) {
  const features = useEnabledFeatures();
  const [state, setState] = React.useState(false);
  const [resultPanel, setResultPanel] = React.useState(true);
  const [title, setTitle] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [iconName, setIconName] = React.useState("");
  const { isLoggedIn } = useNearWallet();

  const handleOpen = () => setState(true);
  const handleClose = () => setState(false);

  const list = () => (
    <MainBoxContainer role="presentation">
      {resultPanel ? (
        <MainPanel
          onClose={handleClose}
          setResultPanel={setResultPanel}
          panelMainTitle={panelMainTitle}
          panelInputTitle={panelInputTitle}
          panelInputPlaceHolder={
            features.buy.cart ? panelInputPlaceHolder : null
          }
          buttonText={buttonText}
          Icon={Icon}
          token={token}
          setTitle={setTitle}
          setSubTitle={setSubTitle}
          setIconName={setIconName}
          isLoggedIn={isLoggedIn}
        />
      ) : (
        <SuccessPanel
          onClose={handleClose}
          setResultPanel={setResultPanel}
          title={title}
          subtitle={subTitle}
          iconName={iconName}
        />
      )}
    </MainBoxContainer>
  );

  return (
    <>
      <ButtonItem
        panelButtonText={panelButtonText}
        buttonType={buttonType}
        Icon={Icon}
        handleOpen={handleOpen}
        isToken={isToken}
      />
      <Drawer
        anchor="right"
        open={state}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: theme.colors.black,
          },
        }}
      >
        {list()}
      </Drawer>
    </>
  );
}

export default BuyPanelDrawer;
