/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from "react";
import Skeleton from "@mui/material/Skeleton";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

type Props = {
  loading: boolean;
  children: React.ReactNode;
  variant?: "circular" | "rectangular" | "text";
  width?: number | string;
  height?: number | string;
  animation?: "pulse" | "wave" | false;
  sx?: SxProps<Theme>;
  style?: React.CSSProperties;
};

export const SkeletonWrapper: FC<Props> = ({
  loading,
  variant = "rectangular",
  width,
  height,
  children,
  sx = { bgcolor: "grey.900" },
  animation = "pulse",
  style,
}) =>
  loading ? (
    <Skeleton
      animation={animation}
      sx={sx}
      variant={variant}
      width={width}
      height={height}
      style={style}
    />
  ) : (
    <>{children}</>
  );
