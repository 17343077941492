import { styled } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";

export const Container = styled(Box)<BoxProps>(({ theme }) => ({
  padding: "41px 120px 145px",
  margin: "auto",
  maxWidth: "1728px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "28px",
    paddingRight: "28px",
  },
}));

export const TitleContainer = styled(Box)<BoxProps>(({ theme }) => ({
  minHeight: 151,
  paddingTop: "38px",
  [theme.breakpoints.down("md")]: {
    minHeight: "auto",
    paddingTop: 0,
  },
}));
