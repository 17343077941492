import { FC } from "react";
import { SVGProps } from "interfaces";

export const Filter: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
  secondColor = "white",
  type,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {type === "duotone" ? (
      <>
        <path
          d="M1.5 4.99219H16.5"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.97305 7.59062C7.40899 7.59062 8.57306 6.42657 8.57306 4.99063C8.57306 3.55468 7.40899 2.39062 5.97305 2.39062C4.5371 2.39062 3.37305 3.55468 3.37305 4.99063C3.37305 6.42657 4.5371 7.59062 5.97305 7.59062Z"
          fill={secondColor}
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5 12.9922H16.5"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.0771 15.5887C12.513 15.5887 13.6771 14.4246 13.6771 12.9887C13.6771 11.5527 12.513 10.3887 11.0771 10.3887C9.64111 10.3887 8.47705 11.5527 8.47705 12.9887C8.47705 14.4246 9.64111 15.5887 11.0771 15.5887Z"
          fill={secondColor}
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M3 5.60156H18"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.47305 8.2C8.90899 8.2 10.0731 7.03594 10.0731 5.6C10.0731 4.16406 8.90899 3 7.47305 3C6.0371 3 4.87305 4.16406 4.87305 5.6C4.87305 7.03594 6.0371 8.2 7.47305 8.2Z"
          fill="#9CFFF8"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 13.6016H18"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5766 16.198C14.0125 16.198 15.1766 15.034 15.1766 13.598C15.1766 12.1621 14.0125 10.998 12.5766 10.998C11.1406 10.998 9.97656 12.1621 9.97656 13.598C9.97656 15.034 11.1406 16.198 12.5766 16.198Z"
          fill="#9CFFF8"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
