import { CircularProgress } from "@mui/material";
import { Collection } from "api";
import { useEffect, useState } from "react";
import { CollectionCreatorName } from "Components/Collection/Creator/Name";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCreative, Keyboard } from "swiper";
import { CarouselCardSlide } from "./components/CarouselCardSlide";

import "swiper/swiper.min.css";
import "swiper/modules/effect-creative/effect-creative";
import * as S from "./styles";

export interface CarouselCardSlideProps {
  collection: Collection;
  label?: string;
  Icon: React.ReactElement;
}

export interface CarouselCardProps {
  items: Array<CarouselCardSlideProps>;
  isLoading?: boolean;
  delay?: number;
}

export const CarouselCard: React.FC<CarouselCardProps> = ({
  items = [],
  isLoading = true,
  delay = 5000,
}) => {
  const [active, setActive] = useState<CarouselCardSlideProps>(items[0]);

  useEffect(() => {
    setActive(items[0]);
  }, [items]);

  return (
    <S.Card>
      {isLoading ? (
        <CircularProgress sx={{ margin: "auto" }} />
      ) : (
        <>
          <Swiper
            loop
            grabCursor
            keyboard
            effect="creative"
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
            modules={[EffectCreative, Autoplay, Keyboard]}
            autoplay={{
              delay,
              disableOnInteraction: true,
            }}
            style={{ aspectRatio: "1/1" }}
            onInit={() => {
              setActive(() => items[1]);
            }}
            onSlideChange={(e) => {
              const activeIndex = e.realIndex;
              setActive(() => items[activeIndex]);
            }}
            initialSlide={1}
          >
            {items.map(({ collection, label, Icon }) => (
              <SwiperSlide key={collection.contract_id}>
                <CarouselCardSlide
                  label={label}
                  Icon={Icon}
                  collection={collection}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <S.AuthorPaper square elevation={0}>
            {active && (
              <CollectionCreatorName
                avatar={active.collection.profile_picture_url}
              />
            )}
          </S.AuthorPaper>
        </>
      )}
    </S.Card>
  );
};
