import { utils } from "near-api-js";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { AcovaService } from "./service";
import * as Models from "./models";
import { useMarketplace } from "hooks/marketPlace";
import { MarketDataJson } from "hooks/useNear";

export const DEFAULT_PAGE_SIZE = 24;

const getNextPageParam = (lastPage: any[], allPages: any[]) => {
  if (lastPage.length < DEFAULT_PAGE_SIZE) {
    return undefined;
  }
  return DEFAULT_PAGE_SIZE * allPages.length;
};

export function useHome() {
  return useQuery(["home", "collections"], () =>
    AcovaService.get.home.collections()
  );
}

export function useHomeLabels() {
  return useQuery(["home", "labels"], () => AcovaService.get.home.labels(), {
    initialData: [],
  });
}

export function useCollections(
  requestConfig?: Models.RequestConfig,
  enabled = true,
  refetchOnWindowFocus = true
) {
  return useQuery(
    ["collections", requestConfig],
    ({ queryKey }) => AcovaService.get.collections(queryKey),
    { enabled, refetchOnWindowFocus }
  );
}

export function useCollection(collectionId: string) {
  return useQuery(["collection", collectionId], ({ queryKey }) =>
    AcovaService.get.collection.item(queryKey)
  );
}

export function useCollectionActivity(
  collectionId: string,
  requestConfig?: Models.CollectionActivityRequestConfig
) {
  return useInfiniteQuery(
    ["collection.activity", collectionId, requestConfig],
    ({ queryKey, pageParam }) =>
      AcovaService.get.collection.activity(queryKey, pageParam),
    { refetchOnWindowFocus: false, getNextPageParam }
  );
}

const useMarketDataEnricher = () => {
  const marketplace = useMarketplace();

  const enrichTokenWithMarketData = (
    token: Models.Token,
    marketData: MarketDataJson | null
  ): Models.Token => ({
    ...token,
    listed_price:
      marketData && token.owner === marketData.owner_id
        ? utils.format.formatNearAmount(marketData.price || "0")
        : null,
  });

  const enrichTokenPromise = async (
    tokenPromise: Promise<Models.Token>
  ): Promise<Models.Token> => {
    const token = await tokenPromise;
    const marketData = await marketplace.getMarketData(
      token.contract_id,
      token.token_id
    );
    return enrichTokenWithMarketData(token, marketData);
  };

  const enrichToken = async (token: Models.Token): Promise<Models.Token> => {
    const marketData = await marketplace.getMarketData(
      token.contract_id,
      token.token_id
    );
    return enrichTokenWithMarketData(token, marketData);
  };

  const enrichTokenPromises = async (
    tokenPromises: Promise<Array<Models.Token>>
  ): Promise<Array<Models.Token>> => {
    const tokens = await tokenPromises;
    const marketData = await Promise.all(
      tokens.map((token) =>
        marketplace.getMarketData(token.contract_id, token.token_id)
      )
    );

    return tokens.map((token, i) =>
      enrichTokenWithMarketData(token, marketData[i])
    );
  };

  const enrichTokens = async (
    tokens: Array<Models.Token>
  ): Promise<Array<Models.Token>> => {
    const marketData = await Promise.all(
      tokens.map((token) =>
        marketplace.getMarketData(token.contract_id, token.token_id)
      )
    );

    return tokens.map((token, i) =>
      enrichTokenWithMarketData(token, marketData[i])
    );
  };

  return {
    enrichToken,
    enrichTokens,
    enrichTokenPromise,
    enrichTokenPromises,
  };
};

export function useCollectionTokens({
  collectionId,
  requestConfig = {
    order_by: "recently_listed",
    limit: DEFAULT_PAGE_SIZE,
  },
}: {
  collectionId: string;
  requestConfig?: Models.CollectionTokensRequestConfig;
}) {
  const enricher = useMarketDataEnricher();
  return useQuery(
    ["collection.tokens", collectionId, requestConfig],
    ({ queryKey }) =>
      enricher.enrichTokenPromises(
        AcovaService.get.collection.tokens(queryKey)
      ),
    { refetchOnWindowFocus: false }
  );
}

export function useInfiniteCollectionTokens({
  collectionId,
  requestConfig = {
    order_by: "recently_listed",
    limit: DEFAULT_PAGE_SIZE,
  },
  enabled = true,
}: {
  collectionId: string;
  requestConfig?: Models.CollectionTokensRequestConfig;
  enabled?: boolean;
}) {
  const enricher = useMarketDataEnricher();
  return useInfiniteQuery(
    ["collection.tokens", collectionId, requestConfig],
    ({ queryKey, pageParam }) =>
      enricher.enrichTokenPromises(
        AcovaService.get.collection.tokens(queryKey, pageParam)
      ),
    {
      refetchOnWindowFocus: false,
      getNextPageParam,
      enabled,
    }
  );
}

export function useCollectionToken(collectionId: string, tokenId: string) {
  const enricher = useMarketDataEnricher();
  return useQuery(["collection.token", collectionId, tokenId], ({ queryKey }) =>
    enricher.enrichTokenPromise(
      AcovaService.get.collection.token.item(queryKey)
    )
  );
}

export function useCollectionTokenActivity(
  collectionId: string,
  tokenId: string
) {
  return useQuery(
    ["collection.token.activity", collectionId, tokenId],
    ({ queryKey }) => AcovaService.get.collection.token.activity(queryKey)
  );
}

export function useCollectionTokenOffers(
  collectionId: string,
  tokenId: string
) {
  return useQuery(
    ["collection.token.offers", collectionId, tokenId],
    ({ queryKey }) => AcovaService.get.collection.token.offers(queryKey)
  );
}

export function useProfile(accountId: string) {
  const enricher = useMarketDataEnricher();

  return useQuery(["profile", accountId], async ({ queryKey }) => {
    const profile = await AcovaService.get.profile(queryKey);

    return {
      ...profile,
      supported_user_tokens: await enricher.enrichTokens(
        profile?.supported_user_tokens
      ),
    };
  });
}
