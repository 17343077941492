import { FC, ReactNode } from "react";
import * as S from "./CircularButton.style";

type CircularButtonProps = {
  Icon: ReactNode;
  size?: S.StyledCircularButtonSize;
  color?: S.StyledCircularButtonColor;
  type?: S.StyledCircularButtonType;
  onClick?: () => void;
};

const CircularButton: FC<CircularButtonProps> = ({
  Icon,
  onClick,
  size = S.StyledCircularButtonSize.Large,
  type = S.StyledCircularButtonType.Solid,
  color = S.StyledCircularButtonColor.Black,
}) => (
  <S.ButtonContainer $size={size} $type={type} $color={color} onClick={onClick}>
    {Icon}
  </S.ButtonContainer>
);

export default CircularButton;
