import styled from "@emotion/styled";
import { Verified } from "assets/icons/Verified";
import { FC } from "react";
import { theme } from "ui/theme/theme";

type Props = {
  children: React.ReactNode;
  isVerified?: boolean;
  avatar: string;
  widthCreator?: string | number;
  heightCreator?: string | number;
  transparent?: boolean;
};

const StyledTag = styled.div<{ $transparent?: boolean }>(($transparent) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "35px",
  padding: "5px",
  fontWeight: 600,
  fontSize: 16,
  gap: 7,
  backgroundColor: $transparent ? "transparent" : theme.colors.white,
  color: $transparent ? theme.colors.white : theme.colors.black,
  ">span": {
    fontWeight: 400,
    fontSize: 14,
  },
  img: {
    objectFit: "cover",
  },
}));

export const CreatorName: FC<Props> = ({
  children,
  isVerified,
  avatar,
  widthCreator = 22,
  heightCreator = 22,
  transparent,
}) => (
  <StyledTag $transparent={transparent}>
    <img
      src={avatar}
      alt="avatar"
      style={{
        borderRadius: "40px",
        width: widthCreator,
        height: heightCreator,
      }}
    />
    <span>{children}</span>
    {isVerified && <Verified type={transparent ? "duotone" : "filled"} />}
  </StyledTag>
);
