import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { Box, BoxProps } from "@mui/system";
import { theme } from "ui/theme/theme";

export const NftTokenFullScreenImageWrapper = styled(Box)<BoxProps>(() => ({
  aspectRatio: "1",
          margin: "auto",
          position: "relative"
}))

export const NftTokenFullScreenImage = styled.img`
  max-width: 100vmin;
  width: 100%;
`;

export const NtfTokenImageContainer = styled.div`
  aspect-ratio: 1;
  position: sticky;
  top: 8rem;
`;

export const NftFullScreenCloseImage = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 50px;
  height: 48px;
  right: 24px;
  top: 24px;
  cursor: pointer;
  background: rgba(2, 9, 21, 0.1);
  border-radius: 35px;
  border: none;

  &:hover {
    background: rgba(2, 9, 21, 0.2);
  }
`;
export const NftTokenImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
`;
export const FullScreenButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 50px;
  height: 48px;
  right: 24px;
  bottom: 24px;
  cursor: pointer;
  background: rgba(2, 9, 21, 0.1);
  border-radius: 35px;
  border: none;

  &:hover {
    background: rgba(2, 9, 21, 0.2);
  }
`;

export const NftTokenDetails = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: "10px"
})

export const NftTokenDetailsTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NftTokenName = styled(Typography)({
  fontWeight: "400",
  fontSize: "3.75rem",
  lineHeight: "120%",
  color: theme.colors.white,
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem"
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "2.5rem"
  }
})

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const NftTokenPriceContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "0.3125rem",
  fontSize: "1.625rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.25rem"
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "1.45rem"
  }
})

export const NftTokenPriceLabel = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 120%;
  color: ${() => theme.colors.white};
`;

export const NftTokenPrice = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: inherit;
  line-height: 120%;
  color: ${() => theme.colors.teal};
`;

export const NftTokenCreatorCollectionContainer = styled.div`
  display: flex;
  margin-top: 22px;
  margin-bottom: 34px;
  gap: 26px;
`;

export const NtfTokenTabsContainer = styled(Box)({
  marginBottom: "2rem",
  overflow: "auto",
  borderBottom: "1px solid #4e535b",
  [theme.breakpoints.down("sm")]: {
    order: "100",
    borderBottom: 0
  },
})

export const NtfTokenCTAButtons = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  gap: "1.312rem",
  button: {
    width: "100%",
    flex: "1 1 10rem",
    fontWeight: "bold",
    padding: "0.875rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
      padding: "0.625rem",
    },
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: "2rem"
  },
})