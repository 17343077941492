import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import Avatar, { AvatarProps } from "@mui/material/Avatar";
import Chip, { ChipProps } from "@mui/material/Chip";

export const IconContainer = styled(Box)<BoxProps & { size?: string }>(
  ({ size = "18px" }) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    height: size,
    width: size,
  })
);

export const Tag = styled(Chip)<ChipProps>(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.15)",
  color: theme.colors.white,
  fontSize: "9px",
  height: "25px",
  "&:not(:last-child)": {
    marginRight: "8px",
  },
  "& .MuiChip-label": {
    lineHeight: "normal",
  },
}));

export const CollectionsAvatar = styled(Avatar)<AvatarProps>(({ theme }) => ({
  border: `3px solid ${theme.colors.black}`,
  height: 160,
  left: 120,
  position: "absolute",
  top: "-110px",
  width: 160,
  [theme.breakpoints.down("md")]: {
    border: `2px solid ${theme.colors.black}`,
    height: 66,
    left: 38.79,
    top: -35,
    width: 66,
  },
}));

export const DetailContainer = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: `${theme.colors.black}`,
  display: "flex",
  minHeight: 377,
  padding: "75px 120px 57px",
  position: "relative",
  width: "100%",
  maxWidth: "1573px",
  gap: "20px",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.down("md")]: {
    padding: "42px 28px 40px",
  },
}));

export const TitleContainer = styled(Box)<BoxProps>(() => ({
  display: "flex",
  alignItems: "center",
}));

export const Title = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: `${theme.colors.white}`,
  fontSize: "60px",
  lineHeight: "72px",
  marginRight: "8px",
  [theme.breakpoints.down("md")]: {
    fontSize: "40px",
    lineHeight: "120%",
  },
}));

export const CreatedBy = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: "24px",
  color: `${theme.colors.white}`,
  marginRight: "8px",
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    lineHeight: "120%",
  },
}));

export const CreatorContainer = styled(Box)<BoxProps>(({ theme }) => ({
  alignItems: "center",
  alignSelf: "center",
  backgroundColor: theme.colors.white,
  borderRadius: "20px",
  display: "flex",
  height: "30px",
  padding: "8px",
}));

export const CreatorText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.black,
  fontSize: "11px",
  marginRight: "3px",
  lineHeight: "normal",
}));

export const DetailText = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: theme.colors.white,
  lineHeight: "20.8px",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
    lineHeight: "130%",
  },
}));

export const ReadMoreText = styled("span")(({ theme }) => ({
  cursor: "pointer",
  color: theme.colors.teal,
  fontWeight: 600,
  marginLeft: "4px",
  display: "inline",
}));

export const TagContainer = styled(Box)<BoxProps>(() => ({
  alignItems: "center",
  display: "flex",
  height: "25px",
  marginTop: "16px",
}));

export const Container = styled(Box)<BoxProps>(() => ({
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const ImageContainer = styled(Box)<BoxProps>(({ theme }) => ({
  height: 265,
  width: "100%",
  [theme.breakpoints.down("md")]: {
    height: 104,
    padding: "0 28px",
  },
}));

export const Image = styled("img")(({ theme }) => ({
  height: 265,
  objectFit: "cover",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    borderRadius: "5px",
    height: 104,
  },
}));
