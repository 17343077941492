import { BoxProps, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FC } from "react";
import * as S from "./styles";

export interface SectionTitleProps extends BoxProps {
  title: string;
  subtitle?: string;
}

export const SectionTitle: FC<SectionTitleProps> = ({
  title,
  subtitle,
  children,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <S.SectionTitle>
      <S.SectionTitleText>
        {title}
        {!isMobile && children}
      </S.SectionTitleText>
      {!!subtitle && <S.SectionSubTitleText>{subtitle}</S.SectionSubTitleText>}
      {isMobile && children}
    </S.SectionTitle>
  );
};
