import { Token, Offer } from "api";

export function groupByCategory(grouped: Token[]): [string, Token[]][] {
  const groupedByCategory = new Map();
  grouped.forEach((item) => {
    const key = item.contract_id;
    const collection = groupedByCategory.get(key);
    if (!collection) {
      groupedByCategory.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(groupedByCategory);
}

export function groupByCollection(offers: Offer[]): [string, Offer[]][] {
  const groupedByCollection = new Map();
  offers.forEach((item) => {
    const key = item.collection_name;
    const collection = groupedByCollection.get(key);
    if (!collection) {
      groupedByCollection.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(groupedByCollection);
}
