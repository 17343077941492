import { FC } from "react";
import { SVGProps } from "interfaces";

export const Share: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8101 11.0277C16.8101 12.0203 16.7409 12.7713 16.6481 13.4797C16.4369 15.0926 15.1092 16.3908 13.4923 16.5702C12.3248 16.6997 11.1265 16.81 9.90502 16.81C8.68358 16.81 7.48526 16.6997 6.31767 16.5702C4.70092 16.3908 3.39934 15.0891 3.22711 13.4716C3.10349 12.3106 3 11.1193 3 9.90502C3 8.69074 3.10349 7.4994 3.22711 6.33849C3.39934 4.72095 4.69737 3.39341 6.30894 3.17226C7.02396 3.07414 7.7809 3 8.7823 3"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
    />
    <path
      d="M9.53809 10.3273L16.6407 3.22461"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6433 6.93222C16.7902 5.45282 16.7903 4.64026 16.6433 3.22546C15.2285 3.07845 14.4159 3.07853 12.9365 3.22546"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
