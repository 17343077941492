/* eslint-disable no-nested-ternary */
import { FC } from "react";
import { SVGProps } from "interfaces";

export const Wallet: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
  type,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {type === "duotone" ? (
      <>
        <path
          d="M5.99854 4.99915L11.334 1.62731C12.0429 1.19945 12.9664 1.48529 13.3095 2.23884L14.9985 5.99915"
          fill="white"
        />
        <path
          d="M3.98584 6.06202L11.3331 1.62731C12.042 1.19945 12.9654 1.48529 13.3086 2.23884L15.5109 7.07477"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
        <path
          d="M2.20788 15.6004C2.43981 16.9174 3.49311 17.9757 4.81532 18.1764C6.12821 18.3755 7.31375 18.5711 9.13455 18.5711C10.9554 18.5711 12.1409 18.3755 13.4538 18.1764C14.776 17.9758 15.8293 16.9174 16.0612 15.6004C16.2402 14.5841 16.4033 13.7016 16.4033 11.9945C16.4033 10.2874 16.2402 9.40497 16.0612 8.38878C15.8293 7.0717 14.776 6.01335 13.4538 5.81275C12.1409 5.61355 10.9554 5.41797 9.13455 5.41797C7.31375 5.41797 6.12821 5.61355 4.81532 5.81275C3.49311 6.01335 2.43981 7.0717 2.20788 8.38878C2.02892 9.40497 1.86572 10.2874 1.86572 11.9945C1.86572 13.7016 2.02892 14.5841 2.20788 15.6004Z"
          stroke={color}
          strokeWidth="1.7"
        />
        <path
          d="M16.7054 14.0013H12.5959C11.4489 14.0013 10.519 13.0715 10.519 11.9245C10.519 10.7775 11.4489 9.84766 12.5959 9.84766H16.7054C17.4944 9.84766 18.134 10.4873 18.134 11.2762V12.5727C18.134 13.3617 17.4944 14.0013 16.7054 14.0013Z"
          fill="white"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
      </>
    ) : type === "filled" ? (
      <>
        <path
          d="M3.98584 6.06202L11.3331 1.62731C12.042 1.19945 12.9654 1.48529 13.3086 2.23884L15.5109 7.07477"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
        <path
          d="M2.20788 15.6004C2.43981 16.9174 3.49311 17.9757 4.81532 18.1764C6.12821 18.3755 7.31375 18.5711 9.13455 18.5711C10.9554 18.5711 12.1409 18.3755 13.4538 18.1764C14.776 17.9758 15.8293 16.9174 16.0612 15.6004C16.2402 14.5841 16.4033 13.7016 16.4033 11.9945C16.4033 10.2874 16.2402 9.40497 16.0612 8.38878C15.8293 7.0717 14.776 6.01335 13.4538 5.81275C12.1409 5.61355 10.9554 5.41797 9.13455 5.41797C7.31375 5.41797 6.12821 5.61355 4.81532 5.81275C3.49311 6.01335 2.43981 7.0717 2.20788 8.38878C2.02892 9.40497 1.86572 10.2874 1.86572 11.9945C1.86572 13.7016 2.02892 14.5841 2.20788 15.6004Z"
          stroke={color}
          strokeWidth="1.7"
        />
        <path
          d="M16.7054 14.0013H12.5959C11.4489 14.0013 10.519 13.0715 10.519 11.9245C10.519 10.7775 11.4489 9.84766 12.5959 9.84766H16.7054C17.4944 9.84766 18.134 10.4873 18.134 11.2762V12.5727C18.134 13.3617 17.4944 14.0013 16.7054 14.0013Z"
          fill={color}
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
      </>
    ) : (
      <>
        <path
          d="M3.98828 6.06398L11.3355 1.62926C12.0444 1.2014 12.9678 1.48725 13.311 2.24079L15.5134 7.07672"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
        <path
          d="M2.20934 15.6023C2.44127 16.9193 3.49457 17.9776 4.81679 18.1783C6.12967 18.3775 7.31522 18.573 9.13602 18.573C10.9568 18.573 12.1424 18.3775 13.4552 18.1783C14.7775 17.9778 15.8308 16.9193 16.0626 15.6023C16.2416 14.586 16.4048 13.7036 16.4048 11.9965C16.4048 10.2894 16.2416 9.40692 16.0626 8.39074C15.8308 7.07365 14.7775 6.01531 13.4552 5.81471C12.1424 5.61551 10.9568 5.41992 9.13602 5.41992C7.31522 5.41992 6.12967 5.61551 4.81679 5.81471C3.49457 6.01531 2.44127 7.07365 2.20934 8.39074C2.03039 9.40692 1.86719 10.2894 1.86719 11.9965C1.86719 13.7036 2.03039 14.586 2.20934 15.6023Z"
          stroke={color}
          strokeWidth="1.7"
        />
        <path
          d="M16.7059 14.0032H12.5963C11.4494 14.0032 10.5195 13.0734 10.5195 11.9264C10.5195 10.7794 11.4494 9.84961 12.5963 9.84961H16.7059C17.4949 9.84961 18.1345 10.4892 18.1345 11.2782V12.5747C18.1345 13.3636 17.4949 14.0032 16.7059 14.0032Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
        />
      </>
    )}
  </svg>
);
