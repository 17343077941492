import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "ui/theme/fonts.css";

import { GlobalStyles } from "ui/theme/global-style";
import { Global } from "@emotion/react";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "ui/theme/theme";
import RootRoutes from "routing/routes/RootRoutes";
import Layout from "ui/components/Layout";

function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const App: React.FC = () => {
  useScrollToTop();
  return (
    <ThemeProvider theme={theme}>
      <Global styles={GlobalStyles} />
      <Layout>
        <RootRoutes/>
      </Layout>
    </ThemeProvider>
  );
};

export default App;
