import Card from "@mui/material/Card";
import styled from "@emotion/styled";
import { theme } from "ui/theme/theme";

export const NewCard = styled(Card)(() => ({
  borderRadius: "0.5rem",
  border: "1px solid rgba(255, 255, 255, 0.15)",
  backgroundColor: theme.colors.black,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    boxShadow: `10px 10px 5px 0px rgba(156,255,248,0.75)`,
  },
}));
