import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import * as S from "./styles";

import { theme } from "ui/theme/theme";
import { CollectionVerified } from "Components/Collection/Verified";
import { CollectionPrice } from "Components/Collection/Price";
import { Avatar } from "@mui/material";
import { Collection } from "api";
import { useNavigate } from "react-router-dom";

interface SimpleCardProps extends Collection {
  index?: number;
}

export const SimpleCard = ({
  index = 1,
  collection_name,
  contract_id,
  profile_picture_url,
  floor_price,
}: SimpleCardProps) => {
  const navigate = useNavigate();

  return (
    <S.NewCardSimple
      id={contract_id}
      onClick={() => navigate(`/collections/${contract_id}`)}
    >
      <Box sx={{ display: "flex" }} component="div">
        <span>{index}</span>
      </Box>
      <Avatar
        sx={{
          width: "2.75rem",
          height: "auto",
          marginRight: "0.2rem",
          aspectRatio: "1",
        }}
        src={profile_picture_url}
        alt={collection_name}
      />
      <Box sx={{ display: "flex", flexDirection: "row", flex: "1" }}>
        <CardContent sx={{ padding: "0 !important", width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "row", ml: "0.2rem" }}>
            <CollectionVerified
              fontSize="1.1rem"
              verified
              name={collection_name}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", ml: "0.2rem" }}>
            <CollectionPrice color={theme.colors.teal} price={floor_price} />
          </Box>
        </CardContent>
      </Box>
    </S.NewCardSimple>
  );
};
