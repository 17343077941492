import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

import { theme } from "ui/theme/theme";
import {
  MenuButton,
  BpIcon,
  BpCheckedIcon,
  FormLabel,
  CheckboxContainer,
  Popover,
} from "./styles";
import { DropdownIcon } from "assets/icons/Dropdown";

function BpCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

function FilterMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filters, setFilters] = useState([
    {
      label: "All",
      selected: true,
    },
    {
      label: "Tokens",
      selected: false,
    },
    {
      label: "Collections",
      selected: false,
    },
    {
      label: "Artists",
      selected: false,
    },
    {
      label: "Categories",
      selected: false,
    },
    {
      label: "Art style",
      selected: false,
    },
  ]);

  const open = Boolean(anchorEl);
  const selectedAll =
    filters.find((filter) => filter.label === "All")?.selected || false;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterLabel: string
  ) => {
    const changeValue = event.target.checked;
    if (filterLabel === "All") {
      setFilters(
        filters.map((filter) => ({ ...filter, selected: changeValue }))
      );
    } else {
      const changeFilter = filters.find(
        (filter) => filter.label === filterLabel
      );
      changeFilter!.selected = changeValue;

      if (changeValue === false) {
        const allFilter = filters.find((filter) => filter.label === "All");
        if (allFilter?.selected) {
          allFilter.selected = false;
        }
      } else if (
        filters
          .filter((item) => item.label !== "All")
          .every((item) => item.selected)
      ) {
        const allFilter = filters.find((filter) => filter.label === "All");
        allFilter!.selected = true;
      }

      const newFilters = [...filters];
      setFilters(newFilters);
    }
  };

  return (
    <div>
      <MenuButton
        id="positioned-MenuButton"
        aria-controls={open ? "positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        selectedAll={selectedAll}
        onClick={handleClick}
        endIcon={
          <DropdownIcon
            color={selectedAll ? theme.colors.black : theme.colors.white}
            style={{ transform: open ? "rotate(-180deg)" : "none" }}
          />
        }
      >
        All
      </MenuButton>
      <Popover
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CheckboxContainer>
          <FormGroup>
            {filters.map((filter) => (
              <FormLabel
                key={filter.label}
                control={
                  <BpCheckbox
                    checked={filter.selected}
                    onChange={(e) => handleCheck(e, filter.label)}
                  />
                }
                label={filter.label}
              />
            ))}
          </FormGroup>
        </CheckboxContainer>
      </Popover>
    </div>
  );
}

export default FilterMenu;
