import { FC } from "react";
import { SVGProps } from "interfaces";

export const PageNotFoundAvatar: FC<SVGProps> = ({
  width = "166",
  height = "158",
  style,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 166 158"
    style={style}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M97.3825 51.0572C80.3352 37.642 55.7412 39.214 39.3207 49.239C11.0207 66.5175 7.34957 109.65 27.5637 129.067C49.8527 150.478 80.2747 145.318 95.4353 131.621C115.086 113.867 123.484 84.637 106.57 62.8644"
      stroke="white"
      strokeWidth="4.5"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.2418 79.0553C51.7998 81.7802 51.6171 84.5839 51.5469 87.3431"
      stroke="white"
      strokeWidth="4.5"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.6202 78.2895C76.6395 81.5789 76.8439 85.0114 76.5156 88.2982"
      stroke="white"
      strokeWidth="4.5"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M83.3884 116.051C82.3825 113.045 80.3937 110.485 78.0297 108.425C66.4339 98.319 46.2547 102.968 39.2891 116.479"
      stroke="white"
      strokeWidth="4.5"
      strokeMiterlimit="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_1310_31115)">
      <path
        d="M131.602 41.1182C130.722 37.7723 130.431 37.0936 132.257 33.8676C135.471 28.1914 142.145 27.1513 140.056 19.1832C137.907 10.9853 128.858 8.74334 121.562 13.6746C115.015 18.0996 115.601 27.7244 120.509 31.033"
        stroke="#9CFFF8"
        strokeWidth="4.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.589 53.4583C135.043 53.6517 134.527 53.9316 134.166 54.3998C133.891 54.7557 133.714 55.16 133.627 55.6012C133.563 55.9257 133.508 56.2802 133.584 56.6078C134.302 59.7137 139.001 56.2316 136.549 54.5131"
        stroke="#9CFFF8"
        strokeWidth="4.5"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1310_31115">
        <rect
          width="56.3969"
          height="56.3969"
          fill="white"
          transform="translate(97.2812 14.1129) rotate(-14.4919)"
        />
      </clipPath>
    </defs>
  </svg>
);
