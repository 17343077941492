import { useState, MouseEvent } from "react";
import IconButton from "@mui/material/IconButton";
import { MenuItem } from "@mui/material";

import { IconContainer } from "../../styles";
import { theme } from "ui/theme/theme";
import { PopoverContainer, PopoverText, MenuContainer } from "./styles";

import { Copy } from "assets/icons/Copy";
import { Email } from "assets/icons/Email";
import { Facebook } from "assets/icons/Facebook";
import { Share } from "assets/icons/Share";
import { Telegram } from "assets/icons/Telegram";
import { Twitter } from "assets/icons/Twitter";

function ShareMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
    setAnchorMenuEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenuEl(null);
  };

  const open = Boolean(anchorEl);
  const openMenu = Boolean(anchorMenuEl);
  return (
    <>
      <IconButton
        sx={{ height: 32, width: 32 }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handleClick}
      >
        <IconContainer>
          <Share height={18} width={18} color={theme.colors.white} />
        </IconContainer>
      </IconButton>
      <PopoverContainer
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <PopoverText>Share collection</PopoverText>
      </PopoverContainer>
      <MenuContainer
        anchorEl={anchorMenuEl}
        open={openMenu}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <IconContainer sx={{ marginRight: "10px" }}>
            <Twitter height={18} width={18} />
          </IconContainer>
          Share to Twitter
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <IconContainer sx={{ marginRight: "10px" }}>
            <Facebook />
          </IconContainer>
          Share to Facebook
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <IconContainer sx={{ marginRight: "10px" }}>
            <Telegram />
          </IconContainer>
          Share via Telegram
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <IconContainer sx={{ marginRight: "10px" }}>
            <Email />
          </IconContainer>
          Email
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <IconContainer sx={{ marginRight: "10px" }}>
            <Copy height={18} width={18} />
          </IconContainer>
          Copy link
        </MenuItem>
      </MenuContainer>
    </>
  );
}

export default ShareMenu;
