import { css } from "@emotion/react";

export const GlobalStyles = css`
  html {
    * {
      box-sizing: border-box;
    }

    body {
      margin: 0;
      padding: 0;
      color: white;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    a {
      text-decoration: none;
      cursor: pointer;
    }

    h1 {
      font-size: 3.5rem;
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    a,
    button,
    p,
    span {
      font-family: "Roobert" !important;
      font-weight: 400;
      line-height: 1.2;
    }
  }
`;
