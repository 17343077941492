import { Box, BoxProps } from "@mui/system";
import { FC } from "react";

export interface AcovaTabProps extends BoxProps {
  label: string;
}

export const AcovaTab: FC<AcovaTabProps> = ({ children, label, ...rest }) => (
  <Box data-label={label} className="acova-tab" {...rest}>
    {children}
  </Box>
);
