import { FC } from "react";
import { SVGProps } from "interfaces";

export const Refresh: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.719 11.457C15.2893 12.6748 14.5088 13.7381 13.4756 14.5129C12.4426 15.2877 11.2033 15.7394 9.9139 15.8109C8.72201 15.8112 7.55895 15.4445 6.58268 14.7607C5.60641 14.077 4.86425 13.1093 4.45703 11.9892"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.10938 8.55506C4.9221 6.32974 7.41833 4.20117 9.91456 4.20117C11.1122 4.20454 12.2796 4.57828 13.2565 5.27114C14.2335 5.96401 14.9722 6.94209 15.3715 8.0713"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3964 13.5917C16.2111 12.7153 16.063 12.2486 15.7206 11.4629C14.8812 11.6364 14.4146 11.7846 13.5918 12.1387"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.60059 6.38867C3.72059 7.27639 3.83376 7.75274 4.11708 8.56165C4.96695 8.4507 5.44326 8.33743 6.29005 8.04516"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
