import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";

export const BlackBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.07)",
  borderRadius: "10px",
  color: theme.colors.white,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  padding: "17px 24px",
  height: "89px",
  [theme.breakpoints.up("xl")]: {
    minWidth: "176px",
  },
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
    padding: "12px 14px",
    height: "72px",
  },
}));

export const TealButton = styled(Button)<ButtonProps>(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.teal,
  borderRadius: 35,
  color: theme.colors.black,
  display: "flex",
  fontWeight: 600,
  fontSize: 16,
  height: 50,
  marginTop: "26px",
  textTransform: "none",
  width: "260px",
  "&:hover": {
    backgroundColor: theme.colors.teal,
  },
  [theme.breakpoints.down("md")]: {
    height: "40px",
    marginTop: "20px",
    width: "100%",
  },
}));

export const SubTitleText = styled(Typography)<TypographyProps>(() => ({
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "16.8px",
  marginTop: "5px",
}));

export const MiniNumberBoxContainer = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.04)",
  borderRadius: "10px",
  color: theme.colors.white,
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  height: "45px",
  justifyContent: "space-between",
  minWidth: "170px",
  padding: "11px 16px 15px",
  [theme.breakpoints.down("md")]: {
    padding: "13px 14px 15px",
    minWidth: "auto",
  },
}));

export const RightContainerBox = styled(Box)<BoxProps>(() => ({
  flex: 1.5,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
}));

export const NumberBoxesContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  flexWrap: "wrap",
  gap: "20px",
  [theme.breakpoints.down("md")]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "12px",
    width: "100%",
  },
}));

export const MiniNumberBoxesContainer = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  marginTop: "24px",
  justifyContent: "flex-end",
  flexWrap: "wrap",
  gap: "20px",
  [theme.breakpoints.down("md")]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "12px",
    width: "100%",
    marginTop: "12px",
  },
}));

export const NumberBoxTitleText = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontSize: "26px",
    lineHeight: "31.2px",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
      lineHeight: "120%",
    },
  })
);

export const MiniNumberBoxTitleText = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    lineHeight: "normal",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "120%",
    },
  })
);
