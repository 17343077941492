import { FC } from "react";
import { SVGProps } from "interfaces";

export const BinIcon: FC<SVGProps> = ({
  width = "18",
  height = "18",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    style={{ color }}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 5.35547H16"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
    />
    <path
      d="M14.0298 5.35547H3.9659L3.89388 6.65764C3.75589 9.15254 3.87313 11.655 4.24379 14.1261C4.40543 15.2037 5.3311 16.0008 6.42073 16.0008H11.575C12.6646 16.0008 13.5903 15.2037 13.7519 14.1261C14.1226 11.655 14.2398 9.15254 14.1018 6.65764L14.0298 5.35547Z"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.20215 5.35464V4.79553C6.20215 4.0541 6.49667 3.34306 7.02095 2.81879C7.54521 2.29453 8.25626 2 8.99768 2C9.7391 2 10.4502 2.29453 10.9744 2.81879C11.4987 3.34306 11.7932 4.0541 11.7932 4.79553V5.35464"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
