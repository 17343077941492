/* eslint-disable no-nested-ternary */
import { FC } from "react";
import { SVGProps } from "interfaces";

export const Discover: FC<SVGProps> = ({
  width = "20",
  height = "20",
  color = "#020915",
  type,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {type === "duotone" ? (
      <>
        <path
          d="M10.0002 18.7504C14.8328 18.7504 18.7503 14.8328 18.7503 10.0002C18.7503 5.1676 14.8328 1.25 10.0002 1.25C5.16761 1.25 1.25 5.1676 1.25 10.0002C1.25 14.8328 5.16761 18.7504 10.0002 18.7504Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.51747 11.4836C9.0713 12.0374 9.5151 13.1863 9.81352 14.1615C9.999 14.7677 10.8311 14.9169 11.1532 14.3709C12.4304 12.2065 13.3604 9.90246 13.4549 7.73421C13.4843 7.0604 12.9406 6.51671 12.2668 6.54606C10.0986 6.64047 7.7946 7.57058 5.6301 8.84776C5.0842 9.16986 5.23349 10.0019 5.83958 10.1874C6.81472 10.4859 7.96365 10.9297 8.51747 11.4836Z"
          fill="white"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : type === "filled" ? (
      <>
        <path
          d="M10.0002 18.7504C14.8328 18.7504 18.7503 14.8328 18.7503 10.0002C18.7503 5.1676 14.8328 1.25 10.0002 1.25C5.16761 1.25 1.25 5.1676 1.25 10.0002C1.25 14.8328 5.16761 18.7504 10.0002 18.7504Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.51747 11.4836C9.0713 12.0374 9.5151 13.1863 9.81352 14.1615C9.999 14.7677 10.8311 14.9169 11.1532 14.3709C12.4304 12.2065 13.3604 9.90246 13.4549 7.73421C13.4843 7.0604 12.9406 6.51671 12.2668 6.54606C10.0986 6.64047 7.7946 7.57058 5.6301 8.84776C5.0842 9.16986 5.23349 10.0019 5.83958 10.1874C6.81472 10.4859 7.96365 10.9297 8.51747 11.4836Z"
          fill={color}
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ) : (
      <>
        <path
          d="M10.0002 18.7504C14.8328 18.7504 18.7503 14.8328 18.7503 10.0002C18.7503 5.1676 14.8328 1.25 10.0002 1.25C5.16761 1.25 1.25 5.1676 1.25 10.0002C1.25 14.8328 5.16761 18.7504 10.0002 18.7504Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.51699 11.4836C9.07082 12.0374 9.51462 13.1863 9.81303 14.1615C9.99852 14.7677 10.8306 14.9169 11.1527 14.3709C12.4299 12.2065 13.3599 9.90246 13.4544 7.73421C13.4838 7.0604 12.9402 6.51671 12.2663 6.54606C10.0982 6.64047 7.79412 7.57058 5.62962 8.84776C5.08372 9.16986 5.233 10.0019 5.83909 10.1874C6.81423 10.4859 7.96316 10.9297 8.51699 11.4836Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    )}
  </svg>
);
