/* eslint-disable no-nested-ternary */
import { FC } from "react";
import { SVGProps } from "interfaces";

export const ExpandIcon: FC<SVGProps> = ({
  width = "18",
  height = "18",
  color = "#020915",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5161 7.48619L14.8773 3.125"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.11816 14.8819L7.48446 10.5156"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.11956 10.8613C2.96019 12.466 2.9601 13.3474 3.11956 14.882C4.65418 15.0414 5.53555 15.0414 7.14023 14.882"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.8806 7.14023C15.0399 5.53554 15.04 4.65417 14.8806 3.11957C13.3459 2.9601 12.4646 2.96019 10.8599 3.11957"
      stroke={color}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
