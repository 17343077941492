import { HomeIcon } from "assets/icons/Home";
import { PageNotFoundAvatar } from "assets/icons/PageNotFoundAvatar";
import { SolidButton } from "Components/Buttons";
import { FC } from "react";
import * as S from "./styles";

type Props = {};

const NotFoundPage: FC<Props> = () => (
    <S.Container>
      <PageNotFoundAvatar />
      <S.PageNotFoundText>
        Uh oh, we can’t seem to find that page.
      </S.PageNotFoundText>
      <SolidButton startIcon={<HomeIcon />} href="/">
        Back to home
      </SolidButton>
    </S.Container>
  );

export default NotFoundPage;
