import { styled, Box, BoxProps } from "@mui/material";
import { theme } from "ui/theme/theme";

export const MainBoxContainer = styled(Box)<BoxProps>(() => ({
  width: "44.125rem",
  backgroundColor: theme.colors.black,
  borderRadius: "20px 0px 0px 20px",
  padding: "129px 120px",
  height: "auto",
  maxHeight: "100vh",
  color: "white",
  "@media (max-width: 500px)": {
    width: "23.438rem",
    padding: "28px",
    height: "812px",
  },
}));

export const CardActionTitleBox = styled(Box)<BoxProps>(() => ({
  fontSize: "80%",
  marginLeft: "0.25rem",
  flex: "1 1 min-content",
}));
