import { SimpleCard } from "Components/Cards/SimpleCard";
import { SectionHeader } from "Components/Section/SectionHeader";
import { Heart } from "assets/icons/Heart";
import useWindowSize from "hooks/useWidthSize";
import { FC, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { GridProps } from "@mui/system";
import { Collection } from "api";
import { CollectionCard } from "Components/Cards/CollectionCard";
import { Section } from "Components/Section";
import { useEnabledFeatures } from "hooks/useFeatures";
import { theme } from "ui/theme/theme";

export interface TopCollectionsProps extends GridProps {
  collections?: Array<Collection>;
  isLoading?: boolean;
}

const TopCollections: FC<TopCollectionsProps> = ({
  collections = [],
  isLoading,
}) => {
  const features = useEnabledFeatures();
  const { width } = useWindowSize();
  const [currentPage, setCurrentPage] = useState<number>(0);

  const itemsPerPage = 5;
  const totalPages = collections.length / itemsPerPage;

  const handleGoBack = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleGoForward = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <Section id="top-collections">
      <Grid container spacing="2rem" marginBottom="2rem">
        <Grid item xs={12}>
          <SectionHeader
            Icon={
              <Heart
                color={theme.colors.white}
                secondColor={theme.colors.black}
              />
            }
            title="Top Collections"
            subtitle="Highly rated collections that deserve the hype."
            dropdownOptions={
              features.home.topCollectionsDropdown
                ? ["Today", "Popular", "New"]
                : undefined
            }
            withPagination={width < 475}
            handleGoBack={handleGoBack}
            handleGoForward={handleGoForward}
          />
        </Grid>
        {isLoading ? (
          <Grid item xs={12} sx={{ display: "flex" }}>
            <CircularProgress sx={{ margin: "0 auto" }} />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={8} lg={6}>
              <Grid container spacing="2rem">
                {collections.slice(0, 2).map((collection, index) => (
                  <Grid item key={collection.contract_id} xs={12} sm={6} md={6}>
                    <CollectionCard {...collection} index={index + 1} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
              <Grid container spacing="2.5625rem">
                {collections.slice(2, 10).map((collection, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={12}
                    lg={6}
                    key={collection.contract_id}
                  >
                    <SimpleCard {...collection} index={index + 3} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Section>
  );
};

export default TopCollections;
