export enum SystemColorKey {
  // Colors
  Teal = "teal",
  White = "white",
  Black = "black",
  Blue = "blue",
  Navy = "navy",
  Lilac = "lilac",
  Grey = "grey",
  DarkWhite = "darkWhite",
}

export type SystemColors = {
  // Colors
  [SystemColorKey.Teal]: string;
  [SystemColorKey.White]: string;
  [SystemColorKey.Black]: string;
  [SystemColorKey.Blue]: string;
  [SystemColorKey.Navy]: string;
  [SystemColorKey.Lilac]: string;
  [SystemColorKey.Grey]: string;
  [SystemColorKey.DarkWhite]: string;
};
