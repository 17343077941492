import { styled, Button, ButtonProps } from "@mui/material";
import { theme } from "ui/theme/theme";
import { SolidButton } from "Components/Buttons";

export const CardSpanIconTypography = styled("span")(() => ({
  margin: "5px",
  display: "flex",
  justifyContent: "center",
}));

export const CardActionButton = styled(SolidButton)<ButtonProps>(() => ({
  width: "auto",
  padding: "0.5rem 0.75rem",
  flex: "1 1 auto",
}));

export const CardActionText = styled("b")(() => ({
  fontSize: "80%",
  marginLeft: "0.25rem",
}));

export const OfferButton = styled(Button)<ButtonProps & { filter?: boolean }>(
  ({ filter }) => ({
    boxShadow: "none",
    textTransform: "none",
    fontSize: filter ? "14px" : "16.18px",
    lineHeight: filter ? "16.8px" : "19.42px",
    color: theme.colors.white,
    height: "57px",
    border: `none`,
    borderRadius: "0px",
    cursor: "pointer",
    width: "100%",
    fontWeight: 600,
    "span > svg > path": {
      stroke: theme.colors.white,
    },
    "&:hover": {
      transition: `background-color 1s`,
      "span > svg > path": {
        stroke: theme.colors.black,
      },
      "span > svg": {
        color: theme.colors.black,
      },
      backgroundColor: theme.colors.white,
      color: theme.colors.black,
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: filter ? "12px" : "14px",
      lineHeight: filter ? "12.5px" : "16.8px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "15.9px",
      lineHeight: "120%",
    },
  })
);
