import {
  useState,
  useMemo,
  useContext,
  createContext,
  FC,
  ReactNode,
} from "react";

interface OfferToHide {
  contract_id?: string;
  token_id?: string;
  price?: string;
  buyer_id?: string;
}

interface HiddenOffersContextValue {
  hideOffer: (
    contract_id: string,
    token_id: string,
    price: string,
    buyer_id: string
  ) => void;
  hiddenOffers: OfferToHide[];
}

export const HiddenOffersContext =
  createContext<HiddenOffersContextValue | null>(null);

type Props = {
  children: ReactNode;
};

export const HiddenOffersContextProvider: FC<Props> = ({ children }) => {
  const key = "hiddenOffers";
  const [hiddenOffers, setHiddenOffers] = useState<OfferToHide[]>(
    JSON.parse(localStorage.getItem(key) || "[]")
  );

  const hideOffer = (
    contract_id: string,
    token_id: string,
    price: string,
    buyer_id: string
  ) => {
    setHiddenOffers((_offers) => {
      const newOffers = [
        ..._offers,
        { contract_id, token_id, price, buyer_id },
      ];
      localStorage.setItem(key, JSON.stringify(newOffers));
      return newOffers;
    });
  };

  const value = useMemo(
    () => ({
      hiddenOffers,
      hideOffer,
    }),
    [hiddenOffers]
  );

  return (
    <HiddenOffersContext.Provider value={value}>
      {children}
    </HiddenOffersContext.Provider>
  );
};

export const useHideReceivedOffers = () => {
  const context = useContext(HiddenOffersContext);
  if (context === null) {
    throw Error("Hidden offers context must be initialised");
  }
  const { hiddenOffers, hideOffer } = context;

  const isOfferHidden = (
    contract_id: string,
    token_id: string,
    price: string,
    buyer_id: string
  ) =>
    hiddenOffers.some(
      (o) =>
        o.contract_id === contract_id &&
        o.token_id === token_id &&
        o.price === price &&
        o.buyer_id === buyer_id
    );

  return {
    hideOffer,
    isOfferHidden,
  };
};
